import styled from 'styled-components';

const DisputeWrapper = styled.div`
  padding-right: 30px;
  .table-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .start {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #1c1c1c;
    }
    .end {
      display: flex;
      .end-1 {
        display: flex;
        background: #f2f2f2;
        border: 0.5px solid #e5e5e5;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        flex-wrap: nowrap;
        width: 108px;
        padding: 4px 10px;
        input {
          background: #f2f2f2;
          border: 0;
          outline: 0;
          width: 60%;
        }
      }
      .end-2 {
        display: flex;
        flex-wrap: nowrap;
        background: #ff6634;
        border: 0.5px solid #ff6634;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #ffffff;
        margin-left: 15px;
        padding: 4px 10px;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
  }
`;

export const ViewMoreContainer = styled.div`
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e5e5e5;
  z-index: 100;
  overflow-y: scroll;
`;
export const ViewMoreTop = styled.div`
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10px;
`;
export default DisputeWrapper;
