import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import ActivityWrapper from './styles';
// import eye from '../../../assets/eye.svg';
import { customBaseUrl, httpGet } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ActivitiesTable from '../../shared/Table/Activitiestable';
// import Delete from '../../../assets/image/delete.svg';
export default function LoginTracker() {
  const [activities, setActivities] = useState([]);
  const [filtDate, setFilterDate] = useState(null);
  const dateFormat = 'YYYY/MM/DD';

  const { userDetails } = useContext(ProfileContext);

  const getLogs = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/history/user-history/${userDetails.userId}`
    );
    if (res?.status) {
      // console.log('>>>', res.data);
      setActivities(res.data);
    }
    hideLoader();
  };

  useEffect(() => {
    getLogs();
  }, []);

  const handleFilter = (date) => {
    //  console.log(date);
    const filtered = activities.filter(
      (el) =>
        new Date(moment(el.loginDate).format(dateFormat)).getTime() >=
          new Date(date[0]).getTime() &&
        new Date(moment(el.loginDate).format(dateFormat)).getTime() <=
          new Date(date[1]).getTime()
      // filtDate &&
      // new Date(moment(el.requestDate).format(dateFormat)) >=
      //   new Date(date[0]) &&
      // new Date(el.requestDate) <= new Date(date[1])
    );
    setActivities(filtered);
    // console.log('filtered', filtered);
  };
  // useEffect(() => {
  //   if (filtDate) {
  //     handleFilter();
  //   }
  // }, [filtDate]);
  return (
    <div>
      <ActivityWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivitiesTable
            columns={[
              { title: 'IP Address', field: 'ip' },
              { title: 'Device', field: 'device' },
              { title: 'Country', field: 'country' },
              { title: 'Date', field: 'loginDate' },
            ]}
            setDate={setFilterDate}
            date={filtDate}
            onChange={handleFilter}
            data={
              activities?.length &&
              activities?.map((item) => {
                return {
                  loginDate: JSON.stringify(
                    new Date(moment(item?.loginDate).format(dateFormat))
                  ),
                  ip: item?.ip ?? 'Unknown',
                  device: item?.device ?? 'Uknown',
                  country: item?.country ?? 'Unknown',
                };
              })
            }
          />
        </div>
      </ActivityWrapper>
    </div>
  );
}
