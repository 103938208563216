import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import Button from '../../shared/Button';
// import { ProfileContext } from '../../../store/context/ProfileContext';

const AddCustomRole = (props) => {
  const { showModal, hideModal } = props;
  // const {
  //   profile: { userId },
  // } = useContext(ProfileContext);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [priv, setPriv] = useState([]);
  const [chipData, setChipData] = useState([]);

  const getPreviledges = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/privileges/all-privileges?page=0&size=20`
    );
    //  console.log('priv000000', res);
    setPriv(res.body.data.privileges);
    if (res.statusCode) {
      // setPriv(res);
      //  console.log('priv11111', priv);
    }
  };

  const handleAddRole = async () => {
    // const Priviledgedata = {
    //   default : true
    //   description: chipdat
    //   name
    // }

    // const arrayOfObj = Object.entries(chipData).map(([key, value]) => ({
    //   id: key,
    //   value,
    // }));
    const data = {
      description,
      name,
      privileges: chipData,
    };

    //  console.log('chipD', chipData);

    const res = await httpPost(
      `/api/v1/admin/manage-user/create-custom-role`,
      data,
      customBaseUrl.authUrl
    );
    if (res) {
      swal('Done', res.message, 'success');
      hideModal();
    } else {
      swal('Oops!', res.message, 'error');
      hideModal();
    }
  };

  useEffect(() => {
    getPreviledges();
  }, []);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalHeader
        className="justify-content-center"
        style={{
          background: '#F1F7FA',
          fontFamily: 'Lucida Bright',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#064A72',
          padding: '30px',
          borderRadius: '14px 14px 0 0 !important',
        }}
      >
        Create Custom Role
      </ModalHeader>
      <ModalBody
        style={{
          padding: '49px 50px 33px 50px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '135.6%',
          color: '#4F4F4F',
        }}
      >
        <div className="my-3 w-100">
          <div className="mb-1">Role Name</div>
          <input
            type="text"
            value={name}
            placeholder="Give this role a name"
            className="w-100"
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="my-3 w-100">
          <div className="mb-1">Role Description</div>
          <input
            type="text"
            value={description}
            placeholder="Give this role a name"
            className="w-100"
            style={{
              background: '#FFFFFF',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
              padding: '15px 12px',
            }}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="my-3 w-100">
          <div className="mb-1">Select Role</div>
          <div>
            <Autocomplete
              multiple
              value={chipData}
              onChange={(e, newValue) => {
                setChipData(newValue);
              }}
              id="multiple-limit-tags"
              options={priv}
              getOptionLabel={(option) => option.description}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select what Roles can do" />
              )}
              sx={{ width: '400px' }}
            />
          </div>
        </div>

        {/* <div className="my-3 w-100 d-flex flex-column">
          <div className="mb-1">Transactions</div>
          <div className="d-flex w-100 align-items-center my-1 ">
            <input
              type="checkbox"
              value={CAN_VIEW_ALL_TRANSACTIONS}
              name=""
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setTransaction(e.target.value);
              }}
            />
            <span>Can View All Transactions</span>
          </div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              name=""
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setTransaction(e.target.value);
              }}
            />
            <span>Can View All Transactions</span>
          </div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              name=""
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setTransaction(e.target.value);
              }}
            />
            <span>Can View All Transactions</span>
            </div>
        </div>
        <div className="my-3 w-100 d-flex flex-column">
          <div className="mb-1">Customers</div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              value={CAN_VIEW_CUSTOMER}
              name=""
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setViewCustomers(e.target.value);
              }}
            />
            <span>Can View Customers</span>
          </div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              name=""
              value={CAN_MANAGE_AND_UPDATE_CUSTOMERS}
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setUpdateCustomers(e.target.value);
              }}
            />
            <span>Can Manage and Update Customers</span>
          </div>
        </div>
        <div className="my-3 w-100 d-flex flex-column">
          <div className="mb-1">Transfer</div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              name=""
              value={CAN_VIEW_TRANSFER}
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setViewTransfer(e.target.value);
              }}
            />
            <span>Can View Transfers</span>
          </div>
          <div className="d-flex w-100 align-items-center my-1">
            <input
              type="checkbox"
              value={CAN_EXPORT_TRANSFER}
              name=""
              id=""
              className=" me-2"
              style={{
                background: '#FFFFFF',
                border: '1px solid #828282',
                borderRadius: '3px',
              }}
              onChange={(e) => {
                setExportTransfer(e.target.value);
              }}
            />
            <span>Can Export Transfers</span>
          </div>
        </div>  */}

        <Button
          content="Add role"
          onClick={handleAddRole}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        />
        <div
          className="text-primary mt-2 text-center"
          onClick={() => {
            hideModal(false);
          }}
        >
          Cancel
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddCustomRole;
