import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListContainer } from './style';
import Button from '../Button';
import placeHolderImage from '../../../assets/image/avatar.png';
import { capFirstWord } from '../../../utils/helper';

export default function List({
  headerText,
  onAction,
  loading,
  list,
  buttonTitle,
  showName = true,
}) {
  const history = useHistory();
  console.log(list);
  return (
    <ListContainer>
      {showName && <div className="header">{headerText}</div>}
      {list?.length ? (
        list.map((item) => (
          <div className="inner" key={Math.random()}>
            <div className="profile-div">
              <div className="image-div">
                <img src={item?.imageUrl || placeHolderImage} alt="default" />
              </div>
              <div
                className="text-div add-cursor"
                onClick={() => {
                  if (headerText === 'Groups')
                    history.push(`/wayagram-group/${item.id}`);
                  if (headerText === 'Pages')
                    history.push(`/wayagram-page/${item.id}`);
                }}
              >
                <span>
                  {capFirstWord(item?.name) || capFirstWord(item?.title)}
                </span>
                <span className="text-muted s-text d-block">
                  {item?.description}
                </span>
              </div>
            </div>
            <div className="action-btn my-auto">
              <Button
                className="btn btn-outline-primary"
                loading={loading}
                onClick={() => onAction(item.userId, item.id)}
                content={buttonTitle}
              />
            </div>
          </div>
        ))
      ) : (
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <Skeleton circle width={50} height={50} />
            <div
              style={{
                display: 'inline-block',
                width: 200,
                marginLeft: '0.5em',
              }}
            >
              <Skeleton />
              <Skeleton />
            </div>
          </div>
          <div className="action-btn">
            <div className="btn">
              <Skeleton height={40} width={40} />
            </div>
          </div>
        </div>
      )}

      <div
        className="show-more"
        onClick={() => {
          history.push({
            pathname: '/wayagram-discover',
            from: 'wayagram',
            state: { activeTab: headerText },
          });
        }}
      >
        SHOW MORE
      </div>
    </ListContainer>
  );
}

List.defaultProps = {
  headerText: 'People to Follow',
};

List.propTypes = {
  headerText: PropTypes.string,
};
