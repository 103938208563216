import React, { useContext } from 'react';
import { Drawer, List, Avatar } from 'antd';
import { NotificationContext } from '../../../store/context/NotificationContext';

const NotificationDrawer = ({ onClose, visible }) => {
  const { allNotification, ReadNotification } = useContext(NotificationContext);
  console.log('allNotification', allNotification);

  // const data = [
  //   {
  //     title: 'Introducing Withdrawal',
  //     description:
  //       'With withdrawal, you can with money from your wallet balance to any bank account.',
  //   },
  //   {
  //     title: '2 Step Verification',
  //     description:
  //       'Enable 2 step verification with SMS/Emailbased OTP along with user credentials to add additional security to your account.',
  //   },
  //   {
  //     title: 'Switch to Live Mode',
  //     description:
  //       'You are currently in Test mode and your daily limit is NGN 200,000! Turn it off to switch to Live Mode!',
  //   },
  // ];
  return (
    <Drawer
      title={<h6 className="fw-bold">Notifications</h6>}
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      className="fs-5 text-secondary"
    >
      <List
        itemLayout="horizontal"
        dataSource={
          allNotification?.length > 0
            ? [...allNotification.filter((el, i) => i < 10)]
            : []
        }
        renderItem={(item) => (
          <List.Item
            style={{
              backgroundColor: item.read ? 'white' : '#f3f3f3',
              paddingLeft: 5,
              paddingRight: 5,
            }}
            onClick={() => {
              if (!item.read)
                ReadNotification(item.notificationId, `${!item.read}`);
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src="https://mpng.subpng.com/20210101/ht/transparent-alarm-icon-time-date-icon-bell-icon-5fef9e70c2e675.9860299416095391847983.jpg" />
              }
              title={
                <a className="text-decoration-none" href="#blah">
                  {/* {item.title} */}
                  {item.category ? item.category : 'Notification'}
                </a>
              }
              description={item.content}
            />
          </List.Item>
        )}
      />
      <a href="/notifications" className="btn btn-link text-secondary">
        View all notifications
      </a>
    </Drawer>
  );
};

export default NotificationDrawer;
