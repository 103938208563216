import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../action/http';
import warnIcon from '../../../assets/image/warn-icon.png';
import { ModalContext } from '../../../store/context/ModalContext';
import { ProfileContext } from '../../../store/context/ProfileContext';
import PinModal from '../../shared/Modals/PinModal';
import VerifyEmailAndPhone from '../Setting/VerifyEmailAndPhone';

export default function TopNotification(props) {
  const { showAlert, type } = props;
  const [showBvn, setShowBvn] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendVerifyEmailOtp, setResendVerifyEmailOtp] = useState(false);
  const {
    profile,
    setVerifiedEmailReload,
    setEmailVerified,
    setPhoneVerified,
    setReloadUser,
    reloadUser,
    kycData,
  } = useContext(ProfileContext);
  const { setModalAction, setShowResendOtp } = useContext(ModalContext);

  useEffect(() => {
    // console.log({ type });
    if (resendVerifyEmailOtp) return 'resend otp to email';
    return () => {
      setResendVerifyEmailOtp(false);
    };
  }, [resendVerifyEmailOtp]);

  const handleVerifyEmail = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp-mail/${profile.email}`
    );
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Opps!', res.message, 'error');
    }
  };
  const handleUpdateKycEmail = async () => {
    const email1 = kycData?.tier1?.filter(
      (e) => e?.requirementType === 'EMAIL'
    )[0];
    await httpPut(
      `/api/v1/kyc/updateCustomerTierData/${email1.id}/${email1.customerId}?attachment=${profile.email}&reference=email`,
      {},
      customBaseUrl.kycUrl
    );
  };
  const handleUpdateKycPhone = async () => {
    const phone1 = kycData?.tier1?.filter(
      (e) => e?.requirementType === 'PHONE_NUMBER'
    )[0];
    await httpPut(
      `/api/v1/kyc/updateCustomerTierData/${phone1.id}/${phone1.customerId}?attachment=${phone1.attachment}&reference=${phone1?.verificationReference}`,
      {},
      customBaseUrl.kycUrl
    );
  };
  const handleVerifyEmailOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/verify-email`,
      {
        phoneOrEmail: profile.email,
        otp,
      },
      customBaseUrl.authUrl
    );
    if (res.status) {
      swal('Done', 'Email verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
      handleUpdateKycEmail();
      setEmailVerified(true);
      setVerifiedEmailReload(true);
      setReloadUser(!reloadUser);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleResendOtpPhone = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/${profile.phoneNumber}`
    );
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  const handleVerifyPhoneOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/verify-phone`,
      {
        phoneOrEmail: profile.phoneNumber,
        otp,
      },
      customBaseUrl.authUrl
    );
    if (res.status) {
      swal('Done', 'Phone number verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
      handleUpdateKycPhone();
      setPhoneVerified(true);
      setVerifiedEmailReload(true);
      setReloadUser(!reloadUser);
    } else {
      handleUpdateKycPhone();
      swal('Opps!', res.message, 'error');
    }
  };

  const handleResendOtpto = () => {
    if (type === 'verifyEmail') {
      handleVerifyEmail();
    } else {
      handleResendOtpPhone();
    }
  };

  const handleSendOtpTo = () => {
    if (type === 'verifyEmail') {
      handleVerifyEmailOtp();
    } else {
      handleVerifyPhoneOtp();
    }
  };

  return (
    <div
      className={
        !showAlert ? 'd-none' : 'text-center notification-div mx-auto my-4 h-20'
      }
    >
      {type === 'bvn' && (
        <div className="d-flex note-alert">
          <img src={warnIcon} alt="warn" width={30} height={30} />
          <p className="my-0 mx-2 text-primary">
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setShowBvn(true);
              }}
            >
              Link your BVN.
            </a>{' '}
            Profile without linked bvn can only make withdrawal not more than 2
            times
          </p>
        </div>
      )}

      {type === 'verifyEmail' && (
        <div className="d-flex note-alert">
          <img src={warnIcon} alt="warn" width={30} height={30} />
          <p className="my-0 mx-2 text-primary" style={{ color: 'red' }}>
            You need to{' '}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleVerifyEmail();
                setShowPin(true);
              }}
            >
              Verify your Email
            </a>{' '}
            to perform a transaction
          </p>
        </div>
      )}

      {type === 'verifyPhone' && (
        <div className="d-flex note-alert">
          <img src={warnIcon} alt="warn" width={30} height={30} />
          <p className="my-0 mx-2 text-primary" style={{ color: 'red' }}>
            You need to{' '}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleResendOtpPhone();
                setShowPin(true);
              }}
            >
              Verify your Phone number
            </a>{' '}
            to perform a transaction
          </p>
        </div>
      )}
      {showBvn ? (
        <VerifyEmailAndPhone
          showAlert={showBvn}
          type="bvn"
          setShowAlert={setShowBvn}
          id="bvn-wrapper"
        />
      ) : (
        ''
      )}

      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          handleResendOtpto={handleResendOtpto}
          setOtp={setOtp}
          numInputs={6}
          separator=""
          buttonLabel="Verify"
          title="Verify Your Account"
          description={
            type === 'verifyEmail'
              ? 'Please input the OTP sent to your Email Address'
              : 'Please input the OTP sent to your Phone'
          }
          isResendOtp
          resendOtp={setResendVerifyEmailOtp}
          handleSubmit={handleSendOtpTo}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
    </div>
  );
}
