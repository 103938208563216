import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import { Modal, ModalHeader } from 'reactstrap';
import Main from './main';
import NewBeneficiary from './newBeneficiary';
import '../FundWallet/index.scss';
// import Confirm from './Confirm';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
// import PinModal from '../PinModal';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { CardContext } from '../../../../store/context/CardContext';
// import { PaymentContext } from '../../../../store/context/PaymentContext';
import Container from '../../Container';
import WithdrawalDetails from './withdrawalDetails';
import Confirm from '../Confirm';
//  import LinkBvn from '../Bvn';
//  import { getIsBvnLinked } from '../../../../store/calls';
import VerifyEmailAndPhone from '../../../pages/Setting/VerifyEmailAndPhone';
import { duplicateTransChecker } from '../../../../utils/helper';

const Index = (props) => {
  const { showModal, hideModal, id } = props;
  const [mode, setMode] = useState('savedBeneficiary');
  const [data, setData] = useState({});
  // const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    bankAccountList: myAccountList,
    wdBankList: bankList,
    withdrawalProvider,
  } = useContext(CardContext);
  const { walletList, setReload, reload } = useContext(WalletContext);
  // const { charges } = useContext(PaymentContext);
  const { profile, isBvnLinked } = useContext(ProfileContext);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [saveBen, setSaveBen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBvn, setShowBvn] = useState(false);
  const [charges, setCharges] = useState(0);

  const getCharges = async (acctNo, amt) => {
    if (!acctNo || !amt) return;
    if (acctNo && amt) {
      const res = await httpGet(
        `${customBaseUrl.temporalservice}/api/v1/wallet/transaction/get-user-transaction-fee/${acctNo}/${amt}/NIP_PAYOUT`
      );
      console.log('res', res);
      setCharges(res);
      console.log('Charges', charges);
    }
  };

  const handleWithdrawal = async () => {
    if (validator.isEmpty(data.crAccount)) {
      swal('Oops!', 'Account number cannot be empty', 'error');
      hideLoader();
      return;
    }
    // if (validator.isEmpty(data.receiverEmail)) {
    //   swal('Oops!', 'Phone number cannot be empty', 'error');
    //   hideLoader();
    //   return;
    // }
    // if (validator.isEmpty(data.receiverName)) {
    //   swal('Oops!', 'Phone number cannot be empty', 'error');
    //   hideLoader();
    //   return;
    // }
    if (validator.isEmpty(data.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }

    // {
    //   "amount": "string",
    //   "bankCode": "string",
    //   "bankName": "string",
    //   "crAccount": "string",
    //   "crAccountName": "string",
    //   "narration": "string",
    //   "transactionPin": "string",
    //   "userId": "string",
    //   "walletAccount": "string"
    // }

    const postData = {
      ...data,
      transactionPin: otp,
      userId: profile.userId,
      transRef: Math.floor(100000 + Math.random() * 900000),
      saveBen,
    };
    const duplicateData = {
      accountNo: data.crAccount,
      amount: data?.amount,
      description: data?.reason,
      time: new Date(),
    };
    if (duplicateTransChecker(duplicateData)) {
      swal('Oops!', 'Duplicate transaction', 'error');
      setLoading(false);
      //  setShowPin(false);
      hideModal(false);
      return;
    }
    localStorage.setItem('transaction', JSON.stringify(duplicateData));
    showLoader();
    const res = await httpPost(
      `/withdrawal/fund`,
      postData,
      customBaseUrl.withdrawalUrl
    );
    if (res.status) {
      hideLoader();
      setLoading(false);
      swal('Done', `Withdrawal Successful`, 'success').then(() => {
        hideModal(false);
        setReload(!reload);
        setOtp('');
        setData({});
      });
    } else {
      setLoading(false);
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const confirmBvnLink = async () => {
    if (!isBvnLinked) {
      setShowConfirmModal(true);
    } else {
      handleWithdrawal();
    }
  };

  const getBeneficiaryList = async () => {
    const res = await httpGet(
      `${customBaseUrl.withdrawalUrl}/getWithdrawalBen?userId=${profile.userId}`
    );
    if (res?.status) {
      setBeneficiaryList(res.data);
    }
  };

  const handleVerifyPin = async () => {
    return confirmBvnLink();
    // showLoader();
    // setLoading(true);
    // if (!otp || otp === '') {
    //   setLoading(false);
    //   hideLoader();
    //   swal('Oops!', 'Pin is required', 'error');
    //   return;
    // }
    // const res = await httpGet(
    //   `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    // );
    // if (res.status) {
    //   setLoading(false);
    //   hideLoader();
    //   confirmBvnLink();
    // } else {
    //   setLoading(false);
    //   hideLoader();
    //   swal('Oops!', res.message || 'Something went wrong', 'error');
    // }
  };

  useEffect(() => {
    getBeneficiaryList();
  }, [profile]);

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader>Transfer to other banks</ModalHeader>
      {withdrawalProvider ? (
        <>
          {mode !== 'withdrawalDetails' && (
            <div className="text-center fund-tab-wrap pb-2 pb-3">
              <div className="row m-0 fund-tab">
                <Container
                  className={`fs-6 ${
                    mode === 'savedBeneficiary' ? 'col fund-tab-active' : 'col'
                  }`}
                  onClick={() => {
                    setMode('savedBeneficiary');
                    setData({});
                  }}
                >
                  Saved Beneficiary
                </Container>
                <Container
                  className={`fs-6 ${
                    mode === 'newBeneficiary' ? 'col fund-tab-active' : 'col'
                  }`}
                  onClick={() => {
                    setMode('newBeneficiary');
                    setData({});
                  }}
                >
                  New Beneficiary
                </Container>
              </div>
            </div>
          )}
          {/* {mode === 'confirm' && (
            <Confirm setShowPin={setShowPin} hideModal={hideModal} />
          )} */}
          {mode === 'savedBeneficiary' && (
            <Main
              getCharges={getCharges}
              setMode={setMode}
              walletList={walletList}
              setData={setData}
              myAccountList={beneficiaryList}
              data={data}
              beneficiaryList={myAccountList}
            />
          )}
          {mode === 'newBeneficiary' && (
            <NewBeneficiary
              getCharges={getCharges}
              setMode={setMode}
              walletList={walletList}
              myAccountList={myAccountList}
              setData={setData}
              data={data}
              bankList={bankList}
            />
          )}
          {mode === 'withdrawalDetails' && (
            <WithdrawalDetails
              charges={charges}
              data={data}
              setMode={setMode}
              otp={otp}
              setOtp={setOtp}
              loading={loading}
              handleSubmit={handleVerifyPin}
              buttonLabel="Continue"
              separator=""
              saveBen={saveBen}
              setSaveBen={setSaveBen}
              // charges={charges?.withdrawalFee || 0}
            />
          )}
          {showConfirmModal ? (
            <Confirm
              showModal={showConfirmModal}
              hideModal={setShowConfirmModal}
              id=""
              cancelText="Skip"
              submitText="Link"
              title="BVN"
              description="You are yet to link your bvn"
              handleSubmit={() => setShowBvn(true)}
              handleCancel={() => {
                setShowConfirmModal(false);
                handleWithdrawal();
              }}
            />
          ) : (
            ''
          )}

          {/* showBvn ? (
            <LinkBvn
              showModal={showBvn}
              hideModal={setShowBvn}
              id="bvn-wrapper"
            />
          ) : (
            ''
          ) */}
          {showBvn ? (
            <VerifyEmailAndPhone
              showAlert={showBvn}
              type="bvn"
              setShowAlert={setShowBvn}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <div className="text-center fund-tab-wrap pb-2 pb-3">
          <h5>Service currently not available</h5>
        </div>
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
