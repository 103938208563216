import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import validator from 'validator';
import { httpPost, httpGet, customBaseUrl } from '../../../../action/http';
import Button from '../../Button';
import './index.scss';

export default function Team(props) {
  const { showModal, hideModal, id, setShowPin, data, setData } = props;
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(`data`, data);

  const getRoles = async () => {
    const res = await httpGet(`${customBaseUrl.roleUrl}/api/v1/roles/custom`);
    if (res) {
      setRoles(res);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleInvite = async () => {
    const res = await httpPost(
      `/api/v1/user-access/invite/corporate`,
      data,
      customBaseUrl.roleUrl
    );

    if (res.status) {
      swal('Done', res.message, 'success');
      hideModal(false);
    } else {
      swal('Opps!', res.message, 'error');
    }
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader>
        <h3 className="text-center">Invite Team Members</h3>
        <p className="text-mute text-center" style={{ fontSize: '16px' }}>
          Please enter email to invite team members to have access to your
          dashboard
        </p>
      </ModalHeader>
      <div className="text-center">
        <div className="inputbox-with-one-input">
          <input
            placeholder="Email Address"
            type="text"
            value={data.email}
            onChange={(e) => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />
        </div>
        <div className="inputbox-with-one-input">
          <input
            placeholder="Tel"
            type="text"
            value={data.phone}
            onChange={(e) => {
              setData({
                ...data,
                phone: e.target.value,
              });
            }}
          />
        </div>

        <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            value={data.roleId}
            onChange={(e) => {
              setData({
                ...data,
                roleId: e.target.value,
              });
            }}
          >
            <option value="Select" selected disabled>
              Select Role
            </option>
            {roles?.length
              ? roles.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.description}
                  </option>
                ))
              : ''}
          </select>
        </div>
        <div className="w-100 text-start">
          This team member is a developer
          <input type="checkbox" name="" id="" className="ms-2" />
        </div>
      </div>
      <ModalFooter className="border-0">
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3 w-100"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setLoading(true);
            if (validator.isEmpty(data.email)) {
              swal('Oops!', 'Email is required', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.phone)) {
              swal('Oops!', 'Enter phone number to continue', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.roleId)) {
              swal('Oops!', 'Select role id to continue', 'error');
              setLoading(false);
              return;
            }
            setLoading(false);
            handleInvite();
            setShowPin(true);
          }}
          content="Invite Team Member"
        />
      </ModalFooter>
    </Modal>
  );
}
