import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { CommentCard } from './style';
import commentIcon from '../../../assets/image/message-icon.png';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';

export default function Gram(props) {
  const { data, handleComment } = props;
  const { username, avatar, UserId, displayName } = data?.Profile;
  const { likesCount, handleLikePost, id: postId, isLiked, comment } = data;
  const [likes, setlikes] = useState(likesCount);
  const history = useHistory();
  const myRef = React.useRef(null);

  return (
    <CommentCard ref={myRef} className="p-3">
      <div className="top-content d-flex">
        <div className="d-flex">
          <img
            src={avatar || imgPlaceholder}
            alt="gram avatar"
            className="me-3"
            style={{ height: '30px', width: '30px', borderRadius: ' 50%' }}
          />
          <p
            className="me-3 "
            aria-hidden="true"
            onClick={() => history.push(`/wayagram-profile/${UserId}`)}
          >
            {`${displayName}`}
          </p>
          <p className="me-3 text-muted">@{username}</p>
          <span className="text-muted">2h</span>
        </div>
      </div>
      <div className="middle-content">
        <p>{comment}</p>
      </div>

      <div className="bottom-content">
        <div className="bottom-content-container mt-3">
          <div className="comment-div">
            <img
              src={commentIcon}
              alt="comment"
              style={{
                width: '1em',
                height: '1em',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleComment(true);
              }}
            />
            {/* <span className="ms-2">{commentCount}</span> */}
          </div>
          <div className="comment-div">
            <i
              className={
                isLiked
                  ? 'mdi mdi-heart text-danger'
                  : 'mdi mdi-heart-outline text_text add-cursor'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => {
                if (likes >= likesCount + 1) {
                  return;
                }
                setlikes(likes + 1);
                handleLikePost(postId);
              }}
            />
            <span className="ms-2">{likes}</span>
          </div>
          <div className="comment-div">
            {/*  eslint-disable jsx-a11y/click-events-have-key-events  */}
            <i
              className={
                isLiked
                  ? 'mdi mdi-twitter-retweet text-success'
                  : 'mdi mdi-twitter-retweet'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              // onClick={}
            />
          </div>
          <div className="comment-div">
            {/*  eslint-disable jsx-a11y/click-events-have-key-events  */}
            <i
              className={
                isLiked
                  ? 'mdi mdi-share-variant text-success'
                  : 'mdi mdi-share-variant'
              }
              role="button"
              tabIndex={0}
              aria-hidden="true"
              // onClick={}
            />
          </div>
        </div>
      </div>
    </CommentCard>
  );
}

Gram.propTypes = {
  data: PropTypes.shape.isRequired,
  handleLikePost: PropTypes.func.isRequired,
  handleComment: PropTypes.func.isRequired,
};
