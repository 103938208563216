import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Modal, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { customBaseUrl, httpGet } from '../../../../action/http';
import Button from '../../Button';
import './MakeDonation/makeDonation.scss';

function PayForProduct(props) {
  const { values, defaultWallet, hideModal, showModal, handleSubmit } = props;
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Pin is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      handleSubmit();
      setLoading(false);
      setOtp('');
      hideModal(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader className="text-left" toggle={() => hideModal(false)}>
          <div className="interest-header normal-header">
            <h3 className="interest-p1">Product Payment</h3>
          </div>
        </ModalHeader>
        <div className="pin-input-container">
          <div className="pin-input-header">Input your 4 digit PIN</div>
          <div className="pin-input-description">
            Please input your 4digit PIN to transfer fund
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Receipient</div>
            <div className="transaction-detail-right">NGO</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Amount</div>
            <div className="transaction-detail-right">{values?.amount}</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Beneficiary Bank:</div>
            <div className="transaction-detail-right">######</div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">From</div>
            <div className="transaction-detail-right">
              {defaultWallet?.accountNo}
            </div>
          </div>
          <div className="transaction-detail mt-4">
            <div className="pin-input-description">Transaction fee:</div>
            <div className="transaction-detail-right">#####</div>
          </div>
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            separator=" "
            containerStyle={{ justifyContent: 'center' }}
            inputStyle="otp-input otp-bottom"
            isInputSecure
            isInputNum
          />

          <div className="w-100 mt-5 text-center">
            <Button
              type="button"
              loading={loading}
              disabled={loading || false}
              onClick={handleVerifyPin}
              content="Confirm"
              className="finish-card"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PayForProduct;
