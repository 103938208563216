import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

export const BillerContext = createContext();

export const BillerContextProvider = (props) => {
  const { children } = props;
  const [airtime, setAirtime] = useState([]);
  const [data, setData] = useState([]);
  const [cabletv, setCabletv] = useState([]);
  const [electricity, setElectricity] = useState([]);
  const [reload, setReload] = useState(false);
  // const [aggregatorData, setAggregatorData] = useState([]);
  const [categData, setCategData] = useState([]);
  //  const [biller, setBiller] = useState([]);
  const [billerProvider, setBillerProvider] = useState(false);
  const [airtimeId, setAirtimeId] = useState(null);
  const [cableId, setCableId] = useState(null);
  const [electricId, setElectricId] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [betId, setBetId] = useState(null);
  const [ePinId, setEPinId] = useState(null);
  const [betting, setBetting] = useState([]);
  const [epin, setEpin] = useState([]);
  // airtime, databundle, cabletv, electricity

  // const getAggregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   if (res?.status) {
  //     setAggregatorData(res.data);
  //     // console.log('aggregatorData', aggregatorData);
  //   }
  // };

  const getCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchAllCategory`
    );
    if (res?.status) {
      //  console.log('billerCategory', res);
      setCategData(res.data);
      setAirtimeId(res?.data?.filter((e) => e?.name === 'airtime')[0]?.id);
      setCableId(res?.data?.filter((e) => e?.name === 'cabletv')[0]?.id);
      setElectricId(res?.data?.filter((e) => e?.name === 'electricity')[0]?.id);
      setDataId(res?.data?.filter((e) => e?.name === 'databundle')[0]?.id);
      setBetId(res?.data?.filter((e) => e?.name === 'betting')[0]?.id);
      setEPinId(res?.data?.filter((e) => e?.name === 'epin')[0]?.id);
    }
  };

  const getAirtime = async () => {
    //  setBiller('Airtime');
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${airtimeId}`
    );
    if (res?.status) {
      //  console.log('billerCategory', res);
      setAirtime(res.data);
    }
  };
  const getBetting = async () => {
    //  setBiller('Airtime');
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${betId}`
    );
    if (res?.status) {
      //  console.log('betting', res);
      setBetting(res.data);
    }
  };
  // console.log('biller', biller);

  const getData = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${dataId}`
    );
    if (res?.status) {
      //  console.log('data', res);
      setData(res.data);
    }
  };
  const getEpin = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${ePinId}`
    );
    if (res?.status) {
      //  console.log('epin', res);
      setEpin(res.data);
    }
  };

  const getCabletv = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${cableId}`
    );
    if (res?.status) {
      //  console.log('cable', res);
      setCabletv(res.data);
    }
  };

  const getElectricity = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${electricId}`
    );
    if (res?.status) {
      //  console.log('electric', res);
      setElectricity(res.data);
    }
  };

  const getBillers = async (categoryId) => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category/${categoryId}`
    );
    if (res?.status) {
      return res.data;
    }
    return [];
  };
  const checkBillerProvider = async () => {
    const res = await httpGet(`
      ${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`);
    if (res?.status) {
      const prov = res?.data?.filter((e) => e?.active);
      //  console.log('prov', prov?.length > 0 ?? false);
      setBillerProvider(prov?.length > 0 ?? false);
    }
  };

  useEffect(() => {
    if (airtimeId) getAirtime();
  }, [airtimeId]);
  useEffect(() => {
    if (cableId) getCabletv();
  }, [cableId]);
  useEffect(() => {
    if (electricId) getElectricity();
  }, [electricId]);
  useEffect(() => {
    if (dataId) getData();
  }, [dataId]);
  useEffect(() => {
    if (betId) getBetting();
  }, [betId]);
  useEffect(() => {
    if (ePinId) getEpin();
  }, [ePinId]);
  useEffect(() => {
    checkBillerProvider();
    // getAggregator();
    getCategory();
  }, [reload]);

  return (
    <BillerContext.Provider
      value={{
        setAirtime,
        airtime,
        setData,
        data,
        reload,
        setReload,
        cabletv,
        electricity,
        getBillers,
        categData,
        billerProvider,
        betting,
        epin,
      }}
    >
      {children}
    </BillerContext.Provider>
  );
};
