import React, { useEffect } from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { customBaseUrl, httpGet } from '../../../../action/http';
import Button from '../../Button';
import { capitalize } from '../../../../utils/helper';
import secureIcon from '../../../../assets/secure.svg';

export default function PinDetails(props) {
  const {
    showModal,
    hideModal,
    setOtp,
    otp,
    handleNext,
    title,
    id,
    separator,
    displayData,
    setLoading,
    loading,
    content,
  } = props;

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      hideModal(false);
      handleNext();

      // swal('Done', `Pin verified`, 'success').then(() => {
      //   hideModal(false);
      //   handleNext();
      // });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  useEffect(() => {
    setOtp('');
  }, []);
  // console.log('##', displayData);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="text-center mt-3" style={{ fontSize: '22px' }}>
          Input your 4 digit PIN
        </div>
        <div
          className="text-center input-detail mb-3"
          style={{ fontSize: '12px', marginTop: '10px' }}
        >
          {content || `Please input your 4digit PIN to transfer fund`}
        </div>
        <div className="mx-auto" style={{ width: '70%' }}>
          {displayData &&
            Object.keys(displayData).map((keyName) => (
              <div className="row mb-3" key={Math.random()}>
                <p className="col text-cmuted">{capitalize(keyName)}</p>
                <p className="col text-bold text-end">{displayData[keyName]}</p>
              </div>
            ))}
          {/* <div className="row">
            <p className="col">Recipent</p>
            <p className="col text-bold">Stanley Toju</p>
          </div>
          <div className="row">
            <p className="col">Amount</p>
            <p className="col text-bold">40,000</p>
          </div>
          <div className="row">
            <p className="col">Beneficiary Bank</p>
            <p className="col text-bold">UBA</p>
          </div>
          <div className="row">
            <p className="col">From</p>
            <p className="col text-bold">012846234532</p>
          </div>
          <div className="row">
            <p className="col">Transaction Fee</p>
            <p className="col text-bold">N10,000</p>
          </div> */}
        </div>

        <div className="mt-2 mb-3">
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={4}
            separator={separator}
            containerStyle={{ justifyContent: 'center' }}
            inputStyle="otp-input otp-bottom"
            isInputSecure
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-3"
          color="primary"
          style={{ height: '36px' }}
          loading={loading}
          disabled={loading || false}
          onClick={() => handleVerifyPin()}
        >
          Next
        </Button>
        <div
          className="flex_center"
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <img
            src={secureIcon}
            alt=""
            style={{ marginBotton: 5, marginRight: 5 }}
          />
          <div>
            Secured by <span style={{ color: '#FF6700' }}>WayaBank</span>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

PinDetails.defaultProps = {
  separator: <span>-</span>,
  title: '',
};

PinDetails.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  setOtp: PropTypes.func.isRequired,
  otp: PropTypes.string.isRequired,
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  separator: PropTypes.node,
};
