import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ModalFooter, ModalBody } from 'reactstrap';
import leftArrow from '../../../../assets/image/left-arrow.png';
import plusIcon from '../../../../assets/image/plus-circle.png';
import amicoIcon from '../../../../assets/image/amico2.png';
import './index.scss';
import CardList from './CardList';
import Container from '../../Container';
import { CardContext } from '../../../../store/context/CardContext';

export default function ManageCard(props) {
  const { showModal, hideModal, setShowLinkCardModal } = props;
  const myRef = React.useRef(null);
  const { cardList: myCardList } = useContext(CardContext);

  if (!myCardList.length) {
    return (
      <div ref={myRef}>
        <Modal
          isOpen={showModal}
          id="manage-bank-modal"
          toggle={() => hideModal(false)}
          centered
        >
          <div className="header">
            <div className="row">
              <div className="col-3 h-left">
                <Container onClick={() => hideModal(false)}>
                  <img src={leftArrow} alt="left" />
                </Container>
              </div>
              <div className="col-6 h-center text-center">Manage Card</div>
              <div className="col-3 h-right text-center">
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowLinkCardModal(true);
                    hideModal(false);
                  }}
                >
                  <img src={plusIcon} alt="plus" />
                </a>
              </div>
            </div>
          </div>
          <ModalBody>
            <div className="img-center">
              <img src={amicoIcon} alt="bank" />

              <h4>Your Card section looks empty</h4>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className="w-100 btn-primary mx-auto mb-3 p-2"
              color="primary"
              onClick={() => {
                setShowLinkCardModal(true);
                hideModal(false);
              }}
            >
              Add Card
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  return (
    <CardList
      showModal
      bankCardList={myCardList}
      hideModal={hideModal}
      setShowLinkCardModal={setShowLinkCardModal}
    />
  );
}

ManageCard.defaultProps = {
  showModal: false,
};

ManageCard.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  setShowLinkCardModal: PropTypes.func.isRequired,
};
