import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';
import {
  getPageSuggestion,
  getGroupSuggestion,
} from '../../components/pages/Wayagram/container';

export const WayagramContext = createContext();

export const WayagramContextProvider = (props) => {
  const { children } = props;
  const [wayagramProfile, setWayagramProfile] = useState({});
  const [wayagramProfileId, setWayagramProfileId] = useState(null);
  const [wayagramFeeds, setWayagramFeeds] = useState([]);
  const [wayagramReload, setWayagramReload] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadMoment, setReloadMoment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSuggestion, setPageSuggestion] = useState([]);
  const [groupSuggestion, setGroupSuggestion] = useState([]);

  // const getWayagramFeeds = async (profileId) => {
  //   const res = await httpGet(
  //     `/post/feed?profile_id=${profileId}`,
  //     'http://157.245.84.14:1000'
  //   );
  //   if (res.status) {
  //     setWayagramFeeds(res.data);
  //   }
  // };

  const handleGroupSuggestion = async () => {
    const resData = await getGroupSuggestion(wayagramProfileId);
    setGroupSuggestion(resData);
  };

  const handlePageSuggestion = async () => {
    const resData = await getPageSuggestion(wayagramProfileId);
    setPageSuggestion(resData);
  };

  const getWayagramProfile = async (userId) => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/get-by-user-id?user_id=${userId}`
    );
    if (res?.status) {
      setWayagramProfile(res?.data);
      setWayagramProfileId(res?.data?.id);
      setLoading(false);
      // getWayagramFeeds(res.data.id);
    }
    console.log(res);
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getWayagramProfile(userId);
  }, [wayagramReload]);

  useEffect(() => {
    if (wayagramProfileId) {
      handleGroupSuggestion();
      handlePageSuggestion();
    }
  }, [wayagramProfileId]);

  return (
    <WayagramContext.Provider
      value={{
        setWayagramProfile,
        wayagramProfile,
        wayagramReload,
        setWayagramReload,
        wayagramProfileId,
        setWayagramProfileId,
        wayagramFeeds,
        setWayagramFeeds,
        groupSuggestion,
        pageSuggestion,
        setReload,
        reload,
        setReloadMoment,
        reloadMoment,
        loading,
      }}
    >
      {children}
    </WayagramContext.Provider>
  );
};
