import React, { useState, useContext } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { CreditCard } from '@material-ui/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { WalletContext } from '../../../store/context/WalletContext';
import RequestCard from './requestCard';
//  import cardsLog from '../../../assets/amico.png';

const CardList = ({ cardList, getCards, showReq }) => {
  const { walletList } = useContext(WalletContext);
  const [showCardReq, setShowCardReq] = useState(false);
  // const [filteredDate, setFilteredDate] = useState('');

  return (
    <>
      <div className="mt-2 mx-auto text-center">
        {cardList?.map(
          (e) =>
            e?.status !== 'APPROVED' && (
              <Card
                key={`${e}`}
                bordered
                style={{
                  width: '80%',
                  borderRadius: 5,
                  height: '100px',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                <Row style={{ height: 200 }}>
                  <Col span={4}>
                    <div style={{ borderWidth: '2px' }}>
                      <CreditCard />
                    </div>
                  </Col>
                  <Col span={12}>
                    <p>********</p>
                    <p>
                      <h6>{e?.status === 'APPROVED' ? ' ' : e?.status}</h6>
                    </p>
                  </Col>
                </Row>
              </Card>
            )
        )}
        <Button
          type="submit"
          onClick={() => getCards()}
          style={{
            marginTop: '40px',
            background: '#FF6700',
            color: '#FFFFFF',
            textAlign: 'centre',
            width: '50%',
            display: 'none',
          }}
        >
          Refresh
        </Button>
        <Button
          type="submit"
          onClick={() => showReq(true)}
          style={{
            marginTop: '40px',
            background: '#FF6700',
            color: '#FFFFFF',
            textAlign: 'centre',
            width: '50%',
            //  display: 'none',
          }}
        >
          Request Card
        </Button>
      </div>
      {showCardReq && (
        <RequestCard
          hideModal={setShowCardReq}
          showModal={showCardReq}
          walletList={walletList}
          getCards={getCards}
        />
      )}
    </>
  );
};

export default CardList;
