import React from 'react';
import List from '../../shared/Cards/List';

const InterestList = () => {
  return (
    <div>
      <List
        headerText="Interest"
        list={[]}
        loading={false}
        buttonTitle="Follow"
        showName={false}
      />
    </div>
  );
};

export default InterestList;
