import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Share } from '@material-ui/icons';
//  import QRCode from 'react-qr-code';
import { toJpeg } from 'html-to-image';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomDropdown from '../../Dropdown';
import './index.scss';
import Button from '../../Button';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import envConfig from '../../../../utils/envConfig';
import InviteModal from '../inviteModal';

const Index = (props) => {
  const { showModal, hideModal, mode } = props;
  const [copied, setCopied] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const { referralCode, profile } = useContext(ProfileContext);
  const [invite, setInvite] = useState(false);
  const referallLink = `${envConfig.websiteAppUrl}/signup?isReferral=${referralCode}`;
  // const data =
  //   mode === 'profile'
  //     ? {
  //         id: profile.userId,
  //         phone: profile.phoneNumber,
  //         firstName: profile.firstName,
  //         lastName: profile.surname,
  //         email: profile.email,
  //       }
  //     : {
  //         referralCode,
  //       };

  const downloadDiv = () => {
    toJpeg(document.getElementById('qr-body')).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'profile_code.jpeg';
      link.href = dataUrl;
      link.click();
    });
  };

  const shareQrCode = () => {
    console.log('share');
  };

  const downloadPdf = () => {
    html2canvas(document.getElementById('qr-body')).then((canvas) => {
      const imageCanvas = canvas.toDataURL('images/png');

      const invoicePDF = new JSPDF();
      invoicePDF.addImage(imageCanvas, 'PNG', 0, 0);
      invoicePDF.save('profile_code.pdf');
    });
  };

  const handleOption = async (type) => {
    if (type === 'download') {
      downloadDiv();
    } else if (type === 'save') {
      downloadPdf();
    } else if (type === 'share') {
      shareQrCode();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id={mode === 'invite' ? 'invite-code-modal' : 'qr-code-modal'}
      centered
    >
      <ModalHeader>
        <h5 className={mode === 'invite' ? 'justify-content-center' : ''}>
          {mode === 'invite' ? 'Invite Friend' : 'Profile QR Code'}
        </h5>
        <div
          className="ml-auto"
          style={
            mode === 'invite' ? { display: 'none' } : { marginTop: '12px' }
          }
        >
          <div className="dot-3x" style={{ position: 'relative' }}>
            <CustomDropdown
              id={12}
              direction="vertical"
              handleOption={handleOption}
              options={[
                // { name: 'Download', type: 'download', id: 1 },
                { name: 'Save', type: 'save', id: 2 },
                // { name: 'Share', type: 'share', id: 3 },
                { name: 'Cancel', type: 'cancel', id: 4 },
              ]}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          id="qr-body"
          style={mode === 'profile' ? { marginBottom: '20px' } : {}}
        >
          <div className="text-header">
            <h5 className="custom-blue">{`${profile.firstName} ${profile.surname}`}</h5>
            {/* <p>Waya QR Code</p> */}
          </div>
          {/* <div className="qr-code-div">
            <QRCode value={`${JSON.stringify(data)}`} />
          </div> */}
          <div
            className={
              mode === 'profile'
                ? 'd-none'
                : 'invite-code-div d-flex justify-content-center my-4'
            }
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="col-md-12 col-sm-12 d-flex my-2">
              <label>Referral Code</label>
              <input
                type="text"
                className="form-control me-2"
                disabled
                value={referralCode}
              />
              {/* <button type="button" className="btn btn-dark">
              Copy
            </button> */}
              <CopyToClipboard
                text={referralCode}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                <Button content="Copy" loading={copied || false} />
              </CopyToClipboard>
            </div>
            <div className="col-md-12 col-sm-12 d-flex">
              <label>Referral Link</label>
              <input
                type="text"
                className="form-control me-2"
                disabled
                value={referallLink}
              />
              {/* <button type="button" className="btn btn-dark">
              Copy
            </button> */}
              <CopyToClipboard
                text={referallLink}
                onCopy={() => {
                  setCopiedLink(true);
                  setTimeout(() => {
                    setCopiedLink(false);
                  }, 3000);
                }}
              >
                <Button content="Copy" loading={copiedLink || false} />
              </CopyToClipboard>
            </div>
          </div>
          <div onClick={() => setInvite(true)}>
            <Share />
            <label>Share invite</label>
          </div>
        </div>
      </ModalBody>
      {invite ? (
        <InviteModal
          referralCode={referralCode}
          profile={profile}
          center
          showModal={invite}
          separator=""
          buttonLabel="Continue"
          title="Invite Contact"
          description="Invite your friend to join you on Wayabank"
          hideModal={setInvite}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  mode: 'profile',
  showModal: false,
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
};

export default Index;
