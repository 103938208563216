import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import { Modal, ModalBody } from 'reactstrap';
import '../index.scss';
import ToggleInput from '../../ToggleInput';
import { customBaseUrl, httpPost } from '../../../../action/http';
import Button from '../../Button';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { ModalContext } from '../../../../store/context/ModalContext';

const ChangePassword = (props) => {
  const { showModal, hideModal } = props;
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  // const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    profile: { email, phoneNumber },
  } = useContext(ProfileContext);
  const {
    setShowResendOtp,
    setModalAction,
    changePasswordVerified,
    setChangePasswordVerified,
    otp,
    otpType,
    setShowPinVerify,
    setOtpType,
    setLoading: setModalLoading,
  } = useContext(ModalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validator.isEmpty(password)) {
      swal('Oops!', 'Password cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(oldPassword)) {
      swal('Oops!', 'Old Password cannot be empty', 'error');
      setLoading(false);
      return;
    }
    setModalAction('changePassword');
    setShowResendOtp(true);
    setLoading(false);
  };

  const handleChangePass = async () => {
    const postData = {
      phoneOrEmail: otpType === 'email' ? email : phoneNumber,
      newPassword: password,
      oldPassword,
      otp,
    };
    const res = await httpPost(
      `/api/v1/password/forgot-password`,
      postData,
      customBaseUrl.authUrl
    );
    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success').then(() => {
        setOtpType('');
        setPassword('');
        setShowPinVerify(false);
        hideModal(false);
        setModalLoading(false);
        setChangePasswordVerified(false);
      });
    } else {
      setLoading(false);
      setChangePasswordVerified(false);
      setModalLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  useEffect(() => {
    if (changePasswordVerified && password !== '') {
      handleChangePass();
    }
  }, [changePasswordVerified]);

  return (
    <div id="reset-password-modal">
      <Modal
        isOpen={showModal}
        toggle={() => hideModal(false)}
        id="reset-password-modal"
        centered
      >
        <ModalBody className="">
          <div className="waya-modal-body">
            <h1 className="modal-header-data">Change Password?</h1>

            <h6
              className="text-secondary text-center"
              style={{ marginBottom: '50px' }}
            >
              Fill in your new password.
            </h6>

            <div className="inputbox-with-one-input confirm-password toggle-pass-wrapper">
              <ToggleInput
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="inputbox-with-one-input toggle-pass-wrapper">
              <ToggleInput
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div
              className="submit-modal-btn-wrap"
              style={{ marginBottom: '50px' }}
            >
              <Button
                type="button"
                loading={loading}
                disabled={loading || false}
                onClick={handleSubmit}
                content="Reset Password"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

ChangePassword.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default ChangePassword;
