/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import Layout from '../../shared/Layout';
import './index.scss';
import RightSidebar from './RightSidebar';
import { getDiscoverData, getTrendingTags } from '../../../store/calls';
// import {
//   followOrUnfollowPage,
//   handleFollow,
//   handleUnFollow,
//   joinGroup,
//   leaveAndDeleteGroup,
// } from './container';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { handleSearchFriends, handleWayaSubmit } from '../../../utils/helper';
import SearchBar from './Searcbar';
import SearchTabs from './SearchTabs';
import { items } from '../../../utils/map';

export default function Discover(props) {
  const { location } = props;
  const [active, setActive] = useState('Groups');
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  // const [pageNumber, setPageNumber] = useState(1);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleGetData = async (val) => {
    setLoading(true);
    const userId = loggedInUser.id;
    const phrase = val || active || 'Post';
    const resData = await getDiscoverData(phrase, 1, searchValue, userId);
    setList(resData);
    setLoading(false);
  };

  const handleSearch = async (val, activeTab, id) => {
    setLoading(true);
    const resData = await handleSearchFriends(
      val,
      activeTab,
      id,
      loggedInUser.id
    );
    setList(resData);
    setLoading(false);
  };

  const handleGetTrending = async () => {
    setLoading(true);
    const userId = loggedInUser.id;
    const resData = await getTrendingTags(userId);
    setList(resData);
    setLoading(false);
  };

  const debounceLoadData = useCallback(_.debounce(handleSearch, 1000), []);

  useEffect(() => {
    const userId = loggedInUser.id;
    debounceLoadData(searchValue, active, userId);
  }, [searchValue]);

  useEffect(() => {
    if (location.from === 'wayagram') {
      setSearchValue(location.data);
      handleSearch(location.data);
    } else {
      handleGetData();
    }
    return () => setList([]);
  }, [active]);

  const handleSubmit = async (type, username, objectId) => {
    setBtnLoading(true);
    handleWayaSubmit(
      type,
      username,
      objectId,
      loggedInUser,
      active,
      searchValue,
      handleSearch,
      setList
    );
    setBtnLoading(false);
  };

  return (
    <Layout route={props} currentPage="wayagram-discover">
      <div className="row wayagram-div">
        <div className="col-8">
          <SearchBar
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          <SearchTabs
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            items={items}
            handleGetTrending={handleGetTrending}
            handleSearch={handleSearch}
            setList={setList}
            setActive={setActive}
            active={active}
            btnLoading={btnLoading}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            handleSubmit={handleSubmit}
            loading={loading}
            list={list}
          />
        </div>
        <RightSidebar isSearch={false} />
      </div>
    </Layout>
  );
}
