import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import mentionSearch, { HashSearch } from './mentionSearch';
import defaultStyle from './style';

export default function MentionInput(props) {
  const { text, setText, placeholder, setMentionList, setHashTagList } = props;
  // const [searchResult, setSearchResult] = useState('');

  return (
    <div>
      <MentionsInput
        value={text}
        onChange={async (e, newValue, newPlainTextValue, mention) => {
          // console.log(e, newValue, newPlainTextValue, mention);
          setText(newPlainTextValue);
          if (mention.length) {
            if (mention[0].id === 'mention') {
              // const nextName = mention[0].display.match('@\\s*(\\w+)');
              setMentionList((item) => [...item, mention[0].display]);
            }
            if (mention[0].id === 'hash') {
              const nextName = mention[0].display.match('@#\\s*(\\w+)');
              setHashTagList((item) => [...item, `#${nextName[1]}`]);
            }
          }
          // const result = await mentionSearch(newPlainTextValue);
          // setSearchResult(result);
        }}
        style={defaultStyle}
        placeholder={placeholder}
      >
        <Mention
          trigger="@"
          displayTransform={(id, display) => `@${display}`}
          data={mentionSearch}
        />
        <Mention
          trigger="#"
          displayTransform={(id, display) => `${display}`}
          data={HashSearch}
        />
      </MentionsInput>
    </div>
  );
}
