import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
// import LandingPage from './components/pages/LandingPage';
// import WayapayProducts from './components/pages/Wayapay';
import TransactionHistory from './components/pages/Wayapay/TransactionHistory';
// import AllCategories from './components/pages/Wayapay/AllCategories';
// import Contacts from './components/pages/Wayapay/Contacts';
import Notifications from './components/pages/Notification';
import ComingSoon from './components/pages/ComingSoon';
import Wayagram from './components/pages/Wayagram';
import Interest from './components/pages/Wayagram/Interest';
import Dispute from './components/pages/Wayagram/Dispute';
import WayagramFollowing from './components/pages/Wayagram/Following';
import WayagramProfile from './components/pages/Wayagram/Profile';
import WayagramGroup from './components/pages/Wayagram/Group';
import WayagramPage from './components/pages/Wayagram/Page';
import 'react-notifications/lib/notifications.css';
import './assets/stylesheets/index.scss';
import VerifyEmail from './components/pages/VerifyEmail';
import Setting from './components/pages/Setting';
import Activity from './components/pages/Activity';
import CorporateSetting from './components/pages/CorporateSetting';
import Logout from './components/pages/Logout';
import Login from './components/pages/Auth/login';
import CorpLogin from './components/pages/Auth/corpAuth';
// import Signup from './components/pages/Auth/signup';
// import ErrorBoundary from './ErrorBoundary';
import ProtectedRoute from './utils/ProtectedRoute';
import CommentView from './components/shared/Cards/CommentView';
import Discover from './components/pages/Wayagram/Discover';
import Chat from './components/pages/Chat';
import { PaymentContextProvider } from './store/context/PaymentContext';
import { ProfileContextProvider } from './store/context/ProfileContext';
import { WalletContextProvider } from './store/context/WalletContext';
import { BillerContextProvider } from './store/context/BillerContext';
import { CardContextProvider } from './store/context/CardContext';
import { WayagramContextProvider } from './store/context/WayagramContext';
import { ModalContextProvider } from './store/context/ModalContext';
import { LayoutContextProvider } from './store/context/LayoutContext';
import MyGroups from './components/pages/Wayagram/MyGroups';
import ManagePages from './components/pages/Wayagram/ManagePages';
import Bookmarks from './components/pages/Wayagram/Bookmarks';
import Wallet from './components/pages/Wayapay/Wallet';
// import TerminalPos from './components/pages/Wayapay/TerminalPos';
// import WebPos from './components/pages/Wayapay/WebPos';
import 'antd/dist/antd.css';
import TerminalPosSettings from './components/pages/Setting/TerminalPos';
import WebPosSetting from './components/pages/Setting/WebPos';
import { NotificationContextProvider } from './store/context/NotificationContext';
// import TeamMembers from './components/pages/Setting/TeamMembers';
// import ManageRoles from './components/pages/Setting/ManageRoles';
import SupportPage from './components/pages/Support';
import ReferalTable from './components/pages/Setting/ReferalTable';
import ApiDocumentation from './components/pages/Setting/apiDocumention';
import store from './store/redux-manager/store';
import SupportButton from './components/shared/buttons/supportButton';
import Cards from './components/pages/Wayapay/cards';
import TicketPage from './components/pages/Support/tickets';
import TicketPages from './components/pages/Support/ztickets';
import LoanRequest from './components/pages/loanRequest';

const App = () => (
  <>
    <Provider store={store}>
      <NotificationContainer />
      {/* <ErrorBoundary> */}
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/corp-login" component={CorpLogin} />
          {/* <Route path="/signup" component={Signup} /> */}
          <NotificationContextProvider>
            <BillerContextProvider>
              <PaymentContextProvider>
                <CardContextProvider>
                  <WalletContextProvider>
                    <ProfileContextProvider>
                      <ModalContextProvider>
                        <WayagramContextProvider>
                          <LayoutContextProvider>
                            {/* <ProtectedRoute
                            exact
                            path="/products"
                            component={WayapayProducts}
                          /> */}
                            <ProtectedRoute
                              exact
                              path="/wallet"
                              component={Wallet}
                            />
                            <ProtectedRoute
                              exact
                              path="/transactions"
                              component={TransactionHistory}
                            />
                            <ProtectedRoute
                              exact
                              path="/contacts"
                              component={ComingSoon}
                            />
                            <ProtectedRoute
                              exact
                              path="/cards"
                              component={Cards}
                            />
                            <ProtectedRoute
                              exact
                              path="/ticket"
                              component={TicketPages}
                            />
                            <ProtectedRoute
                              exact
                              path="/dispute-ticket"
                              component={TicketPage}
                            />
                            <ProtectedRoute
                              exact
                              path="/notifications"
                              component={Notifications}
                            />
                            <ProtectedRoute
                              exact
                              path="/dispute"
                              component={Dispute}
                              // component={ComingSoon}
                            />
                            <ProtectedRoute
                              exact
                              path="/support"
                              component={SupportPage}
                            />
                            {/* <ProtectedRoute
                          exact
                          path="/profile/settings"
                          component={Setting}
                        /> */}
                            <ProtectedRoute
                              exact
                              path="/corporate-settings"
                              component={CorporateSetting}
                            />
                            <ProtectedRoute
                              exact
                              path="/chat"
                              component={Chat}
                            />

                            <ProtectedRoute
                              exact
                              path="/wayagram"
                              component={Wayagram}
                            />
                            <ProtectedRoute
                              path="/settings"
                              component={Setting}
                            />
                            <ProtectedRoute
                              exact
                              path="/interest"
                              component={Interest}
                            />
                            <ProtectedRoute
                              exact
                              path="/post/:id"
                              component={CommentView}
                            />
                            <ProtectedRoute
                              exact
                              path="/following/:id"
                              component={WayagramFollowing}
                            />
                            <ProtectedRoute
                              exact
                              path="/wayagram-profile/:id"
                              component={WayagramProfile}
                            />
                            <ProtectedRoute
                              exact
                              path="/wayagram-group/:id"
                              component={WayagramGroup}
                            />
                            <ProtectedRoute
                              exact
                              path="/activity-log"
                              component={Activity}
                              //  component={ComingSoon}
                            />
                            <ProtectedRoute
                              exact
                              path="/loan-request"
                              component={LoanRequest}
                            />
                            <ProtectedRoute
                              exact
                              path="/wayagram-page/:id"
                              component={WayagramPage}
                            />
                            {/* <Route exact path="/single-post/:id" component={Wayapay} /> */}
                            <ProtectedRoute
                              exact
                              path="/manage-groups"
                              component={MyGroups}
                            />
                            <ProtectedRoute
                              exact
                              path="/manage-pages"
                              component={ManagePages}
                            />
                            <ProtectedRoute
                              exact
                              path="/wayagram-discover"
                              component={Discover}
                            />
                            <ProtectedRoute
                              exact
                              path="/wayagram-bookmarks"
                              component={Bookmarks}
                            />
                            <ProtectedRoute
                              exact
                              path="/terminalPos-settings"
                              component={TerminalPosSettings}
                            />
                            <ProtectedRoute
                              exact
                              path="/referal-table"
                              component={ReferalTable}
                            />
                            <ProtectedRoute
                              exact
                              path="/api-documentation"
                              component={ApiDocumentation}
                            />
                            <ProtectedRoute
                              exact
                              path="/webPos-settings"
                              component={WebPosSetting}
                            />
                          </LayoutContextProvider>
                        </WayagramContextProvider>
                        <ProtectedRoute
                          exact
                          path="/verify_email"
                          component={VerifyEmail}
                        />
                        <Route exact path="/logout" component={Logout} />
                      </ModalContextProvider>
                    </ProfileContextProvider>
                  </WalletContextProvider>
                </CardContextProvider>
              </PaymentContextProvider>
            </BillerContextProvider>
          </NotificationContextProvider>
        </Switch>
      </Router>
      <SupportButton />
    </Provider>
    {/* </ErrorBoundary> */}
  </>
);

export default App;
