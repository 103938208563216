import React from 'react';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';

const ConfirmModal = (props) => {
  const { showModal, hideModal, title, description, handleSubmit } = props;

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalBody>
        <div>
          <h4 className="mt-5 mb-3 text-center">{title}</h4>
          <p className="text-secondary mb-3 text-center">{description}</p>
        </div>
      </ModalBody>
      <ModalFooter className="p-0" style={{ border: '1px solid  #F2F2F2' }}>
        <div className="col-12 m-0">
          <div className="row">
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              style={{ borderRight: '1px solid  #F2F2F2' }}
              onClick={() => hideModal(false)}
            >
              CANCEL
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              onClick={handleSubmit}
            >
              CONTINUE
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
