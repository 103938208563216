import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './eventModal.scss';

import { locationType } from './data';

const SearchEventLocation = (props) => {
  const { handleFormInput, values } = props;
  const [state, setState] = useState({
    selectedType: values.selectedType,
  });

  const handleLocationType = (type) => {
    console.log(type);
    if (type === 'Virtual') {
      setState({ ...state, virtual: true, selectedType: type });
    } else {
      setState({ ...state, virtual: false, selectedType: type });
    }
    handleFormInput(state.selectedType, 'selectedType');
    handleFormInput(state.virtual, 'virtual');
  };
  return (
    <div className="event-inner-content">
      <div className="location-type-container">
        {locationType.map((item) => (
          <div
            key={item}
            className={
              state.selectedType === item
                ? 'location-type-box active-type'
                : 'location-type-box'
            }
            onClick={() => handleLocationType(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {state.selectedType === 'Virtual' ? (
        <div>
          <div className="input-container">
            <input
              name="location"
              values={values.location}
              onChange={(e) => {
                handleFormInput(e.target.value, 'location');
              }}
              placeholder="Event location"
            />
            <span>Where are you hosting the event? e.g Zoom, Skype</span>
          </div>
          <div className="input-container">
            <input
              name="urlLink"
              values={values.urlLink}
              onChange={(e) => {
                handleFormInput(e.target.value, 'urlLink');
              }}
              placeholder="url Link to event"
            />
            <span>Please input the link to the event</span>
          </div>
        </div>
      ) : (
        <div className="input-container mt-5">
          <input
            name="location"
            values={values.location}
            onChange={(e) => {
              handleFormInput(e.target.value, 'location');
            }}
            placeholder="Event location"
          />
          <span>What is the name of the event organizer</span>
        </div>
      )}
    </div>
  );
};

SearchEventLocation.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default SearchEventLocation;
