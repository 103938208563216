import React, { useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import validateImage from '../../../../../utils/validateImage';
import './eventModal.scss';
import eventIcon from '../../../../../assets/images/eventIcon.png';
import fileCopy from '../../../../../assets/images/file_copy.png';
import cameraImg from '../../../../../assets/image/camera_alt.png';
import users from '../../../../../assets/image/users.svg';
import tag from '../../../../../assets/image/tag.svg';
// import privateImg from '../../../../../assets/image/private.svg';
import location from '../../../../../assets/image/location.svg';
import contact from '../../../../../assets/image/contact.svg';

const PublishEventModal = (props) => {
  const { handleFormInput, values } = props;

  // const [loading, setLoading] = useState(false);
  const [previeweventPoster, setPrevieweventPoster] = useState(null);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'eventPoster') {
        handleFormInput(imageData, 'eventPoster');
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'eventPoster') {
          setPrevieweventPoster(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  return (
    <div>
      <div className="event-icon-container">
        <img src={previeweventPoster || eventIcon} alt="event" />
        <img src={cameraImg} className="upload-banner-input" alt="camera" />
        <input
          type="file"
          className="upload-banner-input2"
          onChange={(e) => handlePreview(e, 'eventPoster')}
        />
      </div>
      <div className="event-preview-container my-1">
        {values?.virtual === true ? (
          <div className="event-url-container mt-3">
            <div className="event-url-detail">{values?.urlLink}</div>
            <div className="event-url-copy">
              <img src={fileCopy} alt="flie-copy" />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="event-details-container mb-4">
          <div className="event-title mt-3 mb-1">{values?.eventName}</div>
          <div className="event-date mt-2 mb-3">
            {moment(values?.eventStart).format('MMMM Do YYYY, h:mm:ss a')}
          </div>

          <div className="event-group">
            <img src={users} alt="organizers name" />
            {values?.organizersName}
          </div>
          <div className="event-group">
            <img src={tag} alt="payment" />
            {values?.isPaid === true
              ? `Pay to enter: ${values?.amount}`
              : 'Free to enter'}
          </div>
          <div className="event-group">
            <img src={location} alt="location" />
            {`location: ${values?.location}`}
          </div>
          <div className="event-group">
            <img src={contact} alt="registered" />
            {`registered: ${values?.registered || 0}`}
          </div>
          {/* <div className="event-group">
            <img src={privateImg} alt="private" />
            {values?.isPrivate === true ? 'Private Event' : 'Public Event'}
          </div> */}
        </div>
        {values?.isPaid === true && (
          <div className="payment-info my-3 mx-auto">
            <div className="mb-1">Payment Info</div>
            <div>
              Waya will take a commission from the payment and the rest of the
              money will be paid into my account at the end of post duration or
              its expirey date.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublishEventModal;
