import React from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'reactstrap';
import Button from '../Button';
import avatar2 from '../../../assets/image/avatar2.svg';

const AdminList = (props) => {
  const { showModal, hideModal, members } = props;
  const history = useHistory();

  return (
    <Modal
      isOpen={showModal}
      centered
      toggle={() => hideModal(false)}
      size="lg"
    >
      <div
        style={{
          padding: '27px',
        }}
      >
        <div className="group-search">
          <input type="text" name="" id="" placeholder="Search" />
        </div>
        <div className="request-cont">
          {members?.length ? (
            members.map((member) => (
              <div className="request" key={member?.id}>
                <div
                  className="req-img"
                  onClick={() => {
                    history.push(`/wayagram-profile/${member?.Profile?.id}`);
                  }}
                >
                  <img src={member?.Profile?.avatar || avatar2} alt="" />
                </div>
                <div className="req-det">
                  <div
                    className="det-det"
                    onClick={() => {
                      history.push(`/wayagram-profile/${member?.Profile?.id}`);
                    }}
                  >
                    <div>{member?.Profile?.displayName}</div>
                    <div>{`@${member?.Profile?.username}`}</div>
                  </div>
                  <div
                    className="det-button"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      type="button"
                      className=""
                      onClick={() => {}}
                      content="Admin"
                      style={{
                        background: '#27AE60',
                        width: '80px',
                        color: '#fff',
                        border: '1px solid #27ae60',
                        borderRadius: '2px',
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="wl-100 my-4 text-center">No Admin Member</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AdminList;

export const MuteList = (props) => {
  const { showModal, hideModal, members } = props;
  const history = useHistory();

  return (
    <Modal
      isOpen={showModal}
      centered
      toggle={() => hideModal(false)}
      size="lg"
    >
      <div
        style={{
          padding: '27px',
        }}
      >
        <div className="group-search">
          <input type="text" name="" id="" placeholder="Search" />
        </div>
        <div className="request-cont">
          {members?.length ? (
            members.map((member) => (
              <div className="request" key={member?.id}>
                <div
                  className="req-img"
                  onClick={() => {
                    history.push(`/wayagram-profile/${member?.Profile?.id}`);
                  }}
                >
                  <img src={member?.Profile?.avatar || avatar2} alt="" />
                </div>
                <div className="req-det">
                  <div
                    className="det-det"
                    onClick={() => {
                      history.push(`/wayagram-profile/${member?.Profile?.id}`);
                    }}
                  >
                    <div>{member?.Profile?.displayName}</div>
                    <div>{`@${member?.Profile?.username}`}</div>
                  </div>
                  <div
                    className="det-button"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      type="button"
                      className=""
                      onClick={() => {}}
                      content="Unmute"
                      style={{
                        background: '#E7472C',
                        color: '#fff',
                        border: '1px solid #e7472c',
                        borderRadius: '2px',
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="wl-100 my-4 text-center">No Muted Member</div>
          )}
        </div>
      </div>
    </Modal>
  );
};
