import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Browser, getUserInfo } from '../../../utils/locationInfo';
import './index.scss';
import ToggleInput from '../../shared/ToggleInput';
import 'react-responsive-modal/styles.css';
import { customBaseUrl, httpPost, httpGet } from '../../../action/http';
import Button from '../../shared/Button';
import ForgotPassword from '../../shared/Modals/ForgotPassword';
import ResetPassword from '../../shared/Modals/ResetPassword';
import ResendOtp from '../../shared/Modals/Otp/ResendOtpOutside';
import VerifyOtp from '../../shared/Modals/Otp/VerifyOtp';
import SecurePin from '../../shared/Modals/SecurePin';
import Logo from '../../../assets/image/wayaIcon.png';
import envConfig from '../../../utils/envConfig';
import { hideLoader, showLoader } from '../../../utils/loader';
import { decrypt } from '../../../utils/helper';

const LoginPage = (props) => {
  const history = useHistory();
  const [location, setLocation] = useState({});
  const [data, setData] = useState({
    emailOrPhoneNumber: '',
    password: '',
  });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [confirmOtp, setConfirmOtp] = useState('');

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [showPin, setShowPin] = useState(false);

  const getLocationInfo = async () => {
    const userBrowserName = Browser.getBrowserName();
    const userInfo = await getUserInfo();
    setLocation({
      device: userBrowserName,
      userLocation: userInfo,
    });
  };

  const handleLocation = async (userId) => {
    const userBrowserName = Browser.getBrowserName();
    const loc = await httpGet('http://ip-api.com/json/102.89.34.233');
    await httpPost(
      '/api/v1/history/save',
      {
        city: location?.userLocation?.city || loc?.city,
        country: location?.userLocation?.country_name || loc?.country,
        device: location?.device || userBrowserName,
        id: 0,
        ip: location?.userLocation?.IPv4 || loc?.query,
        province: location?.userLocation?.state || loc?.regionName,
        userId,
      },
      customBaseUrl.authUrl
    );
    //  console.log('Response data', res);
  };

  useEffect(() => {
    showLoader();
    const {
      location: { search },
    } = props;
    if (new URLSearchParams(search).get('client') === 'web') {
      //  console.log('gets here');
      const iv = new URLSearchParams(search).get('iv');
      const token = new URLSearchParams(search).get('token');
      const userId = new URLSearchParams(search).get('userId');
      const profileId = new URLSearchParams(search).get('profileId');

      const decoded = decrypt({ iv, encryptedData: token });

      localStorage.setItem('token', decoded);
      localStorage.setItem('userId', userId);
      localStorage.setItem('profileId', profileId);
      localStorage.setItem('corporate', false);
      localStorage.setItem('showSplashScreen', true);
      history.push('/wallet');
      handleLocation(userId);
      hideLoader();
      return;
    }
    if (localStorage.getItem('token')) {
      window.location.href = '/wallet';
      return;
    }
    window.location.href = `${envConfig.websiteAppUrl}/login`;
  }, []);

  const resendOtp = async (phoneNumber) => {
    setLoading(true);
    setPhone(phoneNumber);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${phoneNumber}`
    );
    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success').then(() => {
        setShowResendOtp(false);
        setShowVerifyOtp(true);
      });
    } else {
      setLoading(false);
      swal('Wrong', `${res.message}`, 'error', {
        button: {
          text: 'Resend OTP',
          className: 'button-warning',
        },
      }).then(() => {
        setPhone('');
      });
    }
  };

  const createPin = async () => {
    setLoading(true);

    if (!validator.equals(otp, confirmOtp)) {
      swal('Oops!', 'Pin do not match', 'error');
      setLoading(false);
      return;
    }

    const userId = localStorage.getItem('userId');

    const postData = {
      phoneOrEmail: email,
      pin: Number(otp),
      userId,
    };

    const res = await httpPost(
      '/api/v1/pin/create-pin',
      postData,
      customBaseUrl.authUrl
    );
    if (res.status) {
      setLoading(false);
      setOtp('');
      setEmail('');
      swal('Done', res.message, 'success').then(() => {
        history.push('/products');
        setShowPin(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  const completeSignup = async () => {
    setLoading(true);
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    const res = await httpPost(
      '/api/v1/auth/verify-otp',
      postData,
      customBaseUrl.authUrl
    );
    if (res.status) {
      setLoading(false);
      setOtp('');
      setPhone('');
      setShowVerifyOtp(false);
      swal('Done', res.message, 'success').then(() => {
        window.location.href = `${envConfig.websiteAppUrl}/login`;
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(data.emailOrPhoneNumber)) {
      setLoading(false);
      swal('Oops!', 'Email cannot be empty', 'error');
      return;
    }
    if (validator.isEmpty(data.password)) {
      setLoading(false);
      swal('Oops!', 'Password cannot be empty', 'error');
      return;
    }
    const res = await httpPost(
      '/api/v1/auth/login',
      { ...data, admin: false },
      customBaseUrl.authUrl
    );
    //  console.log(res);
    if (res?.status) {
      localStorage.setItem('token', res?.data?.token);
      localStorage.setItem('userId', res?.data?.user.id);
      localStorage.setItem('wayaUserData', JSON.stringify(res?.data));
      localStorage.setItem('corporate', res?.data?.corporate);
      setLoading(false);
      setEmail(data?.emailOrPhoneNumber);
      // if (!res.data.user.isPhoneVerified) {
      //   hideModal(false);
      //   setShowVerifyOtp(true);
      // }
      if (!res.data.pinCreated) {
        localStorage.setItem('firstLogin', true);
        // setShowPin(true);
      }
      localStorage.setItem('showSplashScreen', true);
      localStorage.setItem('isEmailVerified', res?.data?.user?.isEmailVerified);
      localStorage.setItem('isPhoneVerified', res?.data?.user?.isPhoneVerified);
      httpPost(
        '/api/v1/history/save',
        {
          city: location?.userLocation?.city,
          country: location?.userLocation?.country_name,
          device: location?.device,
          id: 0,
          ip: location?.userLocation?.IPv4,
          province: location?.userLocation?.state,
          userId: res.data.user.id,
        },
        customBaseUrl.authUrl
      );
      history.push('/products');
    } else {
      setLoading(false);
      swal(
        'Oops!',
        res?.message === 'User account is disabled, kindly contact Waya Admin'
          ? 'Your account has not been verified, proceed to verify'
          : res?.message,
        'error'
      ).then(() => {
        if (
          res?.message === 'User account is disabled, kindly contact Waya Admin'
        ) {
          setEmail(data.emailOrPhoneNumber);
          setShowVerifyOtp(true);
          setPhone(data.emailOrPhoneNumber);
        }
        if (
          res?.message === 'User is disabled' ||
          res?.message === 'Account not Verified'
        ) {
          setEmail(data.emailOrPhoneNumber);
          setPhone(data.emailOrPhoneNumber);
          setShowVerifyOtp(true);
        }
      });
    }
  };
  useEffect(() => {
    getLocationInfo();
    return () => {
      setLocation({});
    };
  }, []);

  return (
    <div id="login-bg">
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
          <div className="header-sec-modal-log">
            <img className="header-sec-logo-log mx-auto" src={Logo} alt="" />
          </div>
          <h1 className="modal-header-data-log">Welcome Back</h1>

          <form>
            <div className="inputbox-with-one-input-log">
              <input
                placeholder="Email or Phone"
                type="text"
                onChange={(e) =>
                  setData({ ...data, emailOrPhoneNumber: e.target.value })
                }
              />
            </div>

            <div className="inputbox-with-one-input-log toggle-pass-wrapper">
              <ToggleInput
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
              />
            </div>
          </form>
          <div className="submit-modal-btn-wrap-log">
            <Button
              type="button"
              loading={loading}
              onClick={handleSubmit}
              content="Login"
            />
          </div>

          <Container className="text-center">
            <span className="">
              Dont have an account?{' '}
              <Link className="text-primary" to="signup">
                Sign up instead
              </Link>
            </span>
            <br />
            <span
              className="text-primary"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Forgot your password?
            </span>
          </Container>
          <div
            style={{
              fontSize: '14px',
              width: '80%',
            }}
            className="mx-auto d-flex  login-footer justify-content-between text-black"
          >
            <span>© Wayapay</span>
            <span>About</span>
            <span>Insights</span>
            <span>Terms</span>
            <span>Privacy</span>
          </div>
        </div>
      </div>
      {showForgotPasswordModal ? (
        <ForgotPassword
          center="true"
          showModal={showForgotPasswordModal}
          hideModal={setShowForgotPasswordModal}
          setShowResetPassword={setShowResetPassword}
        />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeSignup}
          loading={loading}
          numInputs={6}
          resendOtp={setShowResendOtp}
        />
      ) : (
        ''
      )}
      {showResetPassword ? (
        <ResetPassword
          center="true"
          showModal={showResetPassword}
          hideModal={setShowResetPassword}
        />
      ) : (
        ''
      )}
      {showResendOtp ? (
        <ResendOtp
          center="true"
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title="Resend OTP"
          description="Please input the email and mobile number"
          handleSubmit={resendOtp}
          loading={loading}
        />
      ) : (
        ''
      )}
      {showPin ? (
        <SecurePin
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          confirmOtp={confirmOtp}
          setConfirmOtp={setConfirmOtp}
          separator=""
          title="Create Secure PIN"
          description="Keep your account safe with a 4 digit PIN"
          handleSubmit={createPin}
        />
      ) : (
        ''
      )}
    </div>
  );
};

LoginPage.propTypes = {
  // center: PropTypes.bool.isRequired,
  // history: PropTypes.shape.isRequired,
  // setShowPin: PropTypes.func.isRequired,
};

export default LoginPage;
