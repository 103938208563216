import React, { useState } from 'react';
import swal from 'sweetalert';
import validateImage from '../../../../../utils/validateImage';
// import './eventModal.scss';
import eventIcon from '../../../../../assets/images/eventIcon.png';
import cameraImg from '../../../../../assets/image/camera_alt.png';
import users from '../../../../../assets/image/users.svg';
import tag from '../../../../../assets/image/tag.svg';
import privateImg from '../../../../../assets/image/private.svg';
import location from '../../../../../assets/image/location.svg';
import contact from '../../../../../assets/image/contact.svg';

const PublishDonation = (props) => {
  const { handleFormInput, values } = props;

  // const [loading, setLoading] = useState(false);
  const [previeweDonationPoster, setPreviewDonationPoster] = useState(null);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'donationImage') {
        handleFormInput(imageData, 'donationImage');
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'donationImage') {
          setPreviewDonationPoster(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  return (
    <div>
      <div className="event-icon-container">
        <img src={previeweDonationPoster || eventIcon} alt="event" />
        <img src={cameraImg} className="upload-banner-input" alt="camera" />
        <input
          type="file"
          className="upload-banner-input2"
          onChange={(e) => handlePreview(e, 'donationImage')}
        />
      </div>
      <div className="event-preview-container">
        <div className="event-details-container mb-4">
          <div className="event-title mt-3 mb-1">{values?.title}</div>

          <div className="event-group">
            <img src={users} alt="organizers name" />
            {values?.organizerName}
          </div>
          <div className="event-group">
            <img src={tag} alt="payment" />
            {`Description: ${values.description}`}
          </div>
          <div className="event-group">
            <img src={location} alt="location" />
            {`Amount Needed: ${
              values?.displayTotalDonation === 'true'
                ? values?.displayTotalDonation
                : '#####'
            }`}
          </div>
          <div className="event-group">
            <img src={contact} alt="registered" />
            uche and 15 others donated
          </div>
          <div className="event-group">
            <img src={privateImg} alt="private" />
            #350,000 out of #700,000
          </div>
        </div>
      </div>

      <div className="payment-info my-3 mx-auto">
        <div className="mb-1">Payment Info</div>
        <div>
          Waya will take a commission from the payment and the rest of the money
          will be paid into my account at the end of post duration or its
          expirey date.
        </div>
      </div>
    </div>
  );
};

export default PublishDonation;
