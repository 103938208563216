import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';

export const CardContext = createContext();

export const CardContextProvider = (props) => {
  const { children } = props;
  const [reload, setReload] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [wdBankList, setWdBankList] = useState([]);
  const [availableBank, setAvailableBank] = useState([]);
  const [withdrawalProvider, setWithdrawalProvider] = useState(true);

  const getCardList = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpGet(`${customBaseUrl.cardUrl}/card/list/${userId}`);
    if (res?.status) {
      setCardList(res.data);
    }
  };

  const getAvailableBanksForFunding = async () => {
    const res = await httpGet(
      'https://api.paystack.co/bank?gateway=emandate&pay_with_bank=true'
    );
    setAvailableBank(res);
  };

  const getBankAccountList = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/list/${userId}`
    );
    if (res?.status) {
      setBankAccountList(res.data);
    }
  };

  const getBanks = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/WD`
    );
    const wdRes = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/WD`
    );
    if (res?.status) {
      setBankList(
        res?.data?.map((el) => ({ code: el.bankCode, name: el.bankName }))
      );
      // console.log('setBankListsetBankList====>', res.data);
      setWdBankList(wdRes?.data);
    }
  };

  const checkWithdrawalProvider = async () => {
    const res = await httpGet(`${customBaseUrl.withdrawalUrl}/provider/list`);
    if (res?.status) {
      const prov = res?.data?.filter((e) => e?.active);
      console.log('prov', prov?.length > 0 ?? false);
      setWithdrawalProvider(prov?.length > 0 ?? false);
    }
  };

  useEffect(() => {
    getCardList();
    checkWithdrawalProvider();
    getBankAccountList();
    getBanks();
    getAvailableBanksForFunding();
  }, [reload]);

  return (
    <CardContext.Provider
      value={{
        setCardList,
        cardList,
        reload,
        setReload,
        bankAccountList,
        bankList,
        availableBank,
        wdBankList,
        withdrawalProvider,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
