import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import backArrow from '../../../../assets/image/back-arrow.png';
import Button from '../../Button';
import './index.scss';

export default function SingleRequest(props) {
  const {
    showModal,
    hideModal,
    handleNext,
    title,
    id,
    displayData,
    setNotes,
    notes,
  } = props;
  const [showNotes, setShowNote] = useState(false);

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <div className="border-0" style={{ padding: '1rem 1rem' }}>
        <div className="col-12">
          <div className="row">
            <div className="col-1 add-cursor" onClick={() => hideModal(false)}>
              <img src={backArrow} alt="back" style={{ height: '12px' }} />
            </div>
            <div className="col-10 text-center">{title}</div>
          </div>
        </div>
      </div>
      <ModalBody>
        <div className="mx-auto" style={{ width: '70%' }}>
          {displayData &&
            Object.keys(displayData).map((keyName) => (
              <div className="row" key={Math.random()}>
                <p className="col">{keyName}</p>
                <p
                  className={
                    keyName === 'status' && displayData[keyName] === 'PENDING'
                      ? 'col text-bold text-warning'
                      : keyName === 'status' && displayData[keyName] === 'PAID'
                      ? 'col text-bold text-success'
                      : keyName === 'status' &&
                        displayData[keyName] === 'REJECTED'
                      ? 'col text-bold text-danger'
                      : 'col text-bold'
                  }
                >
                  {displayData[keyName]}
                </p>
              </div>
            ))}
          {showNotes && (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Add a Note"
                type="text"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                style={{ height: 70 }}
              />
            </div>
          )}
        </div>
      </ModalBody>
      <div
        className="col-12 mb-3 mt-3 footer"
        style={
          displayData.status !== 'PENDING' && displayData.status !== 'FAILED'
            ? { display: 'none' }
            : {}
        }
      >
        <div className="row">
          <div className="col text-center" style={{ width: '150%' }}>
            <Button
              className={`btn  ${showNotes ? 'btn-danger' : 'btn-reject'}`}
              onClick={() => {
                if (!showNotes) {
                  setShowNote(true);
                } else {
                  handleNext('reject');
                }
              }}
              disabled={showNotes ? notes === '' : false}
              content="Reject"
              style={{ width: '70%' }}
            />
          </div>
          {showNotes && (
            <div className="text-center mt-1">
              <Button
                className="btn btn-reject"
                onClick={() => setShowNote(false)}
                content="X"
                style={{ width: '20%' }}
              />
            </div>
          )}
          {!showNotes && (
            <div className="col text-center">
              <Button
                className="btn btn-primary"
                onClick={() => handleNext('settle')}
                content="Settle"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

SingleRequest.defaultProps = {
  title: '',
};

SingleRequest.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
};
