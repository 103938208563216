import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { OtherDropdownWrapper } from './styles';

function OtherDropdown(props) {
  const {
    options,
    handleClick,
    positionLeft,
    positionRight,
    hide,
    data,
    positionTop,
    positionBottom,
  } = props;

  OtherDropdown.handleClickOutside = () => hide(false);

  return (
    <OtherDropdownWrapper
      left={positionLeft || '0'}
      right={positionRight || '0'}
      top={positionTop}
      bottom={positionBottom}
      style={!options.length ? { display: 'none' } : {}}
    >
      {options.length
        ? options.map((item) => (
            <div
              className="d-flex"
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => handleClick(item.type, data)}
            >
              <div>{item.name}</div>
            </div>
          ))
        : ''}
    </OtherDropdownWrapper>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => OtherDropdown.handleClickOutside,
};

export default onClickOutside(OtherDropdown, clickOutsideConfig);
