import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import SuccessModal from '../SuccessModal';
import {
  customBaseUrl,
  httpPost,
  httpPostUnreloaded,
} from '../../../../action/http';
import NumberInput from '../../NumberInput';
import './index.scss';
import { WalletContext } from '../../../../store/context/WalletContext';
import { CardContext } from '../../../../store/context/CardContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import VerifyOtp from '../Otp/VerifyOtp';
import VerifyPhone from '../Otp/VerifyPhone';
import addCard from '../../../../assets/image/addCard.png';
import Button from '../../Button';
// import PinModal from '../PinModal';

const Index = (props) => {
  const { showModal, hideModal, id, history } = props;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [reference, setReference] = useState('');
  const [data, setData] = useState({});
  const [phone, setPhone] = useState('');
  const { profile: userProfile } = useContext(ProfileContext);
  const { walletList } = useContext(WalletContext);
  const { setReload, reload } = useContext(CardContext);
  const [loading, setLoading] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);

  const handleAddCard = async () => {
    setLoading(true);
    // const postData = {
    //   cardNumber: 'string',
    //   cvv: 'string',
    //   email: 'string',
    //   expiryMonth: 'string',
    //   expiryYear: 'string',
    //   last4digit: 'string',
    //   name: 'string',
    //   userId: 'string',
    // };
    const postData = {
      ...data,
      userId: userProfile.userId,
      email: userProfile.email,
      surname: userProfile.surname,
    };
    const res = await httpPost(`/card/add`, postData, customBaseUrl.cardUrl);
    if (res.status) {
      if (res.message === 'send_otp') {
        setReference(res.data.reference);
        setShowVerifyOtp(true);
        setLoading(false);
        return;
      }
      if (res.message === 'send_phone') {
        setReference(res.data.reference);
        setShowVerifyPhone(true);
        setLoading(false);
        return;
      }
      swal('Done', res.message, 'success').then(async () => {
        setLoading(false);
        hideModal(false);
        setReload(!reload);
        setShowSuccessModal(true);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  const handleVerifyOtp = async () => {
    //  const postData = {
    //   otp,
    //   reference,
    // };
    // const newForm = new FormData();
    // newForm.set('otp', otp);
    // newForm.set('reference', reference);

    const res = await httpPostUnreloaded(
      `/card/submitOtp?otp=${otp}&reference=${reference}&userId=${userProfile.userId}`,
      {},
      customBaseUrl.cardUrl
    );

    if (res.status) {
      setShowSuccessModal(true);
      setShowVerifyOtp(false);
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleVerifyPhone = async () => {
    const res = await httpPostUnreloaded(
      `/card/submitPhone?phone=${phone}&reference=${reference}&userId=${userProfile.userId}`,
      {},
      customBaseUrl.cardUrl
    );

    if (res.status) {
      if (res.message === 'send_otp') {
        setReference(res.data.reference);
        setShowVerifyPhone(false);
        setShowVerifyOtp(true);
        setLoading(false);
        return;
      }
      setShowVerifyPhone(false);
      hideModal(false);
      setReload(!reload);
      setShowSuccessModal(true);
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Add Card</ModalHeader>
      <ModalBody>
        <div className="addCard-body-img">
          <span className="addCard-title">
            {data.cardNumber && data.cardNumber.length > 16
              ? data.cardNumber.slice(0, 16)
              : data.cardNumber}
          </span>
          <img src={addCard} alt="bank" />
          <div className="addCard-info">
            <div className="d-flex">
              <span>Card holder</span>
              <span>Expire date</span>
            </div>
            <div className="d-flex">
              <span>{data.name}</span>
              <span>
                {data.expiryMonth}/{data.expiryYear}
              </span>
            </div>
          </div>
        </div>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <input
              placeholder="Full Name"
              type="text"
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
            <span className="input-detail">Name of Card Owner</span>
          </div>

          <div className="inputbox-with-one-input">
            <NumberInput
              value={data.cardNumber}
              onChange={({ value }) => {
                // if (value.length > 16) {
                //   return;
                // }
                setData({ ...data, cardNumber: value });
              }}
              placeholder="0000 0000 0000 0000"
            />
            <span className="input-detail">Input Card Number</span>
          </div>

          <div className="custom-my">
            <div className="d-flex justify-content-around align-items-center w-100 custom-p-0 ">
              <div className="w-50 d-flex flex-column justify-content-start align-items-center inputbox-with-one-input">
                <NumberInput
                  className="month-input custom-w-100 w-100"
                  format="##"
                  label="Expiry Month"
                  name="expMonth"
                  placeholder="MM"
                  value={data.expiryMonth}
                  type="text"
                  isAllowed={({ value }) => {
                    if (value.toString().length === 2) {
                      return Number(value) <= 12;
                    }
                    return true;
                  }}
                  onChange={({ value }) => {
                    setData((state) => ({ ...state, expiryMonth: value }));
                  }}
                />
              </div>
              <div className="w-50 d-flex flex-column justify-content-start align-items-center inputbox-with-one-input">
                <NumberInput
                  className="year-input custom-w-100"
                  format="##"
                  label="Expiry Year"
                  name="expYear"
                  placeholder="YY"
                  value={data.expiryYear}
                  type="text"
                  isAllowed={({ value }) => {
                    if (value.toString().length === 2) {
                      if (
                        Number(value) ===
                          Number(
                            new Date().getFullYear().toString().substr(-2, 2)
                          ) &&
                        Number(data.expiryMonth) <= new Date().getMonth()
                      )
                        return false;

                      return (
                        Number(value) >=
                        Number(
                          new Date().getFullYear().toString().substr(-2, 2)
                        )
                      );
                    }
                    return true;
                  }}
                  onChange={({ value }) => {
                    setData((state) => ({ ...state, expiryYear: value }));
                  }}
                />
              </div>
            </div>
            <span className="input-detail">Expiry month and Year</span>
          </div>

          {/* <div className="inputbox-with-one-input">
            <NumberInput
              value={data.cardNumber}
              onChange={({ value }) => {
                // console.log('exipire', value);
                // setData({ ...data, cardNumber: value });
              }}
              format="##/##"
              placeholder="MM/YY"
              mask={['M', 'M', 'Y', 'Y']}
            />
            <span className="input-detail">Expiry month and Year</span>
          </div> */}

          <div className="inputbox-with-one-input">
            {/* <input placeholder="CVV" type="text" /> */}
            {/* <input
              format="###"
              placeholder="CVV"
              name="cvv"
              value={data.cvv}
              type="password"
              maxLength={3}
              pattern="[0-9]"
              inputMode="numeric"
              onChange={({ value }) => {
                setData((state) => ({ ...state, cvv: value }));
              }}
            /> */}
            <NumberInput
              format="###"
              placeholder="CVV"
              name="cvv"
              value={data.cvv}
              type="text"
              onChange={({ value }) => {
                setData((state) => ({ ...state, cvv: value }));
              }}
            />
            <span className="input-detail">Input CVV number</span>
          </div>

          <div className="inputbox-with-one-input">
            {/* <input placeholder="CVV" type="text" /> */}
            <NumberInput
              format="####"
              placeholder="Last 4 digit"
              value={data.last4digit}
              type="text"
              onChange={({ value }) => {
                setData((state) => ({ ...state, last4digit: value }));
              }}
            />
            <span className="input-detail">Input Last 4 digit</span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              type="password"
              placeholder="Pin"
              value={data.pin}
              onChange={(e) => setData({ ...data, pin: e.target.value })}
            />
            <span className="input-detail">Input Card Pin</span>
          </div>

          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              value={data.accountNo}
              onChange={(e) => {
                setData({ ...data, accountNo: e.target.value });
              }}
            >
              <option value="Select" selected disabled>
                Select
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option value={item?.accountNo} key={item?.accountNo}>
                          {`${item?.acct_crncy_code}-${item?.accountNo}`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">please select a wallet account</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          onClick={handleAddCard}
          loading={loading}
          disabled={loading || false}
        >
          Add Card
        </Button>
      </ModalFooter>
      {showSuccessModal ? (
        <SuccessModal
          showModal={showSuccessModal}
          hideModal={setShowSuccessModal}
          id="success-wrapper"
          message="Bank card added successfully"
          history={history}
          hideBankAccountModal={() => {
            hideModal(false);
            setReload(!reload);
          }}
        />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Otp"
          description="Please input the OTP"
          isResendOtp={false}
          buttonLabel="Verify"
          handleSubmit={handleVerifyOtp}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}

      {showVerifyPhone ? (
        <VerifyPhone
          center
          showModal={showVerifyPhone}
          hideModal={setShowVerifyPhone}
          otp={phone}
          setOtp={setPhone}
          separator=""
          title="Verify Phone"
          description="Please input the Phone attached to your card"
          isResendOtp={false}
          buttonLabel="Verify"
          handleSubmit={handleVerifyPhone}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  history: PropTypes.shape.isRequired,
};

export default Index;
