import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as DropDown from './styles';
import verticalIcon from '../../../assets/image/vertical-icon.png';

const CustomDropdown = ({ handleOption, options, direction }) => {
  // const time = props.createdAt ? moment(props.createdAt).fromNow() : moment(new Date().getTime()).fromNow()

  /**
   * here i am using useState to toggle the dropdown
   */
  const [dropDown, setDropDown] = useState(false);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter = (ref) => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) =>
      ref.current && !ref.current.contains(event.target)
        ? setDropDown(false)
        : null;

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const momentOptions = (
    <div>
      {options.map((item) => (
        <DropDown.MomentOptions
          className="mb-2 d-flex justify-content-center align-items-center"
          onClick={() => handleOption(item.type, item.id)}
          key={Math.random()}
        >
          {item.name === 'Cancel' ? (
            <span
              className="title ml-2 text-primary"
              onClick={() => setDropDown(!dropDown)}
              role="button"
              tabIndex={0}
              aria-hidden="true"
            >
              {item.name}
            </span>
          ) : (
            <div className="title" onClick={() => setDropDown(!dropDown)}>
              {item.name}
            </div>
          )}
        </DropDown.MomentOptions>
      ))}
    </div>
  );

  return (
    <DropDown.MomentDropdown
      ref={wrapperRef}
      style={{ position: 'absolute', right: 0, bottom: '12px' }}
    >
      <DropDown.MomentToggle onClick={() => setDropDown(!dropDown)}>
        <span className={`options ml-auto ${dropDown ? 'active' : ''}`}>
          {direction === 'vertical' ? (
            <i className="mdi mdi-dots-vertical" />
          ) : (
            <img src={verticalIcon} alt="more" />
          )}
        </span>
      </DropDown.MomentToggle>

      {dropDown ? (
        <DropDown.MomentMenu className="py-1">
          {momentOptions}
        </DropDown.MomentMenu>
      ) : null}
    </DropDown.MomentDropdown>
  );
};

CustomDropdown.defaultProps = {
  direction: 'vertical',
};

CustomDropdown.propTypes = {
  handleOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  direction: PropTypes.string,
};

export default CustomDropdown;
