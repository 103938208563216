import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowForward } from 'react-icons/io';
import { Modal, Row, Col, ModalHeader } from 'reactstrap';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// import { Row } from '../containers/containers';
import facebookIcon from '../../../assets/facebookIcon.svg';
import emailIcon from '../../../assets/emailIcon.svg';
// import InstagramIcon from '../../../assets/instagramIcon.svg';
// import smsIcon from '../../../assets/smsIcon.svg';
import twitterIcon from '../../../assets/twitterIcon.svg';
import envConfig from '../../../utils/envConfig';
import { WhatsAppIcon } from '../Icons';

export default function InviteModal(props) {
  const { showModal, hideModal, title, description, profile, referralCode } =
    props;
  const url = `${envConfig?.websiteAppUrl}/signup?isReferral=${referralCode}`;
  const text =
    'Register and enjoying Banking services with WayaBank today using my referal code';
  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      id="pin-modal"
      size="lg"
    >
      <ModalHeader toggle={() => hideModal(false)}>{title}</ModalHeader>
      <div className="modal-body-rs p-2 pb-3">
        <div className="waya-modal-body mt-3">
          <p className="modal-header-sub-data text-secondary">{description}</p>
          <Row width="80%">
            <Col xs="6">
              <TwitterShareButton
                url={url}
                title={text}
                hashtag="#wayabank"
                // media="http://res.cloudinary.com/lms-center/image/upload/v1599506126/alfons-morales-YLSwjSy7stw-unsplash_wcjqij.jpg"
              >
                <Each name="via Twitter">
                  <img src={twitterIcon} alt="" />
                </Each>
              </TwitterShareButton>
            </Col>
            <Col xs="6">
              <WhatsappShareButton
                url={url}
                title={text}
                //  separator={" "}
                // media="http://res.cloudinary.com/lms-center/image/upload/v1599506126/alfons-morales-YLSwjSy7stw-unsplash_wcjqij.jpg"
                //  className={classes.socialMediaButton}
              >
                {/* <WhatsappIcon size={36} /> */}
                <Each name="via Whatsapp">
                  <WhatsAppIcon size={36} />
                </Each>
              </WhatsappShareButton>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FacebookShareButton
                url={url}
                quote="Hello"
                title={text}
                // description="Hello des"
                hashtag="#wayabank"
              >
                <Each name="via Facebook">
                  <img src={facebookIcon} alt="" />
                </Each>
              </FacebookShareButton>
            </Col>
            <Col xs="6">
              <EmailShareButton
                url={url}
                quote={text}
                hashtag="#wayagram"
                //  media="http://res.cloudinary.com/lms-center/image/upload/v1599506126/alfons-morales-YLSwjSy7stw-unsplash_wcjqij.jpg"
                //  className={classes.socialMediaButton}
                subject={`${profile?.firstName} ${profile?.surname} invites you to WAYABANK`}
                body={text}
              >
                {/* <WhatsappIcon size={36} /> */}
                <Each name="via Email">
                  <img src={emailIcon} alt="" />
                </Each>
              </EmailShareButton>
            </Col>
          </Row>
          {/* <Row>
            <Col sm="12" md={{ size: 8, offset: 3 }}>
              <Each name="via  SMS">
                <img src={smsIcon} alt="" />
              </Each>
            </Col>
          </Row> */}
        </div>
      </div>
    </Modal>
  );
}

InviteModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};
const Each = ({ name, children }) => (
  <Card
    sx={{
      display: 'flex',
      width: '290px',
      pl: 1,
      py: 1,
      pr: 1,
      mt: 2,
      mx: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 70,
    }}
    className="invite_card"
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="p">{name}</Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IoIosArrowForward size={24} color="#828282" />
    </Box>
  </Card>
);
