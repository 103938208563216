import {
  CHANGE_FIELD,
  LOADER_ON,
  LOADER_OFF,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  LOGIN_ATTEMPT,
  LOGOUT_USER,
  SUCCESS_MSG_ON,
  SUCCESS_MSG_OFF,
  ERROR_MSG_OFF,
  ERROR_MSG_ON,
  SET_CURRENT_WALLET,
} from './constants';

export const changeWallet = (text) => {
  return { type: SET_CURRENT_WALLET, payload: text };
};
export const successMsgON = (text) => {
  return { type: SUCCESS_MSG_ON, payload: text };
};

export const successMsgOFF = () => {
  return { type: SUCCESS_MSG_OFF };
};

export const errorMsgON = (text) => {
  return { type: ERROR_MSG_ON, payload: text };
};

export const errorMsgOFF = () => {
  return { type: ERROR_MSG_OFF };
};

export const setField = (text) => ({
  type: CHANGE_FIELD,
  payload: text,
});

export const requestUsersssss = () => (dispatch) => {
  dispatch({ type: REQUEST_PENDING });
  fetch('https://jsonplaceholder.typicode.com/users')
    .then((response) => response.json())
    .then((data) => dispatch({ type: REQUEST_SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: REQUEST_FAILED, payload: error }));
};

export const attemptLogin = () => ({
  type: LOGIN_ATTEMPT,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const loaderON = () => ({
  type: LOADER_ON,
});

export const loaderOFF = () => ({
  type: LOADER_OFF,
});
