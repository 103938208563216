import React from 'react';
import PropTypes from 'prop-types';
import { UploadPostButton } from './style';

export const UploadPost = (props) => {
  const { showPost } = props;
  // const [showFlotPostOptions, setShowFlotPostOptions] = useState(false);
  return (
    <div>
      {/* {showFlotPostOptions === false ? (
        ''
      ) : (
        <div className="more-postActions ">
          <span className="more-postTpe">
            <span onClick={props.openPost} className="more-postType-text">
              Create Post
            </span>
            <span onClick={props.openPost} className="more-postType-circle">
              <img
                src="/assets/images/tour/wayagram.png"
                style={{ height: '28px' }}
              />
            </span>
          </span>

          <span className="more-postTpe">
            <span
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#pollModal"
              className="more-postType-text"
            >
              Create Poll
            </span>
            <span
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#pollModal"
              className="more-postType-circle"
            >
              <img src="/assets/images/wayagram/poll-icon.png" />
            </span>
          </span>
        </div>
      )} */}

      <UploadPostButton
        onClick={() => showPost(true)}
        className="d-flex justify-content-center align-items-center hover-card"
      >
        <i className="mdi mdi-plus" />
      </UploadPostButton>
    </div>
  );
};

UploadPost.propTypes = {
  showPost: PropTypes.func.isRequired,
};

export const example = () => {
  return null;
};
