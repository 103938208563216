import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../action/http';
import IdleTimer from '../../utils/IdleTimer';

export const PaymentContext = createContext();

// Get Sent Payment Request

export const PaymentContextProvider = (props) => {
  const { children } = props;
  const [sentRequest, setSentRequest] = useState([]);
  const [receivedRequest, setReceivedRequest] = useState([]);
  const [ussdCode, setUssdCode] = useState('');
  const [reload, setPaymentReload] = useState(false);
  const [virtualAccount, setVirtualAccount] = useState({});
  const [isTimeout, setIsTimeout] = useState(false);
  const [charges, setCharges] = useState({});
  const [nonWayaTransactions, setNonWayaTransactions] = useState([]);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 2 * 60, // expire after 10 seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [isTimeout]);

  const getSentPaymentRequest = async (userId, size = 10) => {
    const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request-sent/${userId}?page=0&size=${size}`
    );
    if (res?.status) {
      // console.log('setSentRequest', res);
      setSentRequest(res.data);
    }
  };

  // Get Received Payment Request
  const getReceivedPaymentRequest = async (userId, size = 10) => {
    const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request-received/${userId}?page=0&size=${size}`
    );
    if (res?.status) {
      // console.log(res);
      setReceivedRequest(res?.data);
    }
  };
  // Get Received Payment Request
  const getPaymentToNonWaya = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/list-transactions/${userId}`
    );
    if (res?.status) {
      // console.log('setReceivedRequest(res?.data)', res?.data?.nonWayaList);
      setNonWayaTransactions(res?.data?.nonWayaList);
      // setReceivedRequest(res?.data);
    }
  };
  const getUssdCode = async () => {
    // const res = await httpGet(`${customBaseUrl.ussdUrl}/api/shortCode`);
    // if (res?.status) {
    // setUssdCode(res.data);
    // }
    setUssdCode();
  };

  const getVirtualAccount = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.accountCreationUrl}/account/getAccounts/${Number(
        userId
      )}`
    );
    if (res?.status) {
      setVirtualAccount(res.data);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getSentPaymentRequest(userId);
    getReceivedPaymentRequest(userId);
    getVirtualAccount(userId);
    getUssdCode();
    getPaymentToNonWaya(userId);
  }, [reload]);

  useEffect(async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/bank/event/charges`
    );
    if (res?.status) {
      // console.log('$$$$$', res.data);
      const walletTransferFee = [...res.data].filter(
        (item) => item?.chargeName === 'Wallet Transfer Charge'
      )[0]?.fixedAmount;

      const billPaymentFee = [...res.data].filter(
        (item) => item?.chargeName === 'Bill Payment Charge'
      )[0]?.fixedAmount;

      const withdrawalFee = [...res.data].filter(
        (item) => item?.chargeName === 'Wallet Withdrawal Charge'
      )[0]?.fixedAmount;

      setCharges({
        walletTransferFee,
        billPaymentFee,
        withdrawalFee,
      });
    }
  }, []);

  return (
    <PaymentContext.Provider
      value={{
        sentRequest,
        setSentRequest,
        receivedRequest,
        setReceivedRequest,
        ussdCode,
        reload,
        setPaymentReload,
        virtualAccount,
        isTimeout,
        setIsTimeout,
        charges,
        setCharges,
        getSentPaymentRequest,
        nonWayaTransactions,
        getReceivedPaymentRequest,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
