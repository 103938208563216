import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import Container from '../../shared/Container';
import Layout from '../../shared/Layout';
import EditProfile from './EditProfile';
import Notification from './Notification';
import PaymentSetting from './Payment';
// import ChatSetting from './Chat';
// import SynchronizeAccount from './SynchronizeAccount';
// import {Transparency} from './Transparency';

// import HelpSettings from './Help';
import LoginInfo from './LoginInfo';
import { SettingWrapper } from './style';
import Profile from '../../shared/Modals/Profile';
import PrivacySettings from '../../shared/Modals/Profile/Privacy';
import PrivacyPolicy from '../../shared/Modals/Terms/PrivacyPolicy';
import TermsAndCondition from '../../shared/Modals/Terms/TermsAndCondition';
import AboutUs from '../../shared/Modals/Terms/AboutUs';
import FeedbackModal from './FeedbackModal';
import BlockedUser from './BlockedUser';
import MutedUser from './MutedUser';
import DisputeModal from '../../shared/Modals/Profile/Dispute';
import QrCodeModal from '../../shared/Modals/QrCode';
import ChangePassword from '../../shared/Modals/Password/ChangePassword';
import { getLastLoginInfo } from '../../../store/calls';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { ModalContext } from '../../../store/context/ModalContext';
import {
  getAllMutedAccounts,
  handleToggleMute,
  getAllBlockedAccounts,
  unblockAccount,
  getLoginHistory,
} from './container';
import './setting.scss';
import { settingTabs } from './static';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import InviteFriend from './invite';

// import WebPosSetting from './WebPos';
// import TerminalPosSettings from './TerminalPos';

export default function Index(props) {
  const { wayagramProfile } = useContext(WayagramContext);
  const {
    userDetails: { corporate },
  } = useContext(ProfileContext);
  const [activeTab, setActiveTab] = useState('profile');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [showBlockedUserModal, setShowBlockedUserModal] = useState(false);
  // const [showRestrictedUserModal, setShowRestrictedUserModal] = useState(false);
  const [showMutedUserModal, setShowMutedUserModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [mutedAccounts, setMutedAccounts] = useState([]);
  const [blockedAccounts, setBlockedAccounts] = useState([]);
  const [loginHistory, setLoginHistory] = useState([]);
  const [locationInfo, setLocationInfo] = useState({});
  const { setModalAction, setShowResendOtp } = useContext(ModalContext);
  const history = useHistory();

  const getLocationInfo = async () => {
    const info = await getLastLoginInfo();
    setLocationInfo(info);
  };

  const handleLginHistory = async () => {
    const historyData = await getLoginHistory();
    setLoginHistory(historyData);
  };

  useEffect(() => {
    getLocationInfo();
    return () => {
      setLocationInfo({});
    };
  }, []);

  const getMutedAccounts = async () => {
    const authId = wayagramProfile.UserId;
    const res = await getAllMutedAccounts(authId);
    setMutedAccounts(res);
  };

  const getBlockedAccounts = async () => {
    const authId = wayagramProfile.UserId;
    const res = await getAllBlockedAccounts(authId);
    setBlockedAccounts(res);
  };

  const handleMuteUser = async (username) => {
    showLoader();
    const authId = wayagramProfile.UserId;
    const res = await handleToggleMute(authId, username);
    if (res.status) {
      setShowMutedUserModal(false);
      getMutedAccounts();
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
    hideLoader();
  };

  const handleUnblockUser = async (username) => {
    showLoader();
    const authId = wayagramProfile.UserId;
    const res = await unblockAccount(authId, username);
    if (res.status) {
      setShowBlockedUserModal(false);
      getBlockedAccounts();
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
    hideLoader();
  };

  useEffect(() => {
    getMutedAccounts();
    getBlockedAccounts();
    handleLginHistory();
  }, [wayagramProfile.id]);

  return (
    <Layout
      route={props}
      currentPage="settings"
      doNot="/settings/team-members"
      pageName="Settings"
      showName
    >
      <SettingWrapper>
        <div
          className="setting-menu"
          style={{ height: '90vh', overflow: 'auto' }}
        >
          <div className="menu-items p-2">
            {settingTabs.map(
              ({
                id,
                title,
                description,
                active,
                personalTitle,
                personalDescription,
              }) => (
                <Container
                  key={id}
                  className={`row m-0 hover
                    ${activeTab === active ? 'item-active' : ''}`}
                  onClick={() => {
                    if (title === 'Send feedback') {
                      setShowFeedbackModal(true);
                    } else if (title === 'Follow and Invite a friend') {
                      setShowInviteModal(true);
                      return;
                    } else if (title === 'Blocked accounts') {
                      setShowBlockedUserModal(true);
                      return;
                    } else if (title === 'Muted accounts') {
                      setShowMutedUserModal(true);
                      return;
                    } else if (active === 'loginInfo') {
                      setActiveTab('loginInfo');
                      return;
                    } else if (active === 'webPosSettings') {
                      history.push('/webPos-settings');
                      return;
                    } else if (active === 'terminalPosSettings') {
                      history.push('/terminalPos-settings');
                      return;
                    } else if (active === 'teamMembers') {
                      history.push('/settings/team-members');
                      return;
                    } else if (active === 'dashboardSettings') {
                      if (corporate) {
                        history.push('/corporate-settings');
                        return;
                      }
                      history.push('/login?corp_auth=true');
                    } else if (active === 'profile') {
                      history.push('/settings/account');
                      return;
                    } else if (active === 'apiDocumentation') {
                      history.push('/api-documentation');
                      return;
                    } else if (active === 'invite') {
                      history.push('/settings/share-invite');
                    }
                    setActiveTab(active);
                  }}
                >
                  <div className="col-10 pl-3 py-2">
                    <div className="mb-0">
                      {corporate ? title : personalTitle || title}
                    </div>
                    <div
                      className={`mb-0 small-text
                        ${activeTab === active ? 'text-white' : 'text-muted'}
                      `}
                    >
                      {corporate ? description : personalDescription || title}
                    </div>
                  </div>
                  <div className="col-2 my-auto">{'>'}</div>
                </Container>
              )
            )}
          </div>
        </div>
        <div className="setting-menu-detail w-75">
          {activeTab === settingTabs[0].active ? (
            <EditProfile
              setShowProfileModal={setShowProfileModal}
              setShowPrivacyModal={setShowPrivacyModal}
              setShowChangePassword={setShowChangePassword}
              setModalAction={setModalAction}
              setShowResendOtp={setShowResendOtp}
              options={settingTabs[0].options}
              setShowInviteModal={setShowInviteModal}
              setShowFeedbackModal={setShowFeedbackModal}
            />
          ) : activeTab === settingTabs[1].active ? (
            <Notification options={settingTabs[1].options} />
          ) : activeTab === settingTabs[2].active ? (
            <PaymentSetting
              setActiveTab={setActiveTab}
              options={settingTabs[2].options}
              setShowDisputeModal={setShowDisputeModal}
            />
          ) : // : activeTab === settingTabs[6].active ? (
          //   <SynchronizeAccount options={settingTabs[20].options} />
          // )
          activeTab === settingTabs[4]?.active ? (
            <InviteFriend setActiveTab={setActiveTab} />
          ) : activeTab === settingTabs[6]?.active ? null : activeTab ===
            settingTabs[7]?.active ? (
            <LoginInfo
              locationInfo={locationInfo}
              loginHistory={loginHistory}
            />
          ) : (
            ''
          )}
        </div>
      </SettingWrapper>
      {showProfileModal ? (
        <Profile
          center="true"
          hideModal={setShowProfileModal}
          showModal={showProfileModal}
          mode="inside"
        />
      ) : (
        ''
      )}
      {showPrivacyModal ? (
        <PrivacySettings
          center="true"
          hideModal={setShowPrivacyModal}
          showModal={showPrivacyModal}
          mode="inside"
        />
      ) : (
        ''
      )}
      {showDisputeModal ? (
        <DisputeModal
          center="true"
          hideModal={setShowDisputeModal}
          showModal={showDisputeModal}
          mode="inside"
        />
      ) : (
        ''
      )}
      {showInviteModal ? (
        <QrCodeModal
          hideModal={setShowInviteModal}
          showModal={showInviteModal}
          mode="invite"
        />
      ) : (
        ''
      )}
      {showChangePassword ? (
        <ChangePassword
          hideModal={setShowChangePassword}
          showModal={showChangePassword}
        />
      ) : (
        ''
      )}
      {showFeedbackModal ? (
        <FeedbackModal
          hideModal={setShowFeedbackModal}
          showModal={showFeedbackModal}
        />
      ) : (
        ''
      )}
      {showBlockedUserModal ? (
        <BlockedUser
          hideModal={setShowBlockedUserModal}
          showModal={showBlockedUserModal}
          data={blockedAccounts}
          unblockUser={handleUnblockUser}
        />
      ) : (
        ''
      )}
      {showMutedUserModal ? (
        <MutedUser
          hideModal={setShowMutedUserModal}
          showModal={showMutedUserModal}
          data={mutedAccounts}
          handleMute={handleMuteUser}
        />
      ) : (
        ''
      )}
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
      {showPrivacyPolicyModal ? (
        <PrivacyPolicy
          showModal={showPrivacyPolicyModal}
          hideModal={setShowPrivacyPolicyModal}
        />
      ) : (
        ''
      )}
      {showAboutUs ? (
        <AboutUs showModal={showAboutUs} hideModal={setShowAboutUs} />
      ) : (
        ''
      )}
    </Layout>
  );
}
