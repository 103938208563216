import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Input, Switch } from 'antd';
import Box from '@mui/material/Box';
import CopyToClipboard from 'react-copy-to-clipboard';
import Layout from '../../shared/Layout';
import Back from '../../../assets/image/back.svg';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';

export default function ApiDocumentation() {
  const [secCopied, setSecCopied] = useState(false);
  const [copied, setCopied] = useState(false);
  const [liveMode, setLiveMode] = useState(false);
  const [secreteKey, setSecreteKey] = useState([]);
  //  const [publicKey, setPublicKey] = useState([]);
  const history = useHistory();

  const handleChecked = (checked) => {
    setLiveMode(!checked);
  };

  const getApiKeys = async () => {
    const res = await httpGet(
      `${customBaseUrl.identitymanagerservice}/api/v1/waya-merchant/fetch/api-keys`
    );

    //  console.log('api', res);
    setSecreteKey(res.data);
    //  setPublicKey(res.data.activePublicKey);

    // console.log('api', res);
    // console.log('pub', publicKey);
    // console.log('secre', secreteKey);
  };
  const handleGenerateKeeys = async () => {
    const res = await httpPost(
      `${customBaseUrl.identitymanagerservice}/api/v1/waya-merchant/api-keys/generate`
    );
    if (res.status) {
      //  console.log('generate', res);
    }
  };

  useEffect(() => {
    getApiKeys();
  });

  return (
    <Layout>
      <div className="w-100">
        <div className="d-flex w-100">
          <div
            onClick={() => {
              history.push('/settings');
            }}
          >
            <img src={Back} alt="" /> Back
          </div>
          <div className="mx-5">
            <Switch
              checkedChildren="Show API Keys"
              unCheckedChildren="Hide API Keys"
              checked={!liveMode}
              onChange={handleChecked}
              defaultChecked
            />
          </div>
        </div>
        <Card className="w-100" title="API Keys & Webhooks">
          <div className="w-100 d-flex justify-content-between">
            <div>
              <h6>
                API Configuration-
                {/* {liveMode === false ? 'Text Mode' : 'Live Mode'} */}
              </h6>
            </div>
            <Button type="primary" danger>
              Save Changes
            </Button>
          </div>
          <div>
            <div className="d-flex justify-content-center my-4">
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <h6>API Test Secret Key</h6>
              </Box>
              <div className="d-flex mx-3">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 500,
                    height: 40,
                    backgroundColor: '#c4c4c4',
                    borderRadius: '5PX',
                  }}
                >
                  <p className="mx-1">
                    {liveMode === true ? secreteKey.activeSecretKey : ''}
                  </p>
                  <p className="mx-1">
                    {liveMode === false
                      ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                      : ''}
                  </p>
                  <CopyToClipboard
                    text={liveMode === true ? secreteKey.activeSecretKey : ''}
                    onCopy={() => {
                      setSecCopied(true);
                      setTimeout(() => {
                        setSecCopied(false);
                      }, 1000);
                    }}
                  >
                    <div className="d-flex mx-2 my-1">
                      <Button
                        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                      >
                        {secCopied === false ? 'Copy key' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </Box>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <h6>API Test Public Key</h6>
              </Box>
              <div className="d-flex mx-2 bg-light text-dark">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 500,
                    height: 40,
                    backgroundColor: '#c4c4c4',
                    borderRadius: '5PX',
                  }}
                >
                  <p className="mx-1">
                    {liveMode === true ? secreteKey.activePublicKey : ''}
                  </p>
                  <p className="mx-1">
                    {liveMode === false
                      ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                      : ''}
                  </p>
                  <CopyToClipboard
                    text={liveMode === true ? secreteKey.activePublicKey : ''}
                    onCopy={() => {
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                  >
                    <div className="d-flex mx-2 my-1">
                      <Button
                        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                      >
                        {copied === false ? 'Copy key' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </Box>
              </div>
            </div>
            <div className="d-flex justify-content-center my-3">
              <Button onClick={handleGenerateKeeys}>
                Generate New Secrete Key
              </Button>
            </div>
            <div>
              <div className="d-flex justify-content-center w-50">
                <h6>Text Callback URL</h6>
                <Input size="large" placeholder="Enter Text Callback URL" />
              </div>
              <div className="d-flex justify-content-center w-50 my-4">
                <h6>Text Webhook URL</h6>
                <Input size="large" placeholder="Enter Text Webhook URL" />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
}
