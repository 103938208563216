import React from 'react';
import { FiSearch } from 'react-icons/fi';
import styles from './normal.module.css';
import { Col, Row } from '../containers/containers';

const RightIconInput = ({
  type = 'text',
  reference,
  placeholder,
  onchange,
}) => {
  // const handleKeyup=()=>{
  //   onchange()
  // }
  return (
    <Row className={styles.input_Search}>
      <input
        ref={reference}
        type={type}
        id="thisInput"
        className={styles.input}
        placeholder={placeholder}
        onKeyUp={onchange}
      />
      <Col>
        <FiSearch color="#FF6700" />
      </Col>
    </Row>
  );
};

export default RightIconInput;
