import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import { Route, useHistory } from 'react-router-dom';
import './setting.scss';
import UploadTier from './UploadTier';
import ManageBankAccountModal from '../../shared/Modals/BankAccount/manage';
import ManageCardModal from '../../shared/Modals/Card/manage';
import LinkCardModal from '../../shared/Modals/Card';
import BankAccountModal from '../../shared/Modals/BankAccount';

function PaymentSetting(props) {
  const { options, setActiveTab } = props;
  const [showManageBankAccount, setShowManageBankAccount] = useState(false);
  const [showManageCard, setShowManageCard] = useState(false);
  const [showAddBankAccount, setShowAddBankAccount] = useState(false);
  const [showLinkCardModal, setShowLinkCardModal] = useState(false);
  const history = useHistory();

  const handleClick = (title) => {
    if (title === options[0].title) {
      // setShowDisputeModal(true);
      setShowManageCard(true);
    }
    if (title === options[1].title) {
      setShowManageBankAccount(true);
    }
    if (title === options[2].title) {
      setActiveTab('uploadTier');
      /// history.push('/settings/account/upgrade-kyc');
      window.location.href = '/settings/account/upgrade-kyc';
    }
  };

  return (
    <div className="setting-inner-item">
      <div className="menu-items p-2">
        {options &&
          options.map(({ id, title, description }) => (
            <Container
              key={id}
              className="row m-0 col-12 d-flex"
              onClick={() => handleClick(title)}
            >
              <div className="col-11 pl-3 py-2">
                <div className="mb-0">{title}</div>
                <div className="text-muted mb-0 small-text">{description}</div>
              </div>
              <div className="col-1 my-auto">{'>'}</div>
              {showManageCard ? (
                <ManageCardModal
                  showModal={showManageCard}
                  hideModal={setShowManageCard}
                  id="account-wrapper"
                  title="check"
                  description="checking"
                  center
                  history={history}
                  setShowLinkCardModal={setShowLinkCardModal}
                />
              ) : (
                ''
              )}
              {showManageBankAccount ? (
                <ManageBankAccountModal
                  showModal={showManageBankAccount}
                  hideModal={setShowManageBankAccount}
                  id="account-wrapper"
                  title="check"
                  description="checking"
                  center
                  history={history}
                  setShowAddBankAccount={setShowAddBankAccount}
                />
              ) : (
                ''
              )}
              {showAddBankAccount ? (
                <BankAccountModal
                  showModal={showAddBankAccount}
                  hideModal={setShowAddBankAccount}
                  id="account-wrapper"
                  history={history}
                />
              ) : (
                ''
              )}
              {showLinkCardModal ? (
                <LinkCardModal
                  showModal={showLinkCardModal}
                  hideModal={setShowLinkCardModal}
                  id="account-wrapper"
                  history={history}
                />
              ) : (
                ''
              )}
              <Route
                path="/settings/account/upgrade-kyc"
                component={() => <UploadTier />}
              />
            </Container>
          ))}
      </div>
    </div>
  );
}

export default PaymentSetting;
