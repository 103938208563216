import React from 'react';
import styles from './styles.module.css';

export const NormalText = ({
  children,
  size,
  color = '#000000',
  top,
  bottom,
  left,
  right,
  align = 'left',
  pLeft,
  pRight,
  decoration,
}) => {
  return (
    <div
      style={{
        fontSize: size,
        color,
        marginBottom: bottom,
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        textAlign: align,
        paddingLeft: pLeft,
        paddingRight: pRight,
        textDecoration: decoration,
      }}
      className={styles.text}
    >
      {children}
    </div>
  );
};

export const BoldText = ({
  children,
  size,
  color = '#000000',
  top,
  bottom,
  left,
  right,
  align = 'left',
  pLeft,
  pRight,
}) => {
  return (
    <div
      style={{
        fontSize: size,
        color,
        marginBottom: bottom,
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        textAlign: align,
        paddingLeft: pLeft,
        paddingRight: pRight,
      }}
      className={styles.text2}
    >
      {children}
    </div>
  );
};
export const LightText = ({
  children,
  size,
  color = '#000000',
  top,
  bottom,
  left,
  right,
  align = 'left',
  pLeft,
  pRight,
}) => {
  return (
    <div
      style={{
        fontSize: size,
        color,
        marginBottom: bottom,
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        textAlign: align,
        paddingLeft: pLeft,
        paddingRight: pRight,
      }}
      className={styles.text3}
    >
      {children}
    </div>
  );
};
