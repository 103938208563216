import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';

import { httpPost } from '../../../action/http';

import './setting.scss';

const FeedbackModal = (props) => {
  const [values, setValues] = useState({
    feedback: '',
  });
  const { showModal, hideModal } = props;
  const [description, setDecription] = useState('');

  const handleSetDescription = ({ target }) => {
    setDecription(target.value);
  };

  const handleSubmit = async () => {
    const url =
      'https://services.staging.wayabank.ng/content-management-service/api/v1/admin/feedback';
    const res = await httpPost(
      '',
      {
        question: values.feedback,
        description,
      },
      url
    );
    if (res.status) {
      swal('Success', res.message);
      hideModal(false);
    }
  };

  const handleChange = ({ target }) => {
    setValues((prev) => ({
      ...prev,
      feedback: target.value,
    }));
  };

  return (
    <Modal
      isOpen={showModal}
      id="feedback-modal"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalHeader className="" toggle={() => hideModal(false)}>
        <div className="">Send Feedback</div>
      </ModalHeader>
      <ModalBody>
        <textarea
          name="feedback"
          className="textarea"
          placeholder="Enter Feedback Content"
          value={values.feedback}
          onChange={handleChange}
        />
        <textarea
          name="description"
          className="textarea_2"
          placeholder="Enter Feedback Title"
          value={description}
          onChange={handleSetDescription}
        />
      </ModalBody>
      <ModalFooter>
        <div className="feedback-button" onClick={handleSubmit}>
          Send
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
