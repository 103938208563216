import React, { useState, useContext } from 'react';
import { Button, Card } from 'antd';
// import { CreditCard } from '@material-ui/icons';
import Swal from 'sweetalert2';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { WalletContext } from '../../../store/context/WalletContext';
import RequestCard from './requestCard';
import wayaCard from '../../../assets/wayaCard.png';
import wayaLog from '../../../assets/newlog.png';
import sim from '../../../assets/sim.png';
import CustomDropdown from '../../shared/Dropdown';
import { customBaseUrl, httpPut } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const CardDetails = ({ cardList }) => {
  const { walletList } = useContext(WalletContext);
  const [showCardReq, setShowCardReq] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  // const [filteredDate, setFilteredDate] = useState('');
  const cardOption = [
    {
      name: cardList?.cardActive ? 'Block Card' : 'Unblock Card',
      type: 'block',
      id: '1',
    },
  ];
  const handleBlockCard = async (id) => {
    showLoader();
    const res = await httpPut(
      `/card/user-block-card/${id}?reason=self`,
      {},
      customBaseUrl.cardUrl
    );
    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res?.message);
    } else {
      Swal.fire('Done', res?.message);
    }
  };
  const handleOption = async (type) => {
    if (type === 'block') {
      handleBlockCard(cardList?.id);
    }
  };
  return (
    <>
      <div className="mt-2 mx-auto flex-column">
        <Card
          bordered
          style={{
            width: '80%',
            borderRadius: 5,
            marginBottom: '50px',
            height: '70px',
            textAlign: 'center',
          }}
        >
          <div className="card" style={{ width: '18rem' }}>
            <div
              className="card-body"
              style={{
                backgroundImage: `url(${wayaCard})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                backgroundSize: 'cover',
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <img src={wayaLog} width="60px" height="20px" alt="logo" />
                </div>
                <div className="text-end pt-4">
                  <CustomDropdown
                    id={1}
                    direction="vertical"
                    handleOption={handleOption}
                    options={cardOption}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start mt-3">
                <img src={sim} width="30px" height="30px" alt="logo" />
              </div>
              <p className="d-flex justify-content-start mt-2 text-white">
                {showDetails
                  ? cardList?.cardPan?.slice(0, 20)
                  : '*******************'}
              </p>
              <div className="d-flex justify-content-around text-white">
                <p>{cardList?.customerName}</p>
                <p>Valid --/--</p>
              </div>
              <div>{!cardList?.cardActive ? 'Inactive' : ''}</div>
            </div>
          </div>
        </Card>
        <Button
          type="submit"
          onClick={() => setShowDetails(!showDetails)}
          style={{
            marginTop: '100px',
            background: '#FF6700',
            color: '#FFFFFF',
            width: '18rem',
            marginLeft: '25px',
          }}
        >
          {showDetails ? 'Hide' : 'Show'} details
        </Button>
      </div>
      {showCardReq && (
        <RequestCard
          hideModal={setShowCardReq}
          showModal={showCardReq}
          walletList={walletList}
        />
      )}
    </>
  );
};

export default CardDetails;
