import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import NaijaStates from 'naija-state-local-government';
import { Modal, ModalHeader, Button, ModalBody } from 'reactstrap';
// import ToggleInput from '../../ToggleInput';
import SuccessModal from '../../shared/Modals/SuccessModal';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { CardContext } from '../../../store/context/CardContext';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import PinModal from '../../shared/Modals/PinModal';

const RequestCard = (props) => {
  const { showModal, hideModal, id, walletList, getCards } = props;
  const [data, setData] = useState({});
  const { setReload, reload } = useContext(CardContext);
  const { profile: userProfile } = useContext(ProfileContext);
  // const [password, setPassword] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [loading] = useState(false);
  const [cardTypes, setCardTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [otp, setOtp] = useState('');

  const handleSubmitRequest = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Request Fee',
      text: `Please kindly note the service charge for this service cost N${data?.chargeFee} you account will be debited for 
      this service. \n Thank you`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continue',
    }).then((result) => {
      if (result.value) {
        setShowPin(true);
        return console.log({ result });
        //  handleReverseTransaction(row);
      }
      return result;
    });
  };
  const handleRequest = async (e) => {
    e.preventDefault();
    showLoader();
    const postData = {
      accountToBeDebited: data?.accountToBeDebited,
      accountToLink: data?.accountToLink,
      cardProduct: data?.cardProduct,
      cardType: data?.cardType,
      deliveryAddress: {
        address: data?.address,
        city: data?.lga,
        mobileNumber: data?.mobileNumber,
        state: data?.state,
      },
      deliveryMethod: data?.deliveryMethod,
      userId: userProfile.userId,
    };
    const res = await httpPost(
      `/card/request`,
      postData,
      customBaseUrl.cardUrl,
      otp
    );
    if (res?.status) {
      hideLoader();
      setReload(!reload);
      setShowPin(false);
      hideModal(false);
      setOtp('');
      Swal.fire('Done', res?.message);
      getCards();
    } else {
      Swal.fire('Oops!', res.message, 'error');
      setShowPin(false);
      setOtp('');
    }
    hideLoader();
  };
  const getProducts = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/card-product`
    );
    if (res?.status === true) {
      hideLoader();
      setProducts(res?.data);
      // etMessage(res?.message);
      // notification.error(res?.message);
    }
    hideLoader();
  };
  const getCardTypes = async () => {
    showLoader();
    const res = await httpGet(`${customBaseUrl.cardUrl}/card/list/card-type`);
    if (res?.status === true) {
      hideLoader();
      setCardTypes(res?.data);
      // etMessage(res?.message);
      // notification.error(res?.message);
    }
    console.log({ cardTypes, products });
    hideLoader();
  };

  useEffect(() => {
    getProducts();
    getCardTypes();
  }, []);
  useEffect(() => {
    if (data?.state) {
      setLgaList(NaijaStates.lgas(data?.state).lgas);
    } else {
      setLgaList([]);
    }
    console.log('ppp', userProfile);
  }, [data?.state]);
  const states = NaijaStates.states();
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Request for a Card</ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3" onSubmit={handleSubmitRequest}>
          <div className="mb-3">
            <label htmlFor="stateSelect" className="form-label">
              Product Type
            </label>
            <select
              aria-label="select state"
              className="form-select border"
              required
              id="stateSelect"
              onChange={(e) => {
                const { productName, chargeFee } = JSON.parse(e.target.value);
                setData({ ...data, cardProduct: productName, chargeFee });
              }}
            >
              <option value="">select product</option>
              {products?.map((e) => (
                <option key={e?.productName} value={JSON.stringify(e)}>
                  {e?.productName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="stateSelect" className="form-label">
              Card Type
            </label>
            <select
              aria-label="select state"
              className="form-select border"
              required
              id="stateSelect"
              onChange={(e) => setData({ ...data, cardType: e.target.value })}
            >
              <option value="">select card type</option>
              {cardTypes?.map((e) => (
                <option key={e?.type} value={e?.type}>
                  {e?.type}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Full Name
            </label>
            <input
              type="email"
              disabled
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Ade Gabriel"
              value={`${userProfile?.firstName} ${userProfile?.surname}`}
              // onChange={(e) => setData({ ...data, fullName: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              disabled
              value={userProfile?.email}
              //  onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Phone No.
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              required
              onChange={(e) =>
                setData({ ...data, mobileNumber: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="stateSelect" className="form-label">
              States
            </label>
            <select
              aria-label="select state"
              className="form-select border"
              required
              id="stateSelect"
              onChange={(e) => setData({ ...data, state: e.target.value })}
            >
              <option value="">select state</option>
              {states?.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="lgaSelect" className="form-label">
              LGA
            </label>
            <select
              required
              aria-label="select state"
              className="form-select border"
              id="lgaSelect"
              placeholder="name@example.com"
              onChange={(e) => setData({ ...data, lga: e.target.value })}
            >
              <option value="">select lga</option>
              {lgaList?.map((e) => (
                <option key={e}>{e}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="deliveryMethod" className="form-label">
              Delivery Method
            </label>
            <select
              aria-label="select delivery Method"
              className="form-select border"
              required
              id="deliveryMethod"
              onChange={(e) =>
                setData({ ...data, deliveryMethod: e.target.value })
              }
            >
              <option value="">Select delivery method</option>
              <option value="pickup">Pick up</option>
              <option value="delivery">Delivery</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Full delivery address
            </label>
            <input
              required
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              onChange={(e) => setData({ ...data, address: e.target.value })}
            />
          </div>
          <div className="inputbox-with-one-input">
            <label htmlFor="account" className="form-label">
              Select Account to be debited
            </label>
            <select
              required
              aria-label="select state"
              className="form-select border"
              id="account"
              onChange={(e) =>
                setData({ ...data, accountToBeDebited: e.target.value })
              }
            >
              <option value="">select account</option>
              {walletList?.map((item) => (
                <option key={item} value={item?.accountNo}>
                  {`${item?.accountNo} - ${item?.description} - NGN ${item?.clr_bal_amt}`}
                </option>
              ))}
            </select>
          </div>
          <div className="inputbox-with-one-input">
            <label htmlFor="account" className="form-label">
              Select Account to Link
            </label>
            <select
              required
              aria-label="select state"
              className="form-select border"
              id="account"
              onChange={(e) =>
                setData({ ...data, accountToLink: e.target.value })
              }
            >
              <option value="">select account</option>
              {walletList?.map((item) => (
                <option key={item} value={item?.accountNo}>
                  {`${item?.accountNo} - ${item?.description} - NGN ${item?.clr_bal_amt}`}
                </option>
              ))}
            </select>
          </div>
          <Button
            style={{ display: 'flex', textAlign: 'center' }}
            type="submit"
            className="btn-primary mx-auto mb-3 mt-2 text-center"
            color="primary"
          >
            Next
          </Button>
        </form>
      </ModalBody>
      <SuccessModal
        showModal={showSuccessModal}
        hideModal={setShowSuccessModal}
        id="success-wrapper"
        message="Bank account added successfully"
        hideBankAccountModal={hideModal}
      />
      {showPin && (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleRequest}
          // setShowResendOtp={setShowResendOtp}
          // setModalAction={setModalAction}
        />
      )}
    </Modal>
  );
};

RequestCard.defaultProps = {
  id: '',
};

RequestCard.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default RequestCard;
