import React, { useContext, useState } from 'react';
import moment from 'moment';
import { CustomInput } from 'reactstrap';
import Delete from '../../../assets/image/delete.svg';
import Layout from '../../shared/Layout';
import NotifIcon from '../../../assets/notificationIcon.svg';
// import { dateFormater } from '../../../utils/formatDate';
// import { customBaseUrl, httpGet } from '../../../action/http';
// import { WayagramContext } from '../../../store/context/WayagramContext';
import NotificationTable from '../../shared/Table/NotificationTable';
import { NotificationContext } from '../../../store/context/NotificationContext';
// import { Row } from '../../shared/containers/containers';
// import RightIconInput from '../../shared/inputs/inputs';
// import Loader from '../../shared/Loader';

export default function Index(props) {
  const {
    allNotification,
    deleteNotification,
    filterByDateNotification,
    ReadNotification,
  } = useContext(NotificationContext);
  const [date, setDate] = useState('');
  // const [loading, setLoading] = useState(false);

  // console.log('notifications', allNotification);
  // console.log('date', date);

  // const [from, setFrom] = useState('');
  // const { RangePicker } = DatePicker;
  // useEffect(() => {
  //   const { location } = props;
  //   const fromLocation = new URLSearchParams(location.search).get('from');
  //   console.log(fromLocation);
  //   setFrom(fromLocation);
  //   return () => {
  //     setFrom('');
  //   };
  // }, []);

  // const getSocialNotifications = async () => {
  //   const res = await httpGet(
  //     `/notification/get-notifications-user?profile_id=${id}`,
  //     'http://157.245.84.14:1000'
  //   );
  //   if (res?.status) {
  //     setLoading(false);
  //     setNotifications(res.data.data);
  //   }
  // };

  // const getNotifications = (fromLocation) => {
  //   if (fromLocation === 'payments' && UserId) {
  //     getPaymentNotifications();
  //   }
  //   if (fromLocation !== 'payments' && id) {
  //     getSocialNotifications();
  //   }
  // };

  return (
    <Layout routeHistory={props} currentPage="notifications">
      <NotificationTable
        setDate={setDate}
        date={date}
        filter={filterByDateNotification}
        columns={[
          { title: 'ACTIVITY', field: 'content' },
          { title: 'SENDER', field: 'sender' },
          { title: 'DATE', field: 'dateSent' },
          { title: 'STATUS', field: 'status' },
          { title: '', field: 'action' },
        ]}
        data={
          allNotification &&
          allNotification.reverse().map((item) => {
            return {
              content: (
                <div style={{ display: 'flex' }}>
                  <img
                    src={NotifIcon}
                    style={{ marginRight: 20 }}
                    alt={item?.content}
                  />{' '}
                  <p>{item?.content}</p>
                </div>
              ),
              sender: item?.initiatorUsername,
              status: item?.status,
              dateSent: moment(item?.dateSent).format(
                'MMMM Do YYYY, h:mm:ss a'
              ),
              action: (
                <div className="d-flex align-content-center">
                  <img
                    className="my-auto"
                    src={Delete}
                    alt=""
                    onClick={() => deleteNotification(item.notificationId)}
                    style={{
                      width: '16px',
                      height: '16px',
                      marginLeft: '25px',
                    }}
                  />
                  <div
                    className="my-auto"
                    style={{
                      width: '16px',
                      height: '16px',
                      marginLeft: '25px',
                    }}
                  >
                    <CustomInput
                      type="checkbox"
                      checked={item.read}
                      onChange={() =>
                        ReadNotification(item.notificationId, `${!item.read}`)
                      }
                    />
                  </div>
                </div>
              ),
            };
          })
        }
      />
    </Layout>
  );
}
