import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { Modal, ModalBody } from 'reactstrap';
import leftArrow from '../../../../assets/image/left-arrow.png';
import plusIcon from '../../../../assets/image/plus-circle.png';
// import visaIcon from '../../../../assets/image/Visa.png';
import './index.scss';
import { customBaseUrl, httpDelete } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import BankLogo from '../../../../utils/BankLogo';

export default function AccountList(props) {
  const { showModal, hideModal, bankAccountList, setShowAddBankAccount } =
    props;
  const { profile: user } = useContext(ProfileContext);
  const myRef = React.useRef(null);

  const handleDelete = async (accountNumber) => {
    const res = await httpDelete(
      `/bankAccount/delete/${user.userId}/${accountNumber}`,
      {},
      customBaseUrl.cardUrl
    );
    if (res.status) {
      hideModal(false);
    }
  };

  return (
    <div ref={myRef} className="bank-list">
      <Modal
        isOpen={showModal}
        id="manage-bank-list-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header">
          <div className="row">
            <div className="col-3 h-left">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal(false);
                }}
              >
                <img src={leftArrow} alt="left" />
              </a>
            </div>
            <div className="col-6 h-center text-center">
              Manage Bank Accounts
            </div>
            <div className="col-3 h-right text-center">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal(false);
                  setShowAddBankAccount(true);
                }}
              >
                <img src={plusIcon} alt="plus" />
              </a>
            </div>
          </div>
        </div>
        <ModalBody>
          {bankAccountList.length
            ? bankAccountList.map((item) => {
                return (
                  <Collapsible
                    trigger={
                      <div className="bank-list-div col-12">
                        <div className="row">
                          <div className="col-sm-8 col-md-2 img-div my-auto">
                            <img
                              src={
                                BankLogo.find(
                                  (logo) => logo.code === item.bankCode
                                )?.imgPath
                              }
                              alt="visa"
                            />
                          </div>
                          <div className="col-sm-8 col-md-10 pt-2">
                            <p className="mb-0">{item.accountNumber}</p>
                            <p className="mb-0">{item.accountName}</p>
                          </div>
                        </div>
                      </div>
                    }
                    key={Math.random()}
                  >
                    <div className="col-12 account-details">
                      <div className="row">
                        <div className="col-6">Issuer bank</div>
                        <div className="col-6 text-end">{item.bankName}</div>
                      </div>
                      <div className="row">
                        <div className="col-6">Bank bank</div>
                        <div className="col-6 text-end">{item.accountName}</div>
                      </div>
                    </div>

                    <div className="text-center">
                      <a
                        href="/#"
                        className="text-primary text-decoration-none"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(item.accountNumber);
                        }}
                      >
                        Delete Bank Account
                      </a>
                    </div>
                  </Collapsible>
                );
              })
            : ''}
        </ModalBody>
      </Modal>
    </div>
  );
}

AccountList.defaultProps = {
  showModal: false,
  // bankAccountList: [],
};

AccountList.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  // bankAccountList: PropTypes.arrayOf(PropTypes.object),
};
