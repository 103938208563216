import React, { useContext, useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
// import { ActionMeta, OnChangeValue } from 'react-select';
// import CreatableSelect from 'react-select/creatable';
import { ExportJsonCsv } from 'react-export-json-csv';
import moment from 'moment';
import swal from 'sweetalert';
// import Box from '@mui/material/Box';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateRangePicker from '@mui/lab/DateRangePicker';
// import Delete from '../../../assets/image/delete.svg';
import validator from 'validator';
import { Pagination } from 'antd';
import { Cancel } from '@material-ui/icons';
import Layout from '../../shared/Layout';
import Table from '../../shared/Table/transaction';
import './test.scss';
// import { historyDatas } from './MainContent';
import FundWallet from '../../shared/Modals/FundWallet';
import PinModal from '../../shared/Modals/PinModal';
import RequestModal from '../../shared/Modals/Request';
import ReceiveModal from '../../shared/Modals/Receive';
import Profile from '../../shared/Modals/Profile';
import TransReceipt from '../../shared/Modals/Receipt/TransReceipt';
import { PaymentContext } from '../../../store/context/PaymentContext';
// import HistoryTab from './HistoryTab';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../action/http';
import searchIcon from '../../../assets/image/mdi_search.svg';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { WalletContext } from '../../../store/context/WalletContext';
import TransactionContent, {
  NonWayaBoxContainer,
  Tabs,
} from './trasactionCard';
import exportSvg from '../../../assets/btn-export.svg';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ModalContext } from '../../../store/context/ModalContext';
import SingleRequest from '../../shared/Modals/Request/SingleRequest';
import moneyFomatter from '../../../utils/moneyFormatter';

const TransTab = ['All Transactions', 'Non Waya Transfers', 'Payment Requests'];
const RequestsTab = ['SENT REQUEST', 'RECEIVED REQUEST'];

export default function Index(props) {
  // const [currentTab, setCurrentTab] = useState('home');
  const [otp, setOtp] = useState('');
  const [showFund, setShowFund] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showSetPin, setShowSetPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showRequestDetails, setShowRequestDetails] = useState(false);
  const [selected, setSelected] = useState('');
  const [referenceS, setReference] = useState('');
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [verificationOtp, setVerificationOtp] = useState('');

  const {
    sentRequest,
    receivedRequest,
    nonWayaTransactions,
    setPaymentReload,
    reload: paymentReload,
  } = useContext(PaymentContext);

  const { request: sentPaymentRequest } = sentRequest || {};
  const { request: receivedPaymentRequest } = receivedRequest || {};

  const { profile } = useContext(ProfileContext);
  const [showReceipt, setShowReceipt] = useState(false);
  const [receiptInfo, setReceiptInfo] = useState({});
  const [filter, setFilter] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [filteredObj, setFilteredObj] = useState(null);
  const [tab, setTab] = useState(1);
  const [sentRtab, setSentRtab] = useState(1);
  const [totalRequests, setTotalRequest] = useState({
    allRequest: 0,
    sentRequest: 0,
    recievedRequest: 0,
  });
  const [totalExp, setTotalExp] = useState(0);
  const [totalPend, setTotalPend] = useState(0);
  const [totalTran, setTotalTran] = useState(0);
  const [totalRetreived, setTotalRetreived] = useState(0);
  const [notes, setNotes] = useState('');
  const [search, setSearch] = useState(false);
  const {
    transactionHistory,
    /* walletList, */ setPageSize,
    pageSize,
    reload,
    setReload,
    setCurrentPage,
    metaData,
    getTransactionHistory,
  } = useContext(WalletContext);
  const { setModalAction, setShowResendOtp } = useContext(ModalContext);

  useEffect(() => {
    // setPageSize(5);
    // return () => setPageSize(3);
  }, [pageSize]);

  // create pin
  const createPin = async () => {
    setLoading(true);
    const data = {
      email: profile.email,
      pin: Number(otp),
      userId: 0,
    };

    const res = await httpPost(
      '/api/v1/pin/create-pin',
      data,
      customBaseUrl.authUrl
    );
    if (res.status) {
      setLoading(false);
      setOtp('');
      swal('Done', res.message, 'success').then(() => {
        setShowPin(false);
        setShowProfile(true);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  const handleFilter = (obj) => {
    setFilteredObj(obj);
  };
  const handleClearFilter = () => {
    setFiltered([...transactionHistory]);
    setFilter(false);
    setSearch(false);
  };

  const handleSearchByReference = (reference) => {
    console.log('serch', search);
    // setFiltered(search ? reference : transactionHistory);
    if (search) {
      setFiltered(reference);
    } else {
      setFiltered(reference);
    }
    // console.log('reference', reference);
    /* if (reference === '') {
      setFiltered([...transactionHistory]);
    } else {
      if (tab === 1) {
        setFiltered([
          ...transactionHistory.filter(
            (el) =>
              `${el.paymentReference}`.toLocaleLowerCase() ===
              `${reference}`.toLocaleLowerCase()
          ),
        ]);
      }
      if (tab === 2) {
        setFiltered([
          ...nonWayaTransactions.filter(
            (el) =>
              `${el.paymentReference}`.toLocaleLowerCase() ===
              `${reference}`.toLocaleLowerCase()
          ),
        ]);
      }
      if (tab === 3 && sentRtab === 1) {
        setFiltered([
          ...sentPaymentRequest.filter(
            (el) =>
              `${el.reference}`.toLocaleLowerCase() ===
              `${reference}`.toLocaleLowerCase()
          ),
        ]);
      }
      if (tab === 3 && sentRtab === 2) {
        setFiltered([
          ...receivedPaymentRequest.filter(
            (el) =>
              `${el.reference}`.toLocaleLowerCase() ===
              `${reference}`.toLocaleLowerCase()
          ),
        ]);
      }
    } */
  };
  useEffect(() => {
    if (sentPaymentRequest && receivedPaymentRequest) {
      const reducedTotal = [...receivedPaymentRequest, ...sentPaymentRequest]
        .map((el) => el.amount)
        .reduce((a, b) => a + b, 0);
      const sentTotal = sentPaymentRequest
        .map((el) => el.amount)
        .reduce((a, b) => a + b, 0);
      const receivedTotal = receivedPaymentRequest
        .map((el) => el.amount)
        .reduce((a, b) => a + b, 0);
      setTotalRequest((prev) => ({
        ...prev,
        allRequest: `${reducedTotal}`.toLocaleString(),
        sentRequest: sentTotal,
        recievedRequest: receivedTotal,
      }));
      // console.log('reducedTotal', reducedTotal);
    }
  }, [sentPaymentRequest, receivedPaymentRequest]);

  useEffect(() => {
    if (filteredObj) {
      let data;
      if (tab === 1) {
        data = transactionHistory.filter((el) => {
          let d = el;
          if (filteredObj.category && filteredObj.category !== '') {
            d = el.tranCategory === filteredObj.category;
          }
          if (filteredObj.type && filteredObj.type !== '') {
            d = el.partTranType === filteredObj.type.split('')[0];
          }
          if (filteredObj.amount && filteredObj.amount !== '') {
            d = el.tranAmount === +filteredObj.amount;
            // console.log('d', d);
          }
          if (filteredObj.endDate && filteredObj.endDate !== '') {
            const starte = new Date(filteredObj.startDate);
            const ende = new Date(filteredObj.endDate);
            const compareDate = new Date(el.tranDate);
            if (
              compareDate.getFullYear() === starte.getFullYear() ||
              compareDate.getFullYear() === ende.getFullYear()
            ) {
              if (
                compareDate.getMonth() >= starte.getMonth() &&
                compareDate.getMonth() <= ende.getMonth()
              ) {
                if (
                  +compareDate.getDate() >= +starte.getDate() &&
                  +compareDate.getDate() <= +ende.getDate()
                ) {
                  d = true;
                } else {
                  d = false;
                }
              } else {
                d = false;
              }
            } else {
              d = false;
            }
          }
          return d;
        });
      } else if (tab === 2) {
        const req = nonWayaTransactions;
        data = req.filter((el) => {
          let d = el;
          if (filteredObj.amount && filteredObj.amount !== '') {
            d = el.tranAmount === +filteredObj.amount;
            // console.log('d', d);
          }
          return d;
        });
      } else if (tab === 3) {
        const req =
          sentRtab === 1 ? sentPaymentRequest : receivedPaymentRequest;
        data = req.filter((el) => {
          let d = el;
          if (filteredObj.category && filteredObj.category !== '') {
            d = el.transactionCategory === filteredObj.category;
          }

          if (filteredObj.amount && filteredObj.amount !== '') {
            d = el.amount === +filteredObj.amount;
            // console.log('d', d);
          }
          return d;
        });
      }

      // console.log('overall', data);
      setFiltered(data);
      setFilter(false);
    } else {
      if (tab === 1) {
        setFiltered([...transactionHistory]);
      } else if (tab === 2) {
        setFiltered([...nonWayaTransactions]);
      } else if (tab === 3 && sentRtab === 1) {
        setFiltered([...sentPaymentRequest]);
      } else if (tab === 3 && sentRtab === 2) {
        setFiltered([...receivedPaymentRequest]);
      }
      setFilter(false);

      // console.log('overall', filtered);
    }
  }, [
    filteredObj,
    transactionHistory,
    sentPaymentRequest,
    receivedPaymentRequest,
    tab,
    sentRtab,
    nonWayaTransactions,
  ]);

  useEffect(() => {
    (async () => {
      const userId = localStorage.getItem('userId');
      const totalPending = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/total-pending-amount/${userId}`
      );
      const totalExpired = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/total-expired-amount/${userId}`
      );
      const totalTransactions = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/total-transactions-amount/${userId}`
      );
      const totalPayout = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/total-payout-amount/${userId}`
      );
      setTotalExp(totalExpired?.data);
      setTotalPend(totalPending?.data);
      setTotalTran(totalTransactions?.data);
      setTotalRetreived(totalPayout?.data?.amount);
    })();
  }, []);
  const headers = () => {
    if (tab === 1) {
      return [
        { name: 'Reference id', key: 'referenceId' },
        { name: 'Sender Account', key: 'acctNum' },
        { name: 'Sender Name', key: 'senderName' },
        { name: 'Receiver Name', key: 'receiverName' },
        { name: 'Transaction id', key: 'transactionId' },
        { name: 'Transaction Category', key: 'transactionCategory' },
        { name: 'Transaction Type', key: 'transactionType' },
        { name: 'Amount', key: 'amount' },
        { name: 'Narration', key: 'reason' },
        { name: 'Created At', key: 'createdAt' },
      ];
    }
    if (tab === 2) {
      return [
        { name: 'Reference id', key: 'paymentReference' },
        { name: 'PhoneOrEmail', key: 'emailOrPhone' },
        { name: 'Amount', key: 'tranAmount' },
        { name: 'Created At', key: 'Date' },
        { name: 'Reason', key: 'tranNarrate' },
        { name: 'Status', key: 'status' },
      ];
    }
    if (tab === 3) {
      return [
        { name: 'Reference id', key: 'reference' },
        { name: 'From', key: 'From' },
        { name: 'Phone', key: 'Phone' },
        { name: 'Amount', key: 'Amount' },
        { name: 'Created At', key: 'Date' },
        { name: 'Status', key: 'status' },
      ];
    }
    return [];
  };

  const data = filtered.map((el) => {
    if (tab === 1) {
      return {
        referenceId: el.paymentReference,
        transactionId: el.tranId,
        acctNum: el.acctNum,
        receiverName: el.receiverName,
        senderName: el.senderName,
        transactionCategory: el.tranCategory,
        transactionType: el.partTranType === 'D' ? 'Debit' : 'Credit',
        amount: el.tranAmount,
        createdAt: el.createdAt,
        reason: el.tranNarrate,
      };
    }
    if (tab === 2) {
      return {
        Reference: el.paymentReference,
        PhoneOrEmail: `+${el.emailOrPhone}`,
        Amount: `${el.tranAmount}NGN`,
        Reason: el.tranNarrate,
        Date: moment(el.createdAt).format('MMMM Do, YYYY'),
        Time: moment(el.createdAt).format('h:mm a'),
        status: el.status,
      };
    }
    if (tab === 3) {
      return {
        reference: el.reference,
        From: el.senderName,
        Phone: `+${el.receiverPhoneNumber}`,
        Amount: `${el.amount}NGN`,
        Reason: el.reason,
        Date: moment(el.createdAt).format('MMMM Do, YYYY'),
        Time: moment(el.createdAt).format('h:mm a'),
        status: el.status,
      };
    }
    return el;
  });

  // Raw Data
  const nonWayaTranfersCat = [
    {
      name: 'TOTAL TRANSFER',
      prize: totalTran,
    },
    {
      name: 'TOTAL RETRIEVED',
      prize: totalRetreived,
    },
    {
      name: 'TOTAL PENDING',
      prize: totalPend,
    },
    {
      name: 'TOTAL EXPIRED',
      prize: totalExp,
    },
  ];
  const paymentRequestCat = [
    {
      name: 'ALL REQUEST',
      prize: totalRequests.allRequest,
    },
    {
      name: 'TOTAL REQUEST SENT',
      prize: totalRequests.sentRequest,
    },
    {
      name: 'TOTAL REQUEST RECEIVED',
      prize: totalRequests.recievedRequest,
    },
  ];
  // for handling payment request  ..
  const handleSettlePaymentRequest = async () => {
    showLoader();

    if (validator.isEmpty(referenceS)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }

    const res = await httpPost(
      `/settle-payment-request-waya-users/${referenceS}/${otp}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        setShowPin(false);
        setShowVerifyOtp(true);
      });
    } else {
      if (
        res.message === 'PIN validation failed: Referrence Code Already Exist'
      ) {
        setShowPin(false);
        setShowVerifyOtp(true);
        return;
      }
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  // handling reject payment //
  const handleReject = async () => {
    showLoader();
    const res = await httpPut(
      `/reject-payment-request/${referenceS}`,
      {
        note: notes,
      },
      customBaseUrl.paymentRequestUrl
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        // hideModal(false);
        setPaymentReload(!paymentReload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  // handling pin verification
  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      swal('Done', `Pin verified`, 'success').then(() => {
        setLoading(false);
        setShowPin(false);
        handleReject();
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  // handling complete payment
  const handleCompletePaymentRequest = async () => {
    showLoader();

    if (validator.isEmpty(verificationOtp)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }

    const res = await httpPost(
      `/completePaymentWithOTP/${referenceS}/${verificationOtp}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        setShowVerifyOtp(false);
        // hideModal(false);
        setReload(!reload);
        setPaymentReload(!paymentReload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const maskingString = (str, start, end) => {
    if (
      !str ||
      start < 0 ||
      start >= str.length ||
      end < 0 ||
      end > str.length ||
      start >= end
    ) {
      return str;
    }
    const maskLength = end - start;
    const maskedStr =
      str.substring(0, start) + '*'.repeat(maskLength) + str.substring(end);
    return maskedStr;
  };
  const handleChangePage = async (newPage) => {
    console.log('page', newPage);
    setCurrentPage(newPage);
    // setPagination({ ...pagination, current: newPage, pageSize: s });
    //  getRefferals(newPage, s);
    //  setReload(!reload);
    getTransactionHistory(newPage, pageSize);
  };
  const handleChangeRowsPerPage = async (page, size) => {
    //  getRefferals(0, size);
    console.log('page', page, size);
    setCurrentPage(0);
    setPageSize(size);
    getTransactionHistory(0, size);

    //  setReload(!reload);
  };

  // settling payment
  const handleSubmit = () => {
    if (selected === 'settle') {
      handleSettlePaymentRequest();
      return;
    }
    handleVerifyPin();
  };
  return (
    <Layout routeHistory={props} currentPage="wayapay">
      <div className="transaction">
        <Tabs data={TransTab} tab={tab} setTab={setTab} />
        <TransactionHeader
          state={filter}
          setState={setFilter}
          data={transactionHistory}
          filter={handleFilter}
          clear={handleClearFilter}
          reference={handleSearchByReference}
          exportData={data}
          exportHeader={headers()}
          tab={tab}
          search={search}
          setSearch={setSearch}
        />
        {tab !== 2 && <TransactionContent clear={handleClearFilter} />}
        {tab === 2 && <NonWayaBoxContainer data={nonWayaTranfersCat} n />}
        {tab === 3 && <NonWayaBoxContainer data={paymentRequestCat} n />}
        {tab === 3 && (
          <Tabs
            data={RequestsTab}
            tab={sentRtab}
            setTab={setSentRtab}
            rounded
          />
        )}
      </div>
      <div>
        {tab === 1 ? (
          <Table
            // hideModal={hideModal}
            options={{
              exportButton: false,
              search: false,
              sorting: false,
              paging: false,
              headerStyle: {
                backgroundColor: '#FF6700',
                color: '#ffffff',
                whiteSpace: 'nowrap',
                zIndex: 0,
              },
            }}
            columns={[
              { title: 'Reference id', field: 'referenceId' },
              { title: 'Transaction id', field: 'transactionId' },
              { title: 'Transaction Category', field: 'transactionCategory' },
              { title: 'Transaction Type', field: 'transactionType' },
              { title: 'Amount', field: 'amount' },
              { title: 'Sender', field: 'senderName' },
              { title: 'Receiver', field: 'receiverName' },
              //  { title: "Sender's Account", field: 'acctNum' },
              { title: 'Narration', field: 'tranNarrate' },
              { title: 'Created At', field: 'createdAt' },
              // { title: 'Status', field: 'status' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              filtered.length > 0 &&
              filtered.map((el) => {
                return {
                  referenceId: el.paymentReference,
                  transactionId: el.tranId,
                  senderName: el.senderName,
                  receiverName: el.receiverName,
                  //  acctNum: el.acctNum,
                  tranNarrate: el.tranNarrate,
                  transactionCategory: el.tranCategory,
                  transactionType: el.partTranType === 'D' ? 'Debit' : 'Credit',
                  amount: el.tranAmount,
                  createdAt: el.createdAt,
                  //  receiverAcctNum: el.receiverAcctNum,
                  // status: el.createdAt,
                  action: (
                    <div
                      onClick={() => {
                        setReceiptInfo({
                          'Transaction Status': el?.posted_flg
                            ? 'Successful'
                            : 'Failed',
                          'Transaction Amount': `${moneyFomatter(
                            el?.tranAmount
                          )}${el?.tranCrncyCode}`,
                          Date: moment(el?.createdAt).format('LLL'),
                          'Reference Id': el?.paymentReference,
                          'Transaction Type':
                            el?.partTranType === 'C' ? 'Credit' : 'Debit',
                          'Transaction Category': el?.tranCategory,
                          'Sender Name': el?.senderName || '----',
                          'Sender Account Number': maskingString(
                            el?.acctNum,
                            3,
                            7
                          ),
                          'Receiver Name': el?.receiverName || '----',
                          //  'Receiver Account Number': el?.receiverAcctNum,
                          Narration: el?.tranNarrate,
                        });
                        setShowReceipt(true);
                      }}
                      className="d-flex align-content-center"
                    >
                      <span
                        style={{
                          background: '#FFFFFF',
                          border: '1px solid #D0CFCF',
                          boxSizing: 'border-box',
                          borderRadius: '3px',
                          padding: '5px 5px',
                        }}
                        onClick={() => {}}
                      >
                        View More
                      </span>
                    </div>
                  ),
                };
              })
            }
          />
        ) : tab === 2 ? (
          <Table
            columns={[
              { title: 'Name', field: 'fullName' },
              // { title: 'Email', field: 'redeemedEmail' },
              { title: 'Phone or Email', field: 'emailOrPhone' },
              { title: 'Token', field: 'tokenId' },
              { title: 'Amount', field: 'tranAmount' },
              { title: 'Status', field: 'status' },
              { title: 'Created At', field: 'createdAt' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              filtered.length > 0 &&
              filtered.map(
                ({
                  fullName,
                  // redeemedEmail,
                  emailOrPhone,
                  tokenId,
                  tranAmount,
                  createdAt,
                  status,
                  ...el
                }) => {
                  return {
                    fullName,
                    // redeemedEmail,
                    tokenId,
                    emailOrPhone,
                    tranAmount,
                    createdAt,
                    status: status === 'PAYOUT' ? 'RETRIEVED' : status,
                    action: (
                      <div
                        onClick={() => {
                          setReceiptInfo({
                            Reference: el.paymentReference,
                            'Phone or Email': `+${emailOrPhone}`,
                            Amount: `${tranAmount}NGN`,
                            Reason: el.tranNarrate,
                            Date: moment(el.createdAt).format('MMMM Do, YYYY'),
                            Time: moment(el.createdAt).format('h:mm a'),
                            status,
                          });
                          setShowReceipt(true);
                        }}
                        className="d-flex align-content-center"
                      >
                        <span
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #D0CFCF',
                            boxSizing: 'border-box',
                            borderRadius: '3px',
                            padding: '5px 5px',
                          }}
                          onClick={() => {}}
                        >
                          View More
                        </span>
                      </div>
                    ),
                  };
                }
              )
            }
          />
        ) : sentRtab === 1 ? (
          <Table
            // hideModal={hideModal}
            columns={[
              { title: 'Reference id', field: 'reference' },
              { title: 'Name', field: 'receiverName' },
              { title: 'Email', field: 'receiverEmail' },
              { title: 'Phone', field: 'receiverPhoneNumber' },
              { title: 'Amount', field: 'amount' },
              { title: 'Created At', field: 'createdAt' },
              { title: 'Status', field: 'status' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              filtered.length > 0 &&
              filtered.map(
                ({
                  reference,
                  receiverName,
                  receiverEmail,
                  receiverPhoneNumber,
                  amount,
                  createdAt,
                  ...el
                }) => {
                  return {
                    reference,
                    receiverName,
                    receiverEmail,
                    receiverPhoneNumber,
                    amount,
                    createdAt: moment(el.createdAt).format('MMMM Do, YYYY'),
                    status: el.status,
                    action: (
                      <div
                        onClick={() => {
                          setReceiptInfo({
                            From: el.senderName,
                            Phone: `+${receiverPhoneNumber}`,
                            Amount: `${amount}NGN`,
                            Reason: el.reason,
                            Date: moment(el.createdAt).format('MMMM Do, YYYY'),
                            Time: moment(el.createdAt).format('h:mm a'),
                            status: el.status,
                          });
                          setReference(el.reference);
                          setShowReceipt(true);
                        }}
                        className="d-flex align-content-center"
                      >
                        <span
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #D0CFCF',
                            boxSizing: 'border-box',
                            borderRadius: '3px',
                            padding: '5px 5px',
                          }}
                          onClick={() => {}}
                        >
                          View More
                        </span>
                      </div>
                    ),
                  };
                }
              )
            }
          />
        ) : (
          <Table
            // hideModal={hideModal}
            columns={[
              { title: 'Reference id', field: 'reference' },
              { title: 'Name', field: 'senderName' },
              { title: 'Email', field: 'senderEmail' },
              { title: 'Phone', field: 'receiverPhoneNumber' },
              { title: 'Amount', field: 'amount' },
              { title: 'Created At', field: 'createdAt' },
              { title: 'Status', field: 'status' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              filtered.length > 0 &&
              filtered.map(
                ({
                  reference: referenceR,
                  senderName,
                  senderEmail,
                  receiverPhoneNumber,
                  amount,
                  createdAt,
                  ...el
                }) => {
                  return {
                    reference: referenceR,
                    senderName,
                    senderEmail,
                    receiverPhoneNumber,
                    amount,
                    createdAt: moment(el.createdAt).format('MMMM Do, YYYY'),
                    status: el.status,
                    action: (
                      <div
                        onClick={() => {
                          setReceiptInfo({
                            From: el.senderName,
                            Phone: `+${receiverPhoneNumber}`,
                            Amount: `${amount}NGN`,
                            Reason: el.reason,
                            Date: moment(el.createdAt).format('MMMM Do, YYYY'),
                            Time: moment(el.createdAt).format('h:mm a'),
                            status: el.status,
                            Note: el?.rejectionNote ? el.rejectionNote : '',
                          });
                          // setShowReceipt(true);
                          setReference(el.reference);
                          if (el.status === 'PENDING') {
                            setShowRequestDetails(true);
                          } else {
                            setShowReceipt(true);
                          }
                        }}
                        className="d-flex align-content-center"
                      >
                        <span
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #D0CFCF',
                            boxSizing: 'border-box',
                            borderRadius: '3px',
                            padding: '5px 5px',
                          }}
                          onClick={() => {}}
                        >
                          View More
                        </span>
                      </div>
                    ),
                  };
                }
              )
            }
          />
        )}
        {!search ? (
          <Pagination
            onShowSizeChange={handleChangeRowsPerPage}
            total={metaData}
            defaultCurrent={0}
            onChange={handleChangePage}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '30', '50', '100', '1000']}
          />
        ) : (
          ''
        )}
        <div style={{ height: 100 }} />
      </div>
      {showFund ? (
        <FundWallet
          showModal={showFund}
          hideModal={setShowFund}
          setShowPin={setShowPin}
          id="fund-wrapper"
        />
      ) : (
        ''
      )}
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleSubmit}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {showVerifyOtp ? (
        <PinModal
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={verificationOtp}
          setOtp={setVerificationOtp}
          separator=""
          title="Otp Confirmation"
          description="Please input the Otp sent to your email address or phone number"
          isResendOtp={false}
          isResendPin={false}
          buttonLabel="Submit"
          handleSubmit={handleCompletePaymentRequest}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
      {showSetPin ? (
        <PinModal
          center
          showModal={showSetPin}
          hideModal={setShowSetPin}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Welcome Back !!"
          description="Please enter your secured PIN"
          handleSubmit={createPin}
          loading={loading}
        />
      ) : (
        ''
      )}
      {showProfile ? (
        <Profile
          center="true"
          hideModal={setShowProfile}
          showModal={showProfile}
        />
      ) : (
        ''
      )}
      {showRequestModal ? (
        <RequestModal
          showModal={showRequestModal}
          hideModal={setShowRequestModal}
          setShowPin={setShowPin}
          sentRequest={sentRequest}
          receivedRequest={receivedRequest}
          id="request-wrapper"
        />
      ) : (
        ''
      )}
      {showReceiveModal ? (
        <ReceiveModal
          showModal={showReceiveModal}
          hideModal={setShowReceiveModal}
          setShowPin={setShowPin}
          id="receive-wrapper"
        />
      ) : (
        ''
      )}
      {showReceipt ? (
        <TransReceipt
          showModal={showReceipt}
          hideModal={setShowReceipt}
          data={receiptInfo}
          id="receipt-wrapper"
          title={tab === 3 ? 'Transaction Details' : 'Transaction Receipt'}
          tab={tab === 3}
          maskingString={maskingString}
        />
      ) : (
        ''
      )}
      <SingleRequest
        showModal={showRequestDetails}
        hideModal={setShowRequestDetails}
        handleNext={(val) => {
          setSelected(val);
          setShowPin(true);
        }}
        id="single-request-wrapper"
        title="Received Request"
        displayData={receiptInfo || null}
        reference={referenceS}
        notes={notes}
        setNotes={setNotes}
      />
    </Layout>
  );
}

const TransactionHeader = ({
  state,
  // data,
  clear,
  reference,
  exportHeader,
  exportData,
  search,
  setSearch,
}) => {
  // const [setCategoryData] = useState(null);
  // const [setTransactionType] = useState(null);
  //  const [refe, setRefe] = useState('');
  const [searchTranId, setSearchTranId] = useState('');
  // const [searchHis, setSearchHis] = useState([]);
  const filterDetails = () => {
    /* const cat = [...new Set(data.map((el) => el.tranCategory))].map((el) => ({
      value: el,
      label: el,
    })); 
    const transType = [...new Set(data.map((el) => el.partTranType))].map(
      (el) => ({
        value: (el === 'D' && 'Debit') || (el === 'C' && 'Credit'),
        label: (el === 'D' && 'Debit') || (el === 'C' && 'Credit'),
      })
    ); */
    // setCategoryData(cat);
    // setTransactionType(transType);
    // console.log(cat, transType);
  };
  /* useEffect(() => {
    document.querySelector('.filtButton').onclick = (e) => {
      // alert(e.target.id);
      if (e.target.id === 'onit') {
        setState(false);
      }
    };
  }, []); */
  useEffect(() => {
    filterDetails();
  }, [state]);
  useEffect(() => {
    if (searchTranId.length < 1) {
      setSearch(false);
      // setSearchHis([]);
      clear();
    }
  }, [searchTranId]);

  const handleSearchTran = async (val) => {
    val.preventDefault();
    /*  const match = new RegExp(excapeRegExp(val), 'i');
    const found = historyData.filter((item) =>
      match.test(item.text.toLowerCase())
    );
    setHistoryData(found); */
    if (searchTranId.length < 1) return;
    showLoader();
    setSearch(true);
    const res = await httpGet(`
      ${customBaseUrl.walletUrl}/api/v1/wallet/fetchByReferenceNumber/${searchTranId}`);

    if (res?.status) {
      hideLoader();
      reference(res?.data);
    } else {
      hideLoader();
      reference([]);
    }
  };
  return (
    <div className="TransactionHeader">
      <div className="d-flex justify-content-center align-items-center">
        {/* <div
          onClick={() => {
            if (data.length > 0) {
              setState(true);
            } else {
              swal('Oops!', 'Nothing to Filter', 'error');
            }
          }}
          style={{ marginRight: 50, cursor: 'pointer' }}
          className=""
        >
          {' '}
          <span className="pr-2 mx-2 filtButton">
            <BsFilter />
            {state && (
              <TransactionFilter
                trasactionType={transactionType}
                categoryData={categoryData}
                data={data}
                filter={filter}
                clear={clear}
                tab={tab}
              />
            )}
            {state && (
              <div
                // onClick={() => setState(false)}
                style={{
                  width: window.screen.width,
                  height: window.screen.height,
                }}
                className="background_filter"
                id="onit"
              >
                {' '}
              </div>
            )}
          </span>{' '}
          <span>Filter</span>
        </div> */}
        <form onSubmit={handleSearchTran}>
          <div
            style={{ height: 40 }}
            className="d-flex justify-content-center align-items-center "
          >
            <input
              type="text"
              value={searchTranId}
              placeholder="Search by reference id"
              onChange={(e) => setSearchTranId(e.target.value)}
              className="textInput"
            />
            <div className="btn_here bg-green-500">
              {!search ? (
                <img src={searchIcon} alt="" onClick={handleSearchTran} />
              ) : (
                <Cancel
                  onClick={() => {
                    setSearchTranId('');
                    setSearch(false);
                  }}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      <div style={{ marginRight: 30 }}>
        <ExportJsonCsv
          style={{ border: 'none', background: 'none' }}
          headers={exportHeader}
          items={exportData}
        >
          <div className="btn_Export">
            <img
              style={{ marginRight: 5 }}
              src={exportSvg}
              alt="this is for export btn"
            />
            Export
            <span style={{ marginLeft: 5 }}>
              <FiDownload color="#000000" />
            </span>
          </div>
        </ExportJsonCsv>
      </div>
    </div>
  );
};

/*  const TransactionFilter = ({
  trasactionType,
  categoryData,
  filter,
  clear,
  tab,
}) => {
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('');
  const [cate, setCate] = useState('');
  // const [stat, setStat] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  return (
    <div className="TransactionPropOverContainer">
      <div className="TransactionPropOver">
        {tab === 1 && (
          <Select
            data={categoryData}
            setData={setCate}
            title="Transaction Category"
          />
        )}
        {tab === 1 && (
          <Select
            data={trasactionType}
            setData={setType}
            title="Transaction Type"
          />
        )}
        {/* <Select title="Status" setData={setStat} />
        <div style={{ width: '100%', marginBottom: 8 }} className="selectInput">
          <p style={{ margin: 0 }}>Amount</p>
          <input
            type="number"
            placeholder="Enter Amount"
            style={{ width: '100%' }}
            className="textInput"
            value={amount}
            onChange={(el) => setAmount(el.target.value)}
          />
        </div>
        <CustomDateRangeInputs startDate={setStartDate} endDate={setEndDate} />
        <div className="flex-filterBtns">
          <div
            onClick={clear}
            style={{ backgroundColor: '#fff', color: '#000', fontSize: 13 }}
            className="btn_here_filter"
          >
            Clear Filter
          </div>
          <div
            style={{ backgroundColor: '#FF4B01', fontSize: 13 }}
            className="btn_here_filter"
            onClick={() =>
              filter({
                category: cate,
                type,
                amount,
                // status: stat,
                startDate,
                endDate,
              })
            }
          >
            Apply Filter
          </div>
        </div>
      </div>
    </div>
  );
}; */

/* const Select = ({ title, data, setData }) => {
  // const colourOptions = [{ label: 'hii', value: 'babe' }];
  const handleChange = (newValue) => {
    // console.group('Value Changed');
    if (newValue) {
      setData(newValue.value);
    }
  };

  return (
    <div className="selectInput">
      <p style={{ margin: 0 }}>{title}</p>
      <CreatableSelect
        isClearable
        onChange={handleChange}
        // onInputChange={handleInputChange}
        options={!data ? [] : data}
        name="name"
      />
    </div>
  );
}; */

/* function CustomDateRangeInputs({ startDate, endDate }) {
  const [value, setValue] = React.useState([null, null]);
  useEffect(() => {
    startDate(value[0]);
    endDate(value[1]);
  }, [value]);
  // console.log('adte', value);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        label="Advanced keyboard"
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(startProps, endProps) => (
          <div
            style={{
              width: '100%',
              marginBottom: 8,
            }}
            className="selectInput"
          >
            <p style={{ margin: 0 }}>Date Range</p>
            <div className="date-styles">
              <Box sx={{ mx: 1 }}>
                {' '}
                <span style={{ fontSize: 13 }}>from</span>{' '}
              </Box>
              <input
                style={{ width: '40%', border: 0, fontSize: 13 }}
                ref={startProps.inputRef}
                {...startProps.inputProps}
              />
              <Box sx={{ mx: 1 }}>
                {' '}
                <span style={{ fontSize: 13 }}>to</span>{' '}
              </Box>
              <input
                style={{ width: '40%', border: 0, fontSize: 13 }}
                ref={endProps.inputRef}
                {...endProps.inputProps}
              />
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
} */
