import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import RightSidebarWrapper from '../Wayapay/style';
import './index.scss';
import {
  getFollowers,
  getFollowing,
  handleFollow,
  handleUnFollow,
} from './container';
import List from '../../shared/Cards/List';
import Loader from '../../shared/Loader';
import Container from '../../shared/Container';
import ListItem from '../../shared/Cards/ListItem';

export default function Following(props) {
  const { match } = props;
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [activeTab, setActiveTab] = useState('followers');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const handleGetFollowing = async () => {
    // const friendsList = await getFollowing(match.params.id);
    // const userId = localStorage.getItem('userId');
    const friendsList = await getFollowing(match.params.id);
    setList(friendsList?.following);
    setLoading(false);
  };

  const handleGetFollowers = async () => {
    // const friendsList = await getFollowers(match.params.id);
    // const userId = localStorage.getItem('userId');
    const friendsList = await getFollowers(match.params.id);
    setList2(friendsList?.followers);
    setLoading(false);
  };

  const handleGetFeeds = async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const fromActive = params.get('active');
    if (fromActive === 'followers') {
      handleGetFollowers();
    } else {
      setActiveTab('following');
      handleGetFollowing();
    }
  };

  useEffect(() => {
    setLoading(true);
    handleGetFeeds();
  }, []);

  useEffect(() => {
    if (activeTab === 'followers') {
      handleGetFollowers();
    } else if (activeTab === 'following') {
      handleGetFollowing();
    }
    setTrigger(false);
  }, [activeTab, trigger]);

  // const handleSubmit = async (type, username) => {
  //   setBtnLoading(true);
  //   const userId = match.params.id;
  //   let res;
  //   if (type === 'Follow') {
  //     res = await handleFollow(userId, username);
  //   } else if (type === 'Unfollow') {
  //     res = await handleUnFollow(userId, username);
  //   }

  //   if (res?.status) {
  //     setBtnLoading(false);
  //     swal('Done', res?.message, 'success');
  //   } else {
  //     setBtnLoading(false);
  //     swal('Oops!', res?.message, 'error');
  //   }
  // };

  const handleSubmit = async (type, username) => {
    const userId = match.params.id;
    setBtnLoading(true);
    if (type === 'Following') {
      console.log('am here');
      const res = await handleUnFollow(userId, username);
      if (res.status) {
        setTrigger(true);
        NotificationManager.success(res.message, 'success!', 3000);
      } else {
        swal('Oops!', res.message, 'error');
      }
    }
    if (type === 'Follow') {
      const res = await handleFollow(userId, username);
      if (res.status) {
        setTrigger(true);
        NotificationManager.success(res.message, 'success!', 3000);
      } else {
        swal('Oops!', res.message, 'error');
      }
    }
    setBtnLoading(false);
  };

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <Layout route={props} currentPage="Profile">
      <div className="row wayagram-div">
        <div className="col-8">
          <div className="col-12">
            <div className="row">
              <Container
                className={
                  activeTab === 'followers'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => setActiveTab('followers')}
              >
                Followers
              </Container>
              <Container
                className={
                  activeTab === 'following'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => setActiveTab('following')}
              >
                Following
              </Container>
            </div>
          </div>
          {loading && <Loader />}
          {activeTab === 'following' && list && list.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={item.avatar}
                name={`${item?.displayName}`}
                description={item?.username}
                active="People"
                id={item?.id}
                connected={item?.connection?.connected}
                loading={btnLoading}
                selected={selectedId}
                setSelected={setSelectedId}
                handleSubmit={handleSubmit}
              />
            ))
          ) : activeTab === 'following' && list.length === 0 ? (
            <div className="row h-100 d-grid justify-content-center align-items-center">{`No ${activeTab} found`}</div>
          ) : (
            ''
          )}

          {activeTab === 'followers' && list2 && list2.length ? (
            list2.map((item) => (
              <ListItem
                key={Math.random()}
                image={item.avatar}
                name={`${item?.displayName}`}
                description={item?.username}
                active="People"
                id={item?.id}
                connected={item?.connection?.following}
                handleSubmit={handleSubmit}
                loading={btnLoading}
                selected={selectedId}
                setSelected={setSelectedId}
              />
            ))
          ) : activeTab === 'followers' && list2.length === 0 ? (
            <div className="row h-100 d-grid justify-content-center align-items-center">{`No ${activeTab} found`}</div>
          ) : (
            ''
          )}
        </div>

        <RightSidebarWrapper className="col-4">
          <div className="mb-3">
            <input
              className="w-100 form-control search-input ps-3"
              placeholder="Search"
            />
          </div>
          <List />
          <List />
          <List headerText="Groups" />
        </RightSidebarWrapper>
      </div>
    </Layout>
  );
}

// Following.propTypes = {
//   match: PropTypes.shape.isRequired,
// };
