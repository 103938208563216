import React from 'react';
import { IoFilterOutline } from 'react-icons/io5';
import { Popover, Button } from 'antd';
import { VscAdd } from 'react-icons/vsc';
import { BiLinkExternal } from 'react-icons/bi';
import { ExportJsonCsv } from 'react-export-json-csv';
import styles from './styles.module.css';
// import InputSearch from '../inputs/inputs';
import { NormalText } from '../texts/allText';
import { ButtonWithIconLeft } from '../buttons/buttons';
import { Row, Col } from '../containers/containers';

const TableHeaders = ({
  LogClick,
  exportHeader,
  exportData,
  type,
  setType,
  ticketId,
  setTicketId,
  status,
  setStatus,
  handleFilter,
}) => {
  const content = (
    <div>
      <div className="mb-3">
        <label className="form-label">Type</label>
        <select
          className="form-select"
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">Select Type</option>
          <option value="Payment Dispute">Payment Dispute</option>
          <option value="Social Dispute">Social Dispute</option>
          <option value="Auth & Notification">Auth & Notification</option>
          <option value="Other Disputes details">Other Disputes details</option>
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label">Ticket ID</label>
        <input
          type="number"
          className="form-control"
          value={ticketId}
          onChange={(e) => setTicketId(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Status</label>
        <select
          className="form-select"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="">Select Status</option>
          <option value="OPENED">Opened</option>
          <option value="RESOLVED">Resolved</option>
          <option value="PENDING_RESPONSE">Pending</option>
          <option value="WAYAPAY_REVIEWING">Reviewing</option>
          <option value="REJECTED">Rejected</option>
          <option value="CLOSED">Closed</option>
          <option value="TICKET">Ticket</option>
          <option value="REPLY">Rejected</option>
          <option value="PROCESSING">Processing</option>
        </select>
      </div>
      <div className="mb-3">
        <Button
          type="primary"
          onClick={() => {
            console.log('filter');
            handleFilter({ type, ticketId, status });
          }}
          danger
        >
          Apply Filter
        </Button>
      </div>
    </div>
  );

  return (
    <Row justify="space-between" className={styles.contents}>
      <Row justify="flex-start" className={styles.left}>
        <Row>
          <Popover
            className="d-flex"
            content={content}
            title="Filter"
            trigger="click"
          >
            <IoFilterOutline size={15} style={{ marginRight: 15 }} />
            <NormalText size={15}>Filter</NormalText>
          </Popover>
        </Row>
        {/* <Row className={styles.search}>
          <InputSearch placeholder="search with terminal id" />
        </Row> */}
      </Row>
      <Row>
        <Col>
          <ExportJsonCsv
            style={{ border: 'none', background: 'none' }}
            headers={exportHeader}
            items={exportData}
          >
            <ButtonWithIconLeft
              text="Export Data"
              color="#fff"
              bgColor="#27AE60"
            >
              <BiLinkExternal color="#fff" />
            </ButtonWithIconLeft>
          </ExportJsonCsv>
        </Col>
        <Col>
          <ButtonWithIconLeft
            text="Log a Complaint"
            color="#fff"
            bgColor="#FF6700"
            onclick={LogClick}
          >
            <VscAdd color="#fff" />
          </ButtonWithIconLeft>
        </Col>
      </Row>
    </Row>
  );
};
export default TableHeaders;
