export const chatOptions = [
  {
    name: 'Chat settings',
    type: 'Chat settings',
    id: '100',
  },
  {
    name: 'New chat',
    type: 'newChat',
    id: '101',
  },
  {
    name: 'New group',
    type: 'New group',
    id: '101',
  },
  {
    name: 'Clear all messages',
    type: 'Clear all messages',
    id: '102',
  },
  {
    name: 'Archived chat',
    type: 'Archived chat',
    id: '103',
  },
];
export const chatHistoryOptions = [
  {
    name: 'View contact',
    type: 'View contact',
    id: '100',
  },
  {
    name: 'Send money',
    type: 'sendMoney',
    id: '101',
  },
  {
    name: 'Search',
    type: 'search',
    id: '102',
  },
  {
    name: 'Mute',
    type: 'mute',
    id: '103',
  },
  // {
  //   name: 'Report',
  //   type: 'report',
  //   id: '104',
  // },
  {
    name: 'Block',
    type: 'block',
    id: '105',
  },
  {
    name: 'Clear chat',
    type: 'clearChat',
    id: '106',
  },
];

export const chatSettingsOptions = [
  {
    title: 'Account',
    description: 'Privacy, security, change number...',
    id: '001',
  },
  {
    title: 'Chats',
    description: 'Dark mode, font size, chat history..',
    id: '002',
  },
  {
    title: 'Notifications',
    description: 'Message, groups and call tones',
    id: '003',
  },
  {
    title: 'Invite a friend',
    description: '',
    id: '004',
  },
];
