import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import { customBaseUrl, httpPut } from '../../../../action/http';
import bannerImage from '../../../../assets/image/banner.jpg';
import avatarImg from '../../../../assets/image/avatar3.png';
import cameraImg from '../../../../assets/image/camera_alt.png';
import Button from '../../Button';
import validateImage from '../../../../utils/validateImage';
import './index.scss';
import { WayagramContext } from '../../../../store/context/WayagramContext';

export default function WayagramProfile(props) {
  const { showModal, hideModal } = props;
  const myRef = React.useRef(null);
  const {
    wayagramProfile: profile,
    wayagramReload,
    setWayagramReload,
  } = useContext(WayagramContext);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState({ ...profile });
  const [checkData, setCheckData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'image') {
        setPostData({ ...postData, avatar: imageData });
      } else {
        setPostData({ ...postData, coverImage: imageData });
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'image') {
          setPreviewImage(reader.result);
        } else {
          setPreviewHeaderImage(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    previewImage && newForm.append('avatar', postData.avatar);
    /* eslint-disable no-unused-expressions */
    previewHeaderImage && newForm.append('coverImage', postData.coverImage);
    newForm.append('user_id', profile.UserId);
    checkData.username && newForm.append('username', postData.username);
    newForm.append('notPublic', postData.notPublic || false);
    checkData.bio && newForm.append('bio', postData.bio);
    checkData.displayName &&
      newForm.append('displayName', postData.displayName);

    const res = await httpPut(
      `/main/profile/update`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setWayagramReload(!wayagramReload);
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="wayagram-profile-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header d-flex p-3">
          <h4 className="">Edit Profile</h4>
          <Button
            loading={loading}
            disabled={loading || false}
            className="btn btn-primary ml-auto"
            content="save"
            onClick={handleSubmit}
          />
        </div>
        <ModalBody>
          <div className="banner-div">
            <img
              src={previewHeaderImage || postData.coverImage || bannerImage}
              alt="banner"
            />
            <img src={cameraImg} className="upload-banner-input" alt="camera" />
            <input
              type="file"
              accept="image/*"
              className="upload-banner-input2"
              onChange={(e) => handlePreview(e, 'headerImage')}
            />
            <div className="avatar-section d-flex mb-4">
              <img
                src={previewImage || postData?.avatar || avatarImg}
                alt="avatar"
              />
              <img src={cameraImg} className="upload-input" alt="camera" />
              <input
                type="file"
                accept="image/*"
                className="upload-input2"
                onChange={(e) => handlePreview(e, 'image')}
              />
            </div>
          </div>
          <div className="form-div p-3">
            <label htmlFor="">Username</label>
            <input
              className="form-control mb-3"
              value={postData.username}
              onChange={(e) => {
                setPostData({ ...postData, username: e.target.value });
                setCheckData({ checkData, username: e.target.value });
              }}
            />

            <label htmlFor="">Display name</label>
            <input
              className="form-control mb-3"
              value={postData.displayName}
              onChange={(e) => {
                setPostData({ ...postData, displayName: e.target.value });
                setCheckData({ checkData, displayName: e.target.value });
              }}
            />

            <label htmlFor="">Bio</label>
            <input
              className="form-control mb-3"
              value={postData.bio}
              onChange={(e) => {
                setPostData({ ...postData, bio: e.target.value });
                setCheckData({ ...checkData, bio: e.target.value });
              }}
            />

            <label htmlFor="">Choose Privacy</label>
            <select
              className="form-control mb-3"
              value={postData.notPublic}
              onChange={(e) => {
                setPostData({ ...postData, notPublic: e.target.value });
              }}
            >
              <option value="Select" selected disabled>
                Private
              </option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

WayagramProfile.defaultProps = {
  showModal: false,
};

WayagramProfile.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
};
