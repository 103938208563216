import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import RightSidebarWrapper from '../Wayapay/style';
import List from '../../shared/Cards/List';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { handleJoinGroup, followOrUnfollowPage } from './container';

export default function RightSidebar(props) {
  const { isSearch } = props;
  const history = useHistory();
  const { groupSuggestion, pageSuggestion } = useContext(WayagramContext);

  const onAction = async (userId, groupId) => {
    const res = await handleJoinGroup(userId, groupId);
    if (res.status) {
      swal('Done', res.message, 'success');
      // groupSuggestion();
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const followAction = async (userId, pageId) => {
    const res = await followOrUnfollowPage(userId, pageId);
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <RightSidebarWrapper className="col-4">
      <div className="column-right-container mb-3 p-4">
        <div
          className="search-container mb-3"
          style={!isSearch ? { display: 'none' } : {}}
        >
          <input
            className="w-100 form-control ps-3 search-input"
            placeholder="Search"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                history.push({
                  pathname: '/wayagram-discover',
                  from: 'wayagram',
                  data: e.target.value,
                });
              }
            }}
          />
        </div>
        {/* <List /> */}
        <List
          headerText="Groups"
          list={groupSuggestion.slice(0, 3)}
          onAction={onAction}
          loading={false}
          buttonTitle="Join"
        />
        <List
          headerText="Pages"
          list={pageSuggestion.slice(0, 3)}
          onAction={followAction}
          loading={false}
          buttonTitle="Follow"
        />
      </div>
    </RightSidebarWrapper>
  );
}

RightSidebar.defaultProps = {
  isSearch: true,
};

RightSidebar.propTypes = {
  isSearch: PropTypes.string,
};
