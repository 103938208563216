import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Container } from 'semantic-ui-react';
import Map from '../../shared/Modals/Map';
import { httpGet } from '../../../action/http';
import { Browser } from '../../../utils/locationInfo';

export default function LoginInfo(props) {
  const { locationInfo, loginHistory } = props;
  const { device, /* city, country, province, */ loginDate } = locationInfo;
  const [showMap, setShowMap] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [loc, setLoc] = useState('');
  function showPosition(position) {
    setLoc(
      `Latitude: ${position.coords.latitude}<br>Longitude: ${position.coords.longitude}`
    );
  }
  function getLocation() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLoc('Geolocation is not supported by this browser.');
    }
  }

  const handleLocation = async () => {
    const userBrowserName = Browser.getBrowserName();
    const url = 'http://ip-api.com/json/102.89.34.233';
    const res = await httpGet(url);
    if (res.status === 'success') {
      setLocationDetails({
        location: res?.city,
        deviceName: userBrowserName,
      });
    }
  };
  useEffect(() => {
    handleLocation();
    getLocation();
  }, []);
  return (
    <div className="setting-inner-item">
      <div className="menu-items p-2">
        <Container className="row m-0 p-0">
          <div className="col-10 p-3">
            <h5 className="mb-0" onClick={() => setShowMap(true)}>
              Location
            </h5>
            <p className="text-muted mb-0">{loc || locationDetails.location}</p>
          </div>
        </Container>
        <div className="row m-0">
          <div className="col-10 p-3">
            <h5 className="mb-0">Device</h5>
            <p className="text-muted mb-0">
              {device || locationDetails.deviceName || ''}
            </p>
          </div>
        </div>
        <Container className="row m-0 p-0">
          <div className="col-10 p-3">
            <h5 className="mb-0">Time</h5>
            <p className="text-muted mb-0">{moment(loginDate).format('LTS')}</p>
          </div>
        </Container>
        <div className="row m-0">
          <div className="col-12 p-3">
            <h5 className="mb-2">Time Log</h5>
            {loginHistory &&
              loginHistory.map((item) => (
                <div className="col-12" key={Math.random()}>
                  <div
                    className="row d-flex justify-content-space-between"
                    style={{ boxShadow: 'none' }}
                  >
                    <div className="col-8">
                      <p className="text-muted mb-0">
                        {moment(item.loginDate).format('dddd Do MMMM YYYY')}
                      </p>
                      <span className="text-muted">
                        {moment(item.loginDate).format('hh:mm a')}
                      </span>
                    </div>
                    <div className="col-4" style={{ textAlign: 'right' }}>
                      <p className="text-muted my-0">
                        {moment(item.loginDate).format('hh:mm a')}
                      </p>
                      <p className="text-muted my-0 small-text">Logged In</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {showMap ? <Map showModal={showMap} hideModal={setShowMap} /> : ''}
    </div>
  );
}
