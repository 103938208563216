import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import { httpPost, customBaseUrl } from '../../../../action/http';

import Button from '../../Button';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import bannerImage from '../../../../assets/image/banner.jpg';
import avatarImg from '../../../../assets/image/avatar3.png';
import cameraImg from '../../../../assets/image/camera_alt.png';
import validateImage from '../../../../utils/validateImage';
import { handleGetPageCategories } from '../../../pages/Wayagram/container';

const CreatePage = (props) => {
  const { showModal, hideModal, mode, selectedData } = props;
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);
  const { wayagramProfile: profile } = useContext(WayagramContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await handleGetPageCategories();
      setCategories(res);
    })();
  }, []);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'image') {
        setPostData({ ...postData, image: imageData });
      } else {
        setPostData({ ...postData, headerImage: imageData });
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'image') {
          setPreviewImage(reader.result);
        } else {
          setPreviewHeaderImage(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    postData.image && newForm.append('image', postData.image);
    /* eslint-disable no-unused-expressions */
    postData.headerImage && newForm.append('headerImage', postData.headerImage);
    newForm.append('userId', profile.id);
    newForm.append('title', postData.name);
    newForm.append('description', postData.description);
    newForm.append('categoryId', postData.categoryId);
    newForm.append('category', postData.category);
    newForm.append('username', postData.name);
    newForm.append('isPublic', postData.isPublic || false);
    postData.websiteUrl && newForm.append('websiteUrl', postData.websiteUrl);

    const res = await httpPost(
      `/main/page/create-page`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="wayagram-create-group"
      centered
    >
      <ModalHeader>
        <h5 className="">{mode === 'create' ? 'Create' : 'Edit'} Page</h5>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary ml-auto"
          color="primary"
          onClick={handleSubmit}
          content="Create"
        />
      </ModalHeader>
      <ModalBody>
        <div className="banner-div">
          <img src={previewHeaderImage || bannerImage} alt="banner" />
          <img src={cameraImg} className="upload-banner-input" alt="camera" />
          <input
            type="file"
            className="upload-banner-input2"
            onChange={(e) => handlePreview(e, 'headerImage')}
          />
          <div className="avatar-section d-flex mb-4">
            <img src={previewImage || avatarImg} alt="avatar" />
            <img src={cameraImg} className="upload-input" alt="camera" />
            <input
              type="file"
              className="upload-input2"
              onChange={(e) => handlePreview(e, 'image')}
            />
          </div>
        </div>
        <div className="form-div p-3">
          <div className="inputbox-with-one-input">
            <input
              placeholder="Page title"
              value={postData.name}
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, name: e.target.value });
              }}
            />
            <span className="s-text">What is the title of your page</span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              value={postData.description}
              placeholder="Description"
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
              }}
            />
            <span className="s-text">Short description</span>
          </div>
          {/* <div className="inputbox-with-one-input">
            <input
              placeholder="Page Username"
              value={postData.username}
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, username: e.target.value });
              }}
            />
            <span className="s-text">
              What is the Unique username of your page
            </span>
          </div> */}
          <div className="inputbox-with-one-input">
            <select
              value={postData.categoryId}
              onChange={(e) => {
                const [id, name] = e.target.value.split(',');
                setPostData({
                  ...postData,
                  categoryId: id,
                  category: name,
                });
              }}
            >
              <option value="Select" selected disabled>
                Select
              </option>
              {categories
                ? categories.map((item) => (
                    <option value={`${item.id},${item.title}`} key={item.id}>
                      {item.title}
                    </option>
                  ))
                : ''}
            </select>
            <span className="s-text">Select category</span>
          </div>
          <div className="inputbox-with-one-input">
            <select
              value={postData.isPublic}
              onChange={(e) => {
                setPostData({ ...postData, isPublic: e.target.value });
              }}
            >
              <option value="Select" selected disabled>
                Public
              </option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
            <span className="s-text">Choose privacy</span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              value={postData.websiteUrl}
              placeholder="Website Url"
              type="text"
              onChange={(e) => {
                setPostData({ ...postData, websiteUrl: e.target.value });
              }}
            />
            <span className="s-text">Website url</span>
          </div>
        </div>
      </ModalBody>
      {/* <ModalFooter>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary ml-auto mb-3 mt-2"
          color="primary"
          onClick={handleSubmit}
        >
          Next
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

CreatePage.defaultProps = {
  mode: 'create',
  selectedData: {},
  showModal: false,
};

CreatePage.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
  selectedData: PropTypes.node,
};

export default CreatePage;
