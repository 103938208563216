import React, { useState } from 'react';
import Loader from '../../shared/Loader';
import DiscoverInner from './DiscoverInner';

const SearchTabs = ({
  items,
  setSearchValue,
  handleGetTrending,
  searchValue,
  handleSearch,
  setList,
  setActive,
  active,
  list,
  btnLoading,
  setSelectedId,
  selectedId,
  handleSubmit,
  loading,
}) => {
  const tabChildren = [
    {
      id: 1,
      title: 'Following',
      component: (
        <DiscoverInner
          active={active}
          setActive={setActive}
          list={list}
          btnLoading={btnLoading}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          handleSubmit={handleSubmit}
          handleSearch={handleSearch}
        />
      ),
    },
    {
      id: 2,
      title: 'Follower',
      component: (
        <DiscoverInner
          active={active}
          setActive={setActive}
          list={list}
          btnLoading={btnLoading}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          handleSubmit={handleSubmit}
          handleSearch={handleSearch}
        />
      ),
    },
  ];
  return (
    <div className="tab">
      <div className="menu-options mt-3 tab-menu">
        <div
          className="lato-text"
          style={{
            width: '900px',
            display: 'flex',
            justifyContent: 'space-around',
            color: '#b6b3b3',
          }}
        >
          {items.map((val) => (
            <div
              className={`add-cursor p-2 ${val === active ? 'active-tab' : ''}`}
              onClick={() => {
                setList([]);
                setActive(val);
                if (val === 'Trending') {
                  setSearchValue('');
                  handleGetTrending();
                  return;
                }
                if (searchValue !== '') handleSearch(searchValue, val);
              }}
              key={Math.random()}
            >
              {val}
            </div>
          ))}
        </div>
      </div>
      {active && loading ? <Loader /> : <TabPanel component={tabChildren} />}
    </div>
  );
};

export default SearchTabs;

const TabPanel = ({ component }) => {
  const [key, setKey] = useState(1);
  const currentTab = component[key - 1].component;
  const onChange = (id) => {
    setKey(id);
  };
  return (
    <>
      <div className="waya-tabs">
        {component.map((child) => (
          <div className="waya-tab" key={child.id}>
            <div
              className={`tab-title ${
                key === child.id ? 'active-waya-tab' : ''
              }`}
              onClick={() => onChange(child.id)}
            >
              {child.title}
            </div>
          </div>
        ))}
      </div>
      {currentTab}
    </>
  );
};

export { TabPanel };
