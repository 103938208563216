import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  ShareToWhatsapp,
  ShareToFacebook,
  ShareToEmail,
  ShareToTwitter,
  // ShareToTwitter,
} from '../../ShareButton';

const Index = (props) => {
  const { showModal, hideModal, title, description, text, url } = props;

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="invite-people"
      centered
    >
      <ModalHeader className="h-top">
        <div
          className="add-cursor text-start px-3 pt-3"
          onClick={() => hideModal(false)}
        >
          back
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="text-center mb-5">
          <h5>{title}</h5>
          <h6>{description}</h6>
        </div>
        <div className="col-md-12 h-100">
          <div className="row justify-content-around h-20">
            <div className="col-md-4 text-center my-auto invite-wrapper p-2">
              {' '}
              <ShareToWhatsapp url={text} text={url} />{' '}
            </div>
            <div className="col-md-4 text-center my-auto invite-wrapper p-2">
              {' '}
              <ShareToFacebook text={text} url={url} />{' '}
            </div>
          </div>
          <div className="row justify-content-around h-20">
            <div className="col-md-4 text-center my-auto invite-wrapper p-2">
              <ShareToTwitter text={text} url={url} />
            </div>
            <div className="col-md-4 text-center my-auto invite-wrapper p-2">
              <ShareToEmail text={text} url={url} />
            </div>
          </div>
          {/* <div className="row justify-content-around h-20">
            <div className="col-md-4 text-center my-auto invite-wrapper p-2">
              <ShareToEmail text={text} url={url} />
            </div>
          </div> */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Index;
