import { UpCircleOutlined } from '@ant-design/icons';
import { LiveHelp } from '@material-ui/icons';
import {
  Tabs,
  Select,
  Table,
  Card,
  Avatar,
  Upload,
  Button,
  Form,
  Input,
  Checkbox,
  Tooltip,
  DatePicker,
  Radio,
} from 'antd';
import React from 'react';
import Layout from '../../shared/Layout';

export default function TerminalPosSettings(props) {
  const SelectBefore = () => (
    <Select defaultValue="ng" className="rounded-0 select-before">
      <Select.Option value="ng">+234</Select.Option>
      <Select.Option value="us">+001</Select.Option>
    </Select>
  );
  const dataSource = [
    {
      key: '1',
      bankName: 'United Bank of Africa (UBA)',
      accountNo: 62873427532,
      accountName: 'Mrs Alashe',
      currency: 'NGN',
      default: true,
    },
    {
      key: '2',
      bankName: 'Zenith Bank PLC',
      accountNo: 62873427542,
      accountName: 'Mrs Alashe',
      currency: 'NGN',
      default: false,
    },
  ];
  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNo',
      key: 'accountNo',
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      dataIndex: 'default',
      key: 'default',
      render: (e) => <p>{e ? 'Default Account' : ''}</p>,
    },
    {
      dataIndex: 'action',
      key: 'currency',
      render: () => (
        <button type="button" className="shadow btn btn-light">
          Edit
        </button>
      ),
    },
  ];

  // settlement
  const SettlementTab = () => (
    <div className="px-5 py-4">
      <h6>Select Terminal POS</h6>
      <div>
        <Select defaultValue="1" size="large" className="border-0 rounded w-50">
          <Select.Option value="1">Select Terminal</Select.Option>
          <Select.Option value="2">ITEX</Select.Option>
          <Select.Option value="3">Unify Payment</Select.Option>
          <Select.Option value="4">GL AC</Select.Option>
          <Select.Option value="5">Waya Mini TAMS</Select.Option>
        </Select>
      </div>
      <div className="py-5">
        <Table rowSelection dataSource={dataSource} columns={columns} />
      </div>
    </div>
  );

  // payment Pref tab
  const PaymentTab = () => (
    <Form className="w-75 ps-lg-5 py-3">
      <div className="row py-4">
        <div className="col-5 my-2">
          <h6>
            Settlement Schedule{' '}
            <Tooltip
              className="rounded-6"
              placement="bottom"
              color="#FFF"
              title={
                <>
                  <h6>WHAT DOES THIS MEAN?</h6>
                  <span className="text-secondary fs-05">
                    This means you can place your settlement on hold and be
                    settled in bulk.
                  </span>
                </>
              }
            >
              <LiveHelp className="fs-05 text-secondary" />
            </Tooltip>
          </h6>
        </div>
        <div className="col-7 my-2">
          <Form.Item className="m-0">
            <Select defaultValue="1" className="w-75 rounded-0 border-0">
              <Select.Option value="1">Select when to be settled</Select.Option>
              <Select.Option value="2">monthly</Select.Option>
            </Select>
          </Form.Item>
          <div className="d-flex py-2">
            <Form.Item className="m-0">
              <Checkbox>Place settlement on hold until</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <DatePicker placeholder="dd/mm/yyyy" />
            </Form.Item>
          </div>
        </div>
        <div className="col-5 my-2">
          <h6>Preferred Earning Method</h6>
        </div>
        <div className="col-7 my-2">
          <Form.Item className="m-0">
            <Checkbox>Settle to bank account</Checkbox>
            <Tooltip
              className="rounded-6"
              placement="bottom"
              color="#FFF"
              title={
                <>
                  <h6>WHAT DOES THIS MEAN?</h6>
                  <span className="text-secondary fs-05">
                    This means your earnings will be transferred to the bank
                    account you added as default on the settlement account page
                  </span>
                </>
              }
            >
              <LiveHelp className="fs-05 text-secondary" />
            </Tooltip>
          </Form.Item>
          <Form.Item className="m-0">
            <Checkbox>Settle to wallet</Checkbox>
            <Tooltip
              className="rounded-6"
              placement="bottom"
              color="#FFF"
              title={
                <>
                  <h6>WHAT DOES THIS MEAN?</h6>
                  <span className="text-secondary fs-05">
                    This means your earnings will be settled into your
                    Wayapaychat Wallet Account. This change will take effect
                    after 24 hours.
                  </span>
                </>
              }
            >
              <LiveHelp className="fs-05 text-secondary" />
            </Tooltip>
          </Form.Item>
        </div>
      </div>
    </Form>
  );

  // kyc tab
  const KYCTab = () => (
    <div className="px-lg-5 py-3 shadow">
      <Card className="rounded bg-light shadow-sm">
        <div className="d-flex">
          <h5 className="mx-3">
            {' '}
            <br /> Business Information
          </h5>
          <div className="">
            <div className="d-flex">
              <div className="mx-2">
                KYC Status <br />
                <a href="#damn" className="btn btn-sm btn-outline-success">
                  APPROVED
                </a>
              </div>
              <div className="mx-2">
                Type of Registration <br />
                <a href="#damn" className="btn btn-sm btn-outline-info">
                  NON REGISTERED BUSINESS
                </a>
                <br />
                <p className="fs-01 text-break lh-sm">
                  Lifetime collection limit for this business type is <br />
                  N2,000,000, kindly upgrade your account to enjoy <br />
                  unlimited collection
                </p>
              </div>
              <div className="mx-2">
                <br />
                <a href="#damn" className="btn btn-sm btn-outline-success">
                  <UpCircleOutlined /> Upgrade Account
                </a>
              </div>
              <div className="mx-2">
                <br />
                <a href="#damn" className="btn btn-sm btn-primary">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="d-flex">
            <Avatar shape="circle" size={100}>
              RD
            </Avatar>
            <div className="pt-3 ps-2">
              <span>Upload business logo</span> <br />
              <Upload>
                <Button className="shadow-sm">Upload Image</Button>
              </Upload>
            </div>
          </div>
          <div className="w-100">
            <Form className="row">
              <div className="col-md-6 px-4">
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Rich Creative Design"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business Phone Number</p>
                  {/* <SelectBefore /> */}
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    addonBefore={<SelectBefore />}
                    placeholder="+2348163349199"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">General Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Support Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Dispute Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-4">
                  <Checkbox className="fw-bold">Use general email</Checkbox>
                </Form.Item>
                <h5 className="mt-5 fw-bold">Office Address</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Country</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">State</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Lagos"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Local Government Area</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="kosofe"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">City</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Ojota"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Street Address</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="99, sowonlu street"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Home Address</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Country</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">State</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Lagos"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Local Government Area</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="kosofe"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">City</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Ojota"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Street Address</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="99, sowonlu street"
                  />
                </Form.Item>
              </div>
              <div className="col-md-6 px-4">
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Type of Industry</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Fashion"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Type of Business</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Non REgistered Business"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business description</p>
                  <Input.TextArea
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="about business"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Bank Details</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Source</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="United Bank of Africa (UBA)"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Number</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="20735724723"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Richard Daniel"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Owner</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Full Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Richard Daniel"
                  />
                </Form.Item>
                <div className="d-flex">
                  <div className="d-flex">
                    <Form.Item className="pt-2">
                      <span className="fw-bold">Date of birth</span>
                      <Input placeholder="10" />
                    </Form.Item>
                    <Form.Item className="pt-4-5">
                      <Input placeholder="05" />
                    </Form.Item>
                    <Form.Item className="pt-4-5">
                      <Input placeholder="1995" />
                    </Form.Item>
                  </div>
                  <Form.Item className="pt-2">
                    <span className="fw-bold">Gender:</span>
                    <Select defaultValue="male">
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Nationality</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Means of Identification</p>
                  <Select defaultValue="nin">
                    <Select.Option value="nin">National Identity</Select.Option>
                    <Select.Option value="other">other</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Identity Number</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="84767659839786"
                  />
                </Form.Item>
                <h6 className="fw-bold mt-4">How you wish to accept payment</h6>
                <div>
                  <Form.Item className="my-3">
                    <Checkbox className="fw-bold">Without Website/App</Checkbox>
                  </Form.Item>
                  <ul>
                    <li>
                      Receive payment from your customers in under 5 minutes
                      using Wayapaychat Payment Links.
                    </li>
                    <li>
                      You can submit your website/app anytime later if you wish
                      to use it to accept payments
                    </li>
                  </ul>
                  <Form.Item>
                    <Checkbox>With Website/App</Checkbox>
                  </Form.Item>
                  <div className="px-md-4">
                    <Form.Item className="m-0">
                      <Radio checked size="large" />
                      Accept Payment On Website
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Input placeholder="Enter URL" />
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Radio checked size="large" />
                      Accept Payment On App
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Input placeholder="Enter App Link" />
                    </Form.Item>
                  </div>
                  <div className="my-2">
                    <p>
                      We need to verify your website/app to provide you the live
                      API Keys it should contain:
                    </p>
                    <ul>
                      <li className="d-inline px-2">About Us</li>
                      <li className="d-inline px-2">Contact Us</li>
                      <li className="d-inline px-2">Pricing</li>
                      <li className="d-inline px-2">Privacy Policy</li>
                      <li className="d-inline px-2">Terms & Conditions</li>
                      <li className="d-inline px-2">
                        Cancellation/Refund Policy
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
  return (
    <Layout
      route={props}
      currentPage="settings"
      pageName="Web Pos KYC Settings"
      goBack
    >
      <div className="px-2">
        <Tabs defaultActiveKey="1" className="w-100">
          <Tabs.TabPane tab={<span>Settlement Account</span>} key="1">
            <SettlementTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Payment Preferences</span>} key="2">
            <PaymentTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>KYC</span>} key="3">
            <KYCTab />
          </Tabs.TabPane>
        </Tabs>
      </div>
      {/* <KYCTab /> */}
    </Layout>
  );
}
