/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import Contact from './Contact';
import { WayaChatContainer, WayaChat } from './styles';
import NoChatHistory from './noChatHistory';
import ChatHistory from './ChatHistory';
import ChatInnerNav from './ChatInnerNav';
import ChatSettingsOptionsList from './ChatsettingsOptions';
import arrowLeft from '../../../assets/images/arrowLeft.png';
// import contactList from './dummyData';
import { chatSettingsOptions } from './data';
import { AppSocket } from '../../../action/socket';
import { WayagramContext } from '../../../store/context/WayagramContext';
import { handlePreview } from '../../../utils/helper';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpPost } from '../../../action/http';
import Invite from '../../shared/Modals/Invite';

export default function Index(props) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activeContact, setActiveContact] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [showChatSeting, setShowChatSeting] = useState(false);
  const [activeChats, setActiveChats] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const { wayagramProfile } = useContext(WayagramContext);
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errorUpload, setErrorUpload] = useState(false);
  const [selected, setSelected] = useState('');
  const [showInvite, setShowInvite] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const toggleChatInnerNav = () => {
    setShowChatSeting(!showChatSeting);
  };

  const handleOption = (type) => {
    if (type === 'Chat settings') {
      toggleChatInnerNav();
    } else if (type === 'newChat') {
      setShowInvite(true);
    }
    //  console.log({ type, id });
    //  console.log('hi');
  };

  const handleShowSearchBox = () => {
    setShowSearchBox(!showSearchBox);
  };

  useEffect(() => {}, [selectedId]);

  // initiate socket connection
  useEffect(() => {
    AppSocket.createConnection();
    return () => AppSocket.disconnect();
  }, []);

  useEffect(() => {
    const { from } = props.location;
    AppSocket.io.on('load_chat_overview', (data) => {
      //  console.log('data', data);
      if (from === 'wayagramProfile') {
        const { location } = props;
        const newObj = {
          otherDisplayName: `${location.data.displayName}`,
          message: '',
          toIdTyping: false,
          otherImageURL: location.data.avatar,
          otherId: location.data.id,
        };
        setActiveChats([newObj, ...data.chatOverview]);
        setActiveContact(newObj);
        setSelectedId(location.data.id);
      } else {
        setActiveChats(data.chatOverview);
      }
    });
    AppSocket.io.on('load_previous_chats', (data) => {
      //  console.log('previous data', data);
      if (data.status) {
        setChatHistory(data.previousChat);
      }
    });
    // AppSocket.io.on('new_message', (data) => {
    //   // console.log('new mssg 1', data);
    //   if (data.status) {
    //     console.log('new mssg', data);
    //   }
    // });
    // AppSocket.io.on('notify_user', (data) => {
    //   if (data.status) {
    //     console.log('notify', data);
    //     setMessage('');
    //   }
    // });
    // AppSocket.io.on('is_user_online', (data) => {
    //   if (data.status) {
    //     console.log('isOnline', data);
    //   }
    // });
  }, [AppSocket?.io?.connected]);

  useEffect(() => {
    AppSocket.sendRequest('user_connected');
    AppSocket.io.emit('chat_overview');
  }, [AppSocket?.io?.connected]);

  const handleSelectedContact = (contactId) => {
    const selectedContact = activeChats.filter(
      (item) => item.otherId === contactId
    );

    setActiveContact(selectedContact[0]);
    const data = {
      otherId: contactId,
    };
    if (AppSocket.io.connected)
      AppSocket.io.emit('previous_chats', JSON.stringify(data));
    setSelectedId(contactId);
  };

  const handleNewChat = (data) => {
    setBtnLoading(true);
    const newObj = {
      otherDisplayName: `${data.displayName}`,
      message: '',
      toIdTyping: false,
      otherImageURL: data.avatar,
      otherId: data.id,
    };
    setShowInvite(false);
    setActiveChats([newObj, ...activeChats]);
    setActiveContact(newObj);
    setSelectedId(data.id);
    setChatHistory([]);
    setBtnLoading(false);
  };

  const onChangeUpload = (e) => {
    showLoader();
    if (e.target.files[0]) {
      handlePreview(e, setImage, setPreview);
    } else {
      hideLoader();
    }
  };

  const handleSendMessage = (e) => {
    if (e.key === 'Enter') {
      //  console.log('do validate', activeContact);
      const data = {
        receiverId: activeContact.otherId,
        message: message || null,
        chatId: `${wayagramProfile.UserId}_${Date.now()}`,
        forwardMessage: false,
        messageType: message ? 'textMessage' : 'fileMessage',
        fileUrl: !message ? image : 'null',
      };
      //  console.log('send_mssg', JSON.stringify(data), AppSocket.io.connected);
      AppSocket.io.emit('send_message', JSON.stringify(data));
      setChatHistory([
        ...chatHistory,
        { ...data, senderId: wayagramProfile.id },
      ]);
    }
  };

  const handleUpload = async () => {
    const form = new FormData();
    if (!image) {
      swal('Oops!', 'Select an image to upload', 'error');
      hideLoader();
      return;
    }
    form.append('file', image);
    const res = await httpPost(
      `/api/upload/others?fileName=${image.name}&userId=${wayagramProfile.UserId}`,
      form,
      customBaseUrl.fileResourseUrl
    );
    if (res.status) {
      //  console.log('res', res);
      hideLoader();
      handleSendMessage();
      setImage(null);
      setPreview(null);
      setErrorUpload(false);
    } else {
      hideLoader();
      setPreview(null);
      setErrorUpload(true);
    }
  };

  useEffect(() => {
    if (preview) {
      setChatHistory([
        ...chatHistory,
        {
          senderId: wayagramProfile.id,
          fileUrl: preview,
          messageType: 'fileMessage',
        },
      ]);
      handleUpload();
    }
    return () => {
      setPreview(null);
    };
  }, [preview]);

  const deleteMessage = (id) => {
    const newHistory = [...chatHistory].filter((item) => item.id === id);
    setChatHistory(newHistory);
  };

  const clearAllChatHistory = () => {
    setChatHistory([]);
  };

  return (
    <Layout routeHistory={props} currentPage="chat">
      <WayaChatContainer>
        <WayaChat>
          <div className="chat-col-left">
            <div className="chat-left-nav">
              <div className="chat-left-top">
                {!showChatSeting ? (
                  <ChatInnerNav
                    handleOption={handleOption}
                    handleShowSearchBox={handleShowSearchBox}
                    loggedInUser={wayagramProfile}
                    activeChatsCount={activeChats.length}
                    setShowInvite={setShowInvite}
                  />
                ) : (
                  <div className="chat-settings-nav">
                    <img
                      src={arrowLeft}
                      alt="arrow"
                      onClick={toggleChatInnerNav}
                    />
                    <div>Chat Settings</div>
                  </div>
                )}
              </div>
              {showSearchBox && (
                <div className="input-container">
                  <input />
                </div>
              )}
            </div>
            <div>
              {showChatSeting ? (
                <ChatSettingsOptionsList options={chatSettingsOptions} />
              ) : (
                <>
                  {activeChats.map((contact) => (
                    <Contact
                      data={contact}
                      key={contact.id}
                      selectContact={handleSelectedContact}
                      selectedId={selectedId}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="chat-col-right">
            {activeContact?.otherId ? (
              <ChatHistory
                key={activeContact.id}
                user={activeContact}
                data={chatHistory}
                handleSendMessage={handleSendMessage}
                setMessage={setMessage}
                message={message}
                loggedInUser={wayagramProfile}
                handleUpload={onChangeUpload}
                errorUpload={errorUpload}
                deleteMessage={deleteMessage}
                clearAllChatHistory={clearAllChatHistory}
              />
            ) : (
              <NoChatHistory />
            )}
          </div>
        </WayaChat>
      </WayaChatContainer>
      {showInvite ? (
        <Invite
          showModal={showInvite}
          hideModal={setShowInvite}
          btnLoading={btnLoading}
          handleInvite={handleNewChat}
          selected={selected}
          setSelected={setSelected}
          title="Start a conversation"
          btnText="Message"
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
