// import envConfig from '../../../utils/envConfig';

const logout = () => {
  localStorage.clear();
  window.location.href = 'https://staging.wayabank.ng';
};

const Logout = () => {
  return logout();
};

export default Logout;
