import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { httpPost, httpGet, customBaseUrl } from '../../../../action/http';
import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { hideLoader, showLoader } from '../../../../utils/loader';
import moneyFomatter from '../../../../utils/moneyFormatter';

const Betting = (props) => {
  const [showPin, setShowPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [balance, setBalance] = useState();
  const [accountBet, setAccountBet] = useState('');
  const [amountBet, setAmountBet] = useState('');
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const { betting } = useContext(BillerContext);
  const { setReload, reload, walletList } = useContext(WalletContext);
  const [product, setProduct] = useState([]);
  const [bundle, setBundle] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [pin, setPin] = useState(null);
  const [betBundle, setBetBundle] = useState([]);
  //  const [phone, setPhone] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    // console.log('accountAirtime', accountAirtime);
    /* if (validator.isEmpty(phone)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */

    const data = {
      amount: +amountBet,
      accountNumber: customerId,
      type: betBundle?.type ?? providerData?.type,
    };
    showLoader();
    const res = await httpPost(
      `/api/v1/betting/pay?serviceProviderBillerId=${providerData?.billerId}&serviceProviderId=${providerData?.providerId}&userAccountNumber=${accountBet}`,
      data,
      customBaseUrl.billPaymentUrl2,
      pin
    );
    if (res.status) {
      // console.log(res);
      hideLoader();
      setLoading(false);
      hideModal(false);
      swal('Done', res.data?.transactionMessage, 'success');
      setReload(!reload);
    } else {
      hideLoader();
      setLoading(false);
      swal('Oops', res.message, 'error');
    }
  };

  const getProduct = async (bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchProductByBiller?serviceProviderBillerId=${bid}`
    );
    if (res?.status) {
      hideLoader();
      setProduct(res?.data);
    } else {
      hideLoader();
    }
  };
  const getBundle = async (pid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBundleByProduct?serviceProviderProductId=${pid}`
    );
    if (res?.status) {
      hideLoader();
      setBundle(res?.data);
    } else {
      hideLoader();
    }
  };
  const VerifyCustomer = async (sCard) => {
    const data = {
      account: sCard,
      serviceProviderCategoryId: providerData?.categoryId,
      serviceProviderId: providerData?.providerId,
      type: betBundle?.type ?? providerData?.type,
    };
    const res = await httpPost(
      `/api/v1/verifyCustomerToken`,
      data,
      customBaseUrl.billPaymentUrl2
    );
    if (res?.status) {
      setCustomerDetails(res?.data);
    } else {
      hideLoader();
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Betting
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Service Provider" type="text" /> */}
            <select
              //  value={network}
              onChange={(e) => {
                // getFieldParams(e.target.value);
                setProduct([]);
                // setBundle([]);
                // setCustomerDetails([]);
                const {
                  hasProduct,
                  type,
                  billerId,
                  productId,
                  id: cId,
                  serviceProviderCategory: {
                    serviceProvider: { id: pid },
                  },
                } = JSON.parse(e.target.value);

                setProviderData({
                  ...providerData,
                  categoryId: cId,
                  providerId: pid,
                  type,
                  billerId: billerId ?? productId,
                });
                if (hasProduct) {
                  getProduct(id);
                }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="" selected data-default hidden>
                Service Provider
              </option>
              {betting.length
                ? betting.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          {product?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  //  getAmount(e.target.value);
                  setAmountBet('');
                  if (JSON.parse(e.target.value)?.hasBundle) {
                    getBundle(JSON.parse(e.target.value)?.id);
                  }
                  setBetBundle(JSON.parse(e.target.value));
                  //  setAirtimeProd(e.target.value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {product
                  ? product.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {bundle?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  // setDataBundle(JSON.parse(e.target.value));
                  setAmountBet(JSON.parse(e.target.value)?.amount);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Bundle
                </option>
                {bundle
                  ? bundle.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name} - N{moneyFomatter(item.amount)} -{}
                        {item.validity}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {/* <div className="inputbox-with-one-input">
            <input placeholder="Select Package" type="text" />
          </div> */}
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Customer ID" type="text" /> */}
          </div>
          {network ? (
            <>
              <div className="inputbox-with-one-input">
                <input
                  type="number"
                  value={customerId}
                  placeholder="Customer ID"
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                    setCustomerDetails([]);
                    if (e.target.value.length > 3) {
                      VerifyCustomer(e.target.value);
                    }
                  }}
                />
              </div>
              {customerDetails?.name ? (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    disabled
                    value={customerDetails?.name}
                    placeholder="customer name"
                  />
                </div>
              ) : (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    disabled
                    value=""
                    placeholder="customer name"
                  />
                </div>
              )}
            </>
          ) : (
            ''
          )}
          {network ? (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Amount"
                type="number"
                value={amountBet}
                onChange={(e) => setAmountBet(e.target.value)}
              />
            </div>
          ) : (
            ''
          )}
          {/* network ? (
            <div className="inputbox-with-one-input">
              <PhoneInput
                placeholder="Phone Number"
                defaultCountry="NG"
                international
                value={phone}
                onChange={setPhone}
              />
            </div>
          ) : (
            ''
          ) */}
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountBet(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          loading={loading}
          disabled={loading || false}
          color="primary"
          onClick={() => {
            setShowPin(true);
            // handleSubmit();
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Betting"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Betting.defaultProps = {
  id: '',
};

Betting.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Betting;
