import React from 'react';
import { Modal } from 'reactstrap';
import Button from '../../shared/Button';
// import ResetPin from './ResetPin';
// import { getResetPinOtp } from '../../../store/calls';
// import { ProfileContext } from '../../../store/context/ProfileContext';
export default function EditAccess(props) {
  const { showModal, hideModal, loading, setShowPin } = props;
  // const [showResetPin, setShowResetPin] = useState(false);
  // const { profile } = useContext(ProfileContext);

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      id="Edit-access-modal"
    >
      <div className="modal-body-rs p-2 pb-3">
        <div className="header-sec-modal">
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/back.png"
            alt=""
          />
          <img className="header-sec-logo" src="./assets/appLogo.png" alt="" />
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/x.png"
            alt=""
          />
        </div>

        <div className="waya-modal-body">
          <h1 className="modal-header-data">Edit Access</h1>

          <p className="modal-header-sub-data text-secondary">
            Change Adeyanju Gabriel Role and Permission
          </p>
          {/* <div>
            <div className="inputbox-with-one-input">
              Roles
              <select
                name="filter"
                className="border p-2 mt-1 rounded"
                value=""
              >
                <option value=""> </option>
              </select>
            </div>
            <div className="inputbox-with-one-input">
              Permission
              <select
                name="filter"
                className="border p-2 mt-1 rounded"
                value=""
              >
                <option value=""> </option>
              </select>
            </div>
          </div> */}

          <div className="w-100 mt-5 text-center">
            <Button
              type="button"
              className="btn btn-primary btn-lg w-50"
              loading={loading}
              disabled={loading || false}
              onClick={() => setShowPin(true)}
              content="Continue"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
