import React, { useContext, useState } from 'react';
import { WalletContext } from '../../../store/context/WalletContext';
import moneyFomatter from '../../../utils/moneyFormatter';
// import axios from 'axios';
// import { Card, Button, Row, Col } from 'antd';
// import Layout from '../../shared/Layout';
// import { httpPostFormData } from '../../../action/http';
// import Api from '../../../assets/image/Api.png';
// import Contact from '../../../assets/image/Contact.png';
// import whatsapp from '../../../assets/image/whatsapp.png';

const TicketPage = () => {
  //   const handleContact = () => {
  //     window.location = 'https://staging.wayabank.ng/contact';
  //   };
  const { walletList } = useContext(WalletContext);
  const [data, setData] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('SingleLine', data.firstName);
    formData.append('SingleLine1', data.lastName);
    formData.append('SingleLine2', data.actno);
    formData.append('SingleLine3', data.email);
    formData.append('SingleLine4', data.category);
    formData.append('SingleLine5', data.description);
    formData.append('SingleLine6', data.subject);

    // const res = httpPostFormData(
    //   'https://forms.zohopublic.com/wayabank/form/ComplaintTicketingForm/formperma/rIU9pagS6n6lbvkeO7_qGVhloAAbo_NPM1N-iOKsdds/htmlRecords/submit',
    //   formData,
    //   '',
    //   true
    // );
    // try {
    //   const res = await axios.post(
    //     'https://forms.zohopublic.com/wayabank/form/ComplaintTicketingForm/formperma/rIU9pagS6n6lbvkeO7_qGVhloAAbo_NPM1N-iOKsdds/htmlRecords/submit',
    //     formData,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     }
    //   );
    //   // console.log(res);
    //   return res.data;
    // } catch (error) {
    //   // hideLoader();
    //   return error.response?.data;
    // }
    try {
      const response = await fetch(
        'https://forms.zohopublic.com/wayabank/form/ComplaintTicketingForm/formperma/rIU9pagS6n6lbvkeO7_qGVhloAAbo_NPM1N-iOKsdds/htmlRecords/submit',
        {
          method: 'POST',
          body: formData,
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        }
      );
      const result = await response.json();
      console.log('Success:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div style={{ height: 500, overflowY: 'auto' }}>
      <div className="d-flex justify-content-center">
        <h2>Complaint Ticket</h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="border rounded my-auto w-50 px-5"
        style={{ marginLeft: '20%' }}
      >
        <label>Subject</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, subject: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.subject}
              placeholder=""
            />
          </span>
        </div>
        <label>FirstName</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, firstName: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.firstName}
              placeholder=""
            />
          </span>
        </div>
        <label>LastName</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.lastName}
              placeholder=""
            />
          </span>
        </div>
        <label>Account Number</label>
        <div className="">
          <span>
            <select
              name="filter"
              className="rounded w-100 my-2"
              value={data.actno}
              onChange={(e) => {
                const accountNo = e.target.value;
                setData({ ...data, actno: accountNo });
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map((item) => (
                    <option value={item.accountNo} key={item.accountNo}>
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
                : ''}
            </select>
          </span>
        </div>
        <label>Email</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, email: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.email}
              placeholder=""
            />
          </span>
        </div>
        <label>Phone Number</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.phone}
              placeholder=""
            />
          </span>
        </div>
        <label>Issue Category</label>
        <div>
          <span>
            <input
              onChange={(e) => setData({ ...data, category: e.target.value })}
              type="text"
              name="SingleLine"
              className="rounded w-100 my-2"
              value={data.category}
              placeholder=""
            />
          </span>
        </div>
        <label>Issue Description</label>
        <div>
          <span>
            <textarea
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              type="text"
              name="SingleLine"
              className="rounded border w-100 my-2"
              value={data.description}
              placeholder=""
            />
          </span>
        </div>
        <button type="submit">send</button>
      </form>
      <div style={{ height: 200 }} />
      {/* eslint-disable-next-line */}
      {/* <a
        href="https://api.whatsapp.com/send/?phone=2348066252349&text=Hello Wayabank Admin I have a question for you."
        className="d-flex justify-content-end align-items-end p-5"
      >
        <img src={whatsapp} style={{ width: 70 }} alt="" />
      </a> */}
    </div>
  );
};

export default TicketPage;
