import React from 'react';
import {
  GoogleApiWrapper,
  Map,
  // Marker
} from 'google-maps-react';
import Loader from 'react-loader-spinner';

function MapContainer(props) {
  const { google } = props;

  // const triangleCoords = [
  //   { lat: 25.774, lng: -80.19 },
  //   { lat: 18.466, lng: -66.118 },
  //   { lat: 32.321, lng: -64.757 },
  //   { lat: 25.774, lng: -80.19 },
  // ];

  // const displayMarkers = () => {
  //   return locations.map((location, index) => {
  //     return (
  //       <Marker
  //         key={Math.random()}
  //         id={index}
  //         title={location?.title || ''}
  //         position={{
  //           lat: location.lat,
  //           lng: location.lng,
  //         }}
  //       />
  //     );
  //   });
  // };

  return (
    <Map google={google} zoom={15} initialCenter={{ lat: 6.4531, lng: 3.3958 }}>
      {/* {displayMarkers()} */}
    </Map>
  );
}

const LoaderDiv = () => {
  return (
    <div className="row h-100 justify-content-center align-items-center">
      <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyArvD0YkgD-ZjHcPbdlidNCPXxsXjtYf9Q',
  LoadingContainer: LoaderDiv,
})(MapContainer);

export const items = [
  'People',
  'Posts',
  'Groups',
  'Pages',
  'Trending',
  'Topics',
  'Auction',
  'Event',
  'Fundraisal',
];
