import styled from 'styled-components';
import device from '../../../utils/device';

const RightSidebarWrapper = styled.div`
  @media ${device.maxtablet} {
    display: none;
  }
`;

export const TransferWrapper = styled.div`
  padding: 15px 15px;
  background: #e5e5e5;
`;

export const TransferBody = styled.div`
  width: 80%;
  .dial-card {
    border: 1px solid #f2f2f2;
  }
  .section-a {
    height: 100vh;
  }
  @media ${device.maxmobileL} {
    width: 100% !important;
  }
`;

export const FundButton = styled.div`
  position: absolute;
  right: 30px;
  color: #fff;
  border: 1px solid #4f4f4f;
  top: 14px;
  padding: 3px;
  cursor: pointer;
  z-index: 2;
  @media (max-width: 376px) {
    right: 19px !important;
  }
`;

export const CardDetails = styled.div`
  position: absolute;
  left: 30px;
  top: 14px;
  padding: 3px;
  img {
    width: auto !important;
  }
  @media (max-width: 376px) {
    left: 18px !important;
  }
`;

export const LeftArrow = styled.div`
  position: absolute;
  left: -21px;
  top: 53px;
`;

export const RightArrow = styled.div`
  position: absolute;
  right: -21px;
  top: 53px;
`;

export const IntersectLine = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  img {
    height: 156px;
    width: auto !important;
  }
`;

export default RightSidebarWrapper;

export const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 27px)',
  width: 50,
  height: 50,
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
};

export const rightArrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 29px)',
  width: 50,
  height: 50,
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
};
