import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
// import { useHistory } from 'react-router';
import { Modal, ModalBody } from 'reactstrap';
import '../index.scss';
import { customBaseUrl, httpPut } from '../../../../action/http';
// import placeholder from '../../../../assets/image/placeholder-camera.png';
import { ProfileContainer } from './style';
import { hideLoader, showLoader } from '../../../../utils/loader';
// import validateImage from '../../../../utils/validateImage';
import { ProfileContext } from '../../../../store/context/ProfileContext';
// import Button from '../../Button';

const Index = (props) => {
  const { showModal, hideModal, mode } = props;
  const { reload, setReload, profile, isBvnLinked } =
    useContext(ProfileContext);
  const [data, setData] = useState({ ...profile });
  // const [preview, setPreview] = useState(null);
  // const [uploadImg, setUploadImg] = useState(false);
  // const [image, setImage] = useState(null);
  // const history = useHistory();

  // const handlePreview = (e) => {
  //   const imageData = e.target.files[0];
  //   const validFormat = validateImage(imageData);
  //   if (validFormat.valid) {
  //     setImage(imageData);
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       //  setPreview(reader.result);
  //     };
  //     reader.readAsDataURL(imageData);
  //   } else {
  //     swal('Oops!', `${validFormat.message}`, 'error');
  //   }
  // };

  // const handleUpload = async () => {
  //   showLoader();
  //   const form = new FormData();
  //   if (!image) {
  //     swal('Oops!', 'Select an image to upload', 'error');
  //     hideLoader();
  //     return;
  //   }
  //   form.append('file', image);
  //   const persistUser = localStorage.getItem('userId');
  //   const res = await httpPost(
  //     `/api/v1/profile/update-profile-image/${persistUser}`,
  //     form,
  //     customBaseUrl.authUrl
  //   );
  //   if (res?.status) {
  //     hideLoader();
  //     swal('Done', `${res?.message}`, 'success').then(() => {
  //       setImage(null);
  //       //  setUploadImg(true);
  //     });
  //   } else {
  //     hideLoader();
  //     swal('Oops!', res?.message || 'Something went wrong', 'error');
  //   }
  // };

  const handleSubmit = async (e) => {
    showLoader();
    e.preventDefault();

    if (validator.isEmpty(data.firstName)) {
      swal('Oops!', 'First name cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (validator.isEmpty(data.surname)) {
      swal('Oops!', 'Surname cannot be empty', 'error');
      hideLoader();
      return;
    }
    // if (mode === 'outside') {
    //   if (!uploadImg) {
    //     swal('Oops!', 'Upload profile image to continue', 'error');
    //     hideLoader();
    //     return;
    //   }
    // }
    const profileId = localStorage.getItem('profileId');
    const postData = {
      ...data,
      profileId,
    };

    const persistUser = localStorage.getItem('userId');
    const res = await httpPut(
      `/api/v1/profile/update-personal-profile/${persistUser}`,
      postData,
      customBaseUrl.authUrl
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        if (mode === 'outside') {
          hideModal(false);
          setReload(!reload);
        } else {
          setData({});
          setReload(!reload);
        }
        hideModal(false);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  useEffect(() => {
    setData({ ...data, ...profile });
  }, [profile]);
  return (
    <ProfileContainer id="login-modal">
      <Modal
        isOpen={showModal}
        toggle={() => hideModal(false)}
        id="login-modal"
        centered
      >
        <ModalBody className="modal-body-rs col-sm-12 col-md-10">
          <div className="col-12">
            <div className="row">
              <div
                className="col-4 add-cursor text-danger"
                onClick={() => hideModal(false)}
              >
                Skip
              </div>
              <div className="col-4 text-center">Update Profile</div>
              <a
                href="/#"
                className="col-4 text-decoration-none text-end add-cursor text-success"
                onClick={handleSubmit}
              >
                Save
              </a>
            </div>
          </div>

          <div className="waya-modal-body">
            {/* <div
              className="p-image profile-avatar-div text-center"
              style={!image ? { marginBottom: '20px' } : {}}
            >
              <input type="file" onChange={handlePreview} />
              <img
                className="add-cursor"
                src={preview || data?.profileImage || placeholder}
                alt="avatar"
              />
            </div> */}
            {/* <p
              className="text-center mb-0 pb-0 mt-3 mb-3 add-cursor text-primary"
              style={!image ? { display: 'none' } : {}}
              onClick={handleUpload}
            >
              Upload profile image
            </p> */}
            {/* <div className="text-center">
              <Button
                type="button"
                className="btn btn-primary btn-md mb-2 mt-3"
                style={
                  !image
                    ? { display: 'none' }
                    : {
                        height: '33px',
                        width: '65px',
                        padding: '1px',
                        fontSize: '12px',
                      }
                }
                onClick={handleUpload}
                content="Upload"
              />
            </div> */}

            {/* <p className="text-center">Acct Number; 0092112382</p> */}

            <form>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="First name"
                  type="text"
                  disabled={isBvnLinked}
                  value={data?.firstName}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setData({ ...data, firstName: e.target.value });
                  }}
                />
              </div>

              <div className="inputbox-with-one-input">
                <input
                  placeholder="Last Name"
                  type="text"
                  disabled={isBvnLinked}
                  value={data?.surname}
                  onChange={(e) => {
                    setData({ ...data, surname: e.target.value });
                  }}
                />
              </div>

              <div className="inputbox-with-one-input">
                <input
                  placeholder="Date of Birth"
                  type="date"
                  disabled={isBvnLinked}
                  value={data?.dateOfBirth}
                  onChange={(e) => {
                    setData({ ...data, dateOfBirth: e.target.value });
                  }}
                />
              </div>

              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  disabled={isBvnLinked}
                  value={data?.gender}
                  onChange={(e) => {
                    setData({ ...data, gender: e.target.value });
                  }}
                >
                  <option value="Gender" disabled>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="inputbox-with-one-input">
                <input
                  className="form-control"
                  placeholder="Email Address"
                  type="text"
                  value={data?.email}
                  disabled
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </div>

              <div className="inputbox-with-one-input">
                <input
                  className="form-control"
                  placeholder="Phone Number"
                  type="text"
                  value={`+${data?.phoneNumber}`}
                  disabled
                  onChange={(e) => {
                    setData({ ...data, phoneNumber: e.target.value });
                  }}
                />
              </div>

              <div className="inputbox-with-one-input">
                <input
                  placeholder="District/State"
                  type="text"
                  disabled={isBvnLinked}
                  value={data?.district}
                  onChange={(e) => {
                    setData({ ...data, district: e.target.value });
                  }}
                />
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="LGA"
                  type="text"
                  disabled={isBvnLinked}
                  value={data?.city}
                  onChange={(e) => {
                    setData({ ...data, city: e.target.value });
                  }}
                />
              </div>

              <div className="inputbox-with-one-input">
                <input
                  placeholder="Address"
                  type="text"
                  disabled={isBvnLinked}
                  value={data?.address}
                  onChange={(e) => {
                    setData({ ...data, address: e.target.value });
                  }}
                />
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </ProfileContainer>
  );
};

Index.defaultProps = {
  mode: 'outside',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  mode: PropTypes.string,
};

export default Index;
