import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import swal from 'sweetalert';
import '../FundWallet/index.scss';
import { customBaseUrl, httpPost } from '../../../../action/http';
import Button from '../../Button';

const CreateComment = (props) => {
  console.log('got here....');
  const { showModal, hideModal, post, profile } = props;
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);

  const createComment = async () => {
    console.log('submitting comment......');
    setLoading(true);
    const data = {
      ...postData,
      type: 'user',
      profile_id: profile.id,
      post_id: post.id,
      parent_id: post.id,
    };
    const res = await httpPost(
      `/all-posts/comment-create`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Ooops!', res.message, 'error');
    }
  };

  const onChangeHandler = (e) => {
    setPostData({ ...postData, comment: e.target.value });
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="wayagram-create-group"
      centered
    >
      <ModalHeader>
        <div className="header-wrapper">
          <h5 className="">Comment</h5>
          <button
            className="header-close"
            type="button"
            onClick={() => hideModal(false)}
          >
            x
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="inner-wrapper">
          <div className="wrapper">
            <div className="left-gram">
              <img
                src={post?.Profile?.avatar}
                alt="Profile"
                className="profile-image"
              />
            </div>
            <div className="right-gram">
              <div className="inner-right">
                <h6 className="fullname">{post.Profile?.displayName}</h6>
                <h6 className="handle">{`@${post?.Profile?.username}`}</h6>
                <h6 className="hour">2h</h6>
              </div>
              <h6 className="gram-content">{post?.description}</h6>
              {post.images &&
                post.images.map((e) => (
                  <img
                    src={e}
                    // src={e.imageURL}
                    alt="Post"
                    className="post-image"
                    style={{
                      width: '50%',
                      transform: 'translate(50%, 0%)',
                    }}
                    key={e.id}
                  />
                ))}
            </div>
          </div>
          <div className="wrapper second-wrapper">
            <div className="left-gram">
              <img
                src={profile?.avatar}
                alt="Profile"
                className="profile-image"
              />
            </div>
            <div className="right-gram">
              <input
                type="text"
                placeholder="Comment"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
            </div>
          </div>
          <div className="button-wrapper">
            <Button
              loading={loading}
              disabled={loading || false}
              className="btn btn-primary ml-auto mb-3 mt-2"
              color="primary"
              content="Post"
              type="button"
              onClick={createComment}
            >
              Send
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

CreateComment.defaultProps = {
  // showModal: false,
};

CreateComment.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CreateComment;
