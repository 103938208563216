import { atom } from 'recoil';

export const userState = atom({
  key: 'userState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

export const userIdState = atom({
  key: 'userId',
  default: null,
});

export const userRoleState = atom({
  key: 'userRoleState',
  default: null,
});

export const corporateState = atom({
  key: 'corporateState',
  default: false,
});

export const profileState = atom({
  key: 'profileState',
  default: null,
});

export const wayagramProfileState = atom({
  key: 'wayagramProfileState',
  default: null,
});

export const myBankAccountListState = atom({
  key: 'myBankAccountListState',
  default: [],
});

export const myCardListState = atom({
  key: 'myCardListState',
  default: [],
});

export const forceTodoUpdate = atom({
  key: 'forceTODO',
  default: 0,
});

export const forceDiscoverUpdate = atom({
  key: 'forceDiscoverUpdate',
  default: 'People',
});

export const sentPaymentRequestState = atom({
  key: 'sentPaymentRequestState',
  default: {},
});

export const defaultWalletState = atom({
  key: 'defaultWalletState',
  default: null,
});

export const webKyc = atom({
  key: 'webKyc',
  default: false,
});

export const webPosTab = atom({
  key: 'webPosTab',
  default: '1',
});
