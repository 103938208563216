import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './update.scss';
import Button from '../../shared/Button';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpPost } from '../../../action/http';

const LimitOtp = (props) => {
  const { showModal, hideModal, mode, updateLimit } = props;
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  const getOtp = async () => {
    showLoader();
    const res = await httpPost(
      `/api/v1/auth/generate-otp/${phoneOrEmail}`,
      {},
      customBaseUrl.authUrl
    );
    if (res.status === true) {
      hideLoader();
      swal('Done', res?.message);
      updateLimit(true);
      hideModal(false);
    } else {
      swal('Oops!', res?.message);
      hideLoader();
    }
    return [];
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <h5 className="modal-header-data"> Update Transaction Limit </h5>
      </ModalHeader>
      <ModalBody>
        <div
          id="qr-body"
          style={mode === 'profile' ? { marginBottom: '20px' } : {}}
        >
          <div>
            <h5>Phone or Email</h5>
            <div className="inputbox-with-one-input">
              <input
                value={phoneOrEmail}
                placeholder="Phone number or email"
                type="text"
                onChange={(e) => setPhoneOrEmail(e?.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="submit-modal-btn-wrap">
          <Button onClick={getOtp} type="button" content="Get OTP" />
        </div>
      </ModalBody>
    </Modal>
  );
};

LimitOtp.defaultProps = {
  mode: 'profile',
  showModal: false,
};

LimitOtp.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  mode: PropTypes.string,
};

export default LimitOtp;
