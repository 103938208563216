import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { QrcodeOutlined } from '@ant-design/icons';
import { AddMoreIcon, RepeatIcon, SendMoneyIcon } from '../../Icons';
import { FundViaBankAccountOptions } from '../FundWallet/style';

export default function OptionModal(props) {
  const {
    setShowOuter,
    setShowInner,
    setShowWithdraw,
    setShowScan,
    showModal,
    hideModal,
    id,
  } = props;
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Transfer Funds</ModalHeader>
      <ModalBody style={{ background: '#fff' }}>
        <div
          style={{ height: 1000 }}
          className="h-100 d-grid justify-content-center align-items-center"
        >
          <FundViaBankAccountOptions
            className="d-flex p-3 my-3 hover"
            onClick={() => {
              hideModal(false);
              setShowInner(true);
            }}
          >
            <RepeatIcon />
            <p className="m-0 ms-3">Transfer to Own Account</p>
          </FundViaBankAccountOptions>
          <FundViaBankAccountOptions
            className="d-flex p-3 my-3 hover"
            onClick={() => {
              hideModal(false);
              setShowOuter(true);
            }}
          >
            <AddMoreIcon />
            <p className="m-0 ms-3">Transfer to Other Waya Account</p>
          </FundViaBankAccountOptions>
          <FundViaBankAccountOptions
            className="d-flex p-3 my-3 hover"
            onClick={() => {
              hideModal(false);
              setShowScan(true);
            }}
          >
            <QrcodeOutlined style={{ fontSize: '40px', color: 'red' }} />
            <p className="m-0 ms-3">Scan to Pay</p>
          </FundViaBankAccountOptions>
          <FundViaBankAccountOptions
            className="d-flex p-3 my-3 hover"
            onClick={() => {
              hideModal(false);
              setShowWithdraw(true);
            }}
          >
            <SendMoneyIcon active style={{ fontSize: '50px' }} />
            <p className="m-0 ms-3">Transfer to Other Banks</p>
          </FundViaBankAccountOptions>
        </div>
      </ModalBody>
      {/* <ModalFooter>
        <Button
          type="button"
          className="btn btn-primary mx-auto mb-3 mt-3"
          color="primary"
          onClick={() => setSlide(2)}
          content="Continue"
        />
      </ModalFooter> */}
    </Modal>
  );
}
