import React, { useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import QrReader from 'react-qr-reader';
import Swal from 'sweetalert2';
import { customBaseUrl, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import moneyFomatter from '../../../../utils/moneyFormatter';

export default function PayToScan(props) {
  const { setData, data, getCharges, hideModal, walletList } = props;
  const [result, setResult] = useState('No result');
  const [cords, setCords] = useState({});
  const [scannedData, setScannedData] = useState({});
  const [showScanner, setShowScanner] = useState(false);
  const [balance, setBalance] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const verifyQR = async (qr) => {
    console.log('qr1', qr);
    showLoader();
    const qrData = {
      amount: '',
      locationGPS: `${cords.lat},${cords.long}`,
      scannedQCode: qr,
      userAccountNumber: data?.senderAccountNumber,
    };
    const res = await httpPost(
      `/api/v1/decode-scanned-code`,
      qrData,
      customBaseUrl.QrUrl
    );
    if (res) {
      hideLoader();
      setScannedData(res);
      setData({
        ...data,
        name: res?.merchantName,
        amount: res?.amount,
        cords,
        qrCode: qr,
        wayaMerchant: res?.wayaMerchant,
        qrPaymentType: res?.qrPaymentType,
        wayaAccount: res?.wayaAccount,
      });
      setShowDetails(true);
      //  console.log('qrdata', res);
    } else {
      Swal.fire('Oops!', res, 'Error');
      hideModal(false);
      //  console.log('qrdata err', res);
      hideLoader();
    }
  };
  const handleScan = (qrData) => {
    if (qrData) {
      setResult('');
      setShowScanner(false);
      verifyQR(qrData);
      //  setData(JSON.parse(qrData));
      //  const newData = JSON.parse(qrData);
      // setDisplayData({
      //   recipent: `${newData?.firstName} ${newData?.surname}`,
      //   email: newData?.email,
      //   amount: newData?.amount,
      //   reason: newData?.reason,
      // });
      // setShowPin(true);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCords({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
    //  getCharges(data?.senderAccountNumber, data?.amount);
  }, [data]);

  return (
    <ModalBody>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'grid',
        }}
      >
        <div className="text-center mt-3 mb-3">
          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              defaultValue={data.senderAccountNumber}
              onChange={(e) => {
                const [acccountNo, amount] = e.target.value.split(',');
                setShowScanner(true);
                setShowDetails(false);
                setData({
                  ...data,
                  name: '',
                  reason: '',
                  amount: '',
                  senderAccountNumber: acccountNo,
                });
                setBalance(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select account
              </option>
              {walletList?.length
                ? walletList?.map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item?.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance ? moneyFomatter(balance) : 0}.00
            </span>
          </div>
        </div>
        {showScanner ? (
          <div className="text-center mx-auto">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '300px' }}
            />
          </div>
        ) : (
          ''
        )}
        {showDetails ? (
          <div>
            <form className="mx-auto mt-3">
              <div className="inputbox-with-one-input mt-3">
                <input
                  placeholder="Full name"
                  type="text"
                  value={data.name}
                  disabled
                />
                <span className="input-detail">Name of recipent</span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  type="number"
                  min={0}
                  placeholder="0.00"
                  disabled={!scannedData?.customAmount}
                  onChange={(e) => {
                    setData({ ...data, amount: e.target.value });
                    getCharges(data?.senderAccountNumber, e.target.value);
                  }}
                  value={data.amount}
                />
                <span className="input-detail">
                  How much do you want to Send?
                </span>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Add a Note"
                  type="text"
                  value={data.reason}
                  onChange={(e) => {
                    setData({ ...data, reason: e.target.value });
                  }}
                />
                <span className="input-detail">Add descriptive notes</span>
              </div>
            </form>
          </div>
        ) : (
          ''
        )}
        <p>{result}</p>
      </div>
    </ModalBody>
  );
}
