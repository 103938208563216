import {
  customBaseUrl,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  httpPatch,
} from '../../../action/http';

export const getFeeds = async (profileId, page, limit) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/feed?profile_id=${profileId}&page=${
      page || 1
    }&limit=${limit || 30}`
  );
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return [];
};

export const getMyInterest = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/userInterest?profileId=${profileId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getUnAddedInterest = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/notAdded?profileId=${profileId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const addSelectedInterest = async (data) => {
  const { profileId, interestId } = data;
  const postData = {
    profileId,
    interestId,
  };
  const res = await httpPost(
    `/graph/user-interest/selectUserInterest`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const removeSelectedInterest = async (data) => {
  const { profileId, interestId } = data;
  const postData = {
    profileId,
    interestId,
  };
  const res = await httpPost(
    `/graph/user-interest/deleteUserInterest`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const getGroupSuggestion = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-group-suggestion?userId=${userId}`
  );
  if (res.status) {
    return res.suggestion;
  }
  return [];
};

export const getPageSuggestion = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-page-suggestion?userId=${userId}`
  );
  if (res.status) {
    return res.suggestion;
  }
  return [];
};

export const getFriendSuggestion = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/suggestions?user_id=${userId}`
  );
  if (res.status) {
    return res.suggestion;
  }
  return [];
};

export const getConnectionStatus = async (loggedInUserId, friendId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/connection?profile_id=${loggedInUserId}&friend_id=${friendId}`
  );
  if (res.status) {
    return res.data;
  }
  return {};
};

export const getPostsBySelectedInterest = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/recommended-posts?profile_id=${userId}`
  );
  if (res.status) {
    return res;
  }
  return [];
};

export const getUserFeeds = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/user-posts?profile_id=${profileId}&page=1`
  );
  if (res.status) {
    // console.log(res);
    return res.data.data;
  }
  return [];
};

export const getWayaProfile = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/profile/get-by-id?profile_id=${userId}`
  );
  if (res?.status) {
    return res.data;
  }
  return {};
};
export const getEventParticipants = async (eventId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-all-event-participants?event_id=${eventId}`
  );
  if (res.status) {
    return res.data.data;
  }
  return {};
};

export const getUserFriends = async (userId) => {
  const res = await httpGet(`${customBaseUrl.wayagramUrl}/?user_id=${userId}`);
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return [];
};

export const getFollowing = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/following?user_id=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getFollowers = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/followers?user_id=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return {};
};

export const getMyGroups = async (userId, pageNumber) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-user-groups?pageNumber=${
      pageNumber || 1
    }&userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const inviteToGroup = async (requestFrom, requestTo, groupId) => {
  const postData = {
    groupId,
    requestFrom,
    requestTo,
  };
  const res = await httpPost(
    `/main/group/invite-others-to-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};
export const inviteToEvent = async (requestFrom, requestTo, eventId) => {
  const postData = {
    event_id: eventId,
    requestFrom,
    requestTo,
  };
  // const newForm = new FormData();
  // newForm.append('event_id', eventId);
  // newForm.append('requestFrom', requestFrom);
  // newForm.append('requestTo', requestTo);
  const res = await httpPost(
    `/all-posts/invite-others-to-event`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const joinGroup = async (userId, groupId) => {
  const postData = {
    userId,
    groupId,
  };
  const res = await httpPost(
    `/main/group/join-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const leaveAndDeleteGroup = async (userId, groupId) => {
  const postData = {
    userId,
    groupId,
  };
  const res = await httpPut(
    `/main/group/leave-and-delete-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const getAllGroups = async (userId, pageNumber) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-groups?pageNumber=${
      pageNumber || 1
    }&userId=${userId}
    `
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getMyPages = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-user-pages?userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};
export const followOrUnfollowPage = async (userId, pageId) => {
  const postData = {
    userId,
    pageId,
  };
  console.log(postData);
  const res = await httpPut(
    `/main/page/follow-or-un-follow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  console.log(res, 'handleFollow');
  return res;
};

export const getPageById = async (userId, pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-page-by-id?pageId=${pageId}&userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};
export const deletePage = async (data) => {
  const res = await httpDelete(
    `/main/page/admin-delete-page`,
    data,
    customBaseUrl.wayagramUrl
  );
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return {};
};

export const getPagePosts = async (userId, pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/page-posts?profile_id=${pageId}&page_id=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getPageFollowers = async (pageId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-page-followers?pageId=${pageId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getAllPages = async (userId, pageNumber) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-pages?pageNumber=${
      pageNumber || 1
    }&userId=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getFriends = async (userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend?user_id=${userId}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

export const inviteToAPage = async (requestFrom, requestTo, pageId) => {
  const postData = {
    pageId,
    requestFrom,
    requestTo,
  };
  const res = await httpPost(
    `/main/page/invite-to-a-page`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const deletePost = async (data) => {
  const res = await httpDelete(`/delete`, data, 'http://157.245.84.14:3200');
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return {};
};

export const other = () => {
  return [];
};

// Group calls
export const getGroupInfo = async (groupId, userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/view-group-info?groupId=${groupId}&userId=${userId}`
  );
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return {};
};

export const getGroupFeeds = async (groupId, userId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/group-posts?group_id=${groupId}&profile_id=${userId}`
  );
  if (res.status) {
    // console.log(res);
    return res.data;
  }
  return {
    data: [],
  };
};

export const getPrivateGroupRequest = async (groupId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-private-group-requests?groupId=${groupId}`
  );
  if (res.status) {
    return res;
  }
  return {
    data: [],
  };
};

export const getGroupMembers = async (groupId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/see-all-members-in-group?groupId=${groupId}`
  );
  if (res.status) {
    return res;
  }
  return {
    data: [],
  };
};

export const handleFollow = async (id, username) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/follow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  // console.log(res,'handleFollow');
  return res;
};

export const handleUnFollow = async (id, username) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/unfollow`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleBookmark = async (profileId, postId) => {
  const postData = {
    postId,
  };
  const res = await httpPost(
    `/all-posts/create-bookmark?profileId=${profileId}`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleGetBookmark = async (profileId, page, limit) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/all-posts/get-user-bookmarks?profileId=${profileId}&page=${
      page || 1
    }&limit=${limit || 30}`
  );
  return res;
};

export const handleBlockUser = async (id, username) => {
  const postData = {
    user_id: id,
    username,
  };
  const res = await httpPost(
    `/graph/friend/block`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleJoinGroup = async (userid, groupid) => {
  const postData = {
    groupId: groupid,
    userId: userid,
  };
  const res = await httpPost(
    `/main/group/join-group`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleRepost = async (originalPostId, profileId, comment) => {
  const postData = {
    original_post_id: originalPostId,
    reposter_profile_id: profileId,
    repost_comment_desc: comment || '',
  };
  const res = await httpPost(
    `/graph/friend/block`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};
export const eventShowInterest = async (eventId, profileId, going) => {
  const postData = {
    event_id: eventId,
    profile_id: profileId,
    going,
  };
  const res = await httpPatch(
    `/all-posts/attend-event`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleToggleMute = async (authId, username) => {
  const postData = {
    user_id: authId,
    username,
  };
  const res = await httpPut(
    `/graph/friend/mute-or-unmute-friend`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const handleGetPageCategories = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/page/get-all-page-categories?page=1&limit=100`
  );
  if (res.status) {
    return res.interests?.data;
  }
  return [];
};

export const handleGetGroupCategories = async () => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/get-all-groups-categories?page=1&limit=100`
  );
  if (res.status) {
    return res.interests?.data;
  }
  return [];
};

export const handleGetUserInterest = async (id) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/user-interest/userInterest?profileId=${id}`
  );
  if (res?.status) {
    return res?.data;
  }
  return [];
};
