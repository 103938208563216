/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Layout from '../../shared/Layout';
import { MomentWrapper } from './style';
import './index.scss';
import GramCard from '../../shared/Cards/Gram';
import {
  httpDelete,
  httpPost,
  httpPut,
  httpGet,
  customBaseUrl,
} from '../../../action/http';
import PostModal from '../../shared/Modals/Post';
import InterestModal from '../../shared/Modals/InterestsModal/Interest';
import CreatePostModal from '../../shared/Modals/Post/CreatePost';
import { UploadPost } from './GramUtils';
import { WayagramContext } from '../../../store/context/WayagramContext';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import {
  getFeeds,
  // getWayaProfile,
  getUnAddedInterest,
  addSelectedInterest,
  removeSelectedInterest,
  // getFriendSuggestion,
  getPostsBySelectedInterest,
  handleBookmark,
  handleBlockUser,
  handleFollow,
  handleRepost,
  handleToggleMute,
  getMyInterest,
} from './container';
import RightSidebar from './RightSidebar';
// import Events from './events';
import Pagination from '../../shared/Pagination/index';
import SearchBar from './Searcbar';
import InterestList from './InterestList';
import SearchTabs from './SearchTabs';
import { items } from '../../../utils/map';
import { handleSearchFriends, handleWayaSubmit } from '../../../utils/helper';
import { getTrendingTags } from '../../../store/calls';

export default function Index(props) {
  // const MomentData = {};
  const [feeds, setFeeds] = useState([]);
  // const [events, setEvents] = useState([]);
  const [moments, setMoments] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  // const [selectedPostId, setSelectedPostId] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [showSetUp, setShowSetUp] = useState(false);
  const [setupSubmit, setSetupSubmit] = useState(false);
  console.log(setupSubmit);
  const [setUpStep] = useState(0);
  const [unAddedInterest, setUnAddedInterest] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [active, setActive] = useState('Groups');
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  // const [friendSuggestion, setFriendSuggestion] = useState([]);
  // const [
  //   postBySuggestion,
  //   setPostBySuggestion,
  // ] = useState([]);
  const {
    wayagramProfile,
    wayagramProfileId,
    setReload,
    reloadMoment,
    reload,
  } = useContext(WayagramContext);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);

  const handleShowPostModal = async (data) => {
    setShowCreatePost(true);
    setSelectedData(data);
    // setSelectedPostId(data.postId);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const handleGetMoments = async (userId) => {
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/get-user-moments?profile_id=${userId}`
      );
      if (res.status) {
        console.log(res.data);
        setMoments(res?.data?.data);
      }
      return {};
    } catch (error) {
      return error.message;
    }
  };

  const getAllInfo = async () => {
    const allFeeds = await getFeeds(wayagramProfileId, pageNo, pageSize);
    setFeeds(allFeeds.data);
  };

  // const handleEvents = async () => {
  //   try {
  //     const res = await httpGet(
  //       `/all-posts/get-all-events`,
  //       customBaseUrl.wayagramUrl
  //     );
  //     if (res.status) {
  //       setEvents(res?.data?.data);
  //     }
  //     return [];
  //   } catch (error) {
  //     return error.message;
  //   }
  // };

  useEffect(() => {
    (async () => {
      if (wayagramProfileId) {
        const myInterest = await getMyInterest(wayagramProfileId);
        if (!myInterest.length) {
          setShowSetUp(true);
        }
      }
    })();
  }, [wayagramProfileId]);

  useEffect(() => {
    if (wayagramProfileId) {
      handleGetMoments(wayagramProfile.id);
    }
  }, [wayagramProfileId, reloadMoment]);

  useEffect(() => {
    if (wayagramProfileId) {
      getAllInfo();
    }
    return () => {
      setPageSize(10);
      setPageNo(1);
    };
  }, [wayagramProfileId, reload, pageNo]);

  const handleDeletePost = async (postId) => {
    const data = {
      post_id: postId,
    };
    const res = await httpDelete(
      `/all-posts/trash`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setFeeds(feeds.filter((item) => item.id !== postId));
    }
    return {};
  };

  const handleEditPost = async (data) => {
    const postData = {
      description: data.description,
      profile_id: wayagramProfile.id,
      post_id: data.postId,
      // type: 'user',
      isPoll: false,
      isPaid: false,
      forceTerms: false,
    };

    const res = await httpPut(
      `/all-posts/update`,
      postData,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setShowCreatePost(false);
        getAllInfo();
      });
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  // const handleBookmarkPost = async (data) => {
  //   const postData = {
  //     postId: data.postId,
  //   };

  //   const res = await httpPut(
  //     `/all-posts/create-bookmark?profileId=${wayagramProfile.id}`,
  //     postData,
  //     customBaseUrl.wayagramUrl
  //   );
  //   if (res.status) {
  //     swal('Done', res.message, 'success').then(() => {
  //       setShowCreatePost(false);
  //     });
  //   } else {
  //     swal('Oops!', res.message, 'error');
  //   }
  // };
  const handleLikePost = async (postId) => {
    const data = {
      post_id: postId,
      profile_id: wayagramProfile.id,
      type: 'user',
    };
    const res = await httpPost(
      '/all-posts/like-post',
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setReload(true);
      swal('Done', res.message, 'success');
    }
  };

  const handleSubmitInterest = (selectedInterest) => {
    if (selectedInterest.length) {
      // setSetUpSteps(1);
      localStorage.setItem('gramHandleSetup', true);
      setShowSetUp(false);
      setSetupSubmit(true);
    } else {
      swal('Wait', 'Select an Interest', 'error');
    }
  };

  const addInterest = async (interestId) => {
    const profileId = wayagramProfile.id;
    const data = { profileId, interestId };
    await addSelectedInterest(data);
  };

  const removeInterest = async (interestId) => {
    const profileId = wayagramProfile.id;
    const data = { profileId, interestId };
    await removeSelectedInterest(data);
  };

  const handleInterestSuggestion = async () => {
    const profileId = wayagramProfile.id;
    const resData = await getUnAddedInterest(profileId);
    setUnAddedInterest(resData);
  };

  const apiAlertResponse = (res) => {
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const addBookmark = async (postId) => {
    const profileId = wayagramProfile.id;
    const res = await handleBookmark(profileId, postId);
    apiAlertResponse(res);
  };

  const blockUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleBlockUser(authId, username);
    apiAlertResponse(res);
  };

  const handleFollowUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleFollow(authId, username);
    apiAlertResponse(res);
  };

  const repostPost = async (postId) => {
    const profileId = wayagramProfile.id;
    console.log({ postId, profileId });
    const res = await handleRepost(originalPostId, profileId, comment);
    apiAlertResponse(res);
  };

  const handleMuteUser = async (username) => {
    const authId = wayagramProfile.UserId;
    const res = await handleToggleMute(authId, username);
    apiAlertResponse(res);
  };

  // const handleFriendSuggestion = async () => {
  //   const profileId = wayagramProfile.id;
  //   const resData = await getFriendSuggestion(profileId);
  //   setFriendSuggestion(resData);
  // }

  const handlePostBySuggestion = async () => {
    const profileId = wayagramProfile.id;
    await getPostsBySelectedInterest(profileId);
    // setPostBySuggestion(resData.data);
  };

  const handleSubmitRecommend = (selectedFollows) => {
    console.log(selectedFollows);
    setShowSetUp(false);
  };

  // Re-Render for Initial set-up flow
  useEffect(() => {
    // const setup = localStorage.getItem('gramHandleSetup');
    if (wayagramProfileId) {
      // handleFriendSuggestion();
      handleInterestSuggestion();
      handlePostBySuggestion();
      // handleEvents();
    }
  }, [wayagramProfileId]);

  const handleSearch = async (val, activeTab, id) => {
    setLoading(true);
    const resData = await handleSearchFriends(
      val,
      activeTab,
      id,
      wayagramProfile.id
    );
    setList(resData);
    setLoading(false);
  };

  const handleGetTrending = async () => {
    setLoading(true);
    const userId = wayagramProfile.id;
    const resData = await getTrendingTags(userId);
    setList(resData);
    setLoading(false);
  };

  const handleSubmit = async (type, username, objectId) => {
    setBtnLoading(true);
    handleWayaSubmit(
      type,
      username,
      objectId,
      wayagramProfile,
      active,
      searchValue,
      handleSearch,
      setList
    );
    setBtnLoading(false);
  };
  console.log(feeds);
  return (
    <Layout route={props} currentPage="wayagram" pageName="Feed" showName>
      <div className="row wayagram-div">
        <div className="col-8">
          <SearchBar
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {searchValue ? (
            <SearchTabs
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              items={items}
              handleGetTrending={handleGetTrending}
              handleSearch={handleSearch}
              setList={setList}
              setActive={setActive}
              active={active}
              btnLoading={btnLoading}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              handleSubmit={handleSubmit}
              loading={loading}
              list={list}
            />
          ) : (
            <MomentWrapper className="row m-2">
              <div className="moment-header">Moments</div>
              <Carousel
                responsive={responsive}
                ssr
                autoPlay
                infinite={false}
                autoPlaySpeed={10000}
                keyBoardControl
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                // beforeChange={() => setState(true)}
                // afterChange={() => setState(false)}
                // containerClass="first-carousel-container container"
              >
                {moments && moments.length > 0
                  ? moments.map((data) => (
                      <div
                        key={data.id}
                        className="moment-card"
                        style={{
                          backgroundImage:
                            data?.type === 'image'
                              ? `url(${data.content})`
                              : 'none',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '100% 100%',
                          textAlign: 'center',
                          backgroundColor: data.type !== 'image' && '#000000a8',
                          color: data.type !== 'image' && '#fff',
                        }}
                      >
                        <div>
                          {data.type === 'text' ? (
                            <p style={{ textAlign: 'center' }}>
                              {data.content}
                            </p>
                          ) : (
                            ''
                          )}
                          <div className="moment-inner">
                            <img
                              className="moment-img"
                              src={data?.Profile?.avatar || imgPlaceholder}
                              alt="Profile"
                            />
                            <h6 className="fs-6 text-white">{data.userName}</h6>
                          </div>
                        </div>
                      </div>
                    ))
                  : [1, 2, 3, 4].map((d) => (
                      <Skeleton
                        className="MX-2"
                        height={150}
                        width={150}
                        key={d}
                      />
                    ))}
              </Carousel>
              <div className="">
                <div className="moment-header">Interest</div>
                <InterestList />
              </div>
            </MomentWrapper>
          )}
          {/* <MomentWrapper className="row m-2">
            <div className="moment-header">Events</div>
            <Events
              events={events}
              profileData={wayagramProfile}
              handleEvents={handleEvents}
            />
          </MomentWrapper> */}
          <MomentWrapper className="row m-2">
            <div className="moment-header">Post</div>
            <div className="mt-0">
              <Pagination
                dataLength={feeds?.length}
                setPageNo={setPageNo}
                pageNo={pageNo}
              >
                {(feeds || []).map((post) => (
                  <GramCard
                    key={post.id}
                    data={post}
                    handleDeletePost={handleDeletePost}
                    showPostModal={handleShowPostModal}
                    handleLikePost={handleLikePost}
                    addBookmark={addBookmark}
                    blockUser={blockUser}
                    handleFollowUser={handleFollowUser}
                    repostPost={repostPost}
                    handleMuteUser={handleMuteUser}
                    fromLocation="feeds"
                  />
                ))}
              </Pagination>
              {feeds.length <= 0 && (
                <div style={{ height: '50vh', textAlign: 'center' }}>
                  No Post Found
                </div>
              )}
            </div>
          </MomentWrapper>
          <UploadPost showPost={setShowPostModal} />
        </div>
        <RightSidebar isSearch />
      </div>
      {setShowPostModal ? (
        <PostModal
          showModal={showPostModal}
          hideModal={setShowPostModal}
          set={setReload}
        />
      ) : (
        ''
      )}
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="edit"
          handleSubmit={handleEditPost}
          type="user"
          selectedData={selectedData}
        />
      ) : (
        ''
      )}
      {showSetUp ? (
        <InterestModal
          showModal={showSetUp}
          hideModal={setShowSetUp}
          step={setUpStep}
          addInterest={addInterest}
          removeInterest={removeInterest}
          unAddedInterest={unAddedInterest}
          // id="create-post-modal"
          // submitHandle={handleSubmitHandle}
          submitInterest={handleSubmitInterest}
          submitRecommend={handleSubmitRecommend}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
