import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import sharePostIcon from '../../../../assets/image/share-post.svg';
import shareMomentIcon from '../../../../assets/image/share-moment.svg';
import createPageIcon from '../../../../assets/image/create-page.svg';
import CreatePostModal from './CreatePost';
// import CreateGroupModal from './CreateGroup';
import CreateGroupModal from './CreateGroupModal';
import CreateMomentModal from './CreateMoment';
import CreatePageModal from './CreatePage';
// import CreateEventModal from './CreateEvent';
import CreateAuctionModal from './CreateAuction';
import DonationModal from './DonationModal';
import CreateEventModal from './CreateEventModal/index';
import PublishEventModal from './CreateEventModal/PublishEventModal';
import './index.scss';
import Container from '../../Container';

export default function Index(props) {
  const { showModal, hideModal, set } = props;
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showCreateMoment, setShowCreateMoment] = useState(false);
  const [showCreatePage, setShowCreatePage] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showCreateAuction, setShowCreateAuction] = useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [showPusblishModal, setShowPublishModal] = useState(false);
  const myRef = React.useRef(null);

  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="post-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header-box">
          <h4 className="">Create</h4>
        </div>
        <ModalBody className="">
          <Container
            className="d-flex"
            onClick={() => {
              hideModal();
              setShowCreatePost(true);
            }}
          >
            <div className="icon me-3">
              <img src={sharePostIcon} alt="post" />
            </div>
            <div className="text-div">
              <span>Post</span>
              <span className="s-text d-block">
                Share a post or write what is on your mind
              </span>
            </div>
          </Container>
          <Container
            className="d-flex mt-3"
            onClick={() => {
              hideModal();
              setShowCreateMoment(true);
            }}
          >
            <div className="icon me-3">
              <img src={shareMomentIcon} alt="post" />
            </div>
            <div className="text-div">
              <span>Moment</span>
              <span className="s-text d-block">
                Share a photo or write something
              </span>
            </div>
          </Container>
          <Container
            className="d-flex mt-3 mb-3"
            onClick={() => {
              hideModal();
              setShowCreatePage(true);
            }}
          >
            <div className="icon me-3">
              <img src={createPageIcon} alt="post" style={{ height: '30px' }} />
            </div>
            <div className="text-div">
              <span>Page</span>
              <span className="s-text d-block">
                Create a page for your business or fans
              </span>
            </div>
          </Container>
          <hr />
          <Container
            className="d-flex mt-3"
            onClick={() => {
              hideModal();
              setShowCreateGroup(true);
            }}
          >
            <div className="icon me-3">
              <img src={createPageIcon} alt="post" style={{ height: '30px' }} />
            </div>
            <div className="text-div">
              <span>Group</span>
              <span className="s-text d-block">
                Create a private or public group of interests
              </span>
            </div>
          </Container>
          <Container
            className="d-flex mt-3"
            onClick={() => {
              hideModal();
              setShowCreateEvent(true);
            }}
          >
            <div className="icon me-3">
              <img src={createPageIcon} alt="post" style={{ height: '30px' }} />
            </div>
            <div className="text-div">
              <span>Event</span>
              <span className="s-text d-block">
                Bring people together with public or private events
              </span>
            </div>
          </Container>
          <Container
            className="d-flex mt-3"
            onClick={() => {
              hideModal();
              setShowCreateGroup(true);
            }}
          >
            <div className="icon me-3">
              <img src={createPageIcon} alt="post" style={{ height: '30px' }} />
            </div>
            <div className="text-div">
              <span>Auction</span>
              <span className="s-text d-block">
                Auction items privately or publicly
              </span>
            </div>
          </Container>
          <Container
            className="d-flex mt-3"
            onClick={() => {
              hideModal();
              setShowDonation(true);
            }}
          >
            <div className="icon me-3">
              <img src={createPageIcon} alt="post" style={{ height: '30px' }} />
            </div>
            <div className="text-div">
              <span>Donation</span>
              <span className="s-text d-block">Create donation</span>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="create"
          type="user"
        />
      ) : (
        ''
      )}
      {showCreateGroup ? (
        <CreateGroupModal
          showModal={showCreateGroup}
          hideModal={setShowCreateGroup}
          mode="create"
        />
      ) : (
        ''
      )}
      {showCreateMoment ? (
        <CreateMomentModal
          showModal={showCreateMoment}
          hideModal={setShowCreateMoment}
          set={set}
          id="create-moment-modal"
          mode="create"
        />
      ) : (
        ''
      )}
      {showCreatePage ? (
        <CreatePageModal
          showModal={showCreatePage}
          hideModal={setShowCreatePage}
          mode="create"
        />
      ) : (
        ''
      )}
      {showCreateEvent ? (
        <CreateEventModal
          showModal={showCreateEvent}
          hideModal={setShowCreateEvent}
          setShowPublishModal={setShowPublishModal}
          mode="create"
        />
      ) : (
        ''
      )}
      {showCreateAuction ? (
        <CreateAuctionModal
          showModal={showCreateAuction}
          hideModal={setShowCreateAuction}
          mode="create"
        />
      ) : (
        ''
      )}
      {showDonation ? (
        <DonationModal showModal={showDonation} hideModal={setShowDonation} />
      ) : (
        ''
      )}
      {showPusblishModal ? (
        <PublishEventModal
          showModal={showPusblishModal}
          hideModal={setShowPublishModal}
        />
      ) : (
        ''
      )}
    </div>
  );
}

Index.defaultProps = {
  showModal: false,
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
};
