import React from 'react';
import { ContactCard } from './styles';

import placeHolder from '../../../assets/images/contactAvata.png';

// contactAvata.png
const Contact = (props) => {
  const { data, selectContact, selectedId } = props;
  const {
    otherDisplayName: contactName,
    message: lastMessage,
    toIdTyping: isTyping,
    otherImageURL: avatar,
  } = data;
  const lastSeen = '14:22 pm';
  const activityCount = null;

  return (
    <ContactCard
      onClick={() => {
        selectContact(data.otherId);
      }}
    >
      <div
        className={
          selectedId === data.otherId
            ? 'contact-card active-contact'
            : 'contact-card'
        }
      >
        <div className="image-container">
          <img
            src={avatar || placeHolder}
            alt="contact1"
            style={{
              width: 'inherit',
              height: 'inherit',
              borderRadius: '20px',
            }}
          />
        </div>
        <div className="text-container">
          <div className="text-top">
            <div className="username">{contactName || ''}</div>
            <div className="time">{lastSeen || ''}</div>
          </div>
          <div className="text-bottom">
            <div className={isTyping ? 'last-message italic' : 'last-message'}>
              {isTyping ? 'is typing...' : lastMessage}
            </div>
            {activityCount ? (
              <div className="activity-box">{activityCount}</div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </ContactCard>
  );
};

export default Contact;
