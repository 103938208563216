import styled from 'styled-components';
// import device from '../../../../utils/device';

export const ProfileContainer = styled.div`
  .p-image img {
    height: 100px;
  }
`;

export const clogo = {
  background: '#114185',
};
