const headers = [
  {
    header: 'Donation Information',
    description: 'You will receive money  donated into your defaut wallet',
  },
  {
    header: 'Target',
    description: 'How much are you looking to raise',
  },
  {
    header: 'Target',
    description: ' Chose either to display or hide donation to user',
  },
  {
    header: '',
    description: '',
  },
];

const donationButtonTitle = ['Continue', 'Continue', 'Continue', 'Publish'];

export { headers, donationButtonTitle };
