import React, { createContext, useState } from 'react';

export const LayoutContext = createContext();

export const LayoutContextProvider = (props) => {
  const { children } = props;
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleMobileSidebar, setToggleMobileSidebar] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        setReload,
        reload,
        loading,
        setLoading,
        toggleMobileSidebar,
        setToggleMobileSidebar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
