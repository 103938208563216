const headers = [
  {
    header: 'Event Information',
    description: `Name your event, 
    along with why attendees should come, including interesting highlights`,
  },
  {
    header: 'Location',
    description: 'Where is the location of your event?',
  },
  {
    header: 'Date and time',
    description: `Tell your 
    attendees when the event will start and end so they plan for it`,
  },
  {
    header: 'Ticketing fee',
    description: `Tell your attendees 
    if this is a free or paid event and how the pricing works.`,
  },
  {
    header: 'Event Poster',
    description: 'Upload the Event Poster ',
  },
];

const eventButtonTitle = [
  'Continue',
  'Continue',
  'Continue',
  'Continue',
  'Publish',
];

const locationType = ['Virtual', 'Physical'];

export { headers, eventButtonTitle, locationType };
