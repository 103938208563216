import React, { useState, useEffect } from 'react';
import { httpGet } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import './index.css';

const VerifyEmail = () => {
  const [verified, setVerified] = useState(5);
  const [message, setMessage] = useState('');
  let verify;

  useEffect(() => {
    verify();
    return () => {
      setMessage('');
      setVerified(5);
    };
  }, []);

  verify = async () => {
    showLoader();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const foo = params.get('token');
    // let foo = '2331edascaqe2xs'
    // console.log('token', foo);
    try {
      const data = await httpGet(
        `/api/users/confirm/email/verification/${foo}`
      );
      // console.log(data);

      if (data.status !== 200) {
        hideLoader();
        verified(2);
        message(data.errorMessage);
      }
      if (data.status === 200) {
        hideLoader();
        verified(1);
      }
    } catch (error) {
      // console.log(error.response.data.message);
      hideLoader();
      verified(0);
    }
  };

  return (
    <div
      className="page-has-left-panels page-has-right-panels notfound-body"
      id="main-panel-container"
    >
      <div className="container">
        <section className="notfound-section">
          <div className="container mt-5">
            <div className="page-error">
              <div className="page-inner">
                {verified === 1 ? (
                  <h2>Email Verification Successfull</h2>
                ) : verified === 2 ? (
                  <h2>{message}</h2>
                ) : verified === 0 ? (
                  <h2>Problem occured, Please refresh page.</h2>
                ) : (
                  <h2>Loading..</h2>
                )}
                {/* <div className="notfound-description">
								Click the button below to login
              </div> */}
                <div className="page-search">
                  <div className="mt-3">
                    <a href="/login" className="btn btn-verify">
                      Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default VerifyEmail;
