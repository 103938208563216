import React, { useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import swal from 'sweetalert';
import { notification } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { customBaseUrl, httpGet } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';

export default function PayToEmail(props) {
  const { data, setData, walletList, setRequestType, requestType, getCharges } =
    props;
  const [balance, setBalance] = useState(null);
  const [isCorporate, setIsCorporate] = useState(null);
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    if (data.senderAccountNumber && data.amount) {
      getCharges(data.senderAccountNumber, data.amount);
    }
  }, [data]);

  const handleSelectAccount = () => {
    if (isCorporate === 'true') {
      const cData = accountData?.filter((e) => e?.corporate === true)[0];
      setData({
        ...data,
        receiverProfileId: cData?.id,
        receiverEmail: cData?.email,
        receiverPhoneNumber: cData?.phoneNumber,
        receiverName: cData?.otherDetails?.organisationName,
        receiverId: cData?.userId,
      });
    } else {
      const pData = accountData?.filter((e) => e?.corporate === false)[0];
      setData({
        ...data,
        receiverProfileId: pData?.id,
        receiverEmail: pData?.email,
        receiverPhoneNumber: pData?.phoneNumber,
        receiverName: `${pData?.firstName} ${pData?.surname}`,
        receiverId: pData?.userId,
      });
    }
  };

  const handleGetAccount = async () => {
    // if (!data?.userId) return swal('userId can not be null');
    const res = await httpGet(
      `${
        customBaseUrl.authUrl
      }/api/v1/profile/account/${false}/${false}?query=${data?.email}`
    );
    showLoader();
    if (res?.status) {
      hideLoader();
      notification.success(res?.message);
      if (res?.data?.length === 1) {
        setData({
          ...data,
          receiverProfileId: res?.data[0]?.id,
          receiverEmail: res?.data[0]?.email,
          receiverPhoneNumber: res?.data[0]?.phoneNumber,
          receiverName:
            res?.data[0]?.otherDetails?.organisationName ??
            `${res?.data[0]?.firstName} ${res?.data[0]?.surname}`,
          receiverId: res?.data[0]?.userId,
        });
      } else {
        setAccountData(res?.data);
      }
    } else {
      hideLoader();
      setData({
        ...data,
        emailOrPhone: null,
        receiverName: '',
        receiverProfileId: '',
        receiverId: '',
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  useEffect(() => {
    if (accountData.length > 1) {
      handleSelectAccount();
    }
  }, [isCorporate]);

  return (
    <ModalBody>
      <div className="text-center mt-3 mb-3 ">Pay to Email</div>
      <form className="mx-auto mt-3">
        <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            value={requestType}
            onChange={(e) => {
              setRequestType(e.target.value);
              setData({ ...data, reason: '', amount: '', receiverName: '' });
              setAccountData([]);
            }}
          >
            <option value="Select" key="select" selected disabled>
              Select User Type
            </option>
            <option value="wayaUser">Waya User</option>
            <option value="nonWayaUser">Non Waya User</option>
          </select>
          <span className="input-detail">Select Recipient Type</span>
        </div>

        {requestType === 'nonWayaUser' ? (
          <div className="inputbox-with-one-input">
            <input
              placeholder="E-mail Address"
              type="text"
              value={data.email}
              onChange={(e) => {
                setData({ ...data, email: `${e.target.value}`.trim() });
              }}
            />
            <span className="input-detail">Email of recipent</span>
          </div>
        ) : (
          <div>
            {/* {isCorporate === 'true' ? (
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  value={isRegistered}
                  onChange={(e) => setIsRegistered(e.target.value)}
                >
                  <option value="Select" key="select" selected disabled>
                    Account Type
                  </option>
                  <option value={true}>REGISTERED BUSINESS</option>
                  <option value={false}>NON REGISTERED BUSINESS</option>
                </select>
                <span className="input-detail">Account Type</span>
              </div>
            ) : null} */}
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                className="form-control border-end-0 border-top-0 border-start-0"
                placeholder="Email Address"
                required
                value={data?.userId}
                onChange={(e) => {
                  setData({ ...data, receiverName: '', email: e.target.value });
                  setAccountData([]);
                  setIsCorporate(null);
                }}
              />
              <button
                type="button"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '0px',
                  height: '100%',
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                onClick={handleGetAccount}
              >
                Verify
              </button>
            </div>
            {accountData?.length > 1 ? (
              <div className="inputbox-with-one-input mt-3">
                <select
                  name="filter"
                  className=""
                  value={isCorporate}
                  onChange={(e) => {
                    //  setIsRegistered(e.target.value);
                    setIsCorporate(e.target.value);
                    setData({ ...data, receiverName: '' });
                  }}
                >
                  <option value="Select" key="select" selected>
                    Account Type
                  </option>
                  <option value={false}>PERSONAL</option>
                  <option value>CORPORATE</option>
                </select>
                <span className="input-detail">Account Type</span>
              </div>
            ) : (
              ''
            )}
            <div className="inputbox-with-one-input mt-3">
              <input
                placeholder="Full name"
                type="text"
                value={data?.receiverName}
                disabled
              />
              <span className="input-detail">Name of recipent</span>
            </div>
          </div>
        )}

        <div className="inputbox-with-one-input">
          {/* <input
            type="number"
            placeholder="0.00"
            min={0}
            onChange={(e) => {
              setData({ ...data, amount: e.target.value });
            }}
            value={moneyFomatter(data.amount ?? 0)}
          /> */}
          <CurrencyInput
            id="input-amount"
            name="input-amount"
            placeholder="Amount"
            defaultValue={0.0}
            decimalsLimit={2}
            onValueChange={(value, name, values) =>
              setData({ ...data, amount: values?.value })
            }
          />
          <span className="input-detail">How much do you want to Send?</span>
        </div>

        {requestType === 'nonWayaUser' && (
          <div className="inputbox-with-one-input">
            <input
              placeholder="Full name"
              type="text"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
            <span className="input-detail">Name of recipent</span>
          </div>
        )}

        <div className="inputbox-with-one-input">
          <input
            placeholder="Add a Note"
            type="text"
            value={data.reason}
            onChange={(e) => {
              setData({ ...data, reason: e.target.value });
            }}
          />
          <span className="input-detail">Add descriptive notes</span>
        </div>

        <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            defaultValue={data.senderAccountNumber}
            onChange={(e) => {
              const [acccountNo, amount] = e.target.value.split(',');
              setData({
                ...data,
                balance: amount,
                senderAccountNumber: acccountNo,
              });
              setBalance(amount);
            }}
          >
            <option value="Select" selected disabled>
              Select
            </option>
            {walletList.length
              ? walletList
                  .filter((el) => !el.accountNo.startsWith('90'))
                  .map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
              : ''}
          </select>
          <span className="input-detail">
            Your Balance is N{balance ? moneyFomatter(balance) : 0}.00
          </span>
        </div>
      </form>
    </ModalBody>
  );
}
