import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import validator from 'validator';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import PinModal from '../PinModal';
//  import { WalletContext } from '../../../../store/context/WalletContext';
import { ModalContext } from '../../../../store/context/ModalContext';

export default function Settle(props) {
  const { hideModal } = props;
  const [otp, setOtp] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const { setModalAction, setShowResendOtp } = useContext(ModalContext);
  const [showToken, setShowToken] = useState(false);
  const [token, setToken] = useState('');

  const handleSettlePaymentRequest = async () => {
    showLoader();

    if (validator.isEmpty(referenceCode)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }
    const userId = localStorage.getItem('userId');
    const res = await httpPost(
      `/settle-payment-request-waya-users/${referenceCode}/${userId}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        //  hideModal(false);
        setShowToken(true);
        //  setReload(!reload);
      });
    } else {
      hideLoader();
      setOtp('');
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const handleConfirm = async () => {
    showLoader();
    const userId = localStorage.getItem('userId');
    if (validator.isEmpty(referenceCode)) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }

    const res = await httpPost(
      `/completePaymentWithOTP/${referenceCode}/${token}/${userId}`,
      {},
      customBaseUrl.paymentRequestUrl,
      otp
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        hideModal(false);
        //  setShowToken(true);
        //  setReload(!reload);
      });
      setOtp('');
    } else {
      hideLoader();
      setOtp('');
      swal('Oops!', res.message || 'Something went wrong', 'error');
      hideModal(false);
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    if (res.status) {
      setLoading(false);
      setShowPin(false);
      handleSettlePaymentRequest();
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <>
      <ModalBody>
        <div className="text-center mt-3 mb-3" style={{ fontSize: '12px' }}>
          Please enter the 10 digit payment code sent to you
        </div>
        <form className="mx-auto">
          {!showToken ? (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Enter the 10 digit PIN"
                type="number"
                value={referenceCode}
                onChange={(e) => setReferenceCode(e.target.value)}
              />
              <span className="input-detail">10 digit code sent to you</span>
            </div>
          ) : (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Enter the OTP sent to you"
                type="number"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <span className="input-detail">otp code sent to you</span>
            </div>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-3"
          color="primary"
          onClick={() => {
            setShowPin(true);
          }}
        >
          Settle Request
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleVerifyPin}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {showToken ? (
        <PinModal
          center
          showModal={showToken}
          hideModal={setShowToken}
          otp={token}
          setOtp={setToken}
          separator=""
          title="Otp Confirmation"
          description="Please input the Otp sent to your email address or phone number"
          isResendOtp={false}
          isResendPin={false}
          buttonLabel="Submit"
          handleSubmit={handleConfirm}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
    </>
  );
}

Settle.propTypes = {
  hideModal: PropTypes.bool.isRequired,
};
