import React from 'react';
// import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './eventModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

const EventTime = (props) => {
  const { handleFormInput, values } = props;

  return (
    <div className="event-inner-content">
      <div className="event-time-container mt-5 w-100">
        <div className="event-period">
          <div>
            <label>Event starts</label>
          </div>
          <div className="">
            <DatePicker
              className="w-100 border-0 "
              name="eventStart"
              minDate={new Date()}
              showTimeSelect
              selected={values.eventStart}
              onChange={(date) => {
                handleFormInput(date.getTime(), 'eventStart');
              }}
              dateFormat="yyyy/MM/dd"
            />
          </div>
        </div>
        <div className="event-period">
          <div>
            <label>Start Time</label>
          </div>
          <div>
            <DatePicker
              className="w-100 border-0"
              name="eventStart"
              minDate={new Date()}
              showTimeSelect
              selected={values.eventStart}
              dateFormat="h:mm aa"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="event-time-container mt-5 w-100">
        <div className="event-period">
          <div>
            <label>Event ends</label>
          </div>
          <div>
            <DatePicker
              className="border-0"
              name="eventEnd"
              minDate={values.eventStart}
              showTimeSelect
              selected={values.eventEnd}
              onChange={(date) => {
                handleFormInput(date.getTime(), 'eventEnd');
              }}
              dateFormat="yyyy/MM/dd"
            />
          </div>
        </div>
        <div className="event-period">
          <div>
            <label>End Time</label>
          </div>
          <div>
            <DatePicker
              className="w-100"
              name="eventEnd"
              minDate={values.eventStart}
              showTimeSelect
              selected={values.eventEnd}
              dateFormat="h:mm aa"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EventTime.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventTime;
