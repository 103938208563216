import React, { useState, useContext } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import PersonalInfo from './Personalnfo';
import PinInput from './PinIput';
import TransactionStatusModal from './TransactionStatus';
import FinishButton from '../DonationModal/StepButton';
import { headers, donationButtonTitle } from './data';
import { customBaseUrl, httpGet, httpPost } from '../../../../../action/http';
import { WalletContext } from '../../../../../store/context/WalletContext';
import TermsAndCondition from '../../Terms/TermsAndCondition';

const MakeDonationModal = (props) => {
  const { defaultWallet } = useContext(WalletContext);
  const [trxStatusModal, setTrxStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showModal, hideModal, userData, donationId, data } = props;
  const [otp, setOtp] = useState('');
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 2,
    userName: userData.username,
    amount: '',
    description: '',
    donationId,
    profile_id: userData.id,
  });

  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  const handleDonation = async () => {
    setLoading(true);
    const res = await httpPost(
      `/wayagram-service/all-posts/donate?profile_id=${userData.id}`,
      customBaseUrl.wayagramUrl
    );
    if (res?.status) {
      setLoading(false);
    }
    setLoading(false);
    swal('Oops!', res?.message, 'error');
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'PIN is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      handleDonation();
      setLoading(false);
      setOtp('');
      hideModal(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const submitDonation = () => {
    handleVerifyPin();
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader className="text-left" toggle={() => hideModal(false)}>
          <div className="interest-header normal-header">
            <h3 className="interest-p1">{headers[values.step].header}</h3>
            <div className="description-header mt-3">
              <p className="description-text">
                {headers[values.step].description}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <PageSwitch
            step={values.step}
            totalSteps={values.totalSteps}
            handleSteps={handleSteps}
            stepBackward={stepBackward}
            submitDonation={submitDonation}
            handleFormInput={handleFormInput}
            userData={userData}
            values={values}
            data={data}
            setOtp={setOtp}
            otp={otp}
            loading={loading}
            defaultWallet={defaultWallet}
          />
        </ModalBody>
      </Modal>
      {trxStatusModal ? (
        <TransactionStatusModal
          showModal={trxStatusModal}
          hideModal={setTrxStatusModal}
        />
      ) : (
        ''
      )}
    </>
  );
};

function PageSwitch(props) {
  const {
    step,
    // totalSteps,
    handleSteps,
    submitDonation,
    handleFormInput,
    userData,
    values,
    data,
    setOtp,
    otp,
    loading,
    defaultWallet,
    // stepBackward,
  } = props;
  const [showTermsModal, setShowTermsModal] = useState(false);

  const onAction = [handleSteps, submitDonation];

  return (
    <div className="donation-container">
      <div className="info-container">
        {step === 0 && (
          <PersonalInfo
            handleFormInput={handleFormInput}
            userData={userData}
            values={values}
          />
        )}
        {step === 1 && (
          <PinInput
            handleFormInput={handleFormInput}
            userData={userData}
            values={values}
            data={data}
            setOtp={setOtp}
            otp={otp}
            separator=" "
            defaultWallet={defaultWallet}
          />
        )}
        <div className="termsnconditionText my-3">
          By sending, you agree with our{' '}
          <u onClick={() => setShowTermsModal(true)}>terms and conditions</u> of
          donation creation
        </div>
      </div>
      <FinishButton
        className="mb-3 mt-2"
        title={donationButtonTitle[step]}
        onClick={onAction[step]}
        loading={loading}
      />
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default MakeDonationModal;
