import React from 'react';
import './donationProgress.scss';

import userAvatar from '../../../../../../assets/image/avata22.png';

const DonationProgress = () => {
  // const { current, total } = props;
  // const currentPercent = Math.round((parseInt(current) / parseInt(total)) * 100);
  // const totalPercent = 100;
  return (
    <div className="dontaion-progress-container mt-2 w-100">
      <div className="dontaion-progress-box w-100">
        <div className="current-progress" />
      </div>
      <div className="donation-detail-group">
        <div>
          <img src={userAvatar} alt="users" />
        </div>
        <div>Uche and 1 person donated</div>
      </div>
    </div>
  );
};

export default DonationProgress;
