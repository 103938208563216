import React, { useState, useEffect } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import CurrencyInput from 'react-currency-input-field';
import moneyFomatter from '../../../../utils/moneyFormatter';

export default function Main(props) {
  const { data, setData, setMode, walletList, beneficiaryList, getCharges } =
    props;
  const [balance, setBalance] = useState(null);
  const [account, setAccount] = useState();

  useEffect(() => {
    getCharges(account, data.amount);
  }, [data, account]);
  return (
    <>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={data.walletAccount}
              onChange={(e) => {
                const [accountNumber, name, bankCode, bankName] =
                  e.target.value.split(',');
                setData({
                  ...data,
                  crAccount: accountNumber,
                  crAccountName: name,
                  saveBen: true,
                  bankCode,
                  bankName,
                });
              }}
            >
              <option value="Select" selected disabled>
                Select Beneficiaries
              </option>
              {beneficiaryList?.length
                ? beneficiaryList.map((item) => (
                    <option
                      value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
                      key={item.accountNo}
                    >
                      {`${item.accountName}-${item.bankName}`}
                    </option>
                  ))
                : ''}
            </select>
            <span className="input-detail">
              Select Beneficiaries you want to withdraw to
            </span>
          </div>

          <div className="inputbox-with-one-input">
            <input placeholder="Bank Name" type="text" value={data.bankName} />
            <span className="input-detail">Type account Name</span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Account Number"
              type="text"
              value={data.crAccount}
            />
            <span className="input-detail">Account Number of Recipent</span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Account Name"
              type="text"
              value={data.crAccountName}
            />
            <span className="input-detail">Account Name of Recipent</span>
          </div>

          <div className="inputbox-with-one-input">
            <CurrencyInput
              id="input-amount"
              name="input-amount"
              placeholder="Amount"
              defaultValue={0.0}
              decimalsLimit={2}
              onValueChange={(value, name, values) =>
                setData({ ...data, amount: values?.value })
              }
            />
            <span className="input-detail">How much do you want to Send?</span>
          </div>

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={data.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount, acctName] = e.target.value.split(',');
                setAccount(accountNo);
                setData({
                  ...data,
                  walletAccountNo: accountNo,
                  debitAccountName: acctName,
                  balance: amount,
                });
                setBalance(amount);
              }}
            >
              <option value="Select" selected disabled>
                Select Account
              </option>
              {walletList?.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt},${item?.acct_name}`}
                          key={item.accountNo}
                        >
                          {`${item?.description}
                            - ${item?.accountNo}
                            (${item.acct_crncy_code} ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div className="inputbox-with-one-input">
            <input
              placeholder="Add a Note"
              type="text"
              value={data.narration}
              onChange={(e) => {
                setData({ ...data, narration: e.target.value });
              }}
            />
            <span className="input-detail">Narration*</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          onClick={() => {
            if (!data.amount || data.amount === '') {
              swal('Oops!', 'Amount input field can not be empty', 'error');
              return;
            }
            if (!data.bankCode || data.bankCode === '') {
              swal('Oops!', 'Bank field can not be empty', 'error');
              return;
            }
            if (!data.crAccount || data.crAccount === '') {
              swal('Oops!', 'Account details not correct', 'error');
              return;
            }
            if (!data.crAccountName || data.crAccountName === '') {
              swal('Oops!', 'account name can not be empty', 'error');
              return;
            }
            if (!data.narration || data.narration === '') {
              swal('Oops!', 'narration field can not be empty', 'error');
              return;
            }
            if (!data.walletAccountNo || data.walletAccountNo === '') {
              swal('Oops!', 'wallet field can not be empty', 'error');
              return;
            }
            setMode('withdrawalDetails');
          }}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

// <form className="mx-auto mt-3">
//   <div className="inputbox-with-one-input">
//     {/* <input placeholder="Card to Debit" type="text" /> */}
//     <select
//       name="filter"
//       className=""
//       value={data.walletAccount}
//       onChange={(e) => {
//         const [accountNumber, accountName, bankCode, bankName] =
//           e.target.value.split(',');
//         setData({
//           ...data,
//           crAccount: accountNumber,
//           crAccountName: accountName,
//           bankCode,
//           bankName,
//         });
//       }}
//     >
//       <option value="Select" selected disabled>
//         Select Bank Account
//       </option>
//       {beneficiaryList.length
//         ? beneficiaryList.map((item) => (
//             <option
//               value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
//               key={item.accountNo}
//             >
//               {`${item.accountName}-${item.bankName}`}
//             </option>
//           ))
//         : ''}
//     </select>
//     <span className="input-detail">Select saved bank</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input placeholder="Bank Name" type="text" value={data.bankName} />
//     <span className="input-detail">Name of the Bank Used</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input placeholder="Account Number" type="text" value={data.crAccount} />
//     <span className="input-detail">Account Number of Recipent</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Account Name"
//       type="text"
//       value={data.crAccountName}
//     />
//     <span className="input-detail">Account Name of Recipent</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Amount"
//       type="text"
//       value={data.amount}
//       onChange={(e) => setData({ ...data, amount: e.target.value })}
//     />
//     <span className="input-detail">How much do you want to Send?</span>
//   </div>

//   <div className="inputbox-with-one-input">
//     {/* <input placeholder="Card to Debit" type="text" /> */}
//     <select
//       name="filter"
//       className=""
//       value={data.walletAccountId}
//       onChange={(e) => {
//         const [accountNo, amount] = e.target.value.split(',');
//         setData({
//           ...data,
//           walletAccountNo: accountNo,
//         });
//         setBalance(amount);
//       }}
//     >
//       <option value="Select" selected disabled>
//         Select Wallet
//       </option>
//       {walletList.length
//         ? walletList.map(
//             (item) =>
//               item.savingsProductName !== 'COMMISSION' && (
//                 <option
//                   value={`${item.accountNo},${item?.clr_bal_amt}`}
//                   key={item.accountNo}
//                 >
//                   {`${item?.acct_crncy_code}-${item?.accountNo}`}
//                 </option>
//               )
//           )
//         : ''}
//     </select>
//     <span className="input-detail">Your Balance is N{balance || 0}.00</span>
//   </div>
//   <div className="inputbox-with-one-input">
//     <input
//       placeholder="Add a Note"
//       type="text"
//       value={data.narration}
//       onChange={(e) => {
//         setData({ ...data, narration: e.target.value });
//       }}
//     />
//     <span className="input-detail">Optional?</span>
//   </div>
// </form>;
