import React from 'react';
import PropTypes from 'prop-types';

function DonationTarget(props) {
  const { handleFormInput, values } = props;

  return (
    <div>
      <div className="input-container">
        <input
          name="estimatedAmount"
          value={values.estimatedAmount}
          onChange={(e) => {
            handleFormInput(e.target.value, 'estimatedAmount');
          }}
          placeholder="Target"
        />
        <span>How much are you looking to raise</span>
      </div>

      <div className="d-flex justify-content-between border-bottom p-2 mt-5 mb-3">
        <div
          className="d-flex align-items-center"
          style={{
            width: '48%',
          }}
        >
          <input
            type="radio"
            name=""
            id=""
            style={{
              width: '25px',
              height: '25px',
            }}
            className="mx-1 radio radio-light"
            checked={values?.displayTotalDonation === 'true'}
            onChange={() => {
              handleFormInput('true', 'displayTotalDonation');
            }}
          />
          <div
            style={{
              fontSize: '14px',
            }}
          >
            Hide Contribution
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            width: '48%',
          }}
        >
          <input
            style={{
              width: '25px',
              height: '25px',
            }}
            type="radio"
            name=""
            id=""
            className="mx-1"
            checked={values?.displayTotalDonation === 'false'}
            onChange={() => {
              handleFormInput('false', 'displayTotalDonation');
            }}
          />
          <div
            style={{
              fontSize: '14px',
            }}
          >
            Diplay Contribution
          </div>
        </div>
      </div>
      <div
        className=" text-black-50 text-start mb-3 "
        style={{
          fontSize: '10px',
        }}
      >
        Chose either to display or hide donation to user
      </div>
    </div>
  );
}

DonationTarget.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default DonationTarget;
