import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { BiArrowBack } from 'react-icons/bi';
import Skeleton from 'react-loading-skeleton';
import { ViewMoreContainer, ViewMoreTop } from './style';
import { Row, Col } from '../../../shared/containers/containers';
import moneyFomatter from '../../../../utils/moneyFormatter';
// import { dateFormater } from '../../../../utils/formatDate';

const ViewMore = ({ data, setShow }) => {
  console.log('data', data);
  const dataShow = [
    { label: 'Status', val: 'status' },
    { label: 'Type', val: 'status' },
    { label: 'Category', val: 'status' },
    { label: 'Dispute Amount', val: 'disputeAmount' },
    { label: 'Transaction Charges', val: 'transactionChargeAmount' },
    { label: 'Transaction Date', val: 'transactionDate' },
    { label: 'Dispute Date', val: 'dateOfInitiation' },
  ];
  console.log(dataShow);
  return (
    <ViewMoreContainer>
      <ViewMoreTop>
        <Row justify="flex-start">
          <Row
            align="center"
            onclick={() => setShow(false)}
            style={{ cursor: 'pointer' }}
          >
            <BiArrowBack size={24} />
            <Typography variant="p" className="ml-2" size={18}>
              back
            </Typography>
          </Row>
          <Typography style={{ marginLeft: 100 }} variant="h6">
            Dispute Ticket I.D #{data.trackingNumber}
          </Typography>
        </Row>
      </ViewMoreTop>
      <Row align="flex-start" width="100%" style={{ border: '1px solid red' }}>
        <Col
          width="100%"
          align="flex-start"
          style={{ border: '1px solid blue' }}
        >
          <Typography
            style={{ marginBottom: 20, fontSize: 18 }}
            color="#FF6700"
            variant="h6"
          >
            Dispute details
          </Typography>
          <Card style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Transaction Date
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {new Date(data.transactionDate).toLocaleDateString()}
                </Typography>
              </div>
            </Row>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Transaction Amount
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {moneyFomatter(data.transactionAmount)}
                </Typography>
              </div>
            </Row>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Narration od Dispute
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {data.narrationOfDispute}
                </Typography>
              </div>
            </Row>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Transaction ID
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {data.transactionId}
                </Typography>
              </div>
            </Row>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Status
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {data.status}
                </Typography>
              </div>
            </Row>
            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Transaction Number
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {data.trackingNumber}
                </Typography>
              </div>
            </Row>

            <Row
              align="center"
              justify="space-between"
              width="100%"
              style={{ height: 50, borderBottom: '1px solid #E4E4E4' }}
            >
              <div>
                <Typography variant="p" className="ml-2" size={18}>
                  Dispute Descriptions
                </Typography>
              </div>
              <div>
                <Typography variant="p" style={{ fontWeight: '600' }} size={18}>
                  {data.disputeDescription}
                </Typography>
              </div>
            </Row>
          </Card>
        </Col>
        <Col
          width="60%"
          align="flex-start"
          style={{ border: '1px solid blue' }}
        >
          <Typography
            style={{ marginBottom: 20, fontSize: 18 }}
            color="#FF6700"
            variant="h6"
          >
            Attached Files
          </Typography>
          <Card style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
            <Col align="center" justify="space-between" width="100%">
              <div>
                {data.file ? (
                  <img src={data.file} alt="" height={250} width={350} />
                ) : (
                  <Skeleton height={250} width={350} />
                )}
                {/* <Skeleton height={250} width={350} /> */}
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
    </ViewMoreContainer>
  );
};

export default ViewMore;
