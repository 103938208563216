import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { Input, notification } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import cameraImg from '../../../assets/image/camera-alt-01.svg';
import eventIcon from '../../../assets/images/eventIcon.png';
import validateImage from '../../../utils/validateImage';
import Button from '../../shared/Button';
import NinGuide from './uploadBvnguide';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpPost } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';

const UploadModal = (props) => {
  const [imageFile, setImageFile] = useState({});
  const {
    showModal,
    hideModal,
    handleUploadDoc,
    name,
    setPostData,
    postData,
    loading,
    previewImage,
    setPreviewImage,
    setIdType,
    idType,
  } = props;
  const [disable, setDisable] = useState(true);
  const [showNinGide, setShowNinguide] = useState(false);
  const [showGuide, setShowguide] = useState(false);
  const [copied, setCopied] = useState(false);
  const { profile } = useContext(ProfileContext);
  const handlePreview = (e) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setDisable(false);
      setImageFile(imageData);
      setPostData({ ...postData, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };
  const validateId = async (idNumber) => {
    if (idNumber && idNumber.length < 3) return;
    showLoader();
    let res;
    if (idType === 'interPass') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/INTERNATIONAL_PASSPORT?lastName=${profile.surname}&regNo=${idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else if (idType === 'nationalId') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/NIN?regNo=${idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else if (idType === 'driversLicence') {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/driverLicence?dob=${profile?.dateOfBirth}&firstName=${profile.firstName}&lastName=${profile.surname}&regNo=${idNumber}`,
        {},
        customBaseUrl.kycUrl
      );
    } else {
      res = await httpPost(
        `/no-auth/verifyCacRegNo2/VOTERS_CARD?dob=${profile?.dateOfBirth}&firstName=${profile.firstName}&lastName=${profile.surname}&lga=${profile?.city}&regNo=${idNumber}&state=${profile?.state}`,
        {},
        customBaseUrl.kycUrl
      );
    }
    if (res?.status) {
      hideLoader();
      notification.success({
        message: 'successful',
        description: `${res?.message}`,
      });
      Swal.fire({
        title: 'Done!',
        text: res?.message,
        type: 'success',
        timer: 3000,
      });
      setPostData({
        ...postData,
        identificationNumber: idNumber,
      });
    } else {
      // hideModal(false);
      Swal.fire({
        title: 'Oops!',
        text: res?.message,
        type: 'error',
        timer: 3000,
      });
      hideLoader();
      notification.error({
        message: 'error',
        description: `${res?.message}`,
      });
    }
  };
  useEffect(() => {
    setPostData({ ...postData, identificationNumber: '' });
    setImageFile('');
    setPreviewImage('');
    setIdType('');
  }, []);
  return (
    <Modal isOpen={showModal} id="" toggle={() => hideModal(false)} centered>
      <ModalHeader className="text-left">Upload</ModalHeader>
      <ModalBody>
        <div className="event-icon-container">
          <img src={previewImage || eventIcon} alt="event" />
          <img src={cameraImg} className="upload-banner-input" alt="camera" />
          <input
            type="file"
            className="upload-banner-input2"
            onChange={(e) => handlePreview(e)}
          />
        </div>
        <div className="italic my-2 h6 text-center">{postData?.fileName}</div>
        {name === 'ID' && (
          <div className="my-3">
            <select
              className="border border-2 border-radius-2"
              onChange={(e) => {
                setIdType(e.target.value);
                if (e.target.value === 'nationalId') {
                  setShowNinguide(true);
                  setShowguide(true);
                } else {
                  setShowguide(false);
                }
              }}
            >
              <option value="" disabled selected>
                Select Identification Type
              </option>
              <option value="interPass">International Passport</option>
              <option value="nationalId">National Identification Card</option>
              <option value="votersCard">Voters Card</option>
              <option value="driversLicence">Drivers Licence</option>
            </select>
            {idType ? (
              <div style={{ position: 'relative', marginTop: 3 }}>
                <input
                  placeholder="ID Number"
                  type="text"
                  //  disabled={!isNew}
                  className="form-control"
                  required
                  value={postData?.identificationNumber}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      identificationNumber: e.target.value,
                    })
                  }
                />
                <button
                  hidden
                  type="button"
                  disabled={!postData?.identificationNumber}
                  onClick={() => validateId(postData?.identificationNumber)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: '0px',
                    height: '100%',
                    border: 'none',
                  }}
                >
                  validate
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {name === 'tin' && (
          <div>
            <Input
              placeholder="TIN"
              value={postData?.identificationNumber}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  identificationNumber: e.target.value,
                })
              }
            />
          </div>
        )}
        {name === 'cac' && (
          <div>
            <Input
              placeholder="CAC"
              value={postData?.identificationNumber}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  identificationNumber: e.target.value,
                })
              }
            />
          </div>
        )}
        {showGuide && (
          <div>
            <p>
              Steps to generate a 16-digit virtual national identification
              number (VNIN) using the NIMC app:
            </p>
            <p>1. Download the NIMC App </p>
            <p>2. Follow the steps to generate VNIN on the NIMC app</p>
            <p>
              3. Select ‘Input Enterprise Short-Code’ and enter 951641
              <CopyToClipboard
                text={951641}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                <div className="d-flex">
                  <img src={CopyOutlined} alt="" className="me-2" />
                  <div>{copied === false ? '' : 'Copied'}</div>
                </div>
              </CopyToClipboard>
            </p>
            <p>
              4. Copy your generated virtual NIN and paste it in the input field
              above.
            </p>
          </div>
        )}
        {!disable && (
          <div className="w-100 my-5 text-center">
            <Button
              style={{}}
              type="button"
              loading={loading}
              onClick={() => {
                handleUploadDoc(name, imageFile);
              }}
              content="Upload"
              className="bg-primary w-50 p-2 rounded-1 text-white border-0"
            />
          </div>
        )}
      </ModalBody>
      {showNinGide && (
        <NinGuide showModal={showNinGide} hideModal={setShowNinguide} />
      )}
    </Modal>
  );
};

export default UploadModal;
