import React, { useContext, useState } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ProgressBar from '../DonationModal/ProgressBar';
import EventInfo from './EventInfo';
// import EventLocation from './EventLocation';
import SearchEventLocation from './SearchEventLocation';
import EventTime from './EventTime';
import EventTicket from './EventTicket';
import PageCount from '../DonationModal/Pagecount';
import PublishEventModal from './PublishEventModal';
import FinishButton from '../DonationModal/StepButton';

import { headers, eventButtonTitle } from './data';
import '../DonationModal/index.scss';
import { customBaseUrl, httpPost } from '../../../../../action/http';
import { hideLoader, showLoader } from '../../../../../utils/loader';
import { WayagramContext } from '../../../../../store/context/WayagramContext';

const CreateEventModal = (props) => {
  const { wayagramProfile: profile } = useContext(WayagramContext);
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 5,
    eventName: '',
    organizersName: '',
    description: '',
    isPrivate: true,
    isPaid: true,
    eventPoster: null,
    eventStart: new Date(),
    eventEnd: new Date(),
    location: '',
    amount: '',
    virtual: true,
    selectedType: 'Virtual',
  });
  const { showModal, hideModal, setShowPublishModal } = props;

  const handleSteps = () => {
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  const stepBackward = () => {
    if (values.step > 0) {
      setValues((prev) => ({
        ...prev,
        step: values.step - 1,
      }));
    }
  };

  const handleFormInput = (input, name) => {
    setValues({ ...values, [name]: input });
  };

  const handleSubmit = async () => {
    showLoader();

    if (values.eventName === '') {
      swal('error', 'Event Name cannot be empty');
      hideLoader();
      setShowPublishModal(false);
      return;
    }

    if (values.description === '') {
      swal('error', 'description cannot be empty');
      hideLoader();
      setShowPublishModal(false);
      return;
    }

    if (values.eventStart === values.eventEnd) {
      swal('error', 'Event start time and end time cant be thesame');
      hideLoader();
      setShowPublishModal(false);
      return;
    }

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    values.eventPoster && newForm.append('eventPoster', values.eventPoster);
    newForm.append('profile_id', profile.id);
    newForm.append('eventName', values.eventName);
    newForm.append('details', values.description);
    newForm.append('isPrivate', values.isPrivate || false);
    newForm.append('isPaid', values.isPaid || false);
    // newForm.append('organizersName', values.organizersName);
    newForm.append('location', values.location);
    newForm.append('eventStart', values.eventStart);
    newForm.append('eventEnd', values.eventEnd);
    newForm.append('amount', values.amount || 0);
    newForm.append('virtual', values.virtual || false);

    // values.urlLink && newForm.append('urlLink', values.urlLink);

    const res = await httpPost(
      `/all-posts/create-event`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
        setShowPublishModal(false);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
      setShowPublishModal(false);
    }
  };

  const submitEvent = () => {
    console.log(values);
    setShowPublishModal(true);
    handleSubmit();
    hideModal(false);
  };
  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
        size="lg"
      >
        <ModalHeader className="text-left" toggle={() => hideModal(false)}>
          <div className="interest-header normal-header">
            <h3 className="interest-p1">{headers[values.step].header}</h3>
            <div className="description-header mt-3">
              <p className="description-text">
                {headers[values.step].description}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <PageSwitch
            step={values.step}
            totalSteps={values.totalSteps}
            handleSteps={handleSteps}
            stepBackward={stepBackward}
            submitEvent={submitEvent}
            handleFormInput={handleFormInput}
            values={values}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

function PageSwitch(props) {
  const {
    step,
    totalSteps,
    handleSteps,
    submitEvent,
    handleFormInput,
    stepBackward,
    values,
  } = props;

  const onAction = [
    handleSteps,
    handleSteps,
    handleSteps,
    handleSteps,
    submitEvent,
  ];

  return (
    <div className="donation-container">
      {step !== 4 ? (
        <ProgressBar totalSteps={totalSteps} step={step} values={values} />
      ) : (
        ''
      )}
      <div className="info-container">
        {step === 0 && (
          <EventInfo handleFormInput={handleFormInput} values={values} />
        )}
        {step === 1 && (
          <SearchEventLocation
            handleFormInput={handleFormInput}
            values={values}
          />
        )}
        {/* {step === 2 && <EventLocation handleFormInput={handleFormInput} />} */}
        {step === 2 && (
          <EventTime handleFormInput={handleFormInput} values={values} />
        )}
        {step === 3 && (
          <EventTicket handleFormInput={handleFormInput} values={values} />
        )}
        {step === 4 && (
          <PublishEventModal
            handleFormInput={handleFormInput}
            values={values}
          />
        )}
        {step < 5 ? (
          <PageCount
            currentPage={step + 1}
            totalPage={totalSteps}
            stepBackward={stepBackward}
          />
        ) : (
          ''
        )}
      </div>
      <FinishButton title={eventButtonTitle[step]} onClick={onAction[step]} />
    </div>
  );
}

export default CreateEventModal;
