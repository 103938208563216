// import { searchUsers } from '../../../store/calls';

import { customBaseUrl } from '../../../action/http';

// const reArrangeData = (data) => {
//   const mappedData = data.map((item) => {
//     return {
//       id: item.id,
//       display: item.firstName,
//     };
//   });
//   return mappedData;
// };

export const getMentions = async (name) => {
  const Mentionmatcher = name.match(/\B@[a-z0-9_-]+/gi);
  const foundName = Mentionmatcher[Mentionmatcher.length - 1];
  const nextName = foundName.match('@\\s*(\\w+)');
  return nextName;
};

export function HashSearch(query, callback) {
  if (!query) return;
  fetch(
    `${customBaseUrl.wayagramUrl}/all-posts/search-hashtags?query=${query}`,
    {
      json: true,
      headers: {
        Authorization: `${localStorage.token}`,
        'content-type': 'application/json',
      },
    }
  )
    .then((res) => res.json())

    // Transform the users to what react-mentions expects
    .then((res) =>
      res.data.map((item) => ({ display: item.hashtag, id: 'hash' }))
    )
    .then(callback);
}

function Search(query, callback) {
  if (!query) return;
  fetch(
    `${customBaseUrl.wayagramUrl}/main/profile/search-usernames?query=${query}&page=1`,
    {
      json: true,
      headers: {
        Authorization: `${localStorage.token}`,
        'content-type': 'application/json',
      },
    }
  )
    .then((res) => res.json())

    // Transform the users to what react-mentions expects
    .then((res) =>
      res.search.map((user) => ({ display: user.username, id: 'mention' }))
    )
    .then(callback);
}

export default Search;
