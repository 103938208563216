import React from 'react';
import GramCard from '../../shared/Cards/Gram';
import ListItem from '../../shared/Cards/ListItem';

export default function DiscoverInner(props) {
  const {
    active,
    setActive,
    list,
    btnLoading,
    setSelectedId,
    selectedId,
    handleSubmit,
    handleSearch,
  } = props;
  return (
    <div style={{ height: '50vh' }}>
      {active === 'People' && (
        <div
          className={
            list?.length
              ? 'ps-md-3 pe-md-3'
              : 'row h-100 d-flex justify-content-center align-items-center'
          }
          // style={active === 'Posts' ? { display: 'none' } : {}}
        >
          {list?.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={active === 'People' ? item.avatar : item?.imageUrl}
                name={active === 'People' ? `${item?.displayName}` : item?.name}
                description={
                  active === 'People' ? item?.username : item?.description
                }
                active={active}
                id={active === 'People' ? item?.id : null}
                connected={
                  active === 'People' ? item?.connection?.connected : false
                }
                handleSubmit={handleSubmit}
                loading={btnLoading}
                setSelected={setSelectedId}
                selected={selectedId}
              />
            ))
          ) : (
            <div className="text-center">{`No ${active} found`}</div>
          )}
        </div>
      )}

      {active === 'Pages' && (
        <div
          className={
            list?.length
              ? 'ps-md-3 pe-md-3'
              : 'row h-100 d-flex justify-content-center align-items-center'
          }
          // style={active === 'Posts' ? { display: 'none' } : {}}
        >
          {list?.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={item?.imageUrl}
                name={item?.title}
                description={item?.description}
                active={active}
                id={item?.id}
                connected={item?.isFollowing}
                handleSubmit={handleSubmit}
                loading={btnLoading}
                setSelected={setSelectedId}
                selected={selectedId}
              />
            ))
          ) : (
            <div className="text-center">{`No ${active} found`}</div>
          )}
        </div>
      )}

      {active === 'Groups' && (
        <div
          className={
            list?.length
              ? 'ps-md-3 pe-md-3'
              : 'row h-100 d-flex justify-content-center align-items-center'
          }
          // style={active === 'Posts' ? { display: 'none' } : {}}
        >
          {list?.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={item?.imageUrl}
                name={item?.name}
                description={item?.description}
                active={active}
                id={item?.id}
                connected={item?.isUserAMember}
                handleSubmit={handleSubmit}
                loading={btnLoading}
                setSelected={setSelectedId}
                selected={selectedId}
              />
            ))
          ) : (
            <div className="text-center">{`No ${active} found`}</div>
          )}
        </div>
      )}

      {active === 'Trending' && (
        <div
          className={
            list?.length
              ? 'p-md-3'
              : 'row h-100 d-flex justify-content-center align-items-center'
          }
        >
          {list?.length ? (
            list.map((item) => (
              <div
                key={Math.random()}
                onClick={() => {
                  setActive('Posts');
                  handleSearch(item.hashtag.substring(1), 'Posts');
                }}
              >
                <h4 className="mb-0">{item.hashtag}</h4>
                <p className="text-muted">
                  {item?.PostToHashtags?.length} posts
                </p>
              </div>
            ))
          ) : (
            <div className="text-center">{`No ${active} found`}</div>
          )}
        </div>
      )}

      {active === 'Posts' && (
        <div
          className="ps-3 pe-3 h-100 d-flex justify-content-center align-items-center"
          // style={active !== 'Posts' ? { display: 'none' } : {}}
        >
          {list?.length ? (
            list.map((post) => (
              <GramCard
                key={Math.random()}
                data={post}
                handleDeletePost={() => console.log('delete')}
                showPostModal={() => console.log('showPost')}
                handleLikePost={() => console.log('like post')}
                addBookmark={() => console.log('add bookmark')}
                blockUser={() => console.log('block user')}
                handleFollowUser={() => console.log('follow user')}
                repostPost={() => console.log('repost post')}
                handleMuteUser={() => console.log('mute user')}
                fromLocation="discover"
              />
            ))
          ) : (
            <div className="text-center">{`No ${active} found`}</div>
          )}
        </div>
      )}
    </div>
  );
}
