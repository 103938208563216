import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import { Modal } from 'reactstrap';
import ToggleInput from '../ToggleInput';
import 'react-responsive-modal/styles.css';
import { customBaseUrl, httpPost } from '../../../action/http';
import Button from '../Button';
import { hideLoader, showLoader } from '../../../utils/loader';
import VerifyOtp from './Otp/VerifyOtp';

const InAppLogin = (props) => {
  const { showModal, hideModal, email, handleSubmit } = props;
  const [data, setData] = useState({
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [otp, setOtp] = useState('');

  const handleLogin = async () => {
    setLoading(true);
    if (validator.isEmpty(data.password)) {
      setLoading(false);
      swal('Oops!', 'Password cannot be empty', 'error');
      return;
    }
    const res = await httpPost(
      '/api/v1/auth/login',
      { emailOrPhoneNumber: email, ...data, otp, admin: false },
      customBaseUrl.authUrl
    );
    if (res.status) {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('userId', res.data.user.id);
      setLoading(false);
      hideModal(false);
      handleSubmit();
    } else {
      setLoading(false);
      hideLoader(false);
      swal('Wrong!', res.message, 'error');
    }
  };
  const getOtp = async () => {
    showLoader();
    const res = await httpPost(
      `/api/v1/auth/generate-login-otp/${email}`,
      {},
      customBaseUrl.authUrl
    );
    if (res.status) {
      setShowVerifyOtp(true);
      hideLoader();
    } else {
      hideLoader();
      setLoading(false);
      if (res?.message === ' 2FA not active for this user') {
        handleLogin();
      } else {
        swal('Error', res.message, 'error');
      }
    }
  };
  return (
    <div id="login-modal">
      <Modal
        isOpen={showModal}
        id="blockedUser-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div id="" className="modal-body-rs col-sm-12 col-md-8">
          <div className="header-sec-modal">
            <input
              type="image"
              onClick={() => hideModal(false)}
              className="header-img1"
              src="./assets/image/back.png"
              alt=""
              style={{ opacity: 0 }}
            />
            <img
              className="header-sec-logo"
              src="./assets/image/appLogo.png"
              alt=""
            />
            <input
              type="image"
              onClick={() => hideModal(false)}
              className="header-img1"
              src="./assets/image/x.png"
              alt=""
              style={{ opacity: 0 }}
            />
          </div>

          <div className="waya-modal-body mt-3 mb-5">
            <h1 className="modal-header-data">Enter password to continue</h1>

            <form>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Email or Phone"
                  type="text"
                  disabled
                  value={email && `*****${email.slice(4, email.length)}`}
                />
              </div>

              <div className="inputbox-with-one-input toggle-pass-wrapper">
                <ToggleInput
                  placeholder="Password"
                  type="password"
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                />
              </div>
            </form>
            <div className="submit-modal-btn-wrap">
              <Button
                type="button"
                loading={loading}
                onClick={getOtp}
                content="Confirm"
              />
            </div>
          </div>
        </div>
      </Modal>
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          // isResendOtp
          buttonLabel="Verify"
          handleSubmit={handleLogin}
          loading={loading}
          numInputs={6}
          // resendOtp={setShowResendOtp}
        />
      ) : (
        ''
      )}
    </div>
  );
};

InAppLogin.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default InAppLogin;
