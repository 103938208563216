import React, { useState } from 'react';
import swal from 'sweetalert';
import { Modal, ModalHeader } from 'reactstrap';
import Button from '../../Button';
import addImg from '../../../../assets/image/camera-alt-01.svg';
import validateImage from '../../../../utils/validateImage';
import './style.css';
import { customBaseUrl, httpPost } from '../../../../action/http';

const Index = (props) => {
  const { showModal, hideModal, title, reported, type, reporter } = props;
  const [previeweventPoster, setPrevieweventPoster] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState({});
  const handlePreview = (e) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setData({ ...data, file: imageData });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPrevieweventPoster(reader.result);
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  console.log(reported);

  const handleSubmit = async () => {
    setBtnLoading(true);
    const newForm = new FormData();
    newForm.append('file', data?.file);
    newForm.append('subject', data?.subject);
    newForm.append('type', type);
    newForm.append('item_id', reported?.id);
    newForm.append('profile_id', reporter?.id);
    newForm.append('dispute', data?.dispute);
    newForm.append('reporterName', `${reporter?.displayName} `);
    newForm.append('phone', reporter?.phoneNumber);
    const res = await httpPost(
      `/dispute/make-dispute`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideModal(false);
        setBtnLoading(false);
      });
    } else {
      swal('Oops!', res.message, 'error');
      setBtnLoading(false);
    }
  };
  return (
    <div>
      <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
        <ModalHeader
          className="justify-content-center"
          style={{
            background: '#F1F7FA',
            fontFamily: 'Lucida Bright',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '28px',
            color: '#064A72',
            padding: '30px',
            borderRadius: '14px 14px 0 0 !important',
          }}
        >
          {title}
        </ModalHeader>
        <div className="p-4">
          <div className="mt-3">
            I want to report{' '}
            <i>
              <b>
                {reported?.name || reported?.displayName || reported?.title}
              </b>
            </i>
          </div>
          <div className="d-flex flex-column w-100 my-4">
            <div className="mb-2">what is the Reason for your report</div>
            <div className="w-100 p-2 border rounded">
              <select
                name=""
                id=""
                className="w-100 border-0"
                onChange={(e) => setData({ ...data, subject: e.target.value })}
              >
                <option value="" selected>
                  Select reason
                </option>
                <option value="Inappropriate contents">
                  Inappropriate contents
                </option>
                <option value="Promoting hateful contents">
                  Promoting hateful contents
                </option>
                <option value="Abusive">Abusive</option>
                <option value="Fraud">Fraud</option>
              </select>
            </div>
          </div>
          <div className="border p-2 rounded mb-4">
            <textarea
              name=""
              id=""
              placeholder="more details   *optional?"
              onChange={(e) => setData({ ...data, dispute: e.target.value })}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center border   mb-5"
            style={{
              background: 'grey',
              position: 'relative',
              backgroundImage: `url(${previeweventPoster})`,
              backgroundPosition: 'center',
              height: '200px',
            }}
          >
            <input
              type="file"
              src=""
              alt=""
              style={{
                background: 'blue',
                position: 'absolute',
                width: '30px',
                height: '30px',
                opacity: '0',
              }}
              onChange={(e) => {
                handlePreview(e);
              }}
            />
            <img
              src={addImg}
              alt=""
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </div>
          <Button
            content="Send"
            style={{
              width: '100%',
              marginBottom: '25px',
            }}
            onClick={handleSubmit}
            loading={btnLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Index;

export const ReportEvent = (props) => {
  const { showModal, hideModal, title, reported, reporter } = props;
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState({});

  const handleSubmit = async () => {
    setBtnLoading(true);
    const postData = {
      event_id: reported?.id,
      ...data,
    };
    const res = await httpPost(
      `/all-posts/report-an-event?organizer_id=${reported.ProfileId}&reporter_id=${reporter.id}`,
      postData,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideModal(false);
        setBtnLoading(false);
      });
    } else {
      swal('Oops!', res.message, 'error');
      setBtnLoading(false);
    }
  };
  return (
    <div>
      <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
        <ModalHeader
          className="justify-content-center"
          style={{
            background: '#F1F7FA',
            fontFamily: 'Lucida Bright',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '28px',
            color: '#064A72',
            padding: '30px',
            borderRadius: '14px 14px 0 0 !important',
          }}
        >
          {title}
        </ModalHeader>
        <div className="p-4">
          <div className="mt-3 ">
            I want to report the organizers
            <i>
              <b> {reported?.details}</b>
            </i>
          </div>
          <div className="d-flex flex-column w-100 my-4">
            <div className="mb-2">what is the Reason for your report</div>
            <div className="border p-2 rounded mb-4">
              <textarea
                name=""
                id=""
                placeholder="more details* required"
                onChange={(e) => setData({ ...data, dispute: e.target.value })}
              />
            </div>
          </div>

          <Button
            content="Send"
            style={{
              width: '100%',
              marginBottom: '25px',
            }}
            onClick={handleSubmit}
            loading={btnLoading}
          />
        </div>
      </Modal>
    </div>
  );
};
