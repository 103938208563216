import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { httpPost, httpGet, customBaseUrl } from '../../../../action/http';
import Button from '../../Button';
import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
//  import { ProfileContext } from '../../../../store/context/ProfileContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';

const Airtime = (props) => {
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [network, setNetwork] = useState('');
  const [pin, setPin] = useState('');
  // const [postData, setPostData] = useState({});
  const [phone, setPhone] = useState('');
  const [categId] = useState([]);
  //  const [billerAirtime, setBillerAirtime] = useState([]);
  const [bilAirtime, setBilAirtime] = useState([]);
  // const [inputFields, setInputFields] = useState([]);
  const [airtimeAmount, setAirtimeAmont] = useState();
  // const [amountAirtime, setAmountAirtime] = useState();
  // const [setAirtimeName] = useState();
  const [balance, setBalance] = useState();
  const [accountAirtime, setAccountAirtime] = useState('');
  //  const [airtimProd, setAirtimeProd] = useState('');
  const [charges, setCharges] = useState(0);
  const [activeAggregator, setAggregator] = useState('');
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { airtime } = useContext(BillerContext);
  const { setReload, reload, walletList } = useContext(WalletContext);
  const [showAmount, setShowAmount] = useState(false);
  const [airtimeBundle, setAirBundle] = useState([]);
  //  const { profile } = useContext(ProfileContext);

  const getAmount = (dataId) => {
    if (dataId) {
      const findAmount = bilAirtime.find(
        (e) => Number(e.id) === Number(dataId)
      );
      // console.log('findAmount', findAmount);
      setAirtimeAmont(findAmount.amount);
      if (findAmount.amount === 0) {
        setShowAmount(true);
      } else {
        setShowAmount(false);
      }
      //  setAirtimeName(findAmount.name);
    }
  };
  /*  const getbillerCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBillerByCategory?categoryId=${airtimeId}`
    );
    if (res?.status) {
      console.log('billerCategory', res);
      setBillerAirtime(res.data);
    }
    // console.log('billerNamebbb', billerAirtime.billerName);
  };  */
  // console.log('network', network);
  const getProduct = async (net, aggr) => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchProductByBiller?serviceProviderBillerId=${net}`
    );
    if (res?.status) {
      const filterDate = res?.data?.items?.filter((x) => x.subItems.length > 0);
      // if (filterDate?.length) {
      //   console.log('filterDate', filterDate);
      //   // setBilAirtime(filterDate[0].subItems);
      //   setBilAirtime(aggr === 'QuickTeller' ? res?.data : filterDate[0].subItems);
      // }
      setBilAirtime(
        aggr === 'QuickTeller' ? res?.data : filterDate[0].subItems
      );
    }
  };
  // const getAgregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   const aggregator = res?.data;
  //   const quick =
  //     aggregator?.filter((e) => e.aggregator === 'QUICKTELLER') || {};
  //   const baxi = aggregator?.filter((e) => e.aggregator === 'BAXI') || {};
  //   const itex = aggregator?.filter((e) => e.aggregator === 'ITEX') || {};
  //   if (quick[0]?.active) {
  //     setAggregator('QUICKTELLER');
  //   } else if (baxi[0]?.active) {
  //     setAggregator('BAXI');
  //   } else if (itex[0]?.active) {
  //     setAggregator('ITEX');
  //   }
  // };

  const getCharges = async () => {
    const res = await httpGet(
      `${customBaseUrl.temporalservice}/api/v1/wallet/transaction/get-user-transaction-fee/${walletdata?.walletAccountNo}/${airtimeAmount}/${activeAggregator}`
    );
    console.log('res', res);
    if (res?.status !== 404) {
      setCharges(res);
    }
    console.log('Charges', charges);
  };
  // console.log('network', network);

  useEffect(() => {
    if (activeAggregator && walletdata?.walletAccountNo && airtimeAmount) {
      getCharges();
    }
  }, [walletdata?.walletAccountNo, airtimeAmount]);
  // useEffect(() => {
  //   getAgregator();
  // }, []);
  /*  useEffect(() => {
    getbillerCategory();
  }, [airtimeId]); */
  const handleSubmit = async () => {
    setLoading(true);
    // console.log('accountAirtime', accountAirtime);
    if (validator.isEmpty(phone)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }
    /* const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }
    if (phone.length < 5) {
      swal('Oops', 'Invalid Phone Number', 'error');
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */

    // const walletId = walletList.filter((item) => item.defaultWallet && item);
    showLoader();
    const data = {
      amount: +airtimeAmount,
      phone: phone?.replace('+', ''),
      plan: network?.prepaidName,
      type: airtimeBundle?.type ?? network?.type,
    };
    // const ph = data.data.filter((el) => el.name === 'phone')[0];
    // console.log('data', ph);
    const res = await httpPost(
      `/api/v1/airtime/pay?serviceProviderBillerId=${network?.id}&serviceProviderId=${network?.serviceProviderId}&userAccountNumber=${accountAirtime}`,
      data,
      customBaseUrl.billPaymentUrl2,
      pin
    );
    if (res.status) {
      // console.log(res);
      setLoading(false);
      hideModal(false);
      hideLoader();
      setPin('');
      swal('Done', res.data?.transactionMessage, 'success');
      setReload(!reload);
    } else {
      setLoading(false);
      hideLoader();
      setPin('');
      swal('Oops', res.message, 'error');
    }
    hideLoader();
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Airtime Purchase
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              //  value={network}
              onChange={(e) => {
                // getFieldParams(e.target.value);
                const {
                  hasProduct,
                  id: pid,
                  serviceProviderCategory: {
                    serviceProvider: { name },
                  },
                } = JSON.parse(e.target.value);
                setAggregator(name);
                if (hasProduct) {
                  getProduct(pid, name);
                }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="" selected data-default hidden>
                Network Provider
              </option>
              {airtime.length
                ? airtime.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>

          {network?.hasProduct ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={airtimProd}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  const value = JSON.parse(e.target.value);
                  getAmount(value.id);
                  //  setAirtimeProd(value.id);
                  setAirBundle(value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {bilAirtime
                  ? bilAirtime.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}

          {!network?.hasProduct || showAmount ? (
            <div className="inputbox-with-one-input">
              <input
                type="number"
                placeholder="Enter Amount"
                min={0}
                value={airtimeAmount}
                onChange={(e) => {
                  setAirtimeAmont(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}
          {network && categId === 'airtime' ? (
            <div className="inputbox-with-one-input">
              <input
                type="number"
                min={0}
                placeholder="Enter Amount"
                value={airtimeAmount}
                onChange={(e) => {
                  setAirtimeAmont(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {network ? (
            <div className="inputbox-with-one-input">
              {/* <input
                type="text"
                placeholder="Enter Phone Number with Country Code (e.g +234)"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              /> */}
              <input
                placeholder="Phone Number"
                maxLength={11}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {/* {inputFields.length
            ? inputFields.map((item) => (
                <div className="inputbox-with-one-input" key={item.paramName}>
                  {item.subItems.length ? (
                    <select
                      value={postData.paramName}
                      onChange={(e) => {
                        setPostData({
                          ...postData,
                          [item.paramName]: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected data-default hidden>
                        {item.paramName}
                      </option>
                      {item.subItems.length
                        ? item.subItems.map((items) => (
                            <option value={items.name} key={items.name}>
                              {items.name}
                            </option>
                          ))
                        : ''}
                    </select>
                  ) : (
                    <input
                      placeholder={item.paramName}
                      type="number"
                      onChange={(e) => {
                        setPostData({
                          ...postData,
                          [item.paramName]: e.target.value,
                        });
                      }}
                    />
                  )}
                </div>
              ))
            : ''} */}

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountAirtime(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {/* {`${item?.acct_crncy_code}-${item?.accountNo}`} */}
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div>
            <span> Transaction Fees N{charges || 0}.00 </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Airtime Purchase"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

Airtime.defaultProps = {
  id: '',
};

Airtime.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Airtime;
