import {
  CHANGE_FIELD,
  REQUEST_PENDING,
  LOADER_OFF,
  LOADER_ON,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  LOGIN_ATTEMPT,
  SUCCESS_MSG_ON,
  SUCCESS_MSG_OFF,
  ERROR_MSG_OFF,
  ERROR_MSG_ON,
  LOGOUT_USER,
  SET_CURRENT_WALLET,
} from './constants';

const initialState = {
  searchField: 'wert',
};

export const updateField = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_FIELD:
      return {
        ...state,
        searchField: action.payload,
      };
    default:
      return state;
  }
};

const initialWallet = {
  wallet: {},
};

export const updateCurrentWallet = (state = initialWallet, action) => {
  switch (action.type) {
    case SET_CURRENT_WALLET:
      return {
        ...state,
        wallet: action.payload,
      };
    default:
      return state;
  }
};

const initialStateUsers = {
  users: [],
  isPending: true,
  error: '',
};

export const requestUsers = (state = initialStateUsers, action = {}) => {
  switch (action.type) {
    case REQUEST_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isPending: false,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialLoginState = {
  login: false,
};

export const LoginUsers = (state = initialLoginState, action = {}) => {
  switch (action.type) {
    case LOGIN_ATTEMPT:
      return {
        ...state,
        login: true,
      };
    case LOGOUT_USER:
      return {
        ...state,
        login: false,
      };
    default:
      return state;
  }
};

const initialLoaderState = {
  loader: false,
};

export const LoaderSwitch = (state = initialLoaderState, action = {}) => {
  switch (action.type) {
    case LOADER_ON:
      return {
        ...state,
        loader: true,
      };
    case LOADER_OFF:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

const initialSuccessState = {
  successMsg: false,
  errorMsg: false,
  text: 'Oboy it is working',
};

export const successErrorSwitch = (
  state = initialSuccessState,
  action = {}
) => {
  if (action.type === SUCCESS_MSG_ON) {
    return {
      ...state,
      successMsg: true,
      text: action.payload,
    };
  }
  if (action.type === ERROR_MSG_ON) {
    return {
      ...state,
      errorMsg: true,
      text: action.payload,
    };
  }
  if (action.type === SUCCESS_MSG_OFF) {
    return {
      ...state,
      successMsg: false,
    };
  }
  if (action.type === ERROR_MSG_OFF) {
    return {
      ...state,
      errorMsg: false,
    };
  }
  return state;
};
