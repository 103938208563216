import React, { createContext, useState, useEffect } from 'react';
import swal from 'sweetalert';
import {
  customBaseUrl,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from '../../action/http';

// export const userId = localStorage.getItem('userId');

export const NotificationContext = createContext();

export const NotificationContextProvider = (props) => {
  const { children } = props;
  const [reload, setReload] = useState(false);
  const [allNotification, setAllNotification] = useState(null);

  // console.log('this is Bname', Bname);
  const getAllNotification = async (userId) => {
    const res = await httpGet(
      `${customBaseUrl.notificationUrl}/in-app-notifications/${Number(userId)}`
    );
    if (res?.status) {
      setAllNotification([...res?.data?.notifications].reverse());
      // console.log('setAllNotification', res?.data.notifications);
    }
  };

  //   // console.log('this is Bname', Bname);
  const deleteNotification = async (notificationId) => {
    const res = await httpDelete(
      `/delete-notifications/${notificationId}`,
      {},
      customBaseUrl.notificationUrl
    );
    if (res?.status) {
      swal('Done', 'Deleted Successfully', 'success').then(() => {
        const userId = localStorage.getItem('userId');
        getAllNotification(userId);
      });
    }
  };
  const filterByDateNotification = async (userId, start, end) => {
    const res = await httpPost(
      `/filter-by-date/${userId}`,
      {
        endDate: end,
        startDate: start,
      },
      customBaseUrl.notificationUrl
    );
    if (res?.status) {
      setAllNotification([...res?.data?.notifications].reverse());
      // console.log('setAllNotification', res?.data.notifications);
    }
  };
  const ReadNotification = async (notificationId, read) => {
    const userId = localStorage.getItem('userId');
    const res = await httpPut(
      `/in-app-notifications/${userId}/${notificationId}`,
      {
        read,
      },
      customBaseUrl.notificationUrl
    );

    if (res?.status) {
      getAllNotification(userId);
      // console.log('setAllNotification', res);
    }
  };
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getAllNotification(userId);
  }, [reload]);

  return (
    <NotificationContext.Provider
      value={{
        reload,
        setReload,
        allNotification,
        deleteNotification,
        filterByDateNotification,
        ReadNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
