import styled from 'styled-components';
import device from '../../../utils/device';

export const SettingWrapper = styled.div`
  display: flex;
  height: 100%;

  .setting-menu {
    width: 27.1%;
    border-right: #e5e5e5 1px solid;
    @media ${device.maxtablet} {
      width: 100%;
    }
  }

  .setting-menu-detail {
    width: 59%;
    @media ${device.maxtablet} {
      display: '';
      width: 100%;
    }
  }

  .menu-items .row {
    background: #ffffff;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 15px !important;
    cursor: pointer;
    min-height: 3.62em;
    .small-text {
      font-size: 0.75em;
    }
    h5 {
      font-size: 1em;
      font-family: Lato;
    }
  }
  .menu-items .item-active {
    background: #ff4b01;
    color: #ffffff;
  }
  .hover:hover {
    background-color: #ff6700;
    color: #fff0eb;
  }
  .setting-inner-item {
    width: 100%;
    margin: auto;
    /* .menu-items .row {
      min-height: 80px !important;
    } */
    @media ${device.maxmobileL} {
      width: 100%;
    }
  }
`;

export const SettingInnerItem = styled.div`
  .setting-menu {
    width: 50%;
  }
`;

export const ReferalWrapper = styled.div`
  .nav {
    width: 100%;
    .nav-back {
      display: flex;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;

      color: #333333;
      img {
        margin-right: 8px;
      }
    }
  }
  .content {
    margin-top: 69px;
    display: flex;
    justify-content: space-between;

    .part-1 {
      width: 35%;
      text-align: left;
      padding: 9px 10px;
      .head {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 22px;
      }
      .body {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        color: #333333;
      }
    }
    .part-2 {
      width: 40%;
      display: flex;
      flex-direction: column;
      .p-2-top {
        border: 1px solid #e0e0e0;
        padding: 9px 10px;
        box-sizing: border-box;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        .top {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 9px;
          color: #333333;
          margin-bottom: 17px;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
        }
      }
      .p-2-bottom {
        background: #f2f2f2;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 9px 10px;
        .p-2-1 {
          display: flex;
          flex-direction: column;
          text-align: left;
          .top {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 9px;
            line-height: 11px;
            color: #333333;
            margin-bottom: 22px;
          }
          .bottom {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .cont-button {
    width: 100%;
    text-align: center;
    margin-top: 170px;
    button {
      margin-left: auto;
      margin-right: auto;
      background: #ff6634;
      border: 1px solid #ff6634;
      border-radius: 4px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      width: 70%;
      padding: 4px 30px;
    }
  }
`;
