import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import StepButton from '../../shared/Modals/Post/DonationModal/StepButton';
import CustomDropdown from '../../shared/Dropdown';
import placeHolderImage from '../../../assets/image/avatar.png';
import './setting.scss';

const MutedUser = (props) => {
  const { showModal, hideModal, data, handleMute } = props;

  const handleSubmit = () => {
    hideModal();
  };

  const handleOption = (username) => {
    handleMute(username);
  };

  return (
    <Modal
      isOpen={showModal}
      id="blockedUser-modal"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalHeader className="" toggle={() => hideModal(false)}>
        <div className="custome-header">
          <div>Muted account ({data ? data.length : 0})</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          {data.length ? (
            data.map((item) => (
              <div className="blocked-account-container" key={item.id}>
                <div className="account-group">
                  <img src={item.avatar || placeHolderImage} alt="alt" />
                  <div className="mx-3">{item.username}</div>
                </div>
                <div className="account-group resize-icon">
                  <div className="dot-3x" style={{ position: 'relative' }}>
                    <CustomDropdown
                      id={12}
                      direction="horizontal"
                      handleOption={() => handleOption(item.username)}
                      options={[{ name: 'UnMute', type: 'unmute', id: '3001' }]}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4 className="text-center fs-6 fs mt-3">No muted account found</h4>
          )}
        </div>
        <StepButton title="Confirm" onClick={handleSubmit} />
      </ModalBody>
    </Modal>
  );
};

export default MutedUser;
