import React from 'react';
import { useHistory } from 'react-router';
import { ListWrapper } from './style';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import Button from '../Button';
import truncateWithEllipses from '../../../utils/truncate';

export default function ListItem(props) {
  const {
    image,
    name,
    description,
    active,
    id,
    connected,
    handleSubmit,
    fromLocation,
    following,
    loading,
    setSelected,
    selected,
    isAdmin,
    handleTransfer,
    item,
  } = props;
  const history = useHistory();
  let type;
  if (active === 'People') {
    type = connected ? 'Following' : 'Follow';
  } else if (active === 'Groups') {
    type =
      connected && isAdmin ? 'Edit' : connected && !isAdmin ? 'Member' : 'Join';
  } else if (active === 'Pages') {
    type = isAdmin ? 'Edit' : connected && !isAdmin ? 'Unfollow' : 'Follow';
  }

  console.log(type);
  return (
    <ListWrapper>
      <div className="inner">
        <div
          className={
            fromLocation === 'contacts-payments'
              ? 'profile-div profile-c-div'
              : 'profile-div'
          }
        >
          {fromLocation !== 'contacts-payments' && (
            <div className="image-div">
              <img src={image || imgPlaceholder} alt="default" />
            </div>
          )}
          <div className="text-div">
            <span
              className="add-cursor"
              onClick={() => {
                if (active === 'People') {
                  history.push(`/wayagram-profile/${id}`);
                }
                if (active === 'Groups') {
                  history.push(`/wayagram-group/${id}`);
                }
                if (active === 'Pages') {
                  history.push(`/wayagram-page/${id}`);
                }
              }}
            >
              {fromLocation === 'contacts-payments' &&
              fromLocation === 'contacts-socials'
                ? name
                : truncateWithEllipses(name, 10) || ''}
            </span>
            <span className="s-text d-block">{description || ''}</span>
          </div>
        </div>
        {fromLocation === 'contacts-payments' ||
        fromLocation === 'contacts-socials' ? (
          <div
            className="action-btn my-auto d-flex"
            style={connected ? { width: '220px' } : {}}
          >
            <Button
              className={!connected ? 'd-none' : 'btn btn-success btn-sm'}
              loading={loading}
              onClick={() =>
                history.push({
                  pathname: `/chat`,
                  from: 'wayagramProfile',
                  data: item.profile,
                })
              }
              content="Message"
            />
            <Button
              className={
                fromLocation === 'contacts-socials'
                  ? 'd-none'
                  : 'btn btn-danger ms-2 btn-sm'
              }
              loading={loading}
              onClick={() => {
                if (!connected) {
                  handleSubmit(true);
                } else {
                  handleTransfer();
                }
              }}
              content={connected ? 'Send Money' : 'Invite'}
            />
            <Button
              className={
                fromLocation === 'contacts-payments'
                  ? 'd-none'
                  : 'btn btn-danger ms-2 btn-sm'
              }
              disabled={following || false}
              loading={loading}
              onClick={() => {
                if (!connected) {
                  handleSubmit(true);
                } else {
                  handleTransfer();
                }
              }}
              content={
                connected ? (following ? 'Following' : 'Follow') : 'Invite'
              }
            />
          </div>
        ) : fromLocation === 'manageGroup' ? (
          <div className="action-btn my-auto">
            <Button
              className="btn btn-outline-primary"
              loading={loading && selected && selected === id}
              onClick={() => {
                setSelected(id);
                handleSubmit(type, description, id, item);
              }}
              content={
                connected && isAdmin
                  ? 'Edit'
                  : connected && !isAdmin
                  ? 'Member'
                  : 'Join'
              }
            />
          </div>
        ) : (
          <div className="action-btn my-auto">
            <Button
              className={
                connected ? 'btn btn-outline-primary' : 'btn btn-primary'
              }
              loading={loading && selected && selected === id}
              onClick={() => {
                setSelected(id);
                handleSubmit(type, description, id, item);
              }}
              content={type}
            />
          </div>
        )}
      </div>
    </ListWrapper>
  );
}
