import React from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import { trxInfo } from './data';

import './makeDonation.scss';

import successIcon from '../../../../../assets/images/trxSuccess.png';
import failureIcon from '../../../../../assets/images/trxFailure.png';

function TransactionStatusModal(props) {
  const { showModal, hideModal } = props;
  console.log({ showModal, hideModal }, 'HERE');

  return (
    <Modal
      isOpen={showModal}
      id="donation-status"
      toggle={() => hideModal(false)}
      centered
    >
      <ModalBody>
        <img
          src={trxInfo[1].status ? successIcon : failureIcon}
          alt="success"
        />
        <p>{trxInfo[1].message}</p>
        <div>
          <p>{trxInfo[1].description}</p>
        </div>
        <div className="modal-foot">
          {trxInfo[1].status ? (
            <p>Go back to Home</p>
          ) : (
            <div className="modal-foot-group">
              <div className="box">Fund wallet</div>
              <div className="box active-option">Try again</div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

// TransactionStatusModal.propTypes = {
//   handleFormInput: PropTypes.func.isRequired,
// };

export default TransactionStatusModal;
