import React from 'react';
// import axios from 'axios';
// import { Card, Button, Row, Col } from 'antd';
// import Layout from '../../shared/Layout';
// import { httpPostFormData } from '../../../action/http';
// import Api from '../../../assets/image/Api.png';
// import Contact from '../../../assets/image/Contact.png';
// import whatsapp from '../../../assets/image/whatsapp.png';

const TicketPages = () => {
  //   const handleContact = () => {
  //     window.location = 'https://staging.wayabank.ng/contact';
  //   };
  return (
    <div style={{ height: 500, overflowY: 'auto' }}>
      <div className="d-flex justify-content-center">
        <h2>Complaint Ticket</h2>
      </div>
      <div className="App" style={{ width: 1000, textAlign: 'center' }}>
        {/* <h3>Complaint Ticket Form</h3> */}
        <iframe
          src="https://forms.zohopublic.com/wayabank/form/ComplaintTicketingForm/formperma/ut_-h9uwYeIcgRv0goP-ZanRBHFZwWP9_udhzLdUUKI?zf_rszfm=1"
          title="Compl"
          width="100%"
          height={1000}
          style={{
            maxWidth: 640,
            width: '100%',
            overflow: 'auto',
          }}
        />
      </div>
      <div style={{ height: 200 }} />
    </div>
  );
};

export default TicketPages;
