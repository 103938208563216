import React from 'react';
// import { Button } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';
// import { NavbarMenu } from './style';
// import { handleSetWalletDefault } from '../../../store/calls';
// import { WalletContext } from '../../../store/context/WalletContext';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
import ProfileMenu, { Actions } from './profileMenu';

const WayagramNavbar = () => {
  return (
    <div className="topbar">
      <div className="navbar-mid">
        <h6 className="nav-title">Discover</h6>
      </div>
      <div className="">
        <Actions />
      </div>
      <div className="">
        <ProfileMenu imgPlaceholder={imgPlaceholder} />
      </div>
    </div>
  );
};

export default WayagramNavbar;
