import Edit from '@material-ui/icons/Edit';
import React, { useContext, useState } from 'react';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import '../../../assets/stylesheets/index.scss';
import { WalletContext } from '../../../store/context/WalletContext';
import NotificationEmailTable from './accountEmail';
import UpdateEmail from './updateAccountEmail';

export default function AdvanceNotification(props) {
  const { showModal, hideModal } = props;
  const myRef = React.useRef(null);
  const { walletList } = useContext(WalletContext);
  const [showEmail, setShowEmail] = useState(false);
  const [account, setAccount] = useState('');
  const [email, setEmail] = useState('');

  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="manage-bank-modal"
        toggle={() => hideModal(false)}
        centered
        size="lg"
        style={{ maxWidth: '700px', width: '100%' }}
      >
        <div className="header text-center">
          Advance Email Alert Notification Setting
        </div>
        <ModalBody>
          <NotificationEmailTable
            // hideModal={hideModal}
            columns={[
              { title: 'Account Number', field: 'accountNo' },
              { title: 'Description', field: 'description' },
              { title: 'Balance', field: 'clr_bal_amt' },
              { title: 'Email', field: 'notify_email' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              walletList.length > 0 &&
              walletList.map((el) => {
                return {
                  accountNo: el.accountNo,
                  description: el.description,
                  clr_bal_amt: el.clr_bal_amt,
                  amount: el.tranAmount,
                  notify_email: el.notify_email,
                  action: (
                    <div className="d-flex align-content-center">
                      <Edit
                        onClick={() => {
                          setAccount(el.accountNo);
                          setEmail(el.notify_email);
                          setShowEmail(true);
                        }}
                      />
                    </div>
                  ),
                };
              })
            }
          />
        </ModalBody>
        <ModalFooter />
      </Modal>
      {showEmail ? (
        <UpdateEmail
          showModal={showEmail}
          hideModal={setShowEmail}
          account={account}
          email={email}
        />
      ) : (
        ''
      )}
    </div>
  );
  //    return <div>{walletList.length}</div>;
}
