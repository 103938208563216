import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPut,
} from '../../../action/http';

export const getLoginHistory = async () => {
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/history/my-history`
  );
  if (res && res.status) {
    return res.data;
  }
  return [];
};

export const getAllMutedAccounts = async (authId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/get-all-muted-accounts?user_id=${authId}`
  );
  if (res && res.status) {
    return res.data;
  }
  return [];
};

export const getAllBlockedAccounts = async (authId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/graph/friend/get-all-restricted-accounts?user_id=${authId}`
  );
  if (res && res.status) {
    return res.data;
  }
  return [];
};

export const handleToggleMute = async (authId, username) => {
  const postData = {
    user_id: authId,
    username,
  };
  const res = await httpPut(
    `/graph/friend/mute-or-unmute-friend`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};

export const unblockAccount = async (authId, username) => {
  const postData = {
    user_id: authId,
    username,
  };
  const res = await httpPost(
    `/graph/friend/unblock`,
    postData,
    customBaseUrl.wayagramUrl
  );
  return res;
};
