import React from 'react';

import {
  ChatMessageFrom,
  ChatMessageTo,
  TimeStamp,
  TimeStampContainer,
} from './styles';

import chatSeen from '../../../assets/images/chatSeen.png';
import chatUnseen from '../../../assets/images/chatUnseen.png';

const ChatMessage = (props) => {
  const { data, loggedInUser } = props;
  const timeStamp = '14:22';
  const markRead = true;
  return (
    <>
      {data.senderId !== loggedInUser.id ? (
        <>
          <ChatMessageFrom>
            <div className="content">
              {data.messageType === 'fileMessage' ? (
                <img src={data.fileUrl} alt="file" />
              ) : (
                data.message
              )}
            </div>
          </ChatMessageFrom>
          <TimeStamp>{timeStamp}</TimeStamp>
        </>
      ) : (
        <>
          <ChatMessageTo>
            <div className="content">
              {data.messageType === 'fileMessage' ? (
                <img src={data.fileUrl} alt="file" />
              ) : (
                data.message
              )}
            </div>
          </ChatMessageTo>
          <TimeStampContainer>
            <TimeStamp>{timeStamp}</TimeStamp>
            <img src={markRead ? chatSeen : chatUnseen} alt="status" />
          </TimeStampContainer>
        </>
      )}
    </>
  );
};

export default ChatMessage;
