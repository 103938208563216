import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import RightSidebar from './RightSidebar';
import './index.scss';
import { getAllPages, getMyPages, followOrUnfollowPage } from './container';
import Loader from '../../shared/Loader';
import Container from '../../shared/Container';
import ListItem from '../../shared/Cards/ListItem';
import { WayagramContext } from '../../../store/context/WayagramContext';
import WayagramPageProfile from '../../shared/Modals/Profile/WayagramPageProfile';

export default function ManagePages(props) {
  const {
    wayagramProfile: { id },
  } = useContext(WayagramContext);
  const [list, setList] = useState([]);
  const [activeTab, setActiveTab] = useState('myPages');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [showEditProfile, setShowEditProfile] = useState(false);

  const handleGetAllPages = async () => {
    const groupsList = await getAllPages(id);
    setList(groupsList);
    setLoading(false);
    setBtnLoading(false);
  };

  const handleGetMyPages = async () => {
    const myGroupList = await getMyPages(id);
    setList(myGroupList);
    setLoading(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    if (activeTab === 'myPages' && id) {
      setLoading(true);
      handleGetMyPages();
    } else if (activeTab === 'allPages' && id) {
      setLoading(true);
      handleGetAllPages();
    }
  }, [activeTab, id]);

  const handleSubmit = async (e, ...rest) => {
    if (e === 'Follow') {
      setBtnLoading(true);
      const res = await followOrUnfollowPage(id, rest[1]);
      if (res?.status) {
        handleGetAllPages();
      } else {
        setBtnLoading(false);
        swal('error', res.message, 'error');
      }
    }
    if (e === 'Unfollow') {
      setBtnLoading(true);
      const res = await followOrUnfollowPage(id, rest[1]);
      if (res?.status) {
        if (activeTab === 'myPages') {
          handleGetMyPages();
        } else {
          handleGetAllPages();
        }
      } else {
        swal('error', res.message, 'error');
        setBtnLoading(false);
      }
    }
    if (e === 'Edit') {
      setProfileData(rest[2]);
      setShowEditProfile(true);
    }
  };

  return (
    <Layout route={props} currentPage="wayagram">
      <div className="row wayagram-div">
        <div className="col-8">
          <div className="col-12">
            <div className="row">
              <Container
                className={
                  activeTab === 'myPages'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => setActiveTab('myPages')}
              >
                My Pages
              </Container>
              <Container
                className={
                  activeTab === 'allPages'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => setActiveTab('allPages')}
              >
                All Pages
              </Container>
            </div>
          </div>

          {loading && <Loader />}

          {list && list.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={item.imageUrl}
                name={`${item?.title}`}
                description={item?.description}
                active="Pages"
                id={item?.id}
                connected={item?.isFollowing}
                handleSubmit={handleSubmit}
                fromLocation="managePage"
                isAdmin={item?.userId === id}
                selected={selected}
                setSelected={setSelected}
                loading={btnLoading}
                item={item}
              />
            ))
          ) : (
            <div className="row h-100 d-grid justify-content-center align-items-center">
              No page found
            </div>
          )}
        </div>

        <RightSidebar />
      </div>
      {showEditProfile ? (
        <WayagramPageProfile
          showModal={showEditProfile}
          hideModal={setShowEditProfile}
          pageData={profileData}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
