import React from 'react';
import PropTypes from 'prop-types';

import { disputeLog } from './static';

function DisputeLog(props) {
  const { handleStep } = props;

  return (
    <div>
      <div className="complaint-session-top">
        <div>File a Complaint</div>
        <div className="report-button" onClick={handleStep}>
          File Report
        </div>
      </div>
      <div>
        {disputeLog &&
          disputeLog.map((item) => (
            <div className="complaint-card-group" key={item.id}>
              <div className="complaint-card-right">
                <div>
                  <img src={item.avatar} alt="complait" />
                </div>
                <div>
                  <div className="ticket-number">
                    {`#`}
                    {item.ticketNumber}
                  </div>
                  <div className="ticket-time">{item.timestamp}</div>
                </div>
              </div>
              <div className="complaint-card-left">
                <div className="text">{item.amount}</div>
                <div
                  className={
                    item.status === 'Resolved'
                      ? 'text resolved'
                      : 'text unresolved'
                  }
                >
                  {item.status}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

DisputeLog.propTypes = {
  handleStep: PropTypes.func.isRequired,
};

export default DisputeLog;
