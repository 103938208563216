import React, { useEffect, useState } from 'react';
import './test.scss';

import { customBaseUrl, httpGet } from '../../../action/http';
// import { ListWrapper } from '../../shared/Cards/style';
import OtherBillers from '../../shared/Modals/Billers/Others';
import { CustomDropdown2 } from '../../shared/Cards/CustomDropdown';
// import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';

export default function Index() {
  const [, setLoading] = useState(false);
  const [showOtherBillers, setShowOtherBillers] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [query, setQuery] = useState('');

  // http://157.230.223.54:8181/billspay/api/v1/category
  const getAllCategories = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category`
    );
    if (res.status) {
      setLoading(false);
      setCategories(res.data);
    } else {
      setLoading(false);
    }
  };

  const handleSelectOption = (item) => {
    // setData({ ...data, ...selectedUser });
    // setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);
    // setOptions([]);
    setCategories([]);
    setCategoryId(item.categoryId);
  };
  useEffect(() => {
    // getAllCategories();
  }, []);
  const handleSearch = () => {
    getAllCategories();
  };
  return (
    <div style={{ width: '100%' }}>
      <div
        id="transaction-history"
        className="dashboard-two-col-wrap wayapay-div"
        style={{ width: '100%' }}
      >
        <div className="dasboard-col-one" style={{ width: '100%' }}>
          <div className="mt-3 mb-3 mx-auto">
            <input
              className="w-100 form-control"
              placeholder="Search"
              // value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div style={{ width: '70%' }}>
            <div
              className="inputbox-with-one-input"
              style={{ position: 'relative' }}
            >
              <input
                placeholder="Select User"
                type="text"
                value={query}
                onChange={(e) => {
                  // e.persist()
                  setQuery(e.target.value);
                  // handleSearch();
                }}
              />
              <CustomDropdown2
                options={categories}
                handleClick={handleSelectOption}
              />
            </div>
            <div className="inputbox-with-one-input">
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  // defaultValue={data.senderAccountNumber}
                >
                  <option value="Select" selected disabled>
                    Select
                  </option>
                </select>
              </div>
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  // defaultValue={data.senderAccountNumber}
                >
                  <option value="Select" selected disabled>
                    Select
                  </option>
                </select>
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Add a Note"
                  type="text"
                  // value={data.reason}
                />
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Add a Note"
                  type="text"
                  // value={data.reason}
                />
              </div>
            </div>
            {/* {categories.map((item) => (
              <ListWrapper key={Math.random()}>
                <div className="inner">
                  <div className="profile-div">
                    <div className="letterImg">
                      <p>{getFirstLetter(item.categoryName)}</p>
                    </div>
                    <div
                      className="text-div ms-3"
                      onClick={() => {
                        setCategoryId(item.categoryId);
                        setShowOtherBillers(true);
                      }}
                    >
                      <span className="add-cursor">
                        {item.categoryName || ''}
                      </span>
                    </div>
                  </div>
                </div>
              </ListWrapper>
            ))} */}
          </div>
        </div>
        {showOtherBillers ? (
          <OtherBillers
            showModal={showOtherBillers}
            hideModal={setShowOtherBillers}
            categoryId={categoryId}
            id="billpayment-wrapper"
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
