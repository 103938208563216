import React from 'react';

const SearchBar = ({ setSearchValue, searchValue }) => {
  return (
    <div
      className="mt-4 mb-3 mx-auto search-container"
      style={{ width: '70%' }}
    >
      <input
        className="w-100 form-control search-input"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
