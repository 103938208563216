import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DisputeInfo from './DisputeInfo';
import DisputeLog from './DisputLog';
import StepButton from '../../Post/DonationModal/StepButton';
import './dispute.scss';

import cancelIcon from '../../../../../assets/images/cancelIcon.png';
import notifications from '../../../../../assets/images/notifications.png';

const DisputeModal = (props) => {
  const { hideModal, showModal } = props;
  const [values, setValues] = useState({
    step: 0,
    totalSteps: 2,
  });

  const handleStep = () => {
    if (values.totalSteps - 1 === values.step) {
      return;
    }
    setValues((prev) => ({
      ...prev,
      step: values.step + 1,
    }));
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="dispute-resolution"
      centered
    >
      <ModalHeader>
        <div className="dispute-header-container">
          <img src={notifications} alt="notification" />
          <h5 className="mb-0">Dispute Log</h5>
          <img src={cancelIcon} alt="cancel" onClick={() => hideModal(false)} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="complaint-session-container">
          {values.step === 0 && <DisputeLog handleStep={handleStep} />}
          {values.step === 1 && <DisputeInfo handleStep={handleStep} />}
          <StepButton title="Continue" onClick={handleStep} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DisputeModal;
