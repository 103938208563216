import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';

const OtherBillers = (props) => {
  const { showModal, hideModal, id, categoryId } = props;
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [network, setNetwork] = useState('');
  const [postData, setPostData] = useState({});
  const [inputFields, setInputFields] = useState([]);
  const [isValidate, setIsValidate] = useState(false);
  const { getBillers } = useContext(BillerContext);
  const { walletList } = useContext(WalletContext);
  const [categories, setCategories] = useState([]);
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    (async () => {
      const res = await getBillers(categoryId);
      setCategories(res);
    })();

    return () => {
      setCategories([]);
    };
  }, []);

  const getFieldParams = async (billerId) => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category/${categoryId}/biller/${billerId}`
    );
    if (res.status) {
      if (res.data.isValidationRequired) {
        setIsValidate(true);
      } else {
        setIsValidate(false);
      }
      setInputFields(res.data.items);
    }
  };

  const handleValidate = async (objValue) => {
    setLoading(true);

    const data = {
      data: [objValue],
      categoryId,
      billerId: network,
    };

    const res = await httpPost(
      `/api/v1/biller/validate`,
      data,
      customBaseUrl.billPaymentUrl
    );
    if (res.status) {
      setLoading(false);
      setCustomerName(res.data.data[0].value);
      // setOtherItems(res.data.items);
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const walletId = walletList.filter((item) => item.defaultWallet && item);

    const arrayOfObj = Object.entries(postData).map(([key, value]) => ({
      name: key,
      value,
    }));
    const data = {
      amount: postData.amount,
      phone: postData.phone,
      data: arrayOfObj,
      categoryId,
      billerId: network,
      sourceWalletAccountNumber: walletId[0].id,
    };

    const res = await httpPost(
      `/api/v1/biller/pay`,
      data,
      customBaseUrl.billPaymentUrl
    );
    if (res.status) {
      // console.log(res);
      setLoading(false);
      swal('Done', res.data?.transactionMessage, 'success');
    } else {
      setLoading(false);
      swal('Oops', res.message, 'error');
    }
  };

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Pay Bills
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              value={network}
              onChange={(e) => {
                getFieldParams(e.target.value);
                setNetwork(e.target.value);
              }}
            >
              <option value="Select" selected data-default hidden>
                Network Provider
              </option>
              {categories.length
                ? categories.map((item) => (
                    <option value={item.billerId} key={item.billerId}>
                      {item.billerName}
                    </option>
                  ))
                : ''}
            </select>
          </div>

          {inputFields.length
            ? inputFields.map((item) => (
                <div className="inputbox-with-one-input" key={item.paramName}>
                  {item.subItems.length ? (
                    <select
                      value={postData.paramName}
                      onChange={(e) => {
                        const [name, amount] = e.target.value.split(',');
                        setPostData(
                          item.isAmountFixed
                            ? {
                                ...postData,
                                [item.paramName]: name,
                                total_amount: amount,
                              }
                            : {
                                ...postData,
                                [item.paramName]: e.target.value,
                              }
                        );
                      }}
                    >
                      <option value="Select" selected disabled>
                        {item.paramName}
                      </option>
                      {item.subItems.length
                        ? item.subItems.map((items) => (
                            <option
                              value={
                                !item.isAmountFixed
                                  ? items.name
                                  : `${items.name},${items.amount}`
                              }
                              key={items.name}
                            >
                              {items.name}
                            </option>
                          ))
                        : ''}
                    </select>
                  ) : (
                    <input
                      placeholder={item.paramName}
                      type="text"
                      value={postData[item.paramName]}
                      onChange={(e) => {
                        setPostData({
                          ...postData,
                          [item.paramName]: e.target.value,
                        });
                        if (
                          item.paramName === 'account_number' &&
                          e.target.value > 9
                        ) {
                          handleValidate({
                            name: item.paramName,
                            value: e.target.value,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              ))
            : ''}
          {isValidate && (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Customer Name"
                type="text"
                disabled
                value={customerName}
              />
            </div>
          )}
          {isValidate && (
            <>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Amount"
                  type="number"
                  value={postData.amount}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      amount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Phone"
                  type="text"
                  value={postData.phone}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Data Purchase"
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

OtherBillers.defaultProps = {
  id: '',
};

OtherBillers.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default OtherBillers;
