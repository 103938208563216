import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'antd';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { WalletContext } from '../../../store/context/WalletContext';
import RequestCard from './requestCard';
import cardsLog from '../../../assets/amico.png';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpGet } from '../../../action/http';
import CardList from './cardList';
import { hideLoader, showLoader } from '../../../utils/loader';
import CardDetails from './cardDetails';

const CardContent = () => {
  const { userDetails } = useContext(ProfileContext);
  const { walletList } = useContext(WalletContext);
  const [showCardReq, setShowCardReq] = useState(false);
  const [cardList, setCardList] = useState([]);
  // const [setMessage] = useState('');

  const getCard = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/card/user-request/list?userId=${userDetails?.userId}`
    );
    if (res?.status === false) {
      hideLoader();
      return;
      // etMessage(res?.message);
      // notification.error(res?.message);
    }
    hideLoader();
    setCardList(res);
    console.log('lf', res);
  };

  useEffect(() => {
    getCard();
  }, []);
  return (
    <>
      <div className="mt-2 mx-auto">
        {cardList?.map(
          (e) =>
            e?.status === 'APPROVED' && (
              <CardDetails key={e?.id} cardList={e} getCards={getCard} />
            )
        )}
        {cardList?.length < 1 ? (
          <Card
            title="Manage Card"
            bordered
            style={{ width: '90%', textAlign: 'center' }}
          >
            <img src={cardsLog} width="40%" alt="cards" />
            <p>Your card selection is empty</p>
            <button
              type="submit"
              className="btn"
              style={{
                width: '80%',
                textAlign: 'center',
                background: '#ff6700',
              }}
              onClick={() => setShowCardReq(true)}
            >
              Request card
            </button>
          </Card>
        ) : (
          // cardList?.map(
          //   (e) =>
          //     e?.status !== 'APPROVED' && (
          //       <CardList key={e?.id} cardList={cardList} getCards={getCard} />
          //     )
          // )
          <>
            <CardList
              cardList={cardList}
              getCards={getCard}
              showReq={setShowCardReq}
            />
          </>
        )}
        {/* // cardList[0]?.status !== 'APPROVED' ? (
        //   <CardList cardList={cardList} getCards={getCard} />
        // ) : (
        //   <CardDetails cardList={cardList} getCards={getCard} />
        // )} */}
      </div>
      {showCardReq && (
        <RequestCard
          hideModal={setShowCardReq}
          showModal={showCardReq}
          walletList={walletList}
          getCards={getCard}
        />
      )}
    </>
  );
};

export default CardContent;
