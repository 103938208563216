import React from 'react';
import { DropDownWrapper } from './style';
import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';

export function CustomDropdown(props) {
  const { options, handleClick } = props;
  // const options = [1, 2, 3];
  return (
    <DropDownWrapper style={!options.length ? { display: 'none' } : {}}>
      {options.length
        ? options.map((item) => (
            <div
              className="d-flex"
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => handleClick(item)}
            >
              <div className="profile-div">
                <div className="image-div">
                  <img
                    src={item?.avatar || imgPlaceholder}
                    alt="placeholder"
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className="text-div">
                  <span>{`${item.surname} ${item.firstName} ${item?.email}`}</span>
                </div>
              </div>
            </div>
          ))
        : ''}
    </DropDownWrapper>
  );
}

export function CustomDropdown2(props) {
  const { options, handleClick } = props;
  // const options = [1, 2, 3];

  const getFirstLetter = (myStr) => {
    const matches = myStr.match(/\b(\w)/g);
    return matches.join('');
  };
  return (
    <DropDownWrapper style={!options.length ? { display: 'none' } : {}}>
      {options.length
        ? options.map((item) => (
            <div
              className="d-flex"
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => handleClick(item)}
            >
              <div className="profile-div">
                <div className="image-div">
                  {/* <img
                    src={item?.avatar || imgPlaceholder}
                    alt="placeholder"
                    style={{ borderRadius: '50%' }}
                  /> */}
                  <div className="letterImg d-flex justify-content-center align-center">
                    <p>{getFirstLetter(item.categoryName)}</p>
                  </div>
                </div>
                <div className="text-div">
                  <span>{item.categoryName || ''}</span>
                </div>
              </div>
            </div>
          ))
        : ''}
    </DropDownWrapper>
  );
}
