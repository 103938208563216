import React from 'react';
import MaterialTable, { MTablePagination } from 'material-table';
import { Paper } from '@material-ui/core';
import tableIcons from './icons';
import './table.css';
// import search from '../../../assets/search.svg';
// import log from '../../../assets/log.svg';

export default function TransactionTable(props) {
  const { options, columns, data, title } = props;

  // const handleChange = () => [];

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: function cont(prop) {
            return <Paper {...prop} elevation={0} />;
          },
          Toolbar: function toolb() {
            return <div> </div>;
          },
          Pagination: function pagin(prop) {
            return <MTablePagination {...prop} />;
          },
          // Header: function headi(prop) {
          //   return (
          //     <div
          //     // style={{ backgroundColor: 'rgba(231, 71, 44, 0.16)' }}
          //     >
          //       <MTableHeader {...prop} />
          //     </div>
          //   );
          // },
        }}
        icons={tableIcons}
        options={
          options || {
            exportButton: false,
            search: false,
            sorting: false,
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 30],
            headerStyle: {
              backgroundColor: '#ff6700',
              color: '#ffffff',
              whiteSpace: 'nowrap',
              zIndex: 0,
            },
          }
        }
        columns={columns}
        data={data || []}
        title={title || ''}
      />
      <div style={{ marginBottom: 50 }} />
    </div>
  );
}
