import React from 'react';
import CustomDropdown from '../../shared/Dropdown';

import { chatOptions } from './data';
import userAvatar from '../../../assets/mockImages/chatAvata.png';
import search from '../../../assets/images/search.png';

const ChatInnerNav = (props) => {
  const { handleOption, handleShowSearchBox, loggedInUser, activeChatsCount } =
    props;
  return (
    <>
      <div>
        <img
          src={loggedInUser?.avatar || userAvatar}
          alt="user"
          style={{ width: '30px', height: '30px', borderRadius: '15px' }}
        />
      </div>
      <div>Chats ({`${activeChatsCount}`})</div>
      <div className="icons-right">
        <img src={search} alt="search" onClick={handleShowSearchBox} />
        <div
          style={{
            fontSize: '24px',
            marginTop: '3px',
            marginLeft: '26px',
          }}
        >
          <div className="dot-3x" style={{ position: 'relative' }}>
            <CustomDropdown
              id={12}
              direction="vertical"
              handleOption={handleOption}
              options={chatOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatInnerNav;
