export const CHANGE_FIELD = 'CHANGE_FIELD';

export const REQUEST_PENDING = 'REQUEST_PENDING';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REQUEST_FAILED';

export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOADER_ON = 'LOADER_ON';
export const LOADER_OFF = 'LOADER_OFF';

export const SUCCESS_MSG_ON = 'SUCCESS_MSG_ON';
export const SUCCESS_MSG_OFF = 'SUCCESS_MSG_OFF';
export const ERROR_MSG_ON = 'ERROR_MSG_ON';
export const ERROR_MSG_OFF = 'ERROR_MSG_OFF';

export const SET_CURRENT_WALLET = 'SET_CURRENT_WALLET';
