import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import './index.scss';
import {
  getAllGroups,
  getMyGroups,
  joinGroup,
  // leaveAndDeleteGroup,
} from './container';
import RightSidebar from './RightSidebar';
import Loader from '../../shared/Loader';
import Container from '../../shared/Container';
import ListItem from '../../shared/Cards/ListItem';
import { ProfileContext } from '../../../store/context/ProfileContext';
import WayagramGroupProfile from '../../shared/Modals/Profile/WaygramGroupProfile';

export default function MyGroups(props) {
  const {
    profile: { id },
  } = useContext(ProfileContext);
  const [list, setList] = useState([]);
  const [mList, setMList] = useState([]);
  const [activeTab, setActiveTab] = useState('myGroups');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [profileData, setProfileData] = useState({});

  const handleGetAllGroups = async () => {
    const groupsList = await getAllGroups(id);
    setList(groupsList);
    setLoading(false);
    setBtnLoading(false);
  };

  const handleGetMyGroups = async () => {
    const myGroupList = await getMyGroups(id);
    setMList(myGroupList);
    setLoading(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    setList([]);
    setMList([]);
    setLoading(true);
    if (activeTab === 'myGroups' && id) {
      handleGetMyGroups();
    } else if (activeTab === 'allGroups' && id) {
      handleGetAllGroups();
    }
  }, [activeTab, id]);

  const handleSubmit = async (e, ...rest) => {
    if (e === 'Join') {
      setBtnLoading(true);
      const res = await joinGroup(id, rest[1]);
      if (res?.status) {
        handleGetAllGroups();
      } else {
        setBtnLoading(false);
        swal('error', res.message, 'error');
      }
    }
    // if (e === 'Leave') {
    //   setBtnLoading(true);
    //   const res = await leaveAndDeleteGroup(id, rest[1]);
    //   if (res?.status) {
    //     if (activeTab === 'myGroups') {
    //       handleGetMyGroups();
    //     } else {
    //       handleGetAllGroups();
    //     }
    //   } else {
    //     swal('error', res.message, 'error');
    //     setBtnLoading(false);
    //   }
    // }
    if (e === 'Edit') {
      setProfileData(rest[2]);
      setShowEditProfile(true);
    }
  };

  return (
    <Layout route={props} currentPage="wayagram">
      <div className="dashboard-two-col-wrap wayagram-div">
        <div className="dasboard-col-one">
          <div className="col-12">
            <div className="row">
              <Container
                className={
                  activeTab === 'myGroups'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => {
                  setActiveTab('myGroups');
                }}
              >
                My Groups
              </Container>
              <Container
                className={
                  activeTab === 'allGroups'
                    ? 'col-6 active text-center pt-3'
                    : 'col-6 text-center pt-3'
                }
                onClick={() => {
                  setActiveTab('allGroups');
                }}
              >
                All Groups
              </Container>
            </div>
          </div>

          {loading && <Loader />}
          {activeTab === 'myGroups' && mList && mList.length ? (
            mList.map((item) => (
              <ListItem
                key={Math.random()}
                image={item?.userGroup?.imageUrl}
                name={item?.userGroup?.name}
                description={item?.userGroup?.description}
                active="Groups"
                id={item?.userGroup?.id}
                connected={item?.userGroup?.isUserAMember}
                handleSubmit={handleSubmit}
                fromLocation="manageGroup"
                isAdmin={item?.userGroup?.isUserAdmin}
                selected={selected}
                setSelected={setSelected}
                loading={btnLoading}
                item={item}
              />
            ))
          ) : mList.length === 0 && activeTab === 'myGroups' ? (
            <div className="row h-100 d-grid justify-content-center align-items-center">
              No group found
            </div>
          ) : (
            ''
          )}

          {activeTab === 'allGroups' && list && list.length ? (
            list.map((item) => (
              <ListItem
                key={Math.random()}
                image={item?.imageUrl}
                name={item?.name}
                description={item?.description}
                active="Groups"
                id={item?.id}
                connected={item?.isUserAMember}
                handleSubmit={handleSubmit}
                fromLocation="manageGroup"
                isAdmin={item?.isUserAdmin}
                selected={selected}
                setSelected={setSelected}
                loading={btnLoading}
                item={item}
              />
            ))
          ) : list?.length === 0 && activeTab === 'allGroups' ? (
            <div className="row h-100 d-grid justify-content-center align-items-center">
              No group found
            </div>
          ) : (
            ''
          )}
        </div>

        <RightSidebar />
      </div>
      {showEditProfile ? (
        <WayagramGroupProfile
          showModal={showEditProfile}
          hideModal={setShowEditProfile}
          groupData={profileData?.userGroup}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
