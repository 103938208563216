import socketIO from 'socket.io-client';
// import { NotificationManager } from 'react-notifications';
import { customBaseUrl } from './http';

export const AppSocket = {};
export const notifications = [{}];

AppSocket.createConnection = async () => {
  if (AppSocket.io?.connected) return; // if there has been a connection before, return
  // open a connection
  const connectionOptions = {
    'force new connection': true,
    reconnectionAttempts: 'Infinity',
    timeout: 10000,
    transports: ['websocket'],
  };

  AppSocket.io = socketIO(
    `${customBaseUrl.socketUrl}?token=${localStorage.token}`,
    connectionOptions
  );
  // listen for connection
  AppSocket.io.on('connect', () => {
    console.log('connected to server');
  });
  AppSocket.io.on('user_connected', () => {
    console.log('User connected to server');
  });
};

AppSocket.sendRequest = async (path, payload) => {
  if (AppSocket.io && AppSocket.io.connected === true) {
    AppSocket.io.emit(path, payload);
    // NotificationManager.success(' Sent successfully.', 'Success');
  } else {
    console.log('App disconnected from server');
  }
};

AppSocket.disconnect = () => {
  if (AppSocket) AppSocket.io.disconnect();
};
