import styled from 'styled-components';
import device from '../../../utils/device';

const ActivityWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .activity-head {
    padding: 19px 49px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .ah-1 {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: 0.3px;

      color: #000000;
      margin-right: 26px;
    }
    .ah-2 {
      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      letter-spacing: -0.02em;

      color: #000000;
      display: flex;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.29);
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
  .activities {
    padding: 25px 43px;
    .act-table {
      padding: 28px 30px;
      height: 455px;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      width: 100%;
      .act-head {
        display: flex;
        font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        margin-bottom: 12.52px;
      }
      .activity-row {
        width: 65%;
      }
      .duration-row {
        width: 35%;
      }
      .act-body {
        display: flex;
        flex-direction: column;
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height */

        color: #000000;
        .act-data {
          padding: 7px 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          .dat-dur {
            width: 35%;
          }

          .activity-det {
            width: 65%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            .view-more {
              margin-right: 0px;
              display: flex;
              img {
                margin-right: 10px;
              }
            }
            .ad-action {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .about {
    padding: 19px 49px;
    width: 100%;
    .activity-about {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      padding: 17px 27px;
      .activity-about-head {
        display: flex;
        flex-direction: column;
        font-family: DM Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 9px;
        color: #000000;
        .act-text-head {
          margin-bottom: 0px;
        }
        .act-vr {
          color: #fff;
          border-bottom: 5px solid #ff6634;
          margin-top: 9px;
          width: 49px;
          height: 0px;
        }
      }
      .activity-about-det {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height */
        height: 272px;
        color: #000000;
      }
      .activity-set {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        .right {
          width: 70%;
        }
        .left {
          width: 30%;
        }
        .bold {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
        }
        .light {
          font-weight: 300;
          font-size: 16px;
          color: #828282;
        }
      }
    }
  }
  .back_Arrow {
    width: 100%;
  }
  @media ${device.maxmobileL} {
    width: 100%;
  }
`;

export default ActivityWrapper;
