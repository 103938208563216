import React, { useContext, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
// import CustomDropdown from '../../../Cards/CustomDropdown';
import FinishButton from '../DonationModal/StepButton';
import '../DonationModal/index.scss';
import { customBaseUrl, httpPost } from '../../../../../action/http';
import { hideLoader, showLoader } from '../../../../../utils/loader';
import { WayagramContext } from '../../../../../store/context/WayagramContext';
import { handleGetGroupCategories } from '../../../../pages/Wayagram/container';

const CreateGroupModal = (props) => {
  const { showModal, hideModal } = props;
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({
    title: '',
    privacyStatus: 'true',
    description: '',
    invitee: [],
  });
  const { wayagramProfile: profile } = useContext(WayagramContext);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    (async () => {
      const res = await handleGetGroupCategories();
      setCategories(res);
    })();
  }, []);

  const handleSubmit = async () => {
    showLoader();

    const newForm = new FormData();
    newForm.append('userId', profile.id);
    newForm.append('name', values.title);
    newForm.append('description', values.description);
    newForm.append('category', values.category);
    newForm.append('isPublic', values.privacyStatus || false);

    const res = await httpPost(
      `/main/group/create-group`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        id="donation-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader className="text-left" toggle={() => hideModal(false)}>
          <div className="interest-header normal-header">
            <h3 className="interest-p1">Group Information </h3>
            <div className="description-header mt-3">
              <p className="description-text">
                Name your group, along with why attendees should come, including
                interesting highlights
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="info-container">
            <div className="event-inner-content">
              <div className="input-container">
                <input
                  name="title"
                  values={values.title}
                  onChange={handleChange}
                  placeholder="Group name"
                />
                <span>What is the name of your group</span>
              </div>
              <div className="input-container">
                <input
                  name="description"
                  values={values.description}
                  onChange={handleChange}
                  placeholder="Description"
                />
                <span>What is the name of your group</span>
              </div>
              <div className="input-container">
                <select
                  onChange={(e) =>
                    setValues({ ...values, privacyStatus: e.target.value })
                  }
                  defaultValue={values.privacyStatus}
                >
                  <option value="false">Private</option>
                  <option value="true">Public</option>
                </select>
                <span>Is the event private or public?</span>
              </div>
              <div className="input-container">
                <select
                  value={values.category}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      category: e.target.value,
                    });
                  }}
                >
                  <option value="Select" selected disabled>
                    Select
                  </option>
                  {categories
                    ? categories.map((item) => (
                        <option value={item.title} key={item.id}>
                          {item.title}
                        </option>
                      ))
                    : ''}
                </select>
                <span>Select Category</span>
              </div>
            </div>
            <FinishButton title="Contine" onClick={handleSubmit} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateGroupModal;
