import React from 'react';

const PageToPrint = React.forwardRef((props, ref) => {
  const { data } = props;
  return (
    <div ref={ref} style={{ padding: 25 }}>
      <div>
        <h2>Account Statement</h2>
      </div>
      <table width="900" border="0" cellPadding="0" cellSpacing="0">
        <tr>
          <th
            width="843"
            height="43"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 14,
              color: '#000000',
            }}
          >
            <a
              href="/ff"
              style={{
                color: '#D31145',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              Transaction Id
            </a>
          </th>
          <th
            width="843"
            height="43"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 14,
              color: '#000000',
            }}
          >
            <a
              href="/ff"
              style={{
                color: '#D31145',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              Account No
            </a>
          </th>
          <th
            width="843"
            height="43"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 14,
              color: '#000000',
            }}
          >
            <a
              href="/ff"
              style={{
                color: '#D31145',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              Date
            </a>
          </th>
          <th
            width="843"
            height="43"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 14,
              color: '#000000',
            }}
          >
            <a
              href="/ff"
              style={{
                color: '#D31145',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              Type
            </a>
          </th>
          <th
            width="843"
            height="43"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 14,
              color: '#000000',
            }}
          >
            <a
              href="/ff"
              style={{
                color: '#D31145',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
            >
              Amount
            </a>
          </th>
        </tr>
        {data.length > 0 &&
          data.map((el) => (
            <tr key={el.accountNo}>
              <td
                width="843"
                height="64"
                valign="bottom"
                style={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {el.tranId}
              </td>
              <td
                width="843"
                height="64"
                valign="bottom"
                style={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {el.accountNo}
              </td>
              <td
                width="843"
                height="64"
                valign="bottom"
                style={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {el.transactionDate}
              </td>
              <td
                width="843"
                height="64"
                valign="bottom"
                style={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {el.tranType}
              </td>
              <td
                width="843"
                height="64"
                valign="bottom"
                style={{
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {el.tranAmount}
              </td>
            </tr>
          ))}
        <tr>
          <td
            width="843"
            colSpan="2"
            height="20"
            valign="bottom"
            align="center"
            style={{
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: 10,
              color: '#000000',
            }}
          >
            all your minor text here | all your minor text here | all your minor
            text here
          </td>
        </tr>
      </table>
    </div>
  );
});
export default PageToPrint;
