import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, ModalBody, Button } from 'reactstrap';
import { ModalHeader } from 'semantic-ui-react';
import swal from 'sweetalert';
import validator from 'validator';
import { customBaseUrl, httpPost } from '../../../action/http';
import '../../../assets/stylesheets/index.scss';
import { hideLoader, showLoader } from '../../../utils/loader';

export default function UpdateEmail(props) {
  const { showModal, hideModal, account, email } = props;
  const myRef = React.useRef(null);
  const [data, setData] = useState({});

  const handleubmit = async () => {
    if (validator.isEmpty(data.email)) {
      swal('Oops!', 'Email cannot be empty', 'error');
      return;
    }
    showLoader();
    const res = await httpPost(
      `/api/v1/wallet/updateNotificationEmail?accountNumber=${account}&email=${data.email}`,
      {},
      customBaseUrl.walletUrl
    );
    hideLoader();
    swal('', res?.body?.message);
    hideModal(false);
    console.log(res);
  };
  useEffect(() => {
    if (email) {
      setData({ ...data, email });
    }
  }, []);
  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="manage-bank-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <ModalHeader />
        <ModalBody>
          <div className="inputbox-with-one-input">
            <input
              placeholder="Email"
              type="text"
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
            <span className="input-detail">input email address </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-primary mx-auto mb-3 mt-2"
            color="primary"
            onClick={handleubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
