import React from 'react';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { customBaseUrl, httpDelete } from '../../../action/http';
import Button from '../../shared/Button';

const RemoveTeamMember = (props) => {
  const { showModal, hideModal, id, email } = props;

  const handleDelete = async () => {
    const res = await httpDelete(
      `${customBaseUrl.roleUrl}/api/v1/user-access/invite/corporate/${id}`
    );
    if (res) {
      swal('Delected', 'error');
    }
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalHeader
        className="justify-content-center"
        style={{
          background: '#F1F7FA',
          fontFamily: 'Lucida Bright',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#064A72',
          padding: '30px',
          borderRadius: '14px 14px 0 0 !important',
        }}
      >
        Remove Team Member
      </ModalHeader>
      <ModalBody
        className="text-center"
        style={{
          padding: '49px 55px 33px 55px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '135.6%',
          color: '#4F4F4F',
        }}
      >
        <div className="mb-4 w-100">
          By doing this, (`${email}`) user will no longer be able to access this
          business anymore. Do you want to continue?
        </div>

        <Button
          content="Yes, Remove"
          onClick={() => {
            handleDelete();
          }}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        />
        <div
          className="text-primary mt-2"
          onClick={() => {
            hideModal(false);
          }}
        >
          Cancel
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RemoveTeamMember;
