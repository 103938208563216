import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import Layout from '../../shared/Layout';
import ActivityWrapper from './styles';
// import eye from '../../../assets/eye.svg';
import { customBaseUrl, httpGet } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ActivitiesTable from '../../shared/Table/Activitiestable';
import LoginTracker from './loginTracker';
// import Delete from '../../../assets/image/delete.svg';
export default function Index(props) {
  const [activities, setActivities] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [filtDate, setFilterDate] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const dateFormat = 'YYYY/MM/DD';
  const { userDetails } = useContext(ProfileContext);

  const getLogs = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.logUrl}/api/v1/log/${userDetails.userId}`
    );
    if (res?.status) {
      // console.log('>>>', res.data);
      setActivities(res.data);
    }
    hideLoader();
  };

  // const getFilterLogs = async (param) => {
  //   showLoader();
  //   const res = await httpGet(
  //     `${customBaseUrl.logUrl}/api/v1/log/${userDetails.userId}`
  //   );
  //   if (res.status) {
  //     console.log('>>>', res.data);
  //     setActivities(res.data);
  //   }
  //   hideLoader();
  // };

  useEffect(() => {
    getLogs();
  }, []);

  // console.log(
  //   'selectedData',
  //   selectedData,
  //   selectedData?.jsonResponse && JSON.parse(selectedData?.jsonResponse)
  // );

  const given = moment(selectedData?.requestDate, 'YYYY-MM-DD');
  const current = moment().startOf('day');
  // Difference in number of days
  const diff =
    moment.duration(given.diff(current)).asDays() === -1
      ? `${-moment.duration(given.diff(current)).asHours()} hrs ago`
      : `${-moment.duration(given.diff(current)).asDays()} days ago`;

  const handleFilter = (date) => {
    const filtered = activities.filter(
      (el) =>
        new Date(moment(el.requestDate).format(dateFormat)).getTime() >=
          new Date(date[0]).getTime() &&
        new Date(moment(el.requestDate).format(dateFormat)).getTime() <=
          new Date(date[1]).getTime()
      // filtDate &&
      // new Date(moment(el.requestDate).format(dateFormat)) >=
      //   new Date(date[0]) &&
      // new Date(el.requestDate) <= new Date(date[1])
    );
    setActivities(filtered);
    // console.log('filtered', filtered);
  };
  // useEffect(() => {
  //   if (filtDate) {
  //     handleFilter();
  //   }
  // }, [filtDate]);
  return (
    <Layout route={props} currentPage="activity-log">
      <Button
        onClick={() => setIsLogin(false)}
        style={{
          fontSize: '10px',
          width: 100,
          background: !isLogin ? '#27AE60' : '',
          color: !isLogin ? '#fff' : '#000',
        }}
      >
        Activity Log
      </Button>
      <Button
        onClick={() => setIsLogin(true)}
        style={{
          fontSize: '10px',
          width: 100,
          background: isLogin ? '#27AE60' : '',
          color: isLogin ? '#fff' : '#000',
        }}
      >
        Login Activities
      </Button>
      {!isLogin ? (
        <ActivityWrapper>
          <div className="back_Arrow">
            {viewMore && (
              <div onClick={() => setViewMore(false)}>
                <IoIosArrowBack /> back
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {viewMore && (
              <div className="about">
                <div className="activity-about">
                  <div>
                    <div className="activity-set">
                      <div className="left bold">Duration</div>
                      <div className="right bold">Activity</div>
                      <hr />
                    </div>
                    <div className="activity-set">
                      <div className="left bold">{diff}</div>
                      <div className="right bold">{selectedData?.message}</div>
                    </div>
                    <div className="activity-set">
                      <div className="left light">
                        {moment(selectedData?.requestDate).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}{' '}
                        -{' '}
                        {moment(selectedData?.responseDate).format('h:mm:ss a')}
                      </div>
                      <div className="right light">
                        {selectedData?.device};
                        <br />
                        {selectedData?.ip};
                        <br />
                        Location: {selectedData?.location}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!viewMore && (
              <ActivitiesTable
                columns={[
                  { title: 'DURATION', field: 'duration' },
                  { title: 'ACTIVITY', field: 'message' },
                  { title: '', field: 'action' },
                ]}
                setDate={setFilterDate}
                date={filtDate}
                onChange={handleFilter}
                data={
                  activities.length &&
                  activities.map((item) => {
                    return {
                      // duration: moment(item.requestDate).format(
                      //   'MMMM Do YYYY, h:mm:ss a'
                      // ),
                      // duration: item.requestDate.split('T')[0],
                      duration: JSON.stringify(
                        new Date(moment(item.requestDate).format(dateFormat))
                      ),
                      message: item?.message,
                      action: (
                        <div className="d-flex align-content-center">
                          <span
                            style={{
                              background: '#FFFFFF',
                              border: '1px solid #D0CFCF',
                              boxSizing: 'border-box',
                              borderRadius: '3px',
                              padding: '5px 5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedData(item);
                              setViewMore(true);
                            }}
                          >
                            View More
                          </span>
                          {/* <img
                        className="my-auto"
                        src={Delete}
                        alt=""
                        style={{
                          width: '16px',
                          height: '16px',
                          marginLeft: '25px',
                        }}
                      /> */}
                        </div>
                      ),
                    };
                  })
                }
              />
            )}
          </div>
          {/* <div className="about">
            <div className="activity-about">
              <div className="activity-about-head">
                <div className="act-text-head">About this activity</div>
                <div className="act-vr">-</div>
              </div>
            </div>
              </div> */}
        </ActivityWrapper>
      ) : (
        <LoginTracker />
      )}
    </Layout>
  );
}
