import React from 'react';
import { Col, Row } from '../containers/containers';
import { BoldText, NormalText } from '../texts/allText';
import styles from './styles.module.css';

export const CardButton = ({ text, color, radius = 0, onclick }) => {
  return (
    <div
      onClick={onclick}
      style={{ borderRadius: radius }}
      className={styles.button_First}
    >
      <NormalText color={color}>{text}</NormalText>
    </div>
  );
};

export const ButtonWithIcon = ({
  text,
  color,
  radius = 0,
  children,
  onclick,
  width,
}) => {
  return (
    <Row
      onClick={onclick}
      style={{ borderRadius: radius, width }}
      className={styles.button_Second}
    >
      <NormalText color={color}>{text}</NormalText>
      <div className={styles.forward}>{children}</div>
    </Row>
  );
};

export const ButtonPlain = ({ text, color, radius = 0, children, onclick }) => {
  return (
    <div
      onClick={onclick}
      style={{ borderRadius: radius }}
      className={styles.button_plain}
    >
      <div className={styles.forward}>{children}</div>
      <BoldText size={10} color={color}>
        {text}
      </BoldText>
    </div>
  );
};

export const ButtonWithIconLeft = ({
  text,
  color,
  radius = 0,
  children,
  onclick,
  bgColor,
}) => {
  return (
    <div
      onClick={onclick}
      style={{ borderRadius: radius, backgroundColor: bgColor }}
      className={styles.button_withLeft}
    >
      <Col className={styles.the_icon}>{children}</Col>
      <NormalText color={color}>{text}</NormalText>
    </div>
  );
};
