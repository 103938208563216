import React from 'react';
import logo from '../../../assets/image/Grouplogo.svg';

const NoChatHistory = () => {
  return (
    <>
      <div className="no-chat-history">
        <div className="navbar-logo">
          <img src={logo} alt="" />
        </div>
        <p className="description-text">
          Send private message, photos, videos, & money to a friend or group
        </p>
        <div className="send-button">Send</div>
      </div>
    </>
  );
};

export default NoChatHistory;
