import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
// import { httpPost } from '../../../../action/http';
// import Button from '../../Button';

import cancelIcon from '../../../../assets/images/cancelIcon.png';

const PrivacySettings = (props) => {
  const { showModal, hideModal } = props;

  // const handleSubmit = async () => {

  // };

  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      id="privacy-setting"
      centered
    >
      <ModalHeader>
        <div className="profile-header-container">
          <img src={cancelIcon} alt="cancel" onClick={() => hideModal(false)} />
          <h5 className="mb-0">Privacy</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="menu-items p-2">
          <div className="col-11 pl-3 py-2">
            <div className="mb-0">Account privacy</div>
          </div>
          <div className="col-1 my-auto">{'>'}</div>
        </div>
        <div className="menu-items p-2">
          <div className="col-11 pl-3 py-2">
            <div className="mb-0">Chat privacy</div>
          </div>
          <div className="col-1 my-auto">{'>'}</div>
        </div>
        <div className="menu-items p-2">
          <div className="col-11 pl-3 py-2">
            <div className="mb-0">Read receipt</div>
          </div>
          <div className="col-1 my-auto">{'>'}</div>
        </div>
        <div className="mt-5">
          <div>Security</div>
          <div className="small-text">
            Receive notification when one of your contact security code changes
          </div>
          <select className="mt-2">
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="mt-5">Delete my account</div>
      </ModalBody>
    </Modal>
  );
};

PrivacySettings.defaultProps = {
  showModal: false,
};

PrivacySettings.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool,
};

export default PrivacySettings;
