import styled from 'styled-components';

export const WayaChatContainer = styled.div`
  max-width: 1420px;
  max-height: 90vh;
  background-color: #fff;
  padding-left: 20px;
  padding-top: 40px;
  overflow-y: scroll;
  padding-bottom: 40px;
`;

export const WayaChat = styled.div`
  max-width: 949px;
  background: #fafafa;
  border: 3px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 9px;
  min-height: 80vh;
  display: flex;
  height: 80vh;

  .chat-col-left {
    width: 40%;
    border-radius: 9px 0 0 9px;
    border-right: 3px solid #f2f2f2;
  }

  .chat-col-right {
    width: 60%;
    border-radius: 0 9px 9px 0;
    .no-chat-history {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      .description-text {
        width: 60%;
        font-style: normal;
        font-weight: 500;
        font-size: 22px !important;
        text-align: center;
        line-height: 26px;
        color: #0d0d0d;
        margin-top: 10px;
      }
      .send-button {
        background: #ff6634;
        border-radius: 4px;
        width: 187px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 800;
      }
    }
    .sc-eCssSg .sc-fubCfw .hDFggP .dEQTAn {
      position: absolute;
      right: -18px !important;
      bottom: 9px !important;
      /* width: 43px; */
      font-size: 24px !important;
    }
  }

  .chat-left-nav {
    padding: 22px 30px 22px 37px;
    border-bottom: 3px solid #f2f2f2;
    height: 77px;
    .chat-left-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons-right {
        display: flex;
        margin-right: -16px;
        width: 65px;
        justify-content: space-between;
        img {
          cursor: pointer;
        }
      }
    }
    .input-container {
      width: 100%;
      input {
        margin-top: 10px;
        width: 100%;
        border: 2px solid #f2f2f2;
        height: 2.5rem;
        border-radius: 8px;
        padding: 4px 12px;
      }
    }
    .chat-settings-nav {
      display: flex;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        position: absolute;
        left: 0px;
        cursor: pointer;
      }
    }
  }
`;

export const ContactCard = styled.div`
  .contact-card {
    width: 100%;
    height: 93px;
    display: flex;
    align-content: center;
    cursor: pointer;
    :hover {
      background-color: #e5e5e5;
    }
    .image-container {
      margin-left: 36px;
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
    .text-container {
      margin-right: 27px;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 13.5px;
      justify-content: center;
      border-bottom: 2px solid #f2f2f2;
      p {
        margin: 0;
      }
      .text-top {
        display: flex;
        justify-content: space-between;
        .username {
          font-weight: 800;
          font-size: 14px;
          color: #0d0d0d;
        }
        .time {
          color: #828282;
          font-weight: bold;
          font-size: 14px;
        }
      }
      .text-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .last-message {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #828282;
        }
        .italic {
          font-style: italic;
        }
        .activity-box {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: #ff6634;
          color: #fff;
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  .active-contact {
    background-color: #e5e5e5;
  }
`;

export const ChatHistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: orchid; */

  .chat-right-nav {
    display: flex;
    justify-content: space-between;
    /* height: 80px; */
    padding: 24px 49px 13px 15px;
    border-bottom: 3px solid #f2f2f2;
    p {
      margin: 0;
    }
    .chat-top-left {
      display: flex;
      align-items: center;
      .nav-arrow {
        height: 15px;
      }
      .contact-avatar {
        align-self: center;
        margin-left: 29px;
      }
      .contact-name {
        margin-left: 16px;
        width: auto;
        .status {
          font-size: 11px;
          line-height: 13px;
          color: #4f4f4f;
        }
        .italic {
          font-style: italic;
        }
      }
    }
    .activity-group {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 15px;
        cursor: pointer;
      }
    }
  }
  .chat-box-container {
    padding: 28px 39px 38px 33px;
    height: 91%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(80vh - 60px);
    overflow: hidden;
    overflow-y: auto;
    .chat-box-content {
      height: 100%;
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;
    }
    .chat-input-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .input-container {
        position: relative;
        width: 90%;
        .chat-upload {
          position: absolute;
          right: 20px;
          top: 12px;
          cursor: pointer;
        }
        .chat-upload-input {
          width: 29px;
          top: -2px !important;
          opacity: 0;
        }
      }
      input {
        width: 100%;
        padding: 10px 16px;
        background: #f2f2f2;
        border-radius: 32px;
        border: none;
      }
      .send-button {
        align-self: center;
        font-size: 15px;
        line-height: 18px;
        color: #38364f;
        cursor: pointer;
      }
    }
  }
`;

export const ChatMessageFrom = styled.div`
  display: flex;
  .content {
    background-color: #fff;
    color: #383838;
    max-width: 80%;
    padding: 10px;
    border-radius: 0px 20px 20px 20px;
    color: #383838;
    margin-bottom: 5px;
    img {
      height: 176px;
      width: 180px;
    }
  }
`;

export const TimeStamp = styled.div`
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;
export const TimeStampContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    margin-left: 6px;
  }
`;

export const ChatMessageTo = styled.div`
  display: flex;
  justify-content: flex-end;
  .content {
    background-color: rgb(56, 54, 80);
    color: #fff;
    padding: 10px;
    max-width: 80%;
    border-radius: 20px 0px 20px 20px;
    color: #fff;
    margin-bottom: 5px;
    img {
      height: 176px;
      width: 180px;
    }
  }
`;

export const UnknowNumber = styled.div`
  width: 100%;
  background: rgba(255, 223, 205, 0.4);
  margin-top: 5px;
  padding: 14px 17px 20px 17px;
  .title {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
  }
  .list {
    font-size: 12px;
    line-height: 14px;
    color: #38364f;
    margin-left: 40px;
    div {
      margin-top: 24px;
      cursor: pointer;
      :hover {
        color: #ff6634;
      }
    }
  }
`;

export const ChatSettingWrapper = styled.div`
  padding: 26px 32px 30px 31px;
`;

export const ChatSettingContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 9px 20px 9px 9px;
  margin-bottom: 12px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    color: #ff6634;
  }
  .description {
    font-size: 12px;
    line-height: 14px;
    color: #4f4f4f;
  }
`;
