import React from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

/* eslint-disable no-unused-expressions */

const tableIcons = {
  Add: function add() {
    return <AddBox />;
  },
  Check: function check() {
    return <Check />;
  },
  Clear: function clear() {
    return <Clear />;
  },
  Delete: function del() {
    return <DeleteOutline />;
  },
  DetailPanel: function detailPanel() {
    return <ChevronRight />;
  },
  Edit: function edit() {
    return <Edit />;
  },
  Export: function exp() {
    return <SaveAlt />;
  },
  Filter: function filter() {
    return <FilterList />;
  },
  FirstPage: function firstPage() {
    return <FirstPage />;
  },
  LastPage: function lastPage() {
    return <LastPage />;
  },
  NextPage: function nextPage() {
    return <ChevronRight />;
  },
  PreviousPage: function previousPage() {
    return <ChevronLeft />;
  },
  ResetSearch: function resetSearch() {
    return <Clear />;
  },
  Search: function search() {
    return <Search />;
  },
  SortArrow: function sortArrow() {
    return <ArrowDownward />;
  },
  ThirdStateCheck: function ThirdStateCheck() {
    return <Remove />;
  },
  ViewColumn: function ViewColumc() {
    return <ViewColumn />;
  },
};

export default tableIcons;
