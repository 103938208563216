import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';

const Index = (props) => {
  const {
    showModal,
    hideModal,
    id,
    title,
    description,
    cancelText,
    submitText,
    handleSubmit,
    handleCancel,
  } = props;

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalBody>
        <div>
          <h4 className="mt-5 mb-3 text-center">{title}</h4>
          <p className="text-secondary mb-3 text-center">{description}</p>
        </div>
      </ModalBody>
      <ModalFooter className="p-0" style={{ border: '1px solid  #F2F2F2' }}>
        <div className="col-12 m-0">
          <div className="row">
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              style={{ borderRight: '1px solid  #F2F2F2' }}
              onClick={() => handleCancel()}
            >
              {cancelText}
            </div>
            <div
              role="button"
              tabIndex={0}
              aria-hidden="true"
              className="col-6 text-center text-primary pt-3 pb-3"
              onClick={() => handleSubmit()}
            >
              {submitText}
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
