import React, { useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import swal from 'sweetalert';
import { notification } from 'antd';
import CurrencyInput from 'react-currency-input-field';
// import excapeRegExp from 'escape-string-regexp';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { customBaseUrl, httpGet } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';

// import moneyFomatter from '../../../../utils/moneyFormatter';

export default function PayToWayaId(props) {
  const { data, setData, walletList, getCharges } = props;
  const [balance, setBalance] = useState(null);
  const [isCorporate, setIsCorporate] = useState(null);
  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    if (data.senderAccountNumber && data.amount) {
      getCharges(data.senderAccountNumber, data.amount);
    }
  }, [data]);

  const handleSelectAccount = () => {
    console.log({ isCorporate });
    if (isCorporate === 'true') {
      const cData = accountData?.filter((e) => e?.corporate === true)[0];
      setData({
        ...data,
        receiverProfileId: cData?.id,
        receiverEmail: cData?.email,
        receiverPhoneNumber: cData?.phoneNumber,
        receiverName: cData?.otherDetails?.organisationName,
        receiverId: cData?.userId,
      });
    } else {
      const pData = accountData?.filter((e) => e?.corporate === false)[0];
      setData({
        ...data,
        receiverProfileId: pData?.id,
        receiverEmail: pData?.email,
        receiverPhoneNumber: pData?.phoneNumber,
        receiverName: `${pData?.firstName} ${pData?.surname}`,
        receiverId: pData?.userId,
      });
    }
  };

  const handleAccountType = async () => {
    // if (!data?.userId) return swal('userId can not be null');
    const res = await httpGet(
      `${
        customBaseUrl.authUrl
      }/api/v1/profile/account/${false}/${false}?query=all&userId=${
        data?.userId
      }`
    );
    showLoader();
    if (res?.status) {
      hideLoader();
      notification.success(res?.message);
      if (res?.data?.length === 1) {
        setData({
          ...data,
          receiverProfileId: res?.data[0]?.id,
          receiverEmail: res?.data[0]?.email,
          receiverPhoneNumber: res?.data[0]?.phoneNumber,
          receiverName:
            res?.data[0]?.otherDetails?.organisationName ??
            `${res?.data[0]?.firstName} ${res?.data[0]?.surname}`,
          receiverId: res?.data[0]?.userId,
        });
      } else {
        setAccountData(res?.data);
      }
    } else {
      hideLoader();
      setData({
        ...data,
        receiverProfileId: null,
        receiverEmail: null,
        receiverPhoneNumber: null,
        receiverId: null,
        receiverName: '',
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  useEffect(() => {
    if (accountData.length > 1) {
      handleSelectAccount();
    }
  }, [isCorporate]);

  return (
    <ModalBody>
      <div className="text-center mt-3 mb-3">Pay to Waya Id</div>
      <form className="mx-auto mt-3">
        {/* <div
          className="inputbox-with-one-input"
          style={{ position: 'relative' }}
        >
          <input
            placeholder="Select User"
            type="text"
            value={query}
            onChange={(e) => {
              // e.persist()
              setData({ ...data, userId: e.target.value });
              setQuery(e.target.value);
              handleSearch();
            }}
            // onKeyDown={(e) => {
            //   if (e.key === 'Enter') {
            //     handleSearch();
            //   }
            // }}
          />
          <CustomDropdown options={options} handleClick={handleSelectOption} />
          <span className="input-detail">Send money to a Waya User</span>
        </div> */}
        {/* {isCorporate === 'true' ? (
          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              value={isRegistered}
              onChange={(e) => setIsRegistered(e.target.value)}
            >
              <option value="Select" key="select" selected disabled>
                Account Type
              </option>
              <option value={true}>REGISTERED BUSINESS</option>
              <option value={false}>NON REGISTERED BUSINESS</option>
            </select>
            <span className="input-detail">Account Type</span>
          </div>
        ) : null} */}
        <div>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              className="form-control border-end-0 border-top-0 border-start-0"
              placeholder="User ID"
              required
              value={data?.userId}
              onChange={(e) =>
                setData({ ...data, userId: e.target.value, receiverName: '' })
              }
            />
            <button
              type="button"
              style={{
                position: 'absolute',
                top: 0,
                right: '0px',
                height: '100%',
                border: 'none',
                backgroundColor: 'transparent',
              }}
              onClick={handleAccountType}
            >
              Verify
            </button>
          </div>
          <span className="input-detail">User ID of Recipent</span>
          {accountData?.length > 1 ? (
            <div className="inputbox-with-one-input">
              <select
                name="filter"
                className=""
                value={isCorporate}
                onChange={(e) => {
                  setIsCorporate(e.target.value);
                }}
              >
                <option value="Select" key="select" selected>
                  Account Type
                </option>
                <option value={false}>PERSONAL</option>
                <option value>CORPORATE</option>
              </select>
              <span className="input-detail">Account Type</span>
            </div>
          ) : (
            ''
          )}
          <div className="inputbox-with-one-input">
            <input
              placeholder="Name"
              disabled
              type="text"
              value={data?.receiverName}
            />
            <span className="input-detail">Name of Recipent</span>
          </div>
        </div>

        <div className="inputbox-with-one-input">
          <CurrencyInput
            id="input-amount"
            name="input-amount"
            placeholder="Amount"
            defaultValue={0.0}
            decimalsLimit={2}
            onValueChange={(value, name, values) =>
              setData({ ...data, amount: values?.value })
            }
          />
          <span className="input-detail">How much do you want to Send?</span>
        </div>

        <div className="inputbox-with-one-input">
          <input
            placeholder="Add a Note"
            type="text"
            value={data.reason}
            onChange={(e) => {
              setData({ ...data, reason: e.target.value });
            }}
          />
          <span className="input-detail">Add descriptive notes</span>
        </div>

        <div className="inputbox-with-one-input">
          <select
            name="filter"
            className=""
            defaultValue={data.senderAccountNumber}
            onChange={(e) => {
              const [acccountNo, amount] = e.target.value.split(',');
              setData({
                ...data,
                balance: amount,
                senderAccountNumber: acccountNo,
              });
              setBalance(amount);
            }}
          >
            <option value="Select" selected disabled>
              Select
            </option>
            {walletList.length
              ? walletList
                  .filter((el) => !el.accountNo.startsWith('90'))
                  .map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item?.accountNo}
                    >
                      {`${item?.description} - ${item?.accountNo} (${
                        item.acct_crncy_code
                      } ${moneyFomatter(
                        Number(item?.clr_bal_amt).toFixed(2)
                      )})`}
                    </option>
                  ))
              : ''}
          </select>
          <span className="input-detail">
            Your Balance is N{balance || 0}.00
          </span>
        </div>
      </form>
    </ModalBody>
  );
}
