import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { v4 as uuid } from 'uuid';
import { previewDiv, PriceDiv, RemoveDiv, PollContainer } from './style';
// import { userAllowed } from './static';
import plusCircle from '../../../../assets/images/plusCircle.png';
import 'react-datepicker/dist/react-datepicker.css';

export function ImagePreviewOnly(props) {
  const { preview } = props;
  return (
    <div
      className="preview-div"
      style={!preview.length ? { display: 'none' } : {}}
    >
      <div className={`image-grid-div-${preview.length}`}>
        {preview.length
          ? preview.map((item, index) => (
              <div
                className="image-each"
                key={Math.random()}
                style={{
                  backgroundImage: `url(${item})`,
                  gridArea: `grid${index + 1}`,
                }}
              >
                <span className="cancel text-center d-flex justify-content-center align-items-center">
                  {' '}
                  <i className="mdi mdi-close text_danger" />{' '}
                </span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export function PollPreview(props) {
  const { setShowPoll, setPostData, postData } = props;
  const [dat, setDat] = useState([]);
  const isPaid = true;

  const handleAddOption = () => {
    console.log('here');
    setDat((item) => [...item, uuid()]);
    console.log(dat);
  };
  const handleSubOptions = (e) => {
    setPostData({
      ...postData,
      options: { ...postData?.options, [e.target.id]: e.target.value },
    });
  };

  return (
    <PollContainer>
      <div className="poll-container">
        <div className="choice-container">
          <div className="choice-box">
            <input
              value={postData?.option?.option[0]}
              placeholder="Choice 1"
              id="choice1"
              onChange={(e) => {
                handleSubOptions(e);
              }}
            />

            <input
              placeholder="Choice 2"
              id="choice2"
              value={postData?.option?.option[1]}
              onChange={(e) => {
                handleSubOptions(e);
              }}
            />
            {dat.length
              ? dat.map((item, index) => (
                  <input
                    value={postData?.option?.option[2 + index]}
                    placeholder={`Choice ${index + 3}`}
                    id={item}
                    key={item}
                    onChange={(e) => {
                      handleSubOptions(e);
                    }}
                  />
                ))
              : ''}
          </div>
          <div>
            <img src={plusCircle} alt="plus" onClick={handleAddOption} />
          </div>
        </div>
        <div className="poll-duration">
          <p>Poll Length</p>
          <div className="period-container">
            <div className="period-div w-50">
              <p>Days</p>

              <DatePicker
                className="w-100 border-0 border-bottom"
                name="expiresIn"
                showTimeSelect
                selected={postData.expiresIn}
                minDate={new Date()}
                timeIntervals={15}
                onChange={(date) => {
                  setPostData({
                    ...postData,
                    expiresIn: date?.getTime(),
                  });
                }}
              />
            </div>
            <div className="period-div">
              <p>Hours </p>
              <DatePicker
                className="w-50 border-0 border-bottom"
                name="expiresIn"
                showTimeSelect
                selected={postData.expiresIn}
                dateFormat="H"
                disabled
              />
            </div>
            <div className="period-div">
              <p>Mins </p>
              <DatePicker
                className="w-50 border-0 border-bottom"
                name="expiresIn"
                showTimeSelect
                selected={postData.expiresIn}
                dateFormat="mm"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="third-session">
          <div>
            <p className="mb-2">Votes per user</p>
            <input
              type="text"
              className="w-50 border-0 border-bottom"
              onChange={(e) =>
                setPostData({ ...postData, voteLimit: e.target.value })
              }
            />
          </div>
          <div className="d-flex flex-column">
            <select
              className="mb-1 border-0"
              value={postData.isPaid}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  isPaid: e.target.value === 'true' ? !false : false,
                })
              }
            >
              <option value={isPaid}>Paid to vote</option>
              <option value={!isPaid} selected>
                Free to vote
              </option>
            </select>
            <input
              onChange={(e) =>
                setPostData({ ...postData, amount: e.target.value })
              }
              disabled={!postData?.isPaid || false}
              type="text"
              className="w-50 border-0 border-bottom "
              placeholder="N0.0"
            />
          </div>
        </div>
        <RemoveDiv className="mt-2">
          <p
            onClick={() => {
              setShowPoll(false);
              setPostData({});
            }}
          >
            Remove Poll
          </p>
        </RemoveDiv>
      </div>
      <div className="poll-footer">
        <p>
          By sending, you agree with our terms and conditions of poll creation
        </p>
      </div>
    </PollContainer>
  );
}

export function PaidPostPreview(props) {
  const { preview, setPostData, postData, setShowPreview, setPreview } = props;
  return (
    <div
      className="preview-div"
      style={!preview.length ? { display: 'none' } : previewDiv}
    >
      <div className={`image-grid-div-${preview.length}`}>
        {preview.length
          ? preview.map((item, index) => (
              <div
                className="image-each"
                key={Math.random()}
                style={{
                  backgroundImage: `url(${item})`,
                  gridArea: `grid${index + 1}`,
                  position: 'relative',
                }}
              >
                <span>
                  {' '}
                  <i
                    className="mdi mdi-close text-danger"
                    style={{
                      position: 'absolute',
                      backgroundColor: '#fff',
                      top: '5px',
                      right: '5px',
                      borderRadius: '50%',
                      height: '1em',
                      width: '1em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />{' '}
                </span>
              </div>
            ))
          : null}
      </div>
      <PriceDiv>
        <p>Attach Price to Product</p>
        <input
          placeholder="0.00"
          value={postData.amount}
          onChange={(e) => {
            setPostData({
              ...postData,
              amount: e.target.defaultValue,
              // isPaid: true,
            });
          }}
        />
      </PriceDiv>
      <RemoveDiv className="mt-2">
        <p
          onClick={() => {
            setShowPreview(false);
            setPreview([]);
          }}
        >
          Remove Product
        </p>
      </RemoveDiv>
    </div>
  );
}
