import React from 'react';
import PropTypes from 'prop-types';
import arrowLeft from '../../../../../../assets/images/arrowLeft.png';
import './index.scss';

function PageCount(props) {
  const { currentPage, totalPage, stepBackward } = props;
  return (
    <div className="page-count-countainer">
      <div className="navigation-arrow">
        {currentPage > 1 && (
          <img src={arrowLeft} alt="arrow" onClick={stepBackward} />
        )}
      </div>
      <span>{`${currentPage}/${totalPage}`}</span>
    </div>
  );
}

PageCount.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  stepBackward: PropTypes.func.isRequired,
};

export default PageCount;
