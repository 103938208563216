import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function Toggle(props) {
  const {
    text,
    size = 'default',
    checked,
    disabled,
    onChange,
    offstyle = 'btn-danger',
    onstyle = 'btn-success active',
    position = 'front',
  } = props;

  const displayStyle = checked ? onstyle : offstyle;
  return (
    <>
      <label className="switch-container-1">
        {position === 'front' && (
          <span
            className={`switch-label mr-4 ${checked ? `switch-active` : ``}`}
          >
            {text}
          </span>
        )}
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              if (e.target.checked) {
                onChange(!checked);
              }
              onChange(!checked);
            }}
          />
          <span className={`switch ${displayStyle}`}>
            <span className="switch-handle" />
          </span>
        </span>
        {position === 'back' && (
          <span
            className={`switch-label ml-4 ${checked ? `switch-active` : ``}`}
          >
            {text}
          </span>
        )}
      </label>
    </>
  );
}

Toggle.defaultProps = {
  text: '',
  size: '',
  offstyle: 'btn-danger',
  onstyle: 'btn-success active',
  checked: false,
  disabled: false,
};

Toggle.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  offstyle: PropTypes.string,
  onstyle: PropTypes.string,
};

export default Toggle;
