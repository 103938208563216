/* eslint-disable no-unused-expressions */
import React, { useState, useContext } from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { GrCamera, GrEdit } from 'react-icons/gr';
import './imgGrid.scss';
import imgPlaceholder from '../../../../assets/image/imgPlaceholder.png';
import '../FundWallet/index.scss';
import { httpPost, customBaseUrl } from '../../../../action/http';
import Button from '../../Button';
import { WayagramContext } from '../../../../store/context/WayagramContext';
// import { ImagePreviewOnly } from './postUtils';
import 'cropperjs/dist/cropper.css';

const CreatePost = (props) => {
  const { showModal, hideModal, id, mode, selectedData } = props;
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  const {
    wayagramProfile: profile,
    reloadMoment,
    setReloadMoment,
  } = useContext(WayagramContext);
  const [preview, setPreview] = useState([]);
  const [cropper, setCropper] = useState(undefined);
  // const [cropData, setCropData] = useState('#');

  // const onChangeHandler = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   setPostData({ ...postData, file: files[0] });
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setPreview(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setPostData({ ...postData, file: files[0] });
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreview(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleRotate = (direction) => {
    if (direction === 'right') cropper.rotate(90);
    if (direction === 'left') cropper.rotate(-90);
  };

  const createWayagramMoment = async () => {
    setLoading(true);
    const newForm = new FormData();
    newForm.append('profile_id', profile.id);
    postData.file && newForm.append('file', postData.file);
    postData.content && newForm.append('content', postData.content);
    newForm.append('millisecCreatedAt', `${Date.now()}`);
    const res = await httpPost(
      `/all-posts/create-moment`,
      newForm,
      customBaseUrl.wayagramUrl
    );

    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setLoading(false);
        hideModal(false);
        setReloadMoment(!reloadMoment);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-left" toggle={() => hideModal(false)}>
        {mode === 'create' ? 'Create' : 'Edit'} Moments
      </ModalHeader>
      <ModalBody>
        <div className="mx-auto modal-body">
          <div className="image-div d-flex">
            <img src={profile?.avatar || imgPlaceholder} alt="placeholder" />
            <div className="">Whats on your mind ?</div>
          </div>
          <textarea
            type="text"
            className="form-control mb-3"
            placeholder="Type Your Moment"
            value={postData.description}
            onChange={(e) =>
              setPostData({ ...postData, content: e.target.value })
            }
          />
          {/* <div className="display-button">
            <button type="submit">Text</button>
            <button type="submit">Gallery</button>
          </div> */}
          {/* <ImagePreviewOnly preview={preview} /> */}
          <div
            onClick={() => {
              if (typeof cropper !== 'undefined') {
                getCropData();
              }
            }}
          >
            <Cropper
              src={preview}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              checkOrientation={false}
              zoomTo={0.5}
              initialAspectRatio={1}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides
            />
          </div>
          {preview.length ? (
            <div className="d-flex">
              <span className="text-light" onClick={() => handleRotate('left')}>
                left
              </span>
              <span
                className="ml-auto text-light"
                onClick={() => handleRotate('right')}
              >
                right
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mb-3 mt-2 modal-footer">
          <div
            className="add-cursor me-3"
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div className="galler-icon">
              <GrEdit />
            </div>
          </div>
          <div
            className="me-3 add-cursor "
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div className="galler-icon ">
              <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                id="single"
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <GrCamera />
            </div>
          </div>
        </div>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary ml-auto mb-3 mt-2"
          color="primary"
          content="Post"
          onClick={createWayagramMoment}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreatePost.defaultProps = {
  id: '',
  mode: 'create',
  selectedData: {},
};

CreatePost.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  mode: PropTypes.string,
  selectedData: PropTypes.node,
};

export default CreatePost;
