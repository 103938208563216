import React, { useContext, useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Cancel } from '@material-ui/icons';
// import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './create.scss';
// import { notification } from 'antd';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';

// const initial = {
//   address: '',
//   city: '',
//   dob: '',
//   email: '',
//   gender: 'MALE',
//   phone: '',
//   profileImage: '',
//   state: '',
// };
const cacInitial = {
  businessRegDate: '',
  businessType: '',
  cacRegNo: '',
  cacAccount: true,
  city: '',
  dob: '',
  gender: 'MALE',
  officeAddress: '',
  orgEmail: '',
  orgName: '',
  orgPhone: '',
  orgType: '',
  profileImage: '',
  state: '',
};
// const nonCacInitial = {
//   businessRegDate: '',
//   businessType: '',
//   cacAccount: false,
//   cacRegNo: '',
//   city: '',
//   dob: '',
//   gender: 'MALE',
//   officeAddress: '',
//   orgEmail: '',
//   orgName: '',
//   orgPhone: '',
//   orgType: '',
//   profileImage: '',
//   state: '',
// };
const CreateNewAccount = (props) => {
  const { showModal, hideModal, mode, fetchSubscription } = props;
  // const [category, setCategory] = useState('reg');
  const [data, setData] = useState(cacInitial);
  const [businesses, setBusiness] = useState([]);
  const userId = localStorage.getItem('userId');
  const category = 'reg';
  const { profile } = useContext(ProfileContext);
  // const [isReg, setIsReg] = useState(false);
  // const [isNonReg, setIsNonReg] = useState(false);

  // const handleCategory = (e) => {
  //   if (e === 'personal') {
  //     setIsNonReg(false);
  //     setIsReg(false);
  //     setIsPersonal(true);
  //   } else if (e === 'reg') {
  //     setIsNonReg(false);
  //     setIsReg(true);
  //     setIsPersonal(false);
  //   } else if (e === 'nonReg') {
  //     setIsNonReg(true);
  //     setIsReg(false);
  //     setIsPersonal(false);
  //   }
  // }
  const getBusiness = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=50`
    );
    if (res?.businessTypeList.length > 0) {
      console.log('iii', res?.businessTypeList);
      setBusiness(res?.businessTypeList);
    }
  };
  // const validateId = async () => {
  //   if (data?.cacRegNo?.length < 2) return;
  //   // showLoader();
  //   const res = await httpPost(
  //     `/no-auth/verifyCacRegNo2/CAC?regNo=${data?.cacRegNo}`,
  //     {},
  //     customBaseUrl.kycUrl
  //   );
  //   if (res?.status) {
  //     hideLoader();
  //     setData({
  //       ...data,
  //       officeAddress: res?.data?.address,
  //       orgName: res?.data?.name,
  //       orgPhone: res?.data?.localNumber,
  //       businessRegDate: moment(res?.data?.createdAt).format('YYYY-MM-DD'),
  //     });
  //     notification.success({
  //       message: 'successful',
  //       description: `${res?.message}`,
  //     });
  //   } else {
  //     hideLoader();
  //     swal(res?.message ?? 'record not found');
  //     notification.error({
  //       message: 'error',
  //       description: `${res?.message}`,
  //     });
  //     // setData({ ...data, cacRegNo: '' });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data);
    showLoader();
    let res;
    if (category === 'personal') {
      res = await httpPost(
        `/api/v1/profile/createPersonalAccount`,
        data,
        customBaseUrl.authUrl
      );
    } else {
      res = await httpPost(
        `/api/v1/profile/createBusinessAccount`,
        data,
        customBaseUrl.authUrl
      );
    }
    if (res?.status) {
      hideLoader();
      swal(res?.message);
      fetchSubscription(userId);
      hideModal(false);
    } else {
      hideLoader();
      swal(res?.message);
      console.log(res);
    }
  };
  useEffect(() => {
    getBusiness();
    setData({
      ...data,
      firstName: profile?.firstName,
      lastName: profile?.surname,
      phoneNumber: profile?.phoneNumber,
      phone: profile?.phoneNumber,
      email: profile?.email,
      orgPhone: profile?.phoneNumber,
      orgEmail: profile?.email,
    });
  }, [profile]);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalHeader>
        <div
          style={{
            display: 'flex',
            textAlign: 'end',
            justifyContent: 'end',
            margin: 0,
          }}
        >
          <Cancel onClick={() => hideModal(false)} />
        </div>
        <h5 className="modal-header-data">Add New Business Account</h5>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div
            id="qr-body"
            style={mode === 'profile' ? { marginBottom: '20px' } : {}}
          >
            {/* <div className="inputbox-with-one-input">
              <select
                onChange={(e) => {
                  const val = e.target.value;
                  setCategory(val);
                  setData(
                    val === 'personal'
                      ? initial
                      : val === 'reg'
                      ? cacInitial
                      : nonCacInitial
                  );
                }}
                className="form-select border"
              >
                <option value="personal">Personal</option>
                <option value="nonReg">Non Registered Business </option>
                <option value="reg">Registered Business </option>
              </select>
            </div> */}
            {category === 'personal' ? (
              <div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Email"
                    className=" border"
                    type="email"
                    disabled
                    value={data?.email}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Phone Number"
                    className="form-input border"
                    type="text"
                    disabled
                    value={data?.phone}
                    onChange={(e) => {
                      setData({ ...data, phone: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Date of Birth"
                    type="date"
                    value={data?.dob}
                    onChange={(e) => {
                      setData({ ...data, dob: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <select
                    value={data?.gender}
                    onChange={(e) => {
                      setData({ ...data, gender: e.target.value });
                    }}
                    className="form-select border"
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="State"
                    className="form-input border"
                    type="text"
                    value={data?.state}
                    onChange={(e) => {
                      setData({ ...data, state: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="City"
                    className="form-input border"
                    type="text"
                    value={data?.city}
                    onChange={(e) => {
                      setData({ ...data, city: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Address"
                    className="form-input border"
                    type="text"
                    value={data?.address}
                    onChange={(e) => {
                      setData({ ...data, address: e.target.value });
                    }}
                  />
                </div>
              </div>
            ) : category === 'reg' ? (
              <div>
                <div className="inputbox-with-one-input border">
                  <select
                    value={data?.businessType}
                    onChange={(e) => {
                      setData({
                        ...data,
                        businessType: e.target.value,
                      });
                    }}
                    className="form-select border"
                  >
                    <option value="">Select Business Type</option>
                    <option value="RC">RC </option>
                    <option value="BN">BN </option>
                    <option value="IT">IT </option>
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="CAC Registration No."
                    type="text"
                    value={data?.cacRegNo}
                    onChange={(e) => {
                      setData({ ...data, cacRegNo: e.target.value });
                    }}
                    // onPointerOut={validateId}
                    className="form-control"
                    required
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Business Name"
                    className=" border"
                    type="text"
                    // disabled
                    value={data?.orgName}
                    onChange={(e) => {
                      setData({ ...data, orgName: e.target.value });
                    }}
                  />
                </div>
                {/* <div className="inputbox-with-one-input">
                  <label>Business Reg. Date</label>
                  <input
                    type="date"
                    value={data?.businessRegDate}
                    onChange={(e) => {
                      setData({ ...data, businessRegDate: e.target.value });
                    }}
                  />
                </div> */}
                <div className="inputbox-with-one-input border">
                  <select
                    value={data?.category}
                    onChange={(e) => {
                      setData({
                        ...data,
                        orgType: e.target.value,
                      });
                    }}
                    className="form-select border"
                  >
                    <option value="">Select Organization Type</option>
                    {businesses?.map((e) => (
                      <option key={e} value={e?.businessType}>
                        {e.businessType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Org. Email"
                    className=" border"
                    type="email"
                    value={data?.orgEmail}
                    disabled
                    onChange={(e) => {
                      setData({ ...data, orgEmail: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Phone Number"
                    className="form-input border"
                    type="text"
                    disabled
                    value={data?.orgPhone}
                    onChange={(e) => {
                      setData({ ...data, orgPhone: e.target.value });
                    }}
                  />
                </div>
                {/* <div className="inputbox-with-one-input">
                  <label>Date of birth</label>
                  <input
                    className="border"
                    placeholder="Date of Birth"
                    type="date"
                    value={data?.dob}
                    onChange={(e) => {
                      setData({ ...data, dob: e.target.value });
                    }}
                  />
                </div> */}
                {/* <div className="inputbox-with-one-input border">
                  <select
                    value={data?.gender}
                    onChange={(e) => {
                      setData({ ...data, gender: e.target.value });
                    }}
                    className="form-select border"
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="State"
                    className="form-input border"
                    type="text"
                    value={data?.state}
                    onChange={(e) => {
                      setData({ ...data, state: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="City"
                    className="form-input border"
                    type="text"
                    value={data?.city}
                    onChange={(e) => {
                      setData({ ...data, city: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Address"
                    className="form-input border"
                    type="text"
                    value={data?.officeAddress}
                    onChange={(e) => {
                      setData({ ...data, officeAddress: e.target.value });
                    }}
                  />
                </div> */}
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="First Name"
                    className="form-input border"
                    type="text"
                    disabled
                    value={data?.firstName ?? profile?.firstName}
                    onChange={(e) => {
                      setData({ ...data, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Last Name"
                    className="form-input border"
                    type="text"
                    disabled
                    value={data?.lastName ?? profile?.lastName}
                    onChange={(e) => {
                      setData({ ...data, lastName: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Referall Code"
                    className="form-input border"
                    type="text"
                    value={data?.referralCode ?? ''}
                    onChange={(e) => {
                      setData({ ...data, referralCode: e.target.value });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder=" Business Name"
                    className=" border"
                    type="text"
                    value={data?.orgName}
                    onChange={(e) => {
                      setData({ ...data, orgName: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input">
                  <label>Business Reg. Date</label>
                  <input
                    type="date"
                    value={data?.businessRegDate}
                    onChange={(e) => {
                      setData({ ...data, businessRegDate: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <select
                    value={data?.category}
                    onChange={(e) => {
                      setData({
                        ...data,
                        businessType: e.target.value,
                        orgType: e.target.value,
                      });
                    }}
                    className="form-select border"
                  >
                    <option value="">Select Business Type</option>
                    {businesses?.map((e) => (
                      <option key={e} value={e?.businessType}>
                        {e.businessType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Business Email"
                    className=" border"
                    type="email"
                    value={data?.orgEmail}
                    onChange={(e) => {
                      setData({ ...data, orgEmail: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Phone Number"
                    className="form-input border"
                    type="text"
                    value={data?.orgPhone}
                    onChange={(e) => {
                      setData({ ...data, orgPhone: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input">
                  <label>Date of birth</label>
                  <input
                    className="border"
                    placeholder="Date of Birth"
                    type="date"
                    value={data?.dob}
                    onChange={(e) => {
                      setData({ ...data, dob: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <select
                    value={data?.gender}
                    onChange={(e) => {
                      setData({ ...data, gender: e.target.value });
                    }}
                    className="form-select border"
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="State"
                    className="form-input border"
                    type="text"
                    value={data?.state}
                    onChange={(e) => {
                      setData({ ...data, state: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="City"
                    className="form-input border"
                    type="text"
                    value={data?.city}
                    onChange={(e) => {
                      setData({ ...data, city: e.target.value });
                    }}
                  />
                </div>
                <div className="inputbox-with-one-input border">
                  <input
                    placeholder="Address"
                    className="form-input border"
                    type="text"
                    value={data?.officeAddress}
                    onChange={(e) => {
                      setData({ ...data, officeAddress: e.target.value });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-primary" type="submit">
              Create
            </button>
          </div>
        </form>
        {/* <ModalFooter>
          <Button color="secondary" onClick={() => hideModal(false)}>
            Ok
          </Button>
        </ModalFooter> */}
      </ModalBody>
    </Modal>
  );
};

export default CreateNewAccount;
