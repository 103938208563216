import React from 'react';
import styles from './styles.module.css';

export const Row = ({
  align = 'center',
  justify = 'center',
  children,
  className,
  width,
  onclick,
  style,
}) => {
  return (
    <div
      onClick={onclick}
      style={{
        justifyContent: justify,
        alignItems: align,
        width,
        ...style,
      }}
      className={`${styles.row} ${className}`}
    >
      {children}
    </div>
  );
};

export const Col = ({
  align = 'center',
  children,
  justify = 'center',
  className,
  width,
  onclick,
  style,
}) => {
  return (
    <div
      onClick={onclick}
      style={{
        justifyContent: justify,
        alignItems: align,
        width,
        ...style,
      }}
      className={`${styles.col} ${className}`}
    >
      {children}
    </div>
  );
};
