import React from 'react';
import OtpInput from 'react-otp-input';
// import PropTypes from 'prop-types';

import './makeDonation.scss';

function PinInput(props) {
  const { values, setOtp, otp, separator, defaultWallet } = props;

  return (
    <div className="pin-input-container">
      <div className="pin-input-header">Input your 4 digit PIN</div>
      <div className="pin-input-description">
        Please input your 4digit PIN to transfer fund
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Receipient</div>
        <div className="transaction-detail-right">NGO</div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Amount</div>
        <div className="transaction-detail-right">{values?.amount}</div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Beneficiary Bank:</div>
        <div className="transaction-detail-right">######</div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">From</div>
        <div className="transaction-detail-right">
          {defaultWallet?.accountNo}
        </div>
      </div>
      <div className="transaction-detail mt-4">
        <div className="pin-input-description">Transaction fee:</div>
        <div className="transaction-detail-right">#####</div>
      </div>
      <OtpInput
        value={otp}
        onChange={(e) => setOtp(e)}
        separator={separator}
        containerStyle={{ justifyContent: 'center' }}
        inputStyle="otp-input otp-bottom"
        isInputSecure
        isInputNum
      />
    </div>
  );
}

export default PinInput;
