import React, { useEffect, useState, useRef } from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';
//  import { useReactToPrint } from 'react-to-print';
import { customBaseUrl, httpGetPdf } from '../../../action/http';
import ButtonComponent from '../../shared/Button';
import PageToPrint from './AccountPdf';
import statementPDF from './generatePDFStatement';
import moneyFomatter from '../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../utils/loader';

const AccountStatement = ({ bankList }) => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [disabled, setDisables] = useState();
  const [loading] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [data] = useState([]);
  const [csv, setCsv] = useState(false);
  const [pdfBtn] = useState(false);
  // const [print] = useState(false);
  const pageToPrintRef = useRef(false);

  const split = (date) => {
    const d = date.split('T');
    return d[0];
  };
  useEffect(() => {
    if (bankList.length > 0) {
      setAccountNumber(bankList[0].accountNo);
    }
  }, [bankList]);
  const handleFetchStatement = async () => {
    showLoader();
    const res = await httpGetPdf(
      `${
        customBaseUrl.temporalservice
      }/api/v1/wallet/transaction/export/pdf/${accountNumber}?fromdate=${split(
        new Date(start).toISOString()
      )}&todate=${split(new Date(end).toISOString())}`
    );
    const file = new Blob([res]);
    const fileURL = URL.createObjectURL(file);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `Statement_of_Account.pdf`;
    alink.click();
    hideLoader();
  };

  useEffect(() => {
    if (start === '' || end === '') {
      setDisables(true);
    } else {
      setDisables(false);
    }
  }, [start, end]);
  //  console.log('start', data);

  const exportHeader = [
    { name: 'AccountNo', key: 'accountNo' },
    { name: 'Transaction Type', key: 'debitCredit' },
    { name: 'Receivers Email', key: 'receiverEmail' },
    { name: 'tranId', key: 'tranId' },
    { name: 'Reason', key: 'tranNarration' },
    { name: 'Transaction Date', key: 'transactionDate' },
    { name: 'Transaction Time', key: 'transactionTime' },
  ];

  return (
    <div className="account-statement">
      <div className="as-head">
        <div>Account Statment</div>
        <div className="temp">Choose a timeline for your statment</div>
      </div>
      <div className="as-body">
        <div>
          <div>Start Date</div>
          <input
            value={start}
            onChange={(e) => {
              setStart(e.target.value);
              setCsv(false);
            }}
            type="date"
            placeholder="Select a date to begin"
          />
        </div>
        <div>
          <div>End Date</div>
          <input
            value={end}
            onChange={(e) => {
              setEnd(e.target.value);
              setCsv(false);
            }}
            type="date"
            placeholder="Select a date to end"
          />
        </div>
        <div>
          <div>Select Account Number</div>
          <select
            name=""
            onChange={(e) => {
              setAccountNumber(e.target.value);
              setCsv(false);
            }}
            id=""
            className="border"
          >
            {bankList.length > 0 ? (
              bankList.map((item) => (
                <option key={item.accountNo} value={item.accountNo}>
                  {item.accountNo} - {item?.description} - NGN
                  {moneyFomatter(item?.clr_bal_amt)}
                </option>
              ))
            ) : (
              <option value="0">No Account Yet</option>
            )}
          </select>
        </div>
        {/* <div>
          <div>Select File</div>
          <select
            name=""
            id=""
            className="border"
            onChange={(e) => setFileType(e.target.value)}
          >
            <option value="csv">CSV</option>
            <option value="pdf">PDF</option>
          </select>
          </div> */}
      </div>
      <div className="as-button">
        <ButtonComponent
          type="button"
          loading={loading}
          disabled={(loading ? true : null) || disabled}
          onClick={handleFetchStatement}
          style={{ opacity: disabled ? 0.5 : 1 }}
          content="Generate Statment of Account"
        >
          Generate Statment of Account
        </ButtonComponent>
        {pdfBtn && (
          <ButtonComponent
            type="button"
            loading={loading}
            disabled={(loading ? true : null) || disabled}
            onClick={() =>
              statementPDF(data, {
                todate: end,
                fromdate: start,
                account: accountNumber,
              })
            }
            style={{ opacity: disabled ? 0.5 : 1 }}
            content="Print to pdf"
          >
            Print to pdf
          </ButtonComponent>
        )}
        {csv && (
          <ExportJsonCsv
            style={{
              border: 'none',
              background: 'none',
              display: 'flex',
              justtifyContent: 'center',
            }}
            headers={exportHeader}
            items={data}
          >
            <div className="btn_Export">Download Csv</div>
          </ExportJsonCsv>
        )}
      </div>
      <div style={{ position: 'absolute', zIndex: -50, opacity: 0 }}>
        <PageToPrint ref={pageToPrintRef} data={data} />
      </div>
    </div>
  );
};

export default AccountStatement;
