import React from 'react';
import PropTypes from 'prop-types';

import './eventModal.scss';

const EventTicket = (props) => {
  const { handleFormInput, values } = props;

  return (
    <div className="event-inner-content">
      <div className="input-container mt-3">
        <select
          name="isPaid"
          onChange={(e) => {
            handleFormInput(e.target.value, 'isPaid');
          }}
          defaultValue={values.isPaid}
        >
          <option value="false">Free to attend</option>
          <option value="true">Paid to attend</option>
        </select>
        <span>Free to attend or paid event?</span>
      </div>
      {values.isPaid !== 'false' && (
        <div className="input-container">
          <input
            type="number"
            name="amount"
            values={values.amount}
            onChange={(e) => {
              handleFormInput(e.target.value, 'amount');
            }}
            placeholder="Ticket Fee"
          />
          <span>Specify event fee</span>
        </div>
      )}
    </div>
  );
};

EventTicket.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventTicket;
