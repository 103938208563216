import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalFooter } from 'reactstrap';
import '../FundWallet/index.scss';

const CommingSoonModal = (props) => {
  const { showModal, hideModal } = props;

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} centered>
      <ModalFooter className="p-0" style={{ border: '1px solid  #F2F2F2' }}>
        <div className="cs-wrapper">
          <div className="content">
            <div className="cs-text">
              <div className="cst-1">COMING SOON</div>
              <div className="cst-2">This feature is not available yet.</div>
              <div className="cst-3">STAY TUNED</div>
              <div className="cst-4" onClick={() => hideModal(false)}>
                Close
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

CommingSoonModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default CommingSoonModal;
