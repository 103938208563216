import { UpCircleOutlined } from '@ant-design/icons';
import { Add, Announcement, LiveHelp, Delete } from '@material-ui/icons';
import {
  Tabs,
  Card,
  Avatar,
  Upload,
  Button,
  Form,
  Input,
  Select,
  Table,
  Checkbox,
  Divider,
  Tooltip,
  DatePicker,
  Modal,
  Radio,
} from 'antd';
import React, { useState } from 'react';
import Layout from '../../shared/Layout';

export default function WebPosSetting(props) {
  const [modal, setModal] = useState(false);
  const SelectBefore = () => (
    <Select defaultValue="ng" className="border-0 rounded-0">
      <Select.Option value="ng">+234</Select.Option>
      <Select.Option value="us">+001</Select.Option>
    </Select>
  );
  const showModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const dataSource = [
    {
      key: '1',
      bankName: 'United Bank of Africa (UBA)',
      accountNo: 62873427532,
      accountName: 'Mrs Alashe',
      currency: 'NGN',
      default: true,
    },
    {
      key: '2',
      bankName: 'Zenith Bank PLC',
      accountNo: 62873427542,
      accountName: 'Mrs Alashe',
      currency: 'NGN',
      default: false,
    },
  ];
  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNo',
      key: 'accountNo',
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: () => (
        <button type="button" className="btn btn-primary">
          <Add /> Add new bank
        </button>
      ),
      dataIndex: 'default',
      key: 'default',
      render: (e) => <p>{e ? 'Default Account' : ''}</p>,
    },
    {
      dataIndex: 'action',
      key: 'currency',
      render: () => (
        <button type="button" className="shadow btn btn-light">
          Edit
        </button>
      ),
    },
  ];

  //   kyc tab
  const KYCTab = () => (
    <div className="px-lg-5 py-3 shadow">
      <Card className="rounded bg-light shadow-sm">
        <div className="d-flex">
          <h5 className="mx-3">
            {' '}
            <br /> Business Information
          </h5>
          <div className="">
            <div className="d-flex">
              <div className="mx-2">
                KYC Status <br />
                <a href="#damn" className="btn btn-sm btn-outline-success">
                  APPROVED
                </a>
              </div>
              <div className="mx-2">
                Type of Registration <br />
                <a href="#damn" className="btn btn-sm btn-outline-info">
                  NON REGISTERED BUSINESS
                </a>
                <br />
                <p className="fs-01 text-break lh-sm">
                  Lifetime collection limit for this business type is <br />
                  N2,000,000, kindly upgrade your account to enjoy <br />
                  unlimited collection
                </p>
              </div>
              <div className="mx-2">
                <br />
                <a href="#damn" className="btn btn-sm btn-outline-success">
                  <UpCircleOutlined /> Upgrade Account
                </a>
              </div>
              <div className="mx-2">
                <br />
                <a href="#damn" className="btn btn-sm btn-primary">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="d-flex">
            <Avatar shape="circle" size={100}>
              RD
            </Avatar>
            <div className="pt-3 ps-2">
              <span>Upload business logo</span> <br />
              <Upload>
                <Button className="shadow-sm">Upload Image</Button>
              </Upload>
            </div>
          </div>
          <div className="w-100">
            <Form className="row">
              <div className="col-md-6 px-4">
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Rich Creative Design"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business Phone Number</p>
                  {/* <SelectBefore /> */}
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    addonBefore={<SelectBefore />}
                    placeholder="+2348163349199"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">General Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Support Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Dispute Email</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <Form.Item className="my-4">
                  <Checkbox className="fw-bold">Use general email</Checkbox>
                </Form.Item>
                <h5 className="mt-5 fw-bold">Office Address</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Country</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">State</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Lagos"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Local Government Area</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="kosofe"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">City</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Ojota"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Street Address</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="99, sowonlu street"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Home Address</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Country</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">State</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Lagos"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Local Government Area</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="kosofe"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">City</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Ojota"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Street Address</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="99, sowonlu street"
                  />
                </Form.Item>
              </div>
              <div className="col-md-6 px-4">
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Type of Industry</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Fashion"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Type of Business</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Non REgistered Business"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Business description</p>
                  <Input.TextArea
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="about business"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Bank Details</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Source</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="United Bank of Africa (UBA)"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Number</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="20735724723"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Account Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Richard Daniel"
                  />
                </Form.Item>
                <h5 className="mt-5 fw-bold">Owner</h5>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Full Name</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Richard Daniel"
                  />
                </Form.Item>
                <div className="d-flex">
                  <div className="d-flex">
                    <Form.Item className="pt-2">
                      <span className="fw-bold">Date of birth</span>
                      <Input placeholder="10" />
                    </Form.Item>
                    <Form.Item className="pt-4-5">
                      <Input placeholder="05" />
                    </Form.Item>
                    <Form.Item className="pt-4-5">
                      <Input placeholder="1995" />
                    </Form.Item>
                  </div>
                  <Form.Item className="pt-2">
                    <span className="fw-bold">Gender:</span>
                    <Select defaultValue="male">
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Nationality</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="Nigeria"
                  />
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Means of Identification</p>
                  <Select defaultValue="nin">
                    <Select.Option value="nin">National Identity</Select.Option>
                    <Select.Option value="other">other</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item className="my-3">
                  <p className="m-0 fw-bold-sm">Identity Number</p>
                  <Input
                    size="large"
                    className="w-100 rounded-0"
                    placeholder="84767659839786"
                  />
                </Form.Item>
                <h6 className="fw-bold mt-4">How you wish to accept payment</h6>
                <div>
                  <Form.Item className="my-3">
                    <Checkbox className="fw-bold">Without Website/App</Checkbox>
                  </Form.Item>
                  <ul>
                    <li>
                      Receive payment from your customers in under 5 minutes
                      using Wayapaychat Payment Links.
                    </li>
                    <li>
                      You can submit your website/app anytime later if you wish
                      to use it to accept payments
                    </li>
                  </ul>
                  <Form.Item>
                    <Checkbox>With Website/App</Checkbox>
                  </Form.Item>
                  <div className="px-md-4">
                    <Form.Item className="m-0">
                      <Radio checked size="large" />
                      Accept Payment On Website
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Input placeholder="Enter URL" />
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Radio checked size="large" />
                      Accept Payment On App
                    </Form.Item>
                    <Form.Item className="m-0">
                      <Input placeholder="Enter App Link" />
                    </Form.Item>
                  </div>
                  <div className="my-2">
                    <p>
                      We need to verify your website/app to provide you the live
                      API Keys it should contain:
                    </p>
                    <ul>
                      <li className="d-inline px-2">About Us</li>
                      <li className="d-inline px-2">Contact Us</li>
                      <li className="d-inline px-2">Pricing</li>
                      <li className="d-inline px-2">Privacy Policy</li>
                      <li className="d-inline px-2">Terms & Conditions</li>
                      <li className="d-inline px-2">
                        Cancellation/Refund Policy
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );

  // settlement tab
  const SettlementTab = () => (
    <div className="py-3">
      <Table
        className="bg-transparent"
        rowSelection
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );

  // payment Pref tab
  const PaymentTab = () => (
    <div className="py-3 px-2 w-100">
      <Form className="w-100 px-lg-5 py-3 rounded shadow-sm">
        <div className="row">
          <div className="col-6">
            <h6 className="border-orange pb-2">Preferences</h6>
          </div>
          <div className="col-6">
            <button className="float-end btn btn-primary" type="button">
              Save Changes
            </button>
          </div>
        </div>
        <div className="row px-lg-5 py-4">
          <div className="col-6 my-2">
            <h6>Transaction Receipts</h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Checkbox checked>Email me every transaction</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox checked>Email customers for every transaction</Checkbox>
            </Form.Item>
          </div>
          <div className="col-6 my-2">
            <h6>Default Currency</h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Select defaultValue="ng" className="w-25 rounded-0 border-0">
                <Select.Option value="ng">NGN</Select.Option>
                <Select.Option value="us">USD</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-6 my-2">
            <h6>Preferred Payment Methods</h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Checkbox checked>Enable Card Payments</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox checked>Enable USSD Payments</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox checked>Enable QR Payments</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox checked>Enable Wallet Payments</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox checked>Enable Bank Payments</Checkbox>
            </Form.Item>
          </div>
        </div>
        <Divider type="horizontal" className="m-0 bg-secondary" />
        <div className="row px-lg-5 py-4">
          <div className="col-6 my-2">
            <h6>
              Settlement Schedule{' '}
              <Tooltip
                className="rounded-6"
                placement="bottom"
                color="#FFF"
                title={
                  <>
                    <h6>WHAT DOES THIS MEAN?</h6>
                    <span className="text-secondary fs-05">
                      This means you can place your settlement on hold and be
                      settled in bulk.
                    </span>
                  </>
                }
              >
                <LiveHelp className="fs-05 text-secondary" />
              </Tooltip>
            </h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Select defaultValue="1" className="w-75 rounded-0 border-0">
                <Select.Option value="1">
                  Select when to be settled
                </Select.Option>
                <Select.Option value="2">monthly</Select.Option>
              </Select>
            </Form.Item>
            <div className="d-flex py-2">
              <Form.Item className="m-0">
                <Checkbox>Place settlement on hold until</Checkbox>
              </Form.Item>
              <Form.Item className="m-0">
                <DatePicker placeholder="dd/mm/yyyy" />
              </Form.Item>
            </div>
          </div>
          <div className="col-6 my-2">
            <h6>Preferred Earning Method</h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Checkbox>Settle to bank account</Checkbox>
              <Tooltip
                className="rounded-6"
                placement="bottom"
                color="#FFF"
                title={
                  <>
                    <h6>WHAT DOES THIS MEAN?</h6>
                    <span className="text-secondary fs-05">
                      This means your earnings will be transferred to the bank
                      account you added as default on the settlement account
                      page
                    </span>
                  </>
                }
              >
                <LiveHelp className="fs-05 text-secondary" />
              </Tooltip>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox>Settle to wallet</Checkbox>
              <Tooltip
                className="rounded-6"
                placement="bottom"
                color="#FFF"
                title={
                  <>
                    <h6>WHAT DOES THIS MEAN?</h6>
                    <span className="text-secondary fs-05">
                      This means your earnings will be settled into your
                      Wayapaychat Wallet Account. This change will take effect
                      after 24 hours.
                    </span>
                  </>
                }
              >
                <LiveHelp className="fs-05 text-secondary" />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
        <Divider type="horizontal" className="m-0 bg-secondary" />
        <div className="row px-lg-5 py-4">
          <div className="col-6 my-2">
            <h6>
              Subscription Emails{' '}
              <Tooltip
                className="rounded-6"
                placement="bottom"
                color="#FFF"
                title={
                  <>
                    <h6>WHAT DOES THIS MEAN?</h6>
                    <span className="text-secondary fs-05">
                      This means you can place your settlement on hold and be
                      settled in bulk.
                    </span>
                  </>
                }
              >
                <LiveHelp className="fs-05 text-secondary" />
              </Tooltip>
            </h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Checkbox>Allow customers to cancel subscriptions.</Checkbox>
            </Form.Item>
          </div>
          <div className="col-6 my-2">
            <h6>Send notification emails to other users</h6>
          </div>
          <div className="col-6 my-2">
            <Form.Item className="m-0">
              <Checkbox>Send to the business email address only</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox>Send to all team member</Checkbox>
            </Form.Item>
            <Form.Item className="m-0">
              <Checkbox>Send to specific users only</Checkbox>
            </Form.Item>
            <Form.Item>
              <Select defaultValue="1" className="w-50 border-0 rounded-0" />
            </Form.Item>
            <Button className="btn btn-primary btn-sm">Add</Button>
          </div>
        </div>
      </Form>
      <div className="text-center my-4">
        <a href="#reset" className="text-secondary text-decoration-none">
          <Delete /> Clear test data
        </a>
        <br />
        <a href="#reset" className="text-secondary text-decoration-none">
          <Delete /> Close this account
        </a>
      </div>
    </div>
  );

  // API Keys Tab
  const ApiKeyTab = () => (
    <div className="px-lg-5 py-3">
      <Card className="w-100 rounded shadow-sm p-0 m-0">
        <div className="row">
          <div className="col-6">
            <span className="fs-05 fw-bold border-orange pb-2">
              API Configuration -
            </span>
            <span className="fs-05 fw-bold text-orange">Test Mode</span>
          </div>
          <div className="col-6">
            <button className="float-end btn btn-primary" type="button">
              Save Changes
            </button>
          </div>
        </div>
      </Card>
      <div className="py-3 shadow-sm bg-orange-lite text-center">
        <span className="">
          <Announcement /> These keys are for testing only. Please DO NOT use
          them in production.
        </span>
      </div>
      <Card className="w-100 rounded shadow-sm px-5 py-3">
        <Form>
          <Form.Item label={<h6 className="py-2">API Test Secret Key</h6>}>
            <div className="d-flex border ps-5 py-1 rounded">
              <Input
                className="border-0 w-75"
                value="sk_test_42f291215f89cefba7c0d39e920cb86becdc2d5b"
              />
              <button className="btn btn-white shadow" type="button">
                Copy key
              </button>
            </div>
          </Form.Item>
          <Form.Item label={<h6 className="py-2">API Test Public Key</h6>}>
            <div className="d-flex border ps-5 py-1 rounded">
              <Input
                className="border-0 w-75"
                value="sk_test_42f291215f89cefba7c0d39e920cb86becdc2d5b"
              />
              <button className="btn btn-white shadow" type="button">
                Copy key
              </button>
            </div>
          </Form.Item>
          <div className="text-center my-5">
            <button
              onClick={showModal}
              className="shadow btn btn-white text-orange"
              type="button"
            >
              Generate new secret keys <LiveHelp className="text-secondary" />
            </button>
          </div>
          <Form.Item label={<h6>Test Callback URL</h6>}>
            <Input
              size="large"
              className="rounded"
              placeholder="Enter Test Callback URL"
            />
          </Form.Item>
          <Form.Item label={<h6>Test Webhook URL</h6>}>
            <Input
              size="large"
              className="rounded"
              placeholder="Enter Test Webhook URL"
            />
          </Form.Item>
        </Form>
      </Card>
      {modal ? (
        <Modal
          title={<h6 className="text-center">Generate New Keys</h6>}
          visible={modal}
          onCancel={closeModal}
          footer={false}
          closable={false}
          centered
        >
          <div className="">
            <div className="text-center text-orange py-3">
              <Announcement />
              <p className="fs-05">
                Are you sure you want to generate new API keys? If you have
                integrated with the current keys, you would be required to
                change to the newly generated keys.
              </p>
            </div>
            <Form>
              <Form.Item className="my-4">
                <h6>How soon should your old keys expire?</h6>
                <Select
                  // defaultValue="1"
                  className="rounded-0 border-0"
                  size="large"
                  placeholder="Select your current API keys expiry duration"
                >
                  <Select.Option value="1">
                    Select your current API keys expiry duration
                  </Select.Option>
                  <Select.Option value="2">+001</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item className="my-4">
                <h6>How soon should your old keys expire?</h6>
                <Input size="large" placeholder="Enter Password" />
              </Form.Item>
              <div className="d-flex mt-5">
                <Button
                  size="large"
                  type="button"
                  className="mx-2 btn btn-white shadow"
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="button"
                  className="mx-2 btn btn-primary"
                >
                  Yes, Generate new API keys
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
  return (
    <Layout
      route={props}
      currentPage="settings"
      pageName="Web Pos KYC Settings"
      goBack
    >
      <div className="px-2">
        <Tabs defaultActiveKey="1" className="w-100">
          <Tabs.TabPane tab={<span>KYC</span>} key="1">
            <KYCTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Settlement Account</span>} key="2">
            <SettlementTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>Payment Preferences</span>} key="3">
            <PaymentTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span>API Keys & Webhooks</span>} key="4">
            <ApiKeyTab />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Layout>
  );
}
