import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
// import dashboardIcon from '../../../assets/image/Dashboard Icon.svg';
import wallet from '../../../assets/image/wallet Icon.svg';
// import wayagramIcon from '../../../assets/image/Wayagram Icon.svg';
// import chatAndCallIcon from '../../../assets/image/Chat Icon.svg';
// import ContactIcon from '../../../assets/image/contact.svg';

import notificationsIcon from '../../../assets/image/Notifications Icon.svg';
import supportIcon from '../../../assets/image/Support Icon.svg';
import signoutIcon from '../../../assets/image/signout.png';
//  import log from '../../../assets/log.svg';
// import logo from '../../../assets/images/wayaBankLogo1.png';
import logo from '../../../assets/newlog.svg';
import {
  LaptopSidebar,
  createPostStyle,
  logoutStyle,
  siderbarBottomStyle,
} from './style';
import PostModal from '../Modals/Post';
import RetrieveModal from '../Modals/Retrieve';
import { SortSidebarIcons } from '../Icons';
import { LayoutContext } from '../../../store/context/LayoutContext';
import envConfig from '../../../utils/envConfig';

export default function Sidebar(props) {
  const location = useLocation();
  const isCooperateUser = localStorage.getItem('corporate');
  const { pathname } = location;
  const { currentPage } = props;
  const [showPostModal, setShowPostModal] = useState(false);
  const [showRetrieveModal, setShowRetrieveModal] = useState(false);
  const { toggleMobileSidebar } = useContext(LayoutContext);
  const [selected, setSelected] = useState(pathname);
  const [showDropdownType, setShowDropdownType] = useState(currentPage);
  const [showDropdown, setShowDropdown] = useState(true);
  const sideBarMenuItems = [
    {
      id: 15,
      text: 'Payment',
      activeText: 'wallet',
      img: wallet,
      type: 'menu',
    },
    {
      id: 6,
      text: 'Transactions',
      activeText: 'transactions',
      type: 'menu',
    },
    {
      id: 24,
      text: 'Card',
      activeText: 'cards',
      type: 'menu',
    },
    /* {
      id: 11,
      img: supportIcon,
      text: 'Commission',
      activeText: 'commission',
      type: 'menu',
    }, */
    {
      id: 4,
      // img: ContactIcon,
      text: 'Contacts',
      activeText: 'contacts',
      type: 'menu',
    },
    /*  {
      id: 7,
      img: log,
      text: 'Dispute',
      activeText: 'dispute',
      type: 'menu',
    }, */
    {
      id: 8,
      img: notificationsIcon,
      text: 'Notifications',
      activeText: 'notifications',
      type: 'menu',
    },

    {
      id: 10,
      img: supportIcon,
      text: 'Settings',
      activeText: 'settings',
      type: 'menu',
    },
    {
      id: 11,
      img: wallet,
      text: 'Loan Request',
      tip: `Need funds for that unexpected expense?
        \n Our Loan Request feature offers a seamless and convenient way forcustomers to apply for personal and business loans, including proof of funds directly through the bank app. With just a few taps, you can explore various loan options, and submit applications for quick approval. The feature includes real-time status updates, document upload capabilities, and personalized loan offers based on individual financial profiles.
        \n Enjoy a hassle-free loan application process designed to meet your financial needs swiftly and securely.`,
      activeText: 'loan-request',
      type: 'menu',
    },
    // {
    //   id: 11,
    //   img: supportIcon,
    //   text: 'Support',
    //   activeText: 'support',
    //   type: 'menu',
    // },
    {
      id: 12,
      img: supportIcon,
      text: 'Activity Log',
      activeText: 'activity-log',
      type: 'menu',
    },
    {
      id: 13,
      img: signoutIcon,
      text: 'Create',
      activeText: 'create',
      type: 'menu',
    },
    {
      id: 213,
      text: 'Web Collection/ API Site [WAYAQUICK]',
      activeText: 'wayaquick',
      type: 'menu',
    },
    {
      id: 214,
      text: 'Merchant/Agent POS Site [WAYAPOS]',
      activeText: 'wayapos',
      type: 'menu',
    },
    {
      id: 215,
      text: 'Social & Marketplace Site [WAYAGRAM]',
      activeText: 'wayagram',
      type: 'menu',
    },
    // {
    //   id: 14,
    //   img: signoutIcon,
    //   text: 'Wayapay',
    //   activeText: 'wayaSidebarIcon',
    //   type: 'menu',
    // },
    // {
    //   id: 15,
    //   img: signoutIcon,
    //   text: 'Wayagram',
    //   activeText: 'gramSidebarIcon',
    //   type: 'menu',
    // },
    {
      id: 16,
      img: signoutIcon,
      text: 'Logout',
      activeText: 'logout',
      type: 'menu',
    },
  ];
  const redirect = (page, type) => {
    console.log('type1', page);
    if (type === 'menu') {
      setShowDropdownType(page);
      setShowDropdown(!showDropdown);
    }
    if (type === 'submenu') {
      setSelected(page);
    }
    if (page === 'create') {
      setShowPostModal(true);
    }
    if (page === 'retrieve_payment') {
      setShowRetrieveModal(true);
    }
    if (page === 'wayagram') {
      window.location.href = 'https://app.wayagram.ng/wayagram';
    }
    if (page === 'wayapos') {
      window.location.href = 'https://app.wayapos.ng/dashboard';
    }
    if (page === 'wayaquick') {
      window.location.href = 'https://app.wayaquick.com/overview';
    }
    if (page === 'logout') {
      localStorage.clear();
      window.location.href = envConfig.login;
    }
  };

  return (
    <LaptopSidebar toggle={toggleMobileSidebar}>
      <div className="sidebar-wrap">
        <Scrollbars autoHide>
          <div className="sidebar" style={{ background: 'white' }}>
            <div style={{ width: '100%' }} className="navbar-logo">
              <img src={logo} style={{ width: '60%', height: 80 }} alt="" />
            </div>
            <ul className="sidebar-menu-items">
              {sideBarMenuItems.map((item) => {
                const { id, activeText, type, more, text, img, tip } = item;
                if (text === 'Products' && isCooperateUser === 'false') {
                  return null;
                }
                if (text === 'Wallet' && isCooperateUser === 'true') {
                  return null;
                }
                return (
                  <li key={id}>
                    <div
                      className={
                        currentPage !== 'wayagram' && activeText === 'create'
                          ? 'd-none'
                          : activeText === 'logout'
                          ? 'list'
                          : activeText === currentPage
                          ? 'sidebar-menu-li-active list'
                          : 'sidebar-menu-li list'
                      }
                    >
                      <Link
                        to={
                          activeText !== 'logout' &&
                          activeText !== 'wayagram' &&
                          activeText !== 'wayapos' &&
                          activeText !== 'wayaquick' &&
                          `/${activeText}`
                        }
                        onClick={() => {
                          redirect(activeText, type);
                        }}
                        className={
                          currentPage !== 'wayagram' && activeText === 'Create'
                            ? 'd-none'
                            : activeText === currentPage
                            ? 'active text-dark mid-items-tab no-deco'
                            : 'mid-items-tab no-deco'
                        }
                        style={
                          activeText === 'create'
                            ? createPostStyle
                            : activeText === 'wayaSidebarIcon'
                            ? siderbarBottomStyle
                            : activeText === 'logout'
                            ? logoutStyle
                            : {}
                        }
                      >
                        <table className="text-left">
                          <tbody>
                            <tr>
                              <td>
                                {activeText === 'logout' ? (
                                  <img
                                    src={img}
                                    alt=""
                                    style={
                                      activeText === 'create'
                                        ? { display: 'none' }
                                        : activeText === 'logout'
                                        ? { height: '25px' }
                                        : {}
                                    }
                                  />
                                ) : (
                                  <SortSidebarIcons
                                    name={
                                      text === 'Profile'
                                        ? 'profile'
                                        : activeText
                                    }
                                    active={currentPage}
                                  />
                                )}
                              </td>
                              <td
                                className="text-left"
                                style={{ textAlign: 'left', paddingLeft: 12 }}
                              >
                                <span title={tip ?? ''} className="text-left">
                                  {text}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {more && (
                          <span className="ms-2">
                            {showDropdown && activeText === showDropdownType ? (
                              <SortSidebarIcons
                                name="arrowDown"
                                active={currentPage}
                              />
                            ) : (
                              <SortSidebarIcons
                                name="arrowRight"
                                active={activeText === currentPage || false}
                              />
                            )}
                          </span>
                        )}
                      </Link>
                    </div>
                    {activeText === showDropdownType && showDropdown && more && (
                      <div className="more-wrapper">
                        {more.map((moreItem) => (
                          <Link
                            className={
                              selected === `/${moreItem.activeText}`
                                ? 'active more-inner no-deco'
                                : 'more-inner no-deco'
                            }
                            key={Math.random()}
                            to={`/${moreItem.activeText}`}
                            onClick={() => {
                              redirect(moreItem.activeText, moreItem.type);
                            }}
                          >
                            <SortSidebarIcons
                              name={moreItem.activeText}
                              active={pathname.replace('/', '')}
                            />
                            <span className="ms-2">{moreItem.text}</span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Scrollbars>
      </div>
      {showPostModal ? (
        <PostModal showModal={showPostModal} hideModal={setShowPostModal} />
      ) : (
        ''
      )}
      {showRetrieveModal ? (
        <RetrieveModal
          showModal={showRetrieveModal}
          hideModal={setShowRetrieveModal}
        />
      ) : (
        ''
      )}
    </LaptopSidebar>
  );
}

Sidebar.propTypes = {
  currentPage: PropTypes.string.isRequired,
};
