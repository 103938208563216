import React from 'react';
import MaterialTable from 'material-table';
// import { Paper } from '@material-ui/core';
import tableIcons from './icons';
import './table.css';

export default function Index(props) {
  const { options, columns, data, title } = props;

  return (
    <div className="">
      <MaterialTable
        // components={{
        //   Container: function cont(prop) {
        //     return <Paper {...prop} elevation={0} />;
        //   },
        //   Toolbar: function toolb(prop) {
        //     return (
        //       <div>
        //         <MTableToolbar {...prop} />
        //       </div>
        //     );
        //   },
        //   Header: function headi(prop) {
        //     return (
        //       <div
        //       // style={{ backgroundColor: 'rgba(231, 71, 44, 0.16)' }}
        //       >
        //         <MTableHeader {...prop} />
        //       </div>
        //     );
        //   },
        // }}
        icons={tableIcons}
        options={
          {
            exportButton: false,
            searchable: false,
            search: false,
            sorting: false,
            headerStyle: {
              backgroundColor: '#ff6700',
              color: '#ffffff',
              whiteSpace: 'nowrap',
              zIndex: 0,
            },
          } || options
        }
        columns={columns}
        data={data || []}
        title={title || ''}
      />
    </div>
  );
}
