import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet, httpPost } from '../../action/http';

export const WalletContext = createContext();

export const WalletContextProvider = (props) => {
  const { children } = props;
  const [walletList, setWalletList] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [reload, setReload] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState({});
  const [defaultAcc, setDefaultAcc] = useState('');
  const [defaultW, setDefaultW] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [metaData, setMetaData] = useState(0);
  const [debitLimit, setDebitLimit] = useState('');

  const getWalletList = async (userId, profileId) => {
    setWalletLoading(true);
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}/${profileId}`
    );

    if (res && res?.status) {
      setWalletList(res.data);
      if (res.data.length) {
        //  const result = res.data.filter((item) => item?.walletDefault);
        setDefaultWallet(res.data[0]);
      }
      setWalletLoading(false);
    }
  };
  const getTransactionLimit = async (userId, profileId) => {
    setWalletLoading(true);
    const res = await httpPost(
      `/api/v1/wallet/getCustomerDebitLimit?profileId=${profileId}&userId=${userId}`,
      {},
      customBaseUrl.walletUrl
    );
    if (res?.body?.status) {
      setDebitLimit(res?.body?.data);
      setWalletLoading(false);
    }
  };

  const getTransactionHistory = async (page, size) => {
    console.log('fff', page);
    const pages =
      page > 0 ? page - 1 : page === 0 ? 0 : page < 0 ? 0 : currentPage;
    const limit = size ?? pageSize;
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${defaultWallet?.accountNo}?page=${pages}&size=${limit}`
    );

    if (res.status) {
      setTransactionHistory(res.data.content);
      setMetaData(res?.data?.totalElements);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const profileId = localStorage.getItem('profileId');
    getWalletList(userId, profileId);
    getTransactionLimit(userId, profileId);
    // getTransactionHistory();
  }, [reload]);

  useEffect(() => {
    console.log(defaultAcc);
    if (defaultWallet.accountNo) {
      getTransactionHistory(0, pageSize);
    }
  }, [pageSize, defaultWallet?.accountNo, defaultW]);

  return (
    <WalletContext.Provider
      value={{
        setWalletList,
        walletList,
        reload,
        setCurrentPage,
        metaData,
        setReload,
        walletLoading,
        setWalletLoading,
        transactionHistory,
        setPageSize,
        pageSize,
        setDefaultWallet,
        defaultWallet,
        setDefaultAcc,
        defaultAcc,
        defaultW,
        setDefaultW,
        getTransactionHistory,
        debitLimit,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
