import React, { useState } from 'react';
import PropTypes from 'prop-types';

function DisputeInfo(props) {
  const { handleFormInput } = props;
  const [state, setState] = useState({
    transactionDate: '',
    transactionAmount: '',
    description: '',
    extraDetails: '',
  });
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    handleFormInput(state);
  };
  return (
    <div className="px-4 pt-4">
      <div className="transaction-period-container">
        <div className="">
          <div className="mb-2">Transaction Date</div>
          <input
            name="transactionDate"
            values={state.transactionDate}
            onChange={handleChange}
            placeholder="DD/MM/YYYY"
          />
        </div>
        <div className="">
          <div className="mb-2">Transaction Time</div>
          <input
            name="transactionDate"
            values={state.transactionDate}
            onChange={handleChange}
            placeholder="0.00"
          />
        </div>
      </div>
      <div className="tansaction-input-container mt-3">
        <div className="mb-2">Narration of Dispute</div>
        <textarea
          name="description"
          values={state.description}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      <div className="tansaction-input-container mt-3">
        <div className="mb-2">Extra Details</div>
        <textarea
          name="extraDetails"
          values={state.extraDetails}
          onChange={handleChange}
          placeholder=""
        />
      </div>
    </div>
  );
}

DisputeInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default DisputeInfo;
