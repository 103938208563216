import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import '../FundWallet/index.scss';
import Container from '../../Container';
import Settle from './Settle';
import Main from './main';
import AllPayment from './AllPayment';

const Index = (props) => {
  const {
    showModal,
    hideModal,
    id,
    sentRequest,
    receivedRequest,
    getSentPaymentRequest,
    getReceivedPaymentRequest,
  } = props;
  const [mode, setMode] = useState('view');
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center">Request Payment</ModalHeader>
      <div className="text-center fund-tab-wrap pb-2 pb-3">
        <div className="row m-0 fund-tab">
          <Container
            className={`fs-6 ${
              mode === 'send' ? 'col fund-tab-active' : 'col'
            }`}
            onClick={() => setMode('send')}
          >
            Send Request
          </Container>
          <Container
            className={`fs-6 ${
              mode === 'settle' ? 'col fund-tab-active' : 'col'
            }`}
            onClick={() => setMode('settle')}
          >
            Settle Request
          </Container>
          <Container
            className={`fs-6 ${
              mode === 'view' ? 'col fund-tab-active' : 'col'
            }`}
            onClick={() => setMode('view')}
          >
            All Request
          </Container>
        </div>
      </div>

      {mode === 'settle' ? (
        <Settle hideModal={hideModal} />
      ) : mode === 'send' ? (
        <Main hideModal={hideModal} />
      ) : (
        <AllPayment
          hideModal={hideModal}
          sentRequest={sentRequest}
          receivedRequest={receivedRequest}
          getSentPaymentRequest={getSentPaymentRequest}
          getReceivedPaymentRequest={getReceivedPaymentRequest}
        />
        // <Main hideModal={hideModal} />
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
