import React from 'react';
import PropTypes from 'prop-types';

function DonationInfo(props) {
  const { handleFormInput, values } = props;

  return (
    <div>
      <div className="input-container">
        <input
          name="title"
          values={values.title}
          onChange={(e) => handleFormInput(e.target.value, 'title')}
          placeholder="Title of Cause"
        />
        <span>What is the cause you are fundraising for?</span>
      </div>
      <div className="input-container">
        <input
          name="organizerName"
          values={values.organizerName}
          onChange={(e) => handleFormInput(e.target.value, 'organizerName')}
          placeholder="Organizer’s name"
        />
        <span>Who is organizing this donation?</span>
      </div>
      <div className="input-container">
        <input
          name="description"
          values={values.description}
          onChange={(e) => handleFormInput(e.target.value, 'description')}
          placeholder="Description"
        />
        <span>Tell people why they should donate to this cause</span>
      </div>
      <div className="input-container">
        <input
          name="tags"
          values={values.tags}
          onChange={(e) => handleFormInput(e.target.value, 'tags')}
          placeholder="Tags"
        />
        <span>Use tags to improve your discoverability</span>
      </div>
    </div>
  );
}

DonationInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default DonationInfo;
