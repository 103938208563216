import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { httpPut, customBaseUrl } from '../../../../action/http';
// import Skeleton from 'react-loading-skeleton';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Layout from '../../../shared/Layout';
import { TopWrapper } from '../../../shared/Cards/style';
import Button from '../../../shared/Button';
// import Container from '../../../shared/Container';
import '../index.scss';
import './style.scss';
import {
  getGroupFeeds,
  getGroupInfo,
  getPrivateGroupRequest,
  getGroupMembers,
  handleFollow,
  joinGroup,
  leaveAndDeleteGroup,
  inviteToGroup,
} from '../container';
import RightSidebar from '../RightSidebar';
import Loader from '../../../shared/Loader';
import bannerImage from '../../../../assets/image/banner.jpg';
import moreVertical from '../../../../assets/image/moreVertical.svg';
import search from '../../../../assets/image/search.svg';
import right from '../../../../assets/mdi_chevron_right.svg';
import privateAcc from '../../../../assets/private-acc.svg';
import avatarImg from '../../../../assets/image/avatar3.png';
import avatar2 from '../../../../assets/image/avatar2.svg';
import addFile from '../../../../assets/image/addFile.svg';
import OtherDropdown from '../../../shared/Dropdown/OtherDropdown';
import truncateWithEllipses from '../../../../utils/truncate';
import WayagramGroupProfile from '../../../shared/Modals/Profile/WaygramGroupProfile';
import ConfirmModal from '../../../shared/Modals/Confirm/confirmationModal';
import { hideLoader, showLoader } from '../../../../utils/loader';
import GramCard from '../../../shared/Cards/Gram';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import CreatePostModal from '../../../shared/Modals/Post/CreatePost';
import AdminList, { MuteList } from '../../../shared/Modals/groupModals';
import Report from '../../../shared/Modals/ReportAndDispute';
import Share from '../../../shared/Modals/share';
import Invite from '../../../shared/Modals/Invite';

export default function Index(props) {
  const history = useHistory();
  const [showCreatePost, setShowCreatePost] = useState(false);
  const { match } = props;
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  const [feeds, setFeeds] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showMuteModal, setShowMuteModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showUDropdown, setShowUDropdown] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState('Post');
  const [groupRequests, setGroupRequests] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showAdminList, setShowAdminList] = useState(false);
  const [showMutedList, setShowMutedList] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [selected, setSelected] = useState('');
  const [reportedProfile, setReportedProfile] = useState(null);
  const [reportType, setReportType] = useState('Group');
  const [showShare, setShowShare] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [inviteMembers, setInviteMemembers] = useState([]);

  const tabItemsMember = [
    { name: 'Post', activeText: `Post` },
    { name: 'Media', activeText: `Media` },
    { name: `Members`, activeText: `Members (${groupMembers?.length || 0})` },
  ];

  const tabItemsNonMember = [
    { name: 'Post', activeText: `Post` },
    { name: 'Media', activeText: `Media` },
    { name: `Members`, activeText: `Members (${groupMembers?.length || 0})` },
  ];

  const tabItemsAdminAllowPost = [
    { name: 'Post', activeText: `Post` },
    { name: 'Media', activeText: `Media` },
    { name: `Members`, activeText: `Members (${groupMembers?.length || 0})` },
    {
      name: `Requests`,
      activeText: `Requests (${groupRequests?.length || 0})`,
    },
  ];

  const tabItemsAdminDisallowPost = [
    { name: 'Post', activeText: `Post` },
    { name: 'Media', activeText: `Media` },
    { name: `Members`, activeText: `Members (${groupMembers?.length || 0})` },
    { name: 'Pending Post', activeText: `Pending Post` },
    {
      name: `Requests`,
      activeText: `Requests (${groupRequests?.length || 0})`,
    },
  ];
  // const tabItemsPrivate = [
  //   'Post',
  //   'Media',
  //   `Members (${groupMembers?.length || 0})`,
  //   'Pending Post',
  //   `Requests (${groupRequests?.length || 0})`,
  // ];
  // const tabItemsPrivateUser = [
  //   'Post',
  //   'Media',
  //   `Members (${groupMembers?.length || 0})`,
  // ];
  const innerTabsItemsAdmin = [
    {
      name: `${profileData?.isUserOnMute === false ? 'Mute' : 'Unmute'}`,
      type: 'MuteGroup',
    },
    { name: 'Share', type: 'Share' },
    { name: 'Exit', type: 'ExitGROUP' },
    { name: 'Settings', type: 'Settings' },
    { name: 'Delete', type: 'Delete' },
  ];

  const innerTabsItemsUser = [
    {
      name: `${profileData?.isUserOnMute === false ? 'Mute' : 'Unmute'}`,
      type: 'MuteGroup',
    },
    { name: 'Invite', type: 'Invite' },
    { name: 'Share', type: 'Share' },
    { name: 'Report', type: 'ReportGroup' },
    { name: 'Exit', type: 'ExitGROUP' },
  ];

  const membersAdminDDList = [
    { name: 'Make Group Admin', type: 'Make Group Admin' },
    { name: 'Mute', type: 'MuteMember' },
    { name: 'Report', type: 'Report' },
    { name: 'Remove', type: 'Remove' },
  ];

  const membersDDList = [{ name: 'Report', type: 'Report' }];

  const handleOtherOptions = (type, data) => {
    // if (type === 'Make Group Admin') {
    // }

    if (type === 'MuteMember') {
      setShowMuteModal(true);
    }

    if (type === 'Report') {
      setReportType('User');
      setReportedProfile(data);
      setShowReportModal(true);
    }

    // if (type === 'Remove') {
    // }
  };

  const handleGetFeeds = async () => {
    const groupInfo = await getGroupInfo(match.params.id, loggedInUser.id);
    const allFeeds = await getGroupFeeds(groupInfo.id, loggedInUser.id);
    setProfileData(groupInfo);
    setFeeds(allFeeds?.data);
  };

  const handleGetPrivateGroupRequest = async () => {
    const groupRequest = await getPrivateGroupRequest(match.params.id);
    setGroupRequests(groupRequest?.listOfRequests);
    setBtnLoading(false);
  };

  const handleGetGroupMembers = async () => {
    const groupRequest = await getGroupMembers(match.params.id);
    setGroupMembers(groupRequest?.data.filter((member) => member !== null));
    setBtnLoading(false);
  };

  const handleClick = (e) => {
    if (e === 'Delete') {
      setShowModal(true);
    }
    if (e === 'ExitGROUP') {
      setShowExitModal(true);
    }
    if (e === 'Settings') {
      setActive('Settings');
    }
    if (e === 'MuteGroup') {
      setShowMuteModal(true);
    }
    if (e === 'ReportGroup') {
      setReportedProfile(profileData);
      setShowReportModal(true);
    }
    if (e === 'Share') {
      setShowShare(true);
    }
    if (e === 'Invite') {
      setShowInvite(true);
    }
  };

  const handleInvite = async (invitee) => {
    setBtnLoading(true);
    const invite = await inviteToGroup(
      loggedInUser.id,
      invitee,
      match.params.id
    );
    setBtnLoading(false);
    swal('success', invite.message, 'success');
  };

  const handleGroupJoinRequest = async (requestId, requestFrom, accepted) => {
    setBtnLoading(true);
    const data = {
      groupId: match.params.id,
      requestId,
      requestFrom,
      accepted,
    };
    const res = await httpPut(
      `/main/group/accept-private-group-request`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      handleGetPrivateGroupRequest();
    } else {
      swal('error', res.message, 'error');
      setBtnLoading(false);
    }
  };

  const handleFollowUser = async (id, username) => {
    setBtnLoading(true);
    const res = await handleFollow(id, username);
    if (res.status) {
      handleGetGroupMembers();
    } else {
      swal('error', res.message, 'error');
      setBtnLoading(false);
    }
  };

  const handleDelete = async () => {
    showLoader();
    const data = {
      groupId: match.params.id,
      userId: loggedInUser.id,
    };
    const res = await httpPut(
      `/main/group/leave-and-delete-group`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      hideLoader();
      swal('success', res.message, 'success');
      setShowModal(false);
      history.goBack();
    } else {
      hideLoader();
      setShowModal(false);
      swal('error', res.message, 'error');
    }
  };

  const handleMute = async () => {
    showLoader();
    const data = {
      groupId: match.params.id,
      userId: loggedInUser.id,
    };
    const res = await httpPut(
      `/main/group/mute-group-from-user-end`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      hideLoader();
      swal('success', res.message, 'success');
      setShowMuteModal(false);
      history.goBack();
    } else {
      hideLoader();
      setShowMuteModal(false);
      swal('error', res.message, 'error');
    }
  };

  // const handleOption = async () => {};

  // if (!profileData || !feeds.length) {
  //   return <Loader />;
  // }
  useEffect(() => {
    setLoading(true);
    handleGetFeeds();
    if (!profileData?.isPublic) {
      handleGetPrivateGroupRequest();
    }
    if (match.params.id) {
      handleGetGroupMembers();
    }
    setLoading(false);
  }, [loggedInUser.id, match.params.id]);

  const handleJoinAndLeave = async () => {
    const { id } = loggedInUser;
    let res;
    if (!profileData.isUserAMember) {
      res = await joinGroup(id, profileData.id);
    } else {
      res = await leaveAndDeleteGroup(id, profileData.id);
    }
    if (res.status) {
      swal('Done', res.message, 'success');
      handleGetFeeds();
    }
  };

  return (
    <Layout route={props} currentPage="Groups">
      <div className="dashboard-two-col-wrap wayagram-div">
        <div className="dasboard-col-one">
          {profileData ? (
            <>
              <TopWrapper>
                <div
                  className="banner-div"
                  style={{
                    backgroundImage: `url(${
                      profileData.headerImageUrl || bannerImage
                    })`,
                    backgroundSize: 'cover',
                    height: '140px',
                    backgroundPosition: 'center',
                  }}
                >
                  {/* <img src={coverImage || bannerImage} alt="banner" /> */}
                </div>
                <div className="avatar-section d-flex mb-4">
                  <img
                    src={profileData.imageUrl || avatarImg}
                    alt="avatar"
                    style={{
                      borderRadius: '50%',
                      height: '110px',
                      width: '110px',
                      position: 'absolute',
                      left: '19.29px',
                      bottom: '-15.5px',
                    }}
                  />
                  {/* <Skeleton
                    circle
                    width={110}
                    height={110}
                    style={{
                      borderRadius: '50%',
                      height: '110px',
                      width: '110px',
                      position: 'absolute',
                      left: '19.29px',
                      bottom: '-15.5px',
                    }}
                  /> */}
                  <div className="ml-auto">
                    {profileData.isUserAdmin && (
                      <Button
                        className="btn btn-primary btn1"
                        content="Edit Profile"
                        onClick={() => setShowEditProfile(true)}
                        loading={loading}
                      />
                    )}
                    {!profileData.isUserAdmin && (
                      <Button
                        className="btn btn-primary btn1"
                        content={!profileData.isUserAMember ? 'Join' : 'Leave'}
                        onClick={() => handleJoinAndLeave()}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="info-section pe-4 ps-4"
                  // style={loggedInUserId !== userId ? { marginTop: '100px' } : {}}
                >
                  <div className="d-flex justify-content-between align-items-center my-5">
                    <div className="d-flex flex-column w-75">
                      <p className="Group-title">{profileData.name}</p>
                      <p
                        className="bio w-75"
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {truncateWithEllipses(
                          profileData.description || 'Group description',
                          50
                        )}
                      </p>
                    </div>
                    <div>
                      <Button
                        className="btn btn-primary btn1"
                        content="Invite"
                        onClick={() => setShowInvite(true)}
                      />
                    </div>
                  </div>
                </div>
              </TopWrapper>
              <div
                className="menu-options mt-3"
                style={{
                  // overflowX: 'scroll',
                  height: '50px',
                  position: 'relative',
                  borderBottom: '1px solid #e5e5e5',
                }}
              >
                <div
                  className="lato-text"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: '#b6b3b3',
                    alignItems: 'center',
                  }}
                >
                  <div className="d-flex w-75">
                    {profileData.isUserAdmin &&
                    profileData.allowMemberPost &&
                    profileData.isUserAMember
                      ? tabItemsAdminAllowPost.map((item) => (
                          <div
                            className={
                              item.name === active
                                ? 'active add-cursor me-auto '
                                : 'add-cursor me-auto '
                            }
                            onClick={() => {
                              setActive(item.name);
                              // handleGetData(item);
                            }}
                            key={Math.random()}
                          >
                            {item.activeText}
                          </div>
                        ))
                      : profileData.isUserAdmin &&
                        !profileData.allowMemberPost &&
                        profileData.isUserAMember
                      ? tabItemsAdminDisallowPost.map((item) => (
                          <div
                            className={
                              item.name === active
                                ? 'active add-cursor me-auto '
                                : 'add-cursor me-auto '
                            }
                            onClick={() => {
                              setActive(item.name);
                              // handleGetData(item);
                            }}
                            key={Math.random()}
                          >
                            {item.activeText}
                          </div>
                        ))
                      : profileData.isUserAMember
                      ? tabItemsMember.map((item) => (
                          <div
                            className={
                              item.name === active
                                ? 'active add-cursor me-auto '
                                : 'add-cursor me-auto '
                            }
                            onClick={() => {
                              setActive(item.name);
                              // handleGetData(item);
                            }}
                            key={Math.random()}
                          >
                            {item.activeText}
                          </div>
                        ))
                      : tabItemsNonMember.map((item) => (
                          <div
                            className={
                              item.name === active
                                ? 'active add-cursor me-auto '
                                : 'add-cursor me-auto '
                            }
                            key={Math.random()}
                          >
                            {item.activeText}
                          </div>
                        ))}
                  </div>

                  <div
                    className={
                      !profileData.isUserAMember ? 'd-none' : 'd-flex me-0'
                    }
                    style={{ position: 'relative' }}
                  >
                    <div className="me-3 rec">
                      <img src={search} alt="Search" />
                    </div>
                    <div
                      className="add-cursor rec"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <img src={moreVertical} alt="moreVertical" />
                    </div>
                    {showDropdown && (
                      <OtherDropdown
                        hide={setShowDropdown}
                        options={
                          profileData.isUserAdmin === true
                            ? innerTabsItemsAdmin
                            : innerTabsItemsUser
                        }
                        positionLeft="-127px"
                        handleClick={(e) => {
                          setShowDropdown(!showDropdown);
                          handleClick(e);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {profileData.isUserAMember || profileData.isPublic ? (
                <>
                  {active === 'Post' && profileData.isUserAMember && (
                    <div className="px-4 pt-3 pb-4  d-flex align-items-center border">
                      <img
                        src={loggedInUser.avatar || avatar2}
                        alt="Avatar"
                        className="me-3"
                        style={{
                          width: '42px',
                          height: '42px',
                        }}
                      />
                      {/* {profileData.isUserOnMute === false ? ( */}
                      <div
                        className="d-flex create-post"
                        onClick={() => {
                          setShowCreatePost(true);
                        }}
                      >
                        <div> Create a public post</div>
                        <div>
                          <img src={addFile} alt="addFile" />
                        </div>
                      </div>
                      {/* ) : (
                        <div className="d-flex create-post">
                          <div>You have been muted by the admin...</div>
                        </div>
                      )} */}
                    </div>
                  )}

                  {(active === `Members (${groupMembers?.length || 0})` ||
                    active === `Requests (${groupRequests?.length || 0})`) && (
                    <div className="group-search">
                      <input type="text" name="" id="" placeholder="Search" />
                    </div>
                  )}

                  {active === 'Post' && (
                    <div>
                      {feeds.length ? (
                        feeds.map((post) => (
                          <GramCard
                            key={Math.random()}
                            data={post}
                            handleDeletePost={() => console.log('delete')}
                            showPostModal={() => console.log('delete')}
                            handleLikePost={() => console.log('like')}
                            addBookmark={() => console.log('bookmark')}
                            blockUser={() => console.log('block')}
                            handleFollowUser={() => console.log('follow')}
                            fromLocation="group"
                          />
                        ))
                      ) : (
                        <div className="text-center my-5  w-100 border p-5">
                          No Posts
                        </div>
                      )}
                    </div>
                  )}
                  {active === `Requests (${groupRequests?.length || 0})` && (
                    <div className="request-cont">
                      {groupRequests?.length ? (
                        groupRequests.map((request) => (
                          <div className="request" key={request?.requestFrom}>
                            <div
                              className="req-img"
                              onClick={() => {
                                history.push(
                                  `/wayagram-profile/${request?.requestFrom}`
                                );
                              }}
                            >
                              <img src={request?.avatar || avatar2} alt="" />
                            </div>
                            <div className="req-det">
                              <div
                                className="det-det"
                                onClick={() => {
                                  history.push(
                                    `/wayagram-profile/${request?.requestFrom}`
                                  );
                                }}
                              >
                                <div>{request?.displayName}</div>
                                <div>{`@${request?.username}`}</div>
                              </div>
                              <div className="det-button">
                                <Button
                                  type="button"
                                  className="btn-acc"
                                  onClick={() => {
                                    setSelected(request?.requestFrom);
                                    handleGroupJoinRequest(
                                      request?.id,
                                      request?.requestFrom,
                                      true
                                    );
                                  }}
                                  loading={
                                    btnLoading &&
                                    request?.requestFrom === selected
                                  }
                                  content="Accept"
                                />

                                <Button
                                  type="button"
                                  className="btn-del"
                                  onClick={() => {
                                    setSelected(request?.requestFrom);
                                    handleGroupJoinRequest(
                                      request?.id,
                                      request?.requestFrom,
                                      false
                                    );
                                  }}
                                  loading={
                                    btnLoading &&
                                    request?.requestFrom === selected
                                  }
                                  content="Delete"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="wl-100 my-4 text-center">
                          No User Request
                        </div>
                      )}
                    </div>
                  )}

                  {active === `Members` && (
                    <div className="request-cont">
                      {groupMembers?.length ? (
                        groupMembers.map((member) => (
                          <div className="request" key={member?.id}>
                            <div
                              className="req-img"
                              onClick={() => {
                                history.push(
                                  `/wayagram-profile/${member?.Profile?.id}`
                                );
                              }}
                            >
                              <img
                                src={member?.Profile?.avatar || avatar2}
                                alt=""
                              />
                            </div>
                            <div className="req-det">
                              <div
                                className="det-det"
                                onClick={() => {
                                  history.push(
                                    `/wayagram-profile/${member?.Profile?.id}`
                                  );
                                }}
                              >
                                <div>{member?.Profile?.displayName}</div>
                                <div>{`@${member?.Profile?.username}`}</div>
                              </div>
                              <div
                                className="det-button"
                                style={{
                                  position: 'relative',
                                }}
                              >
                                {profileData?.userId !== member?.userId && (
                                  <Button
                                    type="button"
                                    className={
                                      member?.Profile?.connection?.connected ===
                                      false
                                        ? 'btn-follow'
                                        : 'btn-following'
                                    }
                                    disabled={
                                      member?.Profile?.connection?.connected
                                    }
                                    onClick={() => {
                                      handleFollowUser(
                                        member?.userId,
                                        member?.Profile?.username
                                      );
                                      setSelected(member?.id);
                                    }}
                                    loading={
                                      btnLoading && member?.id === selected
                                    }
                                    content={
                                      member?.Profile?.connection?.connected ===
                                      false
                                        ? 'follow'
                                        : 'following'
                                    }
                                  />
                                )}

                                <div
                                  className="add-cursor rec"
                                  onClick={() => {
                                    setSelected(member?.id);
                                    setShowUDropdown(!showUDropdown);
                                  }}
                                >
                                  <img src={moreVertical} alt="moreVertical" />
                                </div>
                                {showUDropdown && member?.id === selected && (
                                  <OtherDropdown
                                    hide={setShowUDropdown}
                                    options={
                                      profileData.isUserAdmin
                                        ? membersAdminDDList
                                        : membersDDList
                                    }
                                    positionLeft="-127px"
                                    style={{
                                      top: '52px',
                                    }}
                                    data={member?.Profile}
                                    handleClick={handleOtherOptions}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="wl-100 my-4 text-center">
                          No Members
                        </div>
                      )}
                    </div>
                  )}
                  {active === `Settings` && (
                    <div className="settings-cont">
                      <div className="part-1">
                        <div className="head">
                          <div className="top">Privacy</div>
                          <div className="bottom">
                            Any member can post any content in this group
                            without the approval of the admin
                          </div>
                        </div>
                        <div className="h-bottom">
                          <select name="" id="">
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="part-2"
                        onClick={() => {
                          setShowAdminList(true);
                        }}
                      >
                        <div className="left">
                          <div className="head">Admin</div>
                          <div className="bottom">Show all admin member</div>
                        </div>
                        <div className="right">
                          <img src={right} alt="" />
                        </div>
                      </div>
                      <div
                        className="part-2"
                        onClick={() => {
                          setShowMutedList(true);
                        }}
                      >
                        <div className="left">
                          <div className="head">Muted Member</div>
                          <div className="bottom">
                            Show all muted member from this group
                          </div>
                        </div>
                        <div className="right">
                          <img src={right} alt="" />
                        </div>
                      </div>
                      <div className="part-2">
                        <div className="left">
                          <div className="head">Removed Member</div>
                          <div className="bottom">
                            Show all member admin removed recently
                          </div>
                        </div>
                        <div className="right">
                          <img src={right} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="private-acc my-5">
                  <img src={privateAcc} alt="" />
                  <div className="pactop">This group is private </div>
                  <div className="pacbottom">
                    join this group so see their posts
                  </div>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>

        <RightSidebar />
      </div>
      {showEditProfile ? (
        <WayagramGroupProfile
          showModal={showEditProfile}
          hideModal={setShowEditProfile}
          groupData={profileData}
        />
      ) : (
        ''
      )}
      {showModal ? (
        <ConfirmModal
          showModal={showModal}
          hideModal={setShowModal}
          title="confirm Delete"
          description="are you sure you want to delete this group?"
          handleSubmit={handleDelete}
        />
      ) : (
        ''
      )}
      {showExitModal ? (
        <ConfirmModal
          showModal={showExitModal}
          hideModal={setShowExitModal}
          title="confirm Exit"
          description="are you sure you want to leave this group?"
          handleSubmit={handleDelete}
        />
      ) : (
        ''
      )}
      {showMuteModal ? (
        <ConfirmModal
          showModal={showMuteModal}
          hideModal={setShowMuteModal}
          title={
            profileData?.isUserOnMute === false
              ? 'confirm Mute'
              : 'confirm unmute'
          }
          description={
            profileData?.isUserOnMute === false
              ? 'are you sure you want to Mute this group?'
              : 'are you sure you want to Unmute this group?'
          }
          handleSubmit={handleMute}
        />
      ) : (
        ''
      )}
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="create"
          type="group"
          groupId={profileData.id}
        />
      ) : (
        ''
      )}
      {showAdminList ? (
        <AdminList
          showModal={showAdminList}
          hideModal={setShowAdminList}
          members={groupMembers.filter((member) => member.isAdmin === true)}
        />
      ) : (
        ''
      )}
      {showMutedList ? (
        <MuteList
          showModal={showMutedList}
          hideModal={setShowMutedList}
          members={groupMembers.filter(
            (member) => member.isUserOnMute === true
          )}
        />
      ) : (
        ''
      )}
      {showReportModal ? (
        <Report
          showModal={showReportModal}
          hideModal={setShowReportModal}
          title={reportType === 'Admin' ? 'Report Group' : 'Report Member'}
          reported={reportedProfile}
          type={reportType}
          reporter={loggedInUser}
        />
      ) : (
        ''
      )}

      {showShare ? (
        <Share
          showModal={showShare}
          hideModal={setShowShare}
          title="Share"
          description="Invite your friend to join you on wayagram"
          url={`${process.env.REACT_APP_FRONTEND}/wayagram-group/${match.params.id}`}
          text={`${process.env.REACT_APP_FRONTEND}/wayagram-group/${match.params.id}`}
        />
      ) : (
        ''
      )}

      {showInvite ? (
        <Invite
          showModal={showInvite}
          hideModal={setShowInvite}
          btnLoading={btnLoading}
          setInviteMembers={setInviteMemembers}
          inviteMembers={inviteMembers}
          handleInvite={handleInvite}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
