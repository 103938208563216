import complaint from '../../../assets/images/complaint.png';

const settingTabs = [
  {
    id: '101',
    title: 'Account Settings',
    active: 'profile',
    description: 'Update Profile, Change Password, ',
    options: [
      {
        id: '1001',
        title: 'Edit Profile',
        description: 'Profile image, handle name',
      },
      {
        id: '1002',
        title: 'Account',
        description: 'Privacy, security',
      },
      {
        id: '1003',
        title: 'Password',
        description: 'Reset password',
      },
      {
        id: '1004',
        title: 'PIN',
        description: 'Reset PIN',
      },

      {
        id: '1006',
        title: 'Account Statement',
        description: 'Get Statement of Account',
      },
      {
        id: '1007',
        title: 'Hide Balance',
        description: 'Hide Balance',
      },
    ],
  },
  /* {
    id: '200',
    title: 'Dashboard Settings',
    personalTitle: 'Corporate Login',
    active: 'dashboardSettings',
    description: 'Profile, KYC, Teams, Payout Account, API Keys',
    personalDescription: 'Login to a corporate account',
  }, */
  {
    id: '102',
    title: 'Notification settings',
    active: 'notification',
    description: 'Email, SMS, Push notification, 2FA',
    options: [
      {
        id: '2001',
        title: 'Push notifications',
        active: false,
      },
      {
        id: '2002',
        title: 'SMS notifications',
        active: true,
      },
      {
        id: '2003',
        title: 'Email notifications',
        active: false,
      },
      {
        id: '2004',
        title: 'New notifications',
        active: true,
      },
      {
        id: '2005',
        title: 'Messages',
        active: true,
      },
      {
        id: '2006',
        title: 'Updates about performance of your post',
        active: true,
      },
      {
        id: '2007',
        title: 'News about new products and updates',
        active: true,
      },
      {
        id: '2008',
        title: 'Suggestions for recommended interests',
        active: true,
      },
      {
        id: '2009',
        title: '2FA',
        active: true,
      },
    ],
  },
  // {
  //   id: '114',
  //   title: 'Web Pos KYC Settings',
  //   active: 'webPosSettings',
  //   description: 'Web POS KYC Settings',
  // },
  // {
  //   id: '115',
  //   title: 'Terminal Pos KYC Settings',
  //   active: 'terminalPosSettings',
  //   description: 'Terminal POS KYC Settings',
  // },
  // {
  //   id: '116',
  //   title: 'Team Members',
  //   active: 'teamMembers',
  //   description: 'Role & Access Management',
  // },
  {
    id: '103',
    title: 'Payment settings',
    active: 'payment',
    description: 'Card, Bank, Credentials',
    options: [
      // {
      //   id: '3001',
      //   title: 'Dispute Resolution',
      //   description: 'Report complaints, customer service',
      // },
      {
        id: '3002',
        title: 'Manage Card',
        description: 'Add your Debit card',
      },
      {
        id: '3003',
        title: 'Manage Bank',
        description: 'Add your Bank details',
      },
      {
        id: '3004',
        // title: 'Credentials',
        title: 'Manage KYC',
        description: 'International passport, BVN, ID Card, NIN',
      },
      // {
      //   id: '3005',
      //   title: 'Other Details',
      //   description: 'Address, Utility Bill',
      // },
    ],
  },
  // {
  //   id: '104',
  //   title: 'Chat settings',
  //   active: 'chat',
  //   description: 'Account, chats, Invites',
  //   options: [
  //     {
  //       id: '4001',
  //       title: 'Last Seen',
  //       description: 'My contacts',
  //     },
  //     {
  //       id: '4002',
  //       title: 'Profile',
  //       description: 'My contact (private)',
  //     },
  //     {
  //       id: '4003',
  //       title: 'About',
  //       description: 'Nobody',
  //     },
  //     {
  //       id: '4004',
  //       title: 'Read receipt',
  //       description: 'Some text/swap button',
  //     },
  //     {
  //       id: '4005',
  //       title: 'Groups',
  //       description: 'My contacts except',
  //     },
  //     {
  //       id: '4006',
  //       title: 'Blocked contacts',
  //       description: '60',
  //     },
  //   ],
  // },
  {
    id: '105',
    title: 'Send feedback',
    active: 'feedback',
    description: 'Write to us on how we can serve you better.',
  },
  {
    id: '113',
    title: 'Invite a Friend',
    active: 'invite',
    description: 'Location, device, time of login',
  },
  /* {
    id: '200',
    title: 'API Key',
    active: 'apiDocumentation',
    description: 'View your Api key and webhooks keys',
  },
  // {
  //   id: '106',
  //   title: 'Synchronize accounts',
  //   active: 'sychronize',
  //   description: 'Write to us on how we can serve you better.',
  //   options: [
  //     {
  //       id: '6001',
  //       title: 'Sync with Twitter',
  //       active: false,
  //     },
  //     {
  //       id: '6002',
  //       title: 'Sync with instagram',
  //       active: true,
  //     },
  //     {
  //       id: '6003',
  //       title: 'Sync with facebook',
  //       active: false,
  //     },
  //   ],
  // },
  // {
  //   id: '107',
  //   title: 'Follow and Invite a friend',
  //   active: 'invite',
  //   description: 'Share wayagram to your friends & family',
  // },
  /*  {
    id: '200',
    title: 'API Key',
    active: 'apiDocumentation',
    description: 'View your Api key and webhooks keys',
  },  */
  // {
  //   id: '108',
  //   title: 'Blocked accounts',
  //   active: 'blocked',
  //   description: 'Share wayagram to your friends & family',
  // },
  // {
  //   id: '109',
  //   title: 'Muted accounts',
  //   active: 'muted',
  //   description: 'Share wayagram to your friends & family',
  // },
  // {
  //   id: '110',
  //   title: 'Transparency',
  //   active: 'terms',
  //   description: 'Terms & Condition, privacy policy, about us!',
  //   options: [
  //     {
  //       id: '10001',
  //       title: 'Terms & Condition',
  //       description: 'Read about our terms & conditions',
  //     },
  //     {
  //       id: '10002',
  //       title: 'Privacy Policy',
  //       description: 'Read about our privacy & policy',
  //     },
  //     {
  //       id: '10004',
  //       title: 'FAQs',
  //       description: 'Frequently asked questions',
  //     },
  //   ],
  // },
  // {
  //   id: '111',
  //   title: 'Help',
  //   active: 'help',
  //   description: 'Faqs, Contact us!',
  //   options: [
  //     {
  //       id: '11001',
  //       title: 'Get Help',
  //       description: 'What do you need help with',
  //     },
  //     {
  //       id: '11002',
  //       title: 'Faqs',
  //       description: 'Read our frequently asked questions',
  //     },
  //     {
  //       id: '11003',
  //       title: 'Contact us',
  //       description: 'Send  us a message',
  //     },
  //   ],
  // },
  // {
  //   id: '112',
  //   title: 'Ads',
  //   active: 'ads',
  //   description: 'Manage your ads',
  // },
  // {
  //   id: '113',
  //   title: 'Login Information',
  //   active: 'loginInfo',
  //   description: 'Location, device, time of login',
  // },
];

const blockedAccounts = [
  {
    id: '100',
    name: 'Chadwick Boseman',
    avatar: complaint,
  },
  {
    id: '101',
    name: 'Barry Allen',
    avatar: complaint,
  },
  {
    id: '102',
    name: 'John Putin',
    avatar: complaint,
  },
  {
    id: '103',
    name: 'Greizeman Cook',
    avatar: complaint,
  },
  {
    id: '105',
    name: 'Chadwick Boseman',
    avatar: complaint,
  },
];

const options = [
  {
    name: 'Unmute',
    id: '100',
  },
];

export { settingTabs, blockedAccounts, options };
