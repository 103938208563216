import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Pagination } from 'antd';
import Layout from '../../shared/Layout';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpGet } from '../../../action/http';
// import moment from 'moment';

export default function RequestHome({ setShowLoan }) {
  const { userDetails } = useContext(ProfileContext);
  const [users, setUsers] = useState([]);
  const [metaData, setMetatData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });

  const getRefferals = async (page, size) => {
    const pages = page ?? page === 0 ? 0 : pagination.current;
    const limit = size ?? pagination.pageSize;
    const res = await httpGet(
      `${customBaseUrl.loanUrl}/api/v1/loan/user/loans?email=${userDetails?.email}&page=${pages}&size=${limit}`
    );
    console.log('res', res);
    setUsers(res?.data?.loans);
    setMetatData(res?.data?.totalItems);
  };
  const handleChangePage = async (newPage, s) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage, pageSize: s });
    getRefferals(newPage, s);
  };
  const handleChangeRowsPerPage = async (page, size) => {
    getRefferals(0, size);
    setPagination({ ...pagination, current: 0, pageSize: size });
  };
  useEffect(() => {
    console.log({ userDetails });
    getRefferals();
  }, [userDetails]);
  return (
    <Layout currentPage="loan-request">
      <div className="text-end my-2 mx-3">
        <button
          className="btn btn-primary"
          onClick={() => setShowLoan(false)}
          type="button"
        >
          Book Loan
        </button>
      </div>
      <MaterialTable
        title="ReferalTable"
        columns={[
          { title: 'Type of Loan', field: 'typeOfLoan' },
          { title: 'Request Amount', field: 'requestAmount' },
          // { title: 'CATEGORY', field: 'category' },
          { title: 'Loan Term', field: 'loanTerm' },
          { title: 'Loan Status', field: 'loanStatus' },
          {
            title: 'When Needed',
            field: 'whenNeeded',
            render: (row) => row?.whenNeeded ?? 'N/A',
          },
        ]}
        data={users}
        options={{
          pageSize: pagination.pageSize,
          headerStyle: {
            backgroundColor: '#FF6700',
            color: '#FFF',
            zIndex: 0,
          },
        }}
        // actions={[
        //   {
        //     icon: 'save',
        //     tooltip: 'Save User',
        //     // onClick: (event, rowData) => alert("You saved " + rowData.name)
        //   },
        // ]}
      />
      <Pagination
        onShowSizeChange={handleChangeRowsPerPage}
        total={metaData}
        onChange={handleChangePage}
      />
    </Layout>
  );
}
