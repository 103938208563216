const headers = [
  {
    header: 'Make Donation',
    description: '',
  },
  {
    header: 'Make Donation',
    description: '',
  },
];

const donationButtonTitle = ['Continue', 'Confirm'];

const trxInfo = [
  {
    message: 'Success',
    status: true,
    description: 'Your transaction has been done successfully',
  },
  {
    message: 'Insufficient Fund',
    status: false,
    description: `You do not have sufficient funds in your account. Please
    fund your account to continue`,
  },
];

export { headers, donationButtonTitle, trxInfo };
