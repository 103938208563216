import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import GoogleMapImage from '../../../../utils/map';

export default function Map(props) {
  const { showModal, hideModal } = props;

  return (
    <Modal
      open={showModal}
      onClose={() => hideModal(false)}
      top
      animationDuration={400}
      center
    >
      <div className="modal-body-rs col-sm-12 col-md-10 pt-4">
        <div
          className="w-100 mt-5 mb-3 map-wrap"
          style={{ position: 'relative' }}
        >
          {/* ADD MAP  */}
          <GoogleMapImage />
        </div>
      </div>
    </Modal>
  );
}
