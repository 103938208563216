import React from 'react';
import './style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Button from '../../shared/Button';

const ChangeBusinessOwner = (props) => {
  const { showModal, hideModal } = props;
  return (
    <Modal
      isOpen={showModal}
      toggle={() => hideModal(false)}
      centered
      size="md"
    >
      <ModalHeader
        className="justify-content-center"
        style={{
          background: '#F1F7FA',
          fontFamily: 'Lucida Bright',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#064A72',
          padding: '30px',
          borderRadius: '14px 14px 0 0 !important',
        }}
      >
        Change Business Owner
      </ModalHeader>
      <ModalBody
        className="text-center"
        style={{
          padding: '49px 55px 33px 55px',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '135.6%',
          color: '#4F4F4F',
        }}
      >
        <div className="mb-4 w-100">
          This action will transfer this business to another administrator. You
          will no longer be the owner of this business, and so you can be
          removed from it.
        </div>
        <div className="my-5 w-100">
          <select
            name=""
            id=""
            className="w-100"
            style={{
              fontFamily: 'Lucida Bright',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '19px',
              /* identical to box height */

              color: '#828282',
            }}
          >
            <option value="">Select New Business Owner</option>
          </select>
        </div>

        <Button
          content="Transfer"
          onClick={() => {}}
          style={{
            width: '100%',
            marginTop: '50px',
          }}
        />
        <div
          className="text-primary mt-2"
          onClick={() => {
            hideModal(false);
          }}
        >
          Cancel
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangeBusinessOwner;
