import complaint from '../../../../../assets/images/complaint.png';

const disputeLog = [
  {
    id: '1001',
    ticketNumber: '229332',
    timestamp: '12:00pm',
    amount: '+4,00.00',
    status: 'Resolved',
    avatar: complaint,
  },
  {
    id: '1002',
    ticketNumber: '429332',
    timestamp: '05:00pm',
    amount: '+14,000.00',
    status: 'Under review',
    avatar: complaint,
  },
];

const description = {};

export { disputeLog, description };
