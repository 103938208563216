import React from 'react';

import { ChatSettingContainer, ChatSettingWrapper } from './styles';
import arrowLeft from '../../../assets/images/arrowLeft.png';

const ChatSettingsOptions = (props) => {
  const { data } = props;
  const { title, description } = data;
  return (
    <ChatSettingContainer>
      <div>
        <div>{title}</div>
        <div className="description">{description}</div>
      </div>
      <img src={arrowLeft} alt="arrow" />
    </ChatSettingContainer>
  );
};

const ChatSettingsOptionsList = (props) => {
  const { options } = props;
  return (
    <ChatSettingWrapper>
      {options.map((item) => (
        <ChatSettingsOptions data={item} key={item.id} />
      ))}
    </ChatSettingWrapper>
  );
};

export default ChatSettingsOptionsList;
