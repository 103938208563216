import React from 'react';
import PropTypes from 'prop-types';

function EventInfo(props) {
  const { handleFormInput, values } = props;

  return (
    <div className="event-inner-content">
      <div className="input-container">
        <input
          name="eventName"
          values={values.eventName}
          onChange={(e) => {
            handleFormInput(e.target.value, 'eventName');
          }}
          placeholder="Title of Cause"
        />
        <span>What is the name of your event</span>
      </div>
      <div className="input-container">
        <input
          name="organizersName"
          values={values.tags}
          onChange={(e) => {
            handleFormInput(e.target.value, 'organizersName');
          }}
          placeholder="Organizers Name"
        />
        <span>Organizers Name</span>
      </div>
      <div className="input-container">
        <textarea
          style={{
            overflow: 'hidden',
            height: '33px',
          }}
          name="description"
          values={values.description}
          onChange={(e) => {
            handleFormInput(e.target.value, 'description');
          }}
          placeholder="Event Description"
        />
        <span>Description of event</span>
      </div>
      <div className="input-container">
        <select
          name="isPrivate"
          onChange={(e) => {
            handleFormInput(e.target.value, 'isPrivate');
          }}
          defaultValue={values.isPrivate}
        >
          <option value="true">Private</option>
          <option value="false">Public</option>
        </select>
        <span>Is the event private or public?</span>
      </div>
    </div>
  );
}

EventInfo.propTypes = {
  handleFormInput: PropTypes.func.isRequired,
};

export default EventInfo;
