import React from 'react';
import { Col, Row } from '../containers/containers';
import styles from './styles.module.css';
import { NormalText, BoldText } from '../texts/allText';

const TransactionStats = ({ name, count, color }) => {
  return (
    <Col
      justify="space-between"
      align="flex-start"
      className={styles.TransactionStats}
    >
      <NormalText bottom={25} size={25} color={color}>
        {name}
      </NormalText>
      <BoldText color={color} size={35}>
        {count}
      </BoldText>
    </Col>
  );
};
export const TransactionCategory = ({ name, count, color }) => {
  return (
    <Row
      justify="space-between"
      align="flex-start"
      className={styles.TransactionCategory}
    >
      <NormalText bottom={25} size={13} color={color}>
        {name}
      </NormalText>
      <BoldText color={color} size={25}>
        {count}
      </BoldText>
    </Row>
  );
};
export default TransactionStats;
