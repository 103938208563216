import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import { customBaseUrl, httpPut } from '../../../../action/http';
import bannerImage from '../../../../assets/image/banner.jpg';
import avatarImg from '../../../../assets/image/avatar3.png';
import cameraImg from '../../../../assets/image/camera_alt.png';
import Button from '../../Button';
import validateImage from '../../../../utils/validateImage';
import './index.scss';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import { handleGetPageCategories } from '../../../pages/Wayagram/container';

export default function WayagramPageProfile(props) {
  const { showModal, hideModal, pageData } = props;
  const myRef = React.useRef(null);
  const {
    wayagramProfile: profile,
    wayagramReload,
    setWayagramReload,
  } = useContext(WayagramContext);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState({ ...pageData });
  const [checkData, setCheckData] = useState({});
  const [categories, setCategories] = useState([]);

  const [previewImage, setPreviewImage] = useState(null);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);

  const handlePreview = (e, name) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      if (name === 'image') {
        setPostData({ ...postData, imageUrl: imageData });
      } else {
        setPostData({ ...postData, headerImageUrl: imageData });
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'image') {
          setPreviewImage(reader.result);
        } else {
          setPreviewHeaderImage(reader.result);
        }
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    previewImage && newForm.append('imageUrl', postData.imageUrl);
    /* eslint-disable no-unused-expressions */
    previewHeaderImage &&
      newForm.append('headerImageUrl', postData.headerImageUrl);
    newForm.append('userId', profile.id);
    newForm.append('categoryId', postData.categoryId);
    newForm.append('category', postData.category);
    newForm.append('pageId', pageData.id);
    checkData.username && newForm.append('username', postData.username);
    checkData.title && newForm.append('title', postData.username);
    newForm.append('isPublic', postData.isPublic || false);
    checkData.websiteUrl && newForm.append('websiteUrl', postData.websiteUrl);
    checkData.description &&
      newForm.append('description', postData.description);

    const res = await httpPut(
      `/main/page/update-page`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        setWayagramReload(!wayagramReload);
        setLoading(false);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  useEffect(() => {
    if (pageData.id) {
      setPostData({ ...pageData });
      setCheckData({ ...pageData });
    }
    (async () => {
      const res = await handleGetPageCategories();
      setCategories(res);
    })();
  }, [pageData.id]);

  return (
    <div ref={myRef}>
      <Modal
        isOpen={showModal}
        id="wayagram-profile-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header d-flex p-3">
          <h4 className="">Edit Page Profile</h4>
          <Button
            loading={loading}
            disabled={loading || false}
            className="btn btn-primary ml-auto"
            content="save"
            onClick={handleSubmit}
          />
        </div>
        <ModalBody>
          <div className="banner-div">
            <img
              src={previewHeaderImage || postData.headerImageUrl || bannerImage}
              alt="banner"
            />
            <img src={cameraImg} className="upload-banner-input" alt="camera" />
            <input
              type="file"
              accept="image/*"
              className="upload-banner-input2"
              onChange={(e) => handlePreview(e, 'headerImage')}
            />
            <div className="avatar-section d-flex mb-4">
              <img
                src={previewImage || postData?.imageUrl || avatarImg}
                alt="avatar"
              />
              <img src={cameraImg} className="upload-input" alt="camera" />
              <input
                type="file"
                accept="image/*"
                className="upload-input2"
                onChange={(e) => handlePreview(e, 'image')}
              />
            </div>
          </div>
          <div className="form-div p-3">
            <label htmlFor="">username</label>
            <input
              className="form-control mb-3"
              value={postData.username}
              onChange={(e) => {
                setPostData({ ...postData, username: e.target.value });
                setCheckData({ ...checkData, username: e.target.value });
              }}
            />
            <label htmlFor="">title</label>
            <input
              className="form-control mb-3"
              value={postData.title}
              onChange={(e) => {
                setPostData({ ...postData, title: e.target.value });
                setCheckData({ ...checkData, title: e.target.value });
              }}
            />

            <label htmlFor="">Description</label>
            <input
              className="form-control mb-3"
              value={postData.description}
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
                setCheckData({ ...checkData, description: e.target.value });
              }}
            />
            <label htmlFor="">Category</label>
            <select
              className="form-control mb-3"
              // value={postData.category}
              onChange={(e) => {
                const [id, name] = e.target.value.split(',');
                setPostData({
                  ...postData,
                  categoryId: id,
                  category: name,
                });
                setCheckData({
                  ...postData,
                  categoryId: id,
                  category: name,
                });
              }}
            >
              <option value={postData.category} selected disabled>
                {postData.category}
              </option>
              {categories
                ? categories.map((item) => (
                    <option value={`${item.id},${item.title}`} key={item.id}>
                      {item.title}
                    </option>
                  ))
                : ''}
            </select>

            <label htmlFor="">Choose Privacy</label>
            <select
              // value={postData.isPublic}
              className="form-control mb-3"
              onChange={(e) => {
                setPostData({ ...postData, isPublic: e.target.value });
              }}
            >
              <option value={postData.isPublic} selected disabled>
                {postData.isPublic === true ? 'Public' : 'Private'}
              </option>
              <option value="true">Public</option>
              <option value="false">Private</option>
            </select>
            <label htmlFor="">Website Url</label>
            <input
              className="form-control mb-3"
              value={postData.websiteUrl}
              onChange={(e) => {
                setPostData({ ...postData, websiteUrl: e.target.value });
                setCheckData({ ...checkData, websiteUrl: e.target.value });
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

WayagramPageProfile.defaultProps = {
  showModal: false,
};

WayagramPageProfile.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  //   pageData: .object,
};
