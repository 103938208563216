import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import validator from 'validator';
import { notification } from 'antd';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import PinModal from '../PinModal';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { PaymentContext } from '../../../../store/context/PaymentContext';
import { ModalContext } from '../../../../store/context/ModalContext';
// import avatar from '../../../../assets/image/avatar.png';

export default function Main(props) {
  const { hideModal } = props;
  const [otp, setOtp] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const { setPaymentReload, reload } = useContext(PaymentContext);
  const { setShowResendOtp, setModalAction } = useContext(ModalContext);
  const [requestType, setRequestType] = useState('wayaUser');
  const [type, setType] = useState('userId');
  const [isCorporate, setIsCorporate] = useState(null);
  const [accountData, setAccountData] = useState([]);
  const [isPhone, setIsPhone] = useState(false);
  const [pWarning, setPwarnin] = useState('');

  const handleSendPaymentRequest = async () => {
    showLoader();
    if (type === 'userId' && !data?.userId) {
      swal('Oops!', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (validator.isEmpty(data?.amount)) {
      swal('Oops!', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (validator.isEmpty(data?.receiverName)) {
      swal('Oops!', 'Receiver cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (!data.reason || data.reason === '') {
      swal('Oops!', 'Note is Required', 'error');
      hideLoader();
      return;
    }
    const postData = {
      ...data,
      amount: data.amount,
      reason: data.reason,
      senderId: profile.userId,
      senderEmail: profile.email,
      senderName: `${profile.firstName} ${profile.surname}`,
      senderProfileId: profile?.id,
    };

    const res = await httpPost(
      `/payment-request-waya`,
      [postData],
      customBaseUrl.paymentRequestUrl
    );
    // console.log(res);
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        hideModal(false);
        setPaymentReload(!reload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleSendNonWayaPR = async () => {
    showLoader();

    // if (!data.phoneNumber || data.phoneNumber === '') {
    //   swal('Oops!', 'Phone Number cannot be empty', 'error');
    //   hideLoader();
    //   return;
    // }
    // if (!data.email || data.email === '') {
    //   swal('Oops!', 'Email cannot be empty', 'error');
    //   hideLoader();
    //   return;
    // }
    // if (!validator.isEmail(data.email)) {
    //   swal('Oops!', 'Invalid Email Format', 'error');
    //   hideLoader();
    //   return;
    // }
    if (!data.amount || data.amount === '') {
      swal('Oops!', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (!data.reason || data.reason === '') {
      swal('Oops!', 'Note is Required', 'error');
      hideLoader();
      return;
    }
    const formatedPhone = `234${data.phoneNumber.slice(1)}`;
    const postData = {
      amount: data.amount,
      reason: data.reason,
      receiverPhoneNumber: isPhone ? formatedPhone : '',
      receiverName: data.name,
      receiverEmail: !isPhone ? data.phoneNumber : '',
      senderId: profile.userId,
      senderName: `${profile.firstName} ${profile.surname}`,
      senderEmail: profile.email,
      senderProfileId: profile?.id,
    };

    const res = await httpPost(
      `/payment-request-non-waya`,
      postData,
      customBaseUrl.paymentRequestUrl
    );
    if (res.status) {
      hideLoader();
      swal('Done', `${res.message}`, 'success').then(() => {
        hideModal(false);
        setPaymentReload(!reload);
      });
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    if (res.status) {
      setLoading(false);
      setShowPin(false);
      if (requestType === 'wayaUser') handleSendPaymentRequest();
      if (requestType === 'nonWayaUser') handleSendNonWayaPR();
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleSelectAccount = () => {
    console.log({ isCorporate });
    if (isCorporate === 'true') {
      const cData = accountData?.filter((e) => e?.corporate === true)[0];
      setData({
        ...data,
        receiverProfileId: cData?.id,
        receiverEmail: cData?.email,
        receiverPhoneNumber: cData?.phoneNumber,
        receiverName: cData?.otherDetails?.organisationName,
        receiverId: cData?.userId,
      });
    } else {
      const pData = accountData?.filter((e) => e?.corporate === false)[0];
      setData({
        ...data,
        receiverProfileId: pData?.id,
        receiverEmail: pData?.email,
        receiverPhoneNumber: pData?.phoneNumber,
        receiverName: `${pData?.firstName} ${pData?.surname}`,
        receiverId: pData?.userId,
      });
    }
  };
  const handleAccountType = async () => {
    //  if (!data?.userId) return swal('userId can not be null');
    let res;
    if (type === 'userId') {
      res = await httpGet(
        `${
          customBaseUrl.authUrl
        }/api/v1/profile/account/${false}/${false}?query=all&userId=${
          data?.userId
        }`
      );
    } else if (type === 'phoneNo') {
      res = await httpGet(
        `${
          customBaseUrl.authUrl
        }/api/v1/profile/account/${false}/${false}?query=${
          data?.receiverPhoneNumber
        }`
      );
    } else if (type === 'email') {
      res = await httpGet(
        `${
          customBaseUrl.authUrl
        }/api/v1/profile/account/${false}/${false}?query=${data?.receiverEmail}`
      );
    }
    showLoader();
    if (res?.status === true) {
      hideLoader();
      console.log(
        isCorporate,
        res?.data[0]?.otherDetails?.organisationName ??
          `${res?.data[0]?.firstName} ${res?.data[0]?.surname}`
      );
      notification.success(res?.message);
      if (res?.data?.length === 1) {
        setData({
          ...data,
          receiverProfileId: res?.data[0]?.id,
          receiverEmail: res?.data[0]?.email,
          receiverPhoneNumber: res?.data[0]?.phoneNumber,
          receiverName:
            res?.data[0]?.otherDetails?.organisationName ??
            `${res?.data[0]?.firstName} ${res?.data[0]?.surname}`,
          receiverId: res?.data[0]?.userId,
        });
      } else {
        setAccountData(res?.data);
      }
    } else {
      setLoading(false);
      hideLoader();
      setData({
        ...data,
        receiverProfileId: null,
        receiverEmail: null,
        receiverPhoneNumber: null,
        receiverId: null,
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyAccount = async () => {
    if (!data?.receiverAccountNo) return swal('Account No can not be null');
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/fetchUserByAccountNo/${data?.receiverAccountNo}`
    );
    showLoader();
    if (res?.status) {
      hideLoader();
      notification.success(res?.message);
      setData({
        ...data,
        receiverProfileId: res?.data?.profileId,
        receiverEmail: res?.data?.email,
        receiverPhoneNumber: res?.data?.phoneNumber,
        receiverName: res?.data?.accountName,
        receiverId: res?.data?.userId,
      });
    } else {
      setLoading(false);
      hideLoader();
      setData({
        ...data,
        receiverProfileId: '',
        receiverEmail: '',
        receiverPhoneNumber: '',
        receiverId: '',
        receiverName: '',
      });
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
    return res;
  };
  useEffect(() => {
    if (accountData.length > 1) {
      handleSelectAccount();
    }
  }, [isCorporate]);

  const validateInput = (val) => {
    setPwarnin('');
    const phoneValidation = /^([\s()-]*\d[\s()-]*){11}$/;
    const mailValidation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (val.match(phoneValidation)) {
      // correct phone structure
      setPwarnin('');
      setIsPhone(true);
      return true;
    }
    if (val.match(mailValidation)) {
      // correct mail format
      setPwarnin('');
      setIsPhone(false);
      return true;
    }
    if (val?.length > 0)
      setPwarnin(
        'Please enter a valid email address in the following format: yourname@example.com or valid phone number'
      );
    // incorrect structure
    return false;
  };
  return (
    <>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              name="filter"
              className=""
              value={requestType}
              onChange={(e) => setRequestType(e.target.value)}
            >
              <option value="Select" key="select" selected disabled>
                Select User Type
              </option>
              <option value="wayaUser">Waya User</option>
              <option value="nonWayaUser">Non Waya User</option>
            </select>
            <span className="input-detail">Select Recipient Type</span>
          </div>

          {requestType === 'wayaUser' && (
            <div>
              <div className="inputbox-with-one-input">
                <select
                  name="filter"
                  className=""
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                    setData({
                      ...data,
                      receiverProfileId: '',
                      receiverEmail: '',
                      receiverPhoneNumber: '',
                      receiverId: '',
                      receiverName: '',
                    });
                    setAccountData([]);
                    setIsCorporate(null);
                  }}
                >
                  <option value="Select" key="select" selected disabled>
                    Select Channel
                  </option>
                  <option value="userId">USER ID</option>
                  <option value="email">EMAIL</option>
                  <option value="accountNo">WAYA ACCOUNT NUMBER</option>
                  <option value="phoneNo">PHONE NUMBER</option>
                </select>
                <span className="input-detail">Select Request Channel</span>
              </div>
              {/* {isCorporate === 'true' ? (
                <div className="inputbox-with-one-input">
                  <select
                    name="filter"
                    className=""
                    value={isRegistered}
                    onChange={(e) => setIsRegistered(e.target.value)}
                  >
                    <option value="Select" key="select" selected disabled>
                      Account Type
                    </option>
                    <option value={true}>REGISTERED BUSINESS</option>
                    <option value={false}>NON REGISTERED BUSINESS</option>
                  </select>
                  <span className="input-detail">Account Type</span>
                </div>
              ) : null} */}
              {type === 'userId' ? (
                <div>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="form-control border-end-0 border-top-0 border-start-0"
                      placeholder="User ID"
                      required
                      value={data?.userId}
                      onChange={(e) => {
                        setData({
                          ...data,
                          receiverName: '',
                          userId: e.target.value,
                        });
                        setAccountData([]);
                      }}
                    />
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '0px',
                        height: '100%',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onClick={handleAccountType}
                    >
                      Verify
                    </button>
                  </div>
                  <span className="input-detail">User ID of Recipent</span>
                  {type !== 'accountNo' && accountData?.length > 0 && (
                    <div className="inputbox-with-one-input">
                      <select
                        name="filter"
                        className=""
                        value={isCorporate}
                        onChange={(e) => setIsCorporate(e.target.value)}
                      >
                        <option value="Select" key="select" selected>
                          Account Type
                        </option>
                        <option value={false}>PERSONAL</option>
                        <option value>CORPORATE</option>
                      </select>
                      <span className="input-detail">Account Type</span>
                    </div>
                  )}
                  <div className="inputbox-with-one-input">
                    <input
                      placeholder="Name"
                      disabled
                      type="text"
                      value={data?.receiverName}
                    />
                    <span className="input-detail">Name of Recipent</span>
                  </div>
                </div>
              ) : type === 'email' ? (
                <div>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="form-control border-end-0 border-top-0 border-start-0"
                      placeholder="Email"
                      required
                      value={data?.receiverEmail}
                      onChange={(e) => {
                        setData({
                          ...data,
                          receiverName: '',
                          receiverEmail: e.target.value,
                        });
                        setAccountData([]);
                      }}
                    />
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '0px',
                        height: '100%',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onClick={handleAccountType}
                    >
                      Verify
                    </button>
                  </div>
                  <span className="input-detail">
                    Email address of Recipent
                  </span>
                  {type !== 'accountNo' && accountData?.length > 0 && (
                    <div className="inputbox-with-one-input mt-3">
                      <select
                        name="filter"
                        className=""
                        value={isCorporate}
                        onChange={(e) => setIsCorporate(e.target.value)}
                      >
                        <option value="Select" key="select" selected>
                          Account Type
                        </option>
                        <option value={false}>PERSONAL</option>
                        <option value>CORPORATE</option>
                      </select>
                      <span className="input-detail">Account Type</span>
                    </div>
                  )}
                  <div className="inputbox-with-one-input">
                    <input
                      placeholder="Name"
                      disabled
                      type="text"
                      value={data?.receiverName}
                    />
                    <span className="input-detail">Name of Recipent</span>
                  </div>
                </div>
              ) : type === 'phoneNo' ? (
                <div>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="form-control border-end-0 border-top-0 border-start-0"
                      placeholder="Phone number"
                      required
                      value={data?.receiverPhoneNumber}
                      onChange={(e) => {
                        setData({
                          ...data,
                          receiverName: '',
                          receiverPhoneNumber: e.target.value,
                        });
                        setAccountData([]);
                      }}
                    />
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '0px',
                        height: '100%',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onClick={handleAccountType}
                    >
                      Verify
                    </button>
                  </div>
                  <span className="input-detail">Phone Number of Recipent</span>
                  {type !== 'accountNo' && accountData?.length > 0 && (
                    <div className="inputbox-with-one-input">
                      <select
                        name="filter"
                        className=""
                        value={isCorporate}
                        onChange={(e) => setIsCorporate(e.target.value)}
                      >
                        <option value="Select" key="select" selected>
                          Account Type
                        </option>
                        <option value={false}>PERSONAL</option>
                        <option value>CORPORATE</option>
                      </select>
                      <span className="input-detail">Account Type</span>
                    </div>
                  )}
                  <div className="inputbox-with-one-input">
                    <input
                      placeholder="Name"
                      disabled
                      type="text"
                      value={data?.receiverName}
                    />
                    <span className="input-detail">Name of Recipent</span>
                  </div>
                </div>
              ) : type === 'accountNo' ? (
                <div>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="form-control border-end-0 border-top-0 border-start-0"
                      placeholder="Account No"
                      required
                      value={data?.receiverAccountNo}
                      onChange={(e) =>
                        setData({
                          ...data,
                          receiverAccountNo: e.target.value,
                          receiverName: '',
                        })
                      }
                    />
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '0px',
                        height: '100%',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onClick={handleVerifyAccount}
                    >
                      Verify
                    </button>
                  </div>
                  <span className="input-detail">Account No of Recipent</span>
                  <div className="inputbox-with-one-input">
                    <input
                      placeholder="Name"
                      disabled
                      type="text"
                      value={data?.receiverName}
                    />
                    <span className="input-detail">Name of Recipent</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            // <div
            //   className="inputbox-with-one-input"
            //   style={{ position: 'relative' }}
            // >
            //   <input
            //     placeholder="Select User"
            //     type="text"
            //     value={query}
            //     onChange={(e) => {
            //       // e.persist()
            //       setQuery(e.target.value);
            //       handleSearch();
            //     }}
            //   />
            //   <CustomDropdown
            //     options={options}
            //     handleClick={handleSelectOption}
            //   />
            //   <span className="input-detail">Name of Recipent</span>
            // </div>
          )}

          {requestType === 'nonWayaUser' && (
            <>
              <div className="inputbox-with-one-input">
                <div className="inputbox-with-one-input">
                  <input
                    placeholder="Phone number or email address"
                    type="text"
                    onPointerLeave={(e) => validateInput(e.target.value)}
                    onChange={(e) => {
                      setData({ ...data, phoneNumber: e.target.value });
                    }}
                  />
                  <span className="input-detail">
                    Email Address or 11 digit phone No
                  </span>
                  <span className="text-danger input-detail">{pWarning}</span>
                </div>
              </div>
              {/* <div className="inputbox-with-one-input">
                <input
                  placeholder="Email Address"
                  type="email"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
                <span className="input-detail">Email Address of Recipent</span>
              </div> */}
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Name"
                  type="text"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
                <span className="input-detail">Name of Recipent</span>
              </div>
            </>
          )}

          <div className="inputbox-with-one-input">
            <input
              placeholder="Amount"
              type="number"
              min="0"
              value={data.amount}
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
              }}
            />
            <span className="input-detail">
              How much do you want to request?
            </span>
          </div>

          <div className="inputbox-with-one-input">
            <input
              placeholder="Add a Note"
              type="text"
              value={data.reason}
              onChange={(e) => {
                setData({ ...data, reason: e.target.value });
              }}
            />
            <span className="input-detail">Reason for payment request</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-3"
          color="primary"
          onClick={() => {
            if (!data.amount || data.amount === '') {
              swal('Oops!', 'amount input field can not be empty', 'error');
              setLoading(false);
              return;
            }
            // if (requestType === 'nonWayaUser' && !data.email && !data.userId) {
            //   swal('Oops!', 'email input field can not be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            setShowPin(true);
          }}
        >
          Send Payment Request
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleVerifyPin}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
    </>
  );
}

Main.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
