import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import { customBaseUrl, httpPost } from '../../../action/http';
import Layout from '../../shared/Layout';
import './index.scss';
import GramCard from '../../shared/Cards/Gram';
import Report from '../../shared/Modals/ReportAndDispute';
import {
  getUserFeeds,
  getWayaProfile,
  handleBookmark,
  handleBlockUser,
  handleFollow,
  getConnectionStatus,
  addSelectedInterest,
  removeSelectedInterest,
  getUnAddedInterest,
  handleGetUserInterest,
} from './container';
import TopSection from '../../shared/Cards/TopSection';
import Loader from '../../shared/Loader';
import { WayagramContext } from '../../../store/context/WayagramContext';
import RightSidebar from './RightSidebar';
import OtherDropdown from '../../shared/Dropdown/OtherDropdown';
import privateIcon from '../../../assets/image/privateIcon.png';
import InterestModal from '../../shared/Modals/InterestsModal/Interest';

export default function Profile(props) {
  const {
    match: {
      params: { id },
    },
  } = props;
  const history = useHistory();
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  // const [list, setList] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [active, setActive] = useState('Post');
  const [showDropdown, setShowDropdown] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [unAddedInterest, setUnAddedInterest] = useState([]);
  const [selectedInterest, seSelectedInterest] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  let handleGetFeeds;

  const tabItems = ['Post', 'Likes', 'Media', 'Donation', 'Events'];

  const innerTabsItems = [
    { name: 'Groups', type: 'Groups' },
    { name: 'Interest', type: 'Interest' },
    { name: 'Pages', type: 'Pages' },
    { name: 'Polls', type: 'Polls' },
    { name: 'Shop', type: 'Shop' },
    { name: 'Ads', type: 'Ads' },
    { name: 'Auction', type: 'Auction' },
    { name: 'Post Promotion', type: 'Post Promotion' },
  ];
  const innerTabsItemsOther = [
    { name: 'Report User', type: 'Report' },
    { name: 'Message', type: 'Message' },
  ];

  const getPageProfile = async () => {
    setLoading(true);
    const wayaProfile = await getWayaProfile(id);
    setProfileData(wayaProfile);
  };

  const handleInterestSuggestion = async (profileId) => {
    const resData = await getUnAddedInterest(profileId);
    setUnAddedInterest(resData);
  };

  const handleGetInterest = async () => {
    const res = await handleGetUserInterest(id);
    seSelectedInterest(res);
  };

  useEffect(() => {
    getPageProfile();
    handleGetInterest();
  }, []);

  useEffect(() => {
    if (profileData.id) {
      if (loggedInUser.id !== profileData.id) {
        (async () => {
          const status = await getConnectionStatus(
            loggedInUser.id,
            profileData.id
          );
          setConnectionStatus(status);
          if (!status.connected && profileData?.notPublic) {
            setActive('privateMode');
          }
          handleGetFeeds();
          setLoading(false);
          setTrigger(false);
          setBtnLoading(false);
        })();
      } else {
        handleGetFeeds();
      }
      setLoading(false);
      setTrigger(false);
      handleInterestSuggestion(profileData.id);
      setBtnLoading(false);
    }
  }, [profileData.id, trigger, id]);

  handleGetFeeds = async () => {
    const allFeeds = await getUserFeeds(id);
    setFeeds(allFeeds);
  };

  const handleLikePost = async (postId) => {
    const data = {
      post_id: postId,
      profile_id: loggedInUser.id,
      type: 'user',
    };
    const res = await httpPost(
      '/all-posts/like-post',
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success');
    }
  };

  const addBookmark = async (postId) => {
    const profileId = loggedInUser.id;
    const res = await handleBookmark(profileId, postId);
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const blockUser = async (username) => {
    const authId = loggedInUser.user.userId;
    const res = await handleBlockUser(authId, username);
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleFollowUser = async (username) => {
    const authId = loggedInUser.user.userId;
    const res = await handleFollow(authId, username);
    if (res.status) {
      swal('Done', res.message, 'success');
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const addInterest = async (interestId) => {
    const profileId = loggedInUser.id;
    const data = { profileId, interestId };
    await addSelectedInterest(data);
  };

  const removeInterest = async (interestId) => {
    const profileId = loggedInUser.id;
    const data = { profileId, interestId };
    await removeSelectedInterest(data);
  };

  // if (!profileData || !feeds.length) {
  //   return <Loader />;
  // }
  return (
    <Layout route={props} currentPage={`wayagram-profile/${id}`}>
      <div className="row wayagram-div">
        <div className="col-8">
          {profileData.id ? (
            <>
              <TopSection
                loggedInUser={loggedInUser}
                profileData={profileData}
                connectionStatus={connectionStatus}
                setTrigger={setTrigger}
                setBtnLoading={setBtnLoading}
                btnLoading={btnLoading}
              />
              <div
                className="menu-options mt-3"
                style={{
                  // overflowX: 'scroll',
                  height: '50px',
                  position: 'relative',
                  borderBottom: '1px solid #e5e5e5',
                }}
              >
                <div
                  className="lato-text"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: '#b6b3b3',
                  }}
                >
                  {tabItems.map((val) => (
                    <div
                      className={
                        val === active ? 'active add-cursor' : 'add-cursor'
                      }
                      onClick={() => {
                        setActive(val);
                        // handleGetData(val);
                      }}
                      key={Math.random()}
                    >
                      {val}
                    </div>
                  ))}
                  <div style={{ position: 'relative' }}>
                    <div
                      className="add-cursor"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      More
                    </div>
                    {showDropdown && (
                      <OtherDropdown
                        hide={setShowDropdown}
                        options={
                          profileData?.id === loggedInUser?.id
                            ? innerTabsItems
                            : innerTabsItemsOther
                        }
                        handleClick={(item) => {
                          console.log(item);
                          if (item === 'Groups') history.push('/manage-groups');
                          if (item === 'Pages') history.push('/manage-pages');
                          if (item === 'Interest') {
                            setShowInterest(true);
                          }
                          if (item === 'Report') {
                            setShowReportModal(true);
                          }
                          if (item === 'Message') {
                            history.push({
                              pathname: `/chat`,
                              from: 'wayagramProfile',
                              data: profileData,
                            });
                          }
                        }}
                        positionLeft="-127px"
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="mid-bottom-content"></div> */}

              {active === 'privateMode' && (
                <div className="mt-5 mb-5 text-center">
                  <img
                    className="mb-4 mt-5"
                    src={privateIcon}
                    alt="private"
                    style={{ height: '40px' }}
                  />
                  <h3>This account is private</h3>
                  <h5 className="text-muted">
                    follow this account so see their posts
                  </h5>
                </div>
              )}

              {active === 'Post' && (
                <div className="">
                  {feeds.length && !loading ? (
                    feeds.map((post) => (
                      <GramCard
                        key={Math.random()}
                        data={post}
                        handleDeletePost={() => console.log('delete')}
                        showPostModal={() => console.log('delete')}
                        handleLikePost={handleLikePost}
                        addBookmark={addBookmark}
                        blockUser={blockUser}
                        handleFollowUser={handleFollowUser}
                        fromLocation="profile"
                      />
                    ))
                  ) : (
                    <Skeleton width={406} height={236} />
                  )}
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>

        <RightSidebar />
      </div>

      {showInterest ? (
        <InterestModal
          showModal={showInterest}
          hideModal={setShowInterest}
          step={0}
          addInterest={addInterest}
          removeInterest={removeInterest}
          unAddedInterest={unAddedInterest}
          selectedInterest={selectedInterest || []}
          submitInterest={() => setShowInterest(false)}
        />
      ) : (
        ''
      )}
      {showReportModal ? (
        <Report
          showModal={showReportModal}
          hideModal={setShowReportModal}
          title="Report User"
          reported={profileData}
          type="User"
          reporter={loggedInUser}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}

Profile.propTypes = {
  // match: PropTypes.shape.isRequired,
};
