import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  Tooltip,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'reactstrap';
import imgPlaceholder from '../../../../assets/image/imgPlaceholder.png';
// import post1 from '../../../../assets/image/post1.png';
import galleryIcon from '../../../../assets/image/gallery.png';
// import gallerIcon from '../../../../assets/images/messageIcon.png';
import paidPostIcon from '../../../../assets/image/postPaidIcon.svg';
import gifIcon from '../../../../assets/images/gifIcon.png';
import postIcon3 from '../../../../assets/images/postIcon3.png';
import cameraIcon from '../../../../assets/images/cameraIcon.png';
import '../FundWallet/index.scss';
import { customBaseUrl, httpPost } from '../../../../action/http';
import Button from '../../Button';
// import validateImage from '../../../../utils/validateImage';
import './imgGrid.scss';
import { ImagePreviewOnly, PaidPostPreview, PollPreview } from './postUtils';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import PollDisclaimer from './PollDisclaimer';
import MentionInput from '../../CustomInput/MentionInput';
import TermsAndCondition from '../Terms/TermsAndCondition';
import { convertTimeToMs } from '../../../../utils/helper';

// import { getWayagramProfile } from '../../../../store/calls';

const CreatePost = (props) => {
  const {
    showModal,
    hideModal,
    id,
    mode,
    handleSubmit,
    selectedData,
    type,
    groupId,
    pageId,
  } = props;
  const [postData, setPostData] = useState({ ...selectedData });
  const [loading, setLoading] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltype, setTooltype] = useState('');
  const {
    wayagramProfile: profile,
    reload,
    setReload,
  } = useContext(WayagramContext);
  const [preview, setPreview] = useState([]);
  const [postMode, setPostMode] = useState('');
  const [showPreview, setShowPreview] = useState(true);
  const [showPoll, setShowPoll] = useState(false);
  const [showPollDisclaimer, setShowPollDisclaimer] = useState(false);
  const [placeholderMode, setPlaceholderMode] = useState('post');
  const [mentionList, setMentionList] = useState([]);
  const [hashTagList, setHashTagList] = useState([]);

  const handleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  // const handleCreatePost = async () => {
  //   const allFeeds = await createWayagramPost('b5100b04-bac5-4a71-8643-4711217c9ca6');
  // };

  const handlePreview = (e) => {
    const { files } = e.target;
    if (files.length > 4) {
      swal('Oops!', `Upload exceeded limit of 4`, 'error');
      return;
    }
    if (files.length) {
      setPostData({ ...postData, images: files });
      let imgArr = [];
      /* eslint-disable no-plusplus */
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        /* eslint-disable no-loop-func */
        reader.onloadend = () => {
          imgArr = [...imgArr, reader.result];
          if (files.length - 1 === i) {
            console.log(files.length - 1 === i);
            console.log([...preview, ...imgArr]);
            setPreview([...preview, ...imgArr]);
          }
        };
        reader.readAsDataURL(files[i]);
        // const validFormat = validateImage(files[i]);
        // if (validFormat.valid) {
        //   const reader = new FileReader();
        //   reader.onloadend = () => {
        //     console.log('file is uploaded');
        //     imgArr = []
        //     setPreview([...preview, reader.result]);
        //   };
        //   reader.readAsDataURL(files[i]);
        // } else {
        //   swal('Oops!', `${validFormat.message}`, 'error');
        // }
      }
      // console.log(imgArr);
    }
  };

  const createWayagramPostCont = async () => {
    // console.log('got here.....');
    // if (placeholderMode === 'poll') {
    //   setShowPollDisclaimer(true);
    // }
    // setLoading(true);
    // hideModal(false);
    // const data = {
    //   description: postData.description,
    //   profile_id: profile.id,
    //   type: 'user',
    //   isPoll: false,
    //   isPaid: false,
    //   forceTerms: false,
    // };
    setLoading(true);
    let isPaid = false;
    let isPoll = false;

    /* eslint-disable no-unused-expressions */
    postData.isPaid ? (isPaid = true) : (isPaid = false);
    postData.isPoll ? (isPoll = true) : (isPoll = false);
    if (postData.isPoll === true && postData.description === '') {
      swal('Error', 'Poll Description cannot be Empty', 'error');
      setLoading(false);

      return;
    }
    if (postData.isPoll === true && postData.voteLimit === '') {
      swal('Error', 'Please input poll vote user limit', 'error');
      setLoading(false);

      return;
    }

    if (postData.isPoll === true && postData?.options.length < 2) {
      swal('Error', 'Please poll options has to be two or more', 'error');
      setLoading(false);

      return;
    }

    const newForm = new FormData();
    /* eslint-disable no-unused-expressions */
    postData.images &&
      Object.values(postData.images).forEach((file) => {
        newForm.append('images', file);
      });
    newForm.append('profile_id', profile.id);
    pageId && newForm.append('page_id', pageId);
    groupId && newForm.append('group_id', groupId);
    newForm.append('type', type);
    postData.description && newForm.append('description', postData.description);
    newForm.append('isPoll', isPoll);
    newForm.append('isPaid', isPaid);
    postData.voteLimit && newForm.append('voteLimit', postData.voteLimit);
    postData.expiresIn &&
      newForm.append(
        'expiresIn',
        convertTimeToMs(
          postData.expiresInDays,
          postData.expiresInHours,
          postData.expiresInMins
        )
      );

    postData.options &&
      Object.values(postData?.options).forEach((option) => {
        newForm.append('options', option);
      });
    newForm.append('forceTerms', false);
    isPaid && newForm.append('amount', postData.amount);
    mentionList.length && newForm.append('mentions', mentionList);
    hashTagList.length && newForm.append('hashtags', hashTagList);

    const res = await httpPost(
      `/all-posts/create`,
      newForm,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setLoading(false);
      hideModal(false);
      setReload(!reload);
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };

  const createWayagramPost = async () => {
    if (postData.isPoll) {
      await swal({
        title: 'Payment Info',
        text: 'All paid votes will be credited into your Rubies Bank (default wallet) at the end of the post duration. A commision of total payment will be paid to Waya.',
        buttons: true,
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          createWayagramPostCont();
        } else {
          setLoading(false);
        }
      });
    } else {
      createWayagramPostCont();
    }
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    handleSubmit(postData);
  };

  const handleChange = (query) => {
    setPostData({ ...postData, description: query });
  };

  console.log(postData);
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-left" toggle={() => hideModal(false)}>
        {mode === 'create' ? 'Create' : 'Edit'}{' '}
        {placeholderMode === 'post'
          ? 'Post'
          : placeholderMode === 'shop'
          ? 'Product'
          : placeholderMode === 'poll'
          ? 'Poll'
          : ''}
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="d-flex">
            <div className="image-div">
              <img src={profile?.avatar || imgPlaceholder} alt="placeholder" />
            </div>
            {/* <textarea
              placeholder={
                placeholderMode === 'post'
                  ? 'Whats on your mind?'
                  : placeholderMode === 'shop'
                  ? 'Describe your product'
                  : 'Ask a question'
              }
              type="text"
              className="form-control"
              value={postData.description}
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
              }}
            /> */}
            <MentionInput
              setText={handleChange}
              text={postData.description}
              setMentionList={setMentionList}
              setHashTagList={setHashTagList}
              placeholder={
                placeholderMode === 'post'
                  ? 'Whats on your mind?'
                  : placeholderMode === 'shop'
                  ? 'Describe your product'
                  : 'Ask a question'
              }
            />
          </div>
          {postMode === 'post' ? (
            <ImagePreviewOnly preview={preview} />
          ) : postMode === 'paidPost' && showPreview ? (
            <PaidPostPreview
              preview={preview}
              setPostData={setPostData}
              postData={postData}
              setShowPreview={setShowPreview}
              setPreview={setPreview}
            />
          ) : (
            ''
          )}
          {showPoll && (
            <PollPreview
              // preview={preview}
              setPostData={setPostData}
              postData={postData}
              setShowPoll={setShowPoll}
              options={postData.options}
              setShowTermsModal={setShowTermsModal}

              // setPreview={setPreview}
            />
          )}
          {showPollDisclaimer && (
            <PollDisclaimer
              showModal={showPollDisclaimer}
              setShowPoll={setShowPoll}
              hideModal={setShowPollDisclaimer}
            />
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <div
          className="mb-3 mt-2"
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              id="imagePost"
              onMouseEnter={() => {
                setTooltype('image');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => {
                  setPostMode('post');
                  handlePreview(e);
                }}
              />
              {/* <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">Check out this avatar</Tooltip>
                }
              > */}
              <img
                src={galleryIcon}
                alt="gallery"
                style={{ width: '1.3em', height: '1.3em' }}
              />
              {tooltype === 'image' && (
                <Tooltip
                  key="image"
                  placement="bootom"
                  isOpen={tooltipOpen}
                  target="imagePost"
                  toggle={handleTooltip}
                >
                  image
                </Tooltip>
              )}
              {/* </OverlayTrigger> */}
            </div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              id="gifPost"
              onMouseEnter={() => {
                setTooltype('gif');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onClick={() => setPostData({ ...postData, isPaid: true })}
                onChange={(e) => {
                  setPostMode('post');
                  handlePreview(e);
                }}
              />
              <img src={gifIcon} alt="gif" />
              {tooltype === 'gif' && (
                <Tooltip
                  key="gif"
                  placement="bootom"
                  isOpen={tooltipOpen}
                  target="gifPost"
                  toggle={handleTooltip}
                >
                  gif
                </Tooltip>
              )}
            </div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              onClick={() => {
                setShowPoll(true);
                setPlaceholderMode('poll');
                setPostData({ ...postData, isPoll: true });
              }}
              id="pollPost"
              onMouseEnter={() => {
                setTooltype('poll');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              {/* <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => {
                  setPostMode('paidPost');
                  setPostData({ ...postData, isPaid: true });
                  handlePreview(e);
                }}
              /> */}
              <img src={postIcon3} alt="poll" />
              {tooltype === 'poll' && (
                <Tooltip
                  key="poll"
                  placement="bootom"
                  isOpen={tooltipOpen}
                  target="pollPost"
                  toggle={handleTooltip}
                >
                  poll
                </Tooltip>
              )}
            </div>
            <div
              className="add-cursor me-3"
              style={{ position: 'relative', display: 'inline-block' }}
              id="paidPost"
              onMouseEnter={() => {
                setTooltype('paid');
              }}
              onMouseLeave={() => {
                setTooltype('');
              }}
            >
              <input
                type="file"
                style={{ width: '20px', opacity: '0', position: 'absolute' }}
                className="image-div-input"
                accept="image/*"
                multiple="multiple"
                onChange={(e) => {
                  setPostMode('paidPost');
                  setPostData({ ...postData, isPaid: true });
                  handlePreview(e);
                  setShowPreview(true);
                  setPlaceholderMode('shop');
                }}
              />
              <img
                src={paidPostIcon}
                alt="gallery"
                data-bs-toggle="tooltip"
                title="Disabled tooltip"
              />
              {tooltype === 'paid' && (
                <Tooltip
                  key="paid"
                  placement="bootom"
                  isOpen={tooltipOpen}
                  target="paidPost"
                  toggle={handleTooltip}
                >
                  Paid Post
                </Tooltip>
              )}
            </div>
          </div>
          <div
            className="add-cursor"
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <input
              type="file"
              style={{ width: '20px', opacity: '0', position: 'absolute' }}
              className="image-div-input"
              accept="image/*"
              multiple="multiple"
              onChange={(e) => {
                setPostData({ ...postData, isPaid: true });
                setPostMode('paidPost');
                handlePreview(e);
              }}
            />
            <img src={cameraIcon} alt="camera" />
          </div>
        </div>
        <Button
          loading={loading}
          disabled={loading || false}
          className="btn btn-primary mb-3 mt-2"
          color="primary"
          content="Send"
          onClick={mode === 'create' ? createWayagramPost : handleSubmitEdit}
        >
          Next
        </Button>
      </ModalFooter>
      {showTermsModal ? (
        <TermsAndCondition
          showModal={showTermsModal}
          hideModal={setShowTermsModal}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

CreatePost.defaultProps = {
  id: '',
  mode: 'create',
  selectedData: {},
};

CreatePost.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  mode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  selectedData: PropTypes.node,
};

export default CreatePost;
