import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { Modal, ModalBody } from 'reactstrap';
import leftArrow from '../../../../assets/image/left-arrow.png';
import plusIcon from '../../../../assets/image/plus-circle.png';
import wayaIcon from '../../../../assets/image/wayaIcon.png';
import './index.scss';

export default function AccountList(props) {
  const { showModal, hideModal, walletList, setShowCreateWallet } = props;
  return (
    <div className="bank-list">
      <Modal
        isOpen={showModal}
        id="manage-bank-list-modal"
        toggle={() => hideModal(false)}
        centered
      >
        <div className="header">
          <div className="row">
            <div className="col-3 h-left">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal(false);
                }}
              >
                <img src={leftArrow} alt="left" />
              </a>
            </div>
            <div className="col-6 h-center text-center">Manage Account</div>
            <div className="col-3 h-right text-center">
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowCreateWallet(true);
                  hideModal(false);
                }}
              >
                <img src={plusIcon} alt="plus" />
              </a>
            </div>
          </div>
        </div>
        <ModalBody>
          {walletList.length
            ? walletList.map((item) => {
                return (
                  <Collapsible
                    trigger={
                      <div className="wallet-list-div">
                        <div className="row">
                          <div className="col-2 img-div my-auto">
                            <img src={wayaIcon} alt="visa" />
                          </div>
                          <div className="col-9 pt-2">
                            <p className="mb-0">{`${item?.accountNo}`}</p>
                            <p className="mb-0">{`${item?.acct_crncy_code}`}</p>
                          </div>
                        </div>
                      </div>
                    }
                    key={Math.random()}
                  >
                    <div className="col-12 account-details">
                      <div className="row">
                        <div className="col-6">Issuer bank</div>
                        <div className="col-6 text-end">
                          {item?.savingsProductName}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">Bank name</div>
                        <div className="col-6 text-end">{item?.clientName}</div>
                      </div>
                    </div>
                  </Collapsible>
                );
              })
            : ''}
        </ModalBody>
      </Modal>
    </div>
  );
}

AccountList.defaultProps = {
  showModal: false,
  // walletList: [],
};

AccountList.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  // walletList: PropTypes.arrayOf(PropTypes.object),
  setShowCreateWallet: PropTypes.func.isRequired,
};
