import React, { useContext, useState } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import swal from 'sweetalert';
import { customBaseUrl, httpPut, httpGet } from '../../../../action/http';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { hideLoader } from '../../../../utils/loader';
import VerifyOtp from '../Otp/VerifyOtp';
import PinModal from '../PinModal';
import { ModalContext } from '../../../../store/context/ModalContext';

export default function RequestPayment(props) {
  const { hideModal } = props;
  const { userDetails } = useContext(ProfileContext);
  const { setReload, reload } = useContext(WalletContext);
  const { setModalAction } = useContext(ModalContext);
  const [referenceCode, setReferenceCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);

  const handleRedeemTransfer = async () => {
    setLoading(true);

    if (!otp || otp === '') {
      swal('Oops!', 'Otp cannot be empty', 'error');
      hideLoader(false);
      return;
    }

    const res = await httpPut(
      `/withdrawal/non-waya/transaction/redeem/PIN`,
      {
        tokenPIN: otp,
        beneficiaryUserId: userDetails.userId,
      },
      customBaseUrl.withdrawalUrl,
      pin
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success').then(() => {
        setReload(!reload);
        setLoading(false);
        setShowVerifyOtp(false);
        setPin('');
        hideModal(false);
      });
    } else {
      setPin('');
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const sendVerificationPin = async () => {
    setLoading(true);

    if (!referenceCode || referenceCode === '') {
      setLoading(false);
      swal('Wrong!', 'Retrieval code is required', 'error');
      return;
    }

    const profileId = localStorage.getItem('profileId');
    const postData = {
      beneficiaryProfileId: profileId,
      beneficiaryUserId: userDetails.userId,
      receiverName: `${userDetails.firstName} ${userDetails?.surname}`,
      statusAction: 'ACCEPTED',
      token: referenceCode,
    };

    const res = await httpPut(
      `/api/v1/wallet/non-waya/transaction/redeem/new`,
      postData,
      customBaseUrl.walletUrl,
      pin
    );
    if (res.status) {
      setLoading(false);
      swal('Done', `${res.message}`, 'success');
      setShowVerifyOtp(true);
    } else {
      if (res.message === 'UNABLE TO CONFIRMED TRANSACTION WITH PIN SENT') {
        setShowVerifyOtp(true);
      }
      setPin('');
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };
  const handleVerifyPin = async () => {
    setLoading(true);
    if (!pin || pin === '') {
      setLoading(false);
      swal('Oops!', 'Otp is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${pin}`
    );
    if (res.status) {
      setLoading(false);
      setShowPin(false);
      sendVerificationPin();
    } else {
      setLoading(false);
      setPin('');
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  return (
    <>
      <ModalBody>
        <div className="text-center mt-3 mb-3" style={{ fontSize: '12px' }}>
          Please enter the 10 digit payment code sent to you
        </div>
        <form className="mx-auto">
          {/* <div className="inputbox-with-one-input">
            <input
              placeholder="Enter amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <span className="input-detail">Amount expecting</span>
          </div> */}
          <div className="inputbox-with-one-input">
            <input
              placeholder="Enter the 10 digit PIN"
              type="number"
              value={referenceCode}
              onChange={(e) => setReferenceCode(e.target.value)}
            />
            <span className="input-detail">10 digit code sent to you</span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-primary mx-auto mb-3 mt-3"
          color="primary"
          disabled={loading || false}
          loading={loading}
          onClick={() => {
            setShowPin(true);
            //  sendVerificationPin();
          }}
        >
          Retrieve Money
        </Button>
      </ModalFooter>
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Otp Confirmation"
          description="Please input the Otp sent to your email address or phone number"
          isResendOtp={false}
          buttonLabel="Submit"
          handleSubmit={handleRedeemTransfer}
          loading={loading}
          numInputs={6}
        />
      ) : (
        ''
      )}
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={pin}
          setOtp={setPin}
          loading={loading}
          isResendPin={false}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleVerifyPin}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
    </>
  );
}
