import { selector, useSetRecoilState, selectorFamily } from 'recoil';
import swal from 'sweetalert';
import validator from 'validator';
import { customBaseUrl, httpGet, httpPost } from '../action/http';
import {
  userState,
  profileState,
  myBankAccountListState,
  forceTodoUpdate,
} from './initialState';

export const profileDetailState = selector({
  key: 'profileDetailState',
  get: async ({ get }) => {
    if (localStorage.getItem('token')) {
      // const setProfile = useSetRecoilState(profileState);
      get(forceTodoUpdate);
      const persistUser = localStorage.getItem('userId');
      const res = await httpGet(
        `/profile-service/profile/${Number(persistUser)}`
      );
      if (res.status) {
        // setProfile(res.data);
        return res.data;
      }
      return {};
    }
    return {};
  },
});

export const getWayagramProfile = selectorFamily({
  key: 'getWayagramProfile',
  get:
    (userId) =>
    async ({ get }) => {
      if (localStorage.getItem('token')) {
        // const setProfile = useSetRecoilState(wayagramProfileState);
        get(forceTodoUpdate);
        const persistUser = localStorage.getItem('userId');
        const res = await httpGet(
          `${customBaseUrl.wayagramUrl}/get-by-user-id?user_id=${
            userId || Number(persistUser)
          }`
        );
        if (res.status) {
          // setProfile(res.data);
          console.log(res.data);
          return res.data;
        }
        return {};
      }
      return {};
    },
});

export const getWalletList = selector({
  key: 'getWalletList',
  get: async ({ get }) => {
    const persistUser = localStorage.getItem('userId');
    get(forceTodoUpdate);
    const res = await httpGet(
      // `/wallet/accounts/${Number(persistUser)}`,
      // 'http://157.230.223.54:9009'
      // '/api/v1/wallets',
      // 'http://46.101.41.187:9196'
      `${customBaseUrl.walletUrl}/wallet/find/by/userId/${persistUser}`
    );
    return res || [];
    // if (res.status) {
    //   return res.data;
    // }
    // return [];
  },
});

export const getWalletDetails = selectorFamily({
  key: 'getWalletDetails',
  get:
    (accountNo) =>
    async ({ get }) => {
      // const persistUser = localStorage.getItem('userId'); 7
      get(forceTodoUpdate);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallets/${accountNo}`
      );
      if (res.status) {
        return res;
      }
      return {};
    },
});

export const getBankList = selector({
  key: 'getBankList',
  get: async () => {
    const res = await httpGet(`/bankAccount/getBanks`, customBaseUrl.cardUrl);
    if (res.status) {
      return res.data;
    }
    return [];
  },
});

export const getProfileDetails = async (id) => {
  console.log('dancing');
  const setProfile = useSetRecoilState(profileState);
  const res = await httpGet(`/profile-service/profile/${id}`);
  if (res.status) {
    setProfile(res.data);
  }
};

// Get Added bank account
export const getBankAccountListFxn = async () => {
  // console.log('##', id);
  const res = await httpGet(`${customBaseUrl.cardUrl}/bankAccount/list`);
  if (res.status) {
    return res.data;
  }
  return [];
};

// Get Added card
export const getCardListFxn = async () => {
  // console.log('##', id);
  const res = await httpGet(`${customBaseUrl.cardUrl}/card/list`);
  if (res.status) {
    return res.data;
  }
  return [];
};

export const getBankAccountList = selector({
  key: 'getBankAccountList',
  get: async ({ get }) => {
    const setBankAccount = useSetRecoilState(myBankAccountListState);
    const user = get(profileState);
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/list/${user.userId}`
    );
    if (res.status) {
      setBankAccount(res.data);
      return res.data;
    }
    return [];
  },
});

// Get billers categories
export const getBillerCategories = selectorFamily({
  key: 'getBillerCategories',
  get: (category) => async () => {
    const res = await httpGet(
      `/category/${category}`,
      'http://157.230.223.54:8181/billspay/api/v1',
      // 'http://47e0f5846df5.ngrok.io/billspay/api/v1',
      true
    );
    if (res.status) {
      // console.log(res.data);
      return res.data;
    }
    return [];
  },
});

// gets wayagram feeds
export const getWayagramFeeds = selectorFamily({
  key: 'getWayagramFeeds',
  get:
    (profileId) =>
    async ({ get }) => {
      get(forceTodoUpdate);
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/post/feed?profile_id=${profileId}`
      );
      if (res.status) {
        // console.log(res);
        return res.data;
      }
      return [];
    },
});

export const getReferralCode = selector({
  key: 'getReferralCode',
  get: async ({ get }) => {
    if (localStorage.getItem('token')) {
      const persistUser = localStorage.getItem('userId');
      const user = get(userState);
      const res = await httpGet(
        `${customBaseUrl.authUrl}/profile-service/referral-code/${
          user ? user.id : Number(persistUser)
        }`
      );
      if (res.status) {
        return res.data;
      }
      return {};
    }
    return {};
  },
});

// Search users
export const searchUsers = async (query) => {
  // console.log('##', query);
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/search/search-profile-name/${query}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};

// Get USSD Code
export const getUssdCode = selector({
  key: 'getUssdCode',
  get: async () => {
    // const res = await httpGet(`${customBaseUrl.ussdUrl}/api/shortCode`);
    // if (res.status) {
    //   return res.data;
    // }
    // return '';
  },
});

export const sendResetPinOtpViaEmail = async (type, email, redirectUrl) => {
  let res;
  if (type === 'createPin') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/create-pin/byEmail?email=${email}&redirectUrl=WAYABANK`
    );
  }
  if (type === 'resetPin') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/forgot-pin/byEmail?email=${email}&redirectUrl=${redirectUrl}`
    );
  }
  if (type === 'changePassword') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail?email=${email}&redirectUrl=${redirectUrl}`
    );
  }

  if (res.status) {
    swal('Done', `Otp has been sent to ${email}`, 'success');
    return true;
  }
  swal('Oops!', res.message, 'error');
  return false;
};

export const sendResetPinOtpViaPhone = async (type, phone) => {
  let res;

  if (type === 'changePassword') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byPhone?phoneNumber=${phone}`
    );
  }

  if (type === 'createPin') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/create-pin/byPhone?phoneNumber=${phone}`
    );
  }

  if (type === 'resetPin') {
    res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/forgot-pin/byPhone?phoneNumber=${phone}`
    );
  }

  if (res.status) {
    swal('Done', `Otp has been sent to ${phone}`, 'success');
    return true;
  }
  swal('Oops!', res.message, 'error');
  return false;
};

export const getBusinessTypes = async () => {
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/business/type/find/all`
  );
  // console.log(res);
  return res;
};

// export const getSentPaymentRequest = async (userId) => {
//   // console.log('##', query);
//   const res = await httpGet(
//     `/payment-request-service/payment-request-sent/${userId}?page=0`,
//     'http://157.230.223.54:9300'
//   );
//   if (res.status) {
//     return res.data;
//   }
//   return {};
// };

// export const getReceivedPaymentRequest = async (userId) => {
//   // console.log('##', query);
//   const res = await httpGet(
//     `/payment-request-service/payment-request-received/${userId}?page=0`,
//     'http://157.230.223.54:9300'
//   );
//   if (res.status) {
//     return res.data;
//   }
//   return {};
// };

// Get Sent Payment Request
export const getSentPaymentRequest = selectorFamily({
  key: 'getSentPaymentRequest',
  get:
    (userId) =>
    async ({ get }) => {
      get(forceTodoUpdate);
      const res = await httpGet(
        `${customBaseUrl.paymentRequestUrl}/payment-request-sent/${userId}?page=0`
      );
      if (res.status) {
        // console.log(res);
        return res.data;
      }
      return {};
    },
});

// Get Received Payment Request
export const getReceivedPaymentRequest = selectorFamily({
  key: 'getReceivedPaymentRequest',
  get:
    (userId) =>
    async ({ get }) => {
      get(forceTodoUpdate);
      const res = await httpGet(
        `${customBaseUrl.paymentRequestUrl}/payment-request-received/${userId}?page=0`
      );
      if (res.status) {
        // console.log(res);
        return res.data;
      }
      return {};
    },
});

// Set default wallet
export const handleSetWalletDefault = async (accountNo) => {
  const userId = localStorage.getItem('userId');
  const profileId = localStorage.getItem('profileId');
  const data = {
    newDefaultAcctNo: accountNo,
    userId,
    profileId,
  };
  const res = await httpPost(
    `/api/v1/wallet/user/account/toggle`,
    data,
    customBaseUrl.walletUrl
  );
  if (res.status) {
    swal('Done', `Default wallet set successful`, 'success');
  } else {
    swal('Oops!', res.message, 'error');
  }
};

// Get Wayagram categories
export const getCategories = selector({
  key: 'getCategories',
  get: async () => {
    const res = await httpGet(
      `/get-all-page-categories?pageNumber=1`,
      'http://157.245.84.14:6002'
    );
    if (res.status) {
      return res.data;
    }
    return [];
  },
});

// Get All Groups, Pages etc
export const getDiscoverData = async (type, pageNumber, searchVal, userId) => {
  if (validator.isEmpty(searchVal || '')) {
    return [];
  }
  let res;
  if (type === 'Groups') {
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/group/get-all-groups?pageNumber=${
        pageNumber || 1
      }
        &userId=${userId}`
    );
  }
  if (type === 'Pages') {
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/page/search-pages?query=${
        searchVal || 'a'
      }
        &pageNumber=${pageNumber || 1}&userId=${userId}`
    );
  }

  if (type === 'Posts') {
    console.log('//', searchVal);
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/search?query=${searchVal || 'a'}`
    );
  }

  if (type === 'People') {
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/search?query=${
        searchVal || 'a'
      }&profile_id=${userId}`
    );
  }

  if (type === 'People') {
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/search?query=${
        searchVal || 'a'
      }&profile_id=${userId}`
    );
  }

  if (type === 'Trending') {
    res = await httpGet(
      `${customBaseUrl.wayagramUrl}/all-posts/get-trending-hashtags?profile_id=${userId}`
    );
  }

  if (res?.status) {
    return res.data;
  }
  return [];
};

// Search groups
export const searchGroups = async (query, pageNumber, profileId) => {
  // console.log('##', query);
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/main/group/search-groups?query=${query}&pageNumber=${pageNumber}&userId=${profileId}`
  );
  if (res?.status) {
    return res?.data;
  }
  return [];
};

// Search groups
export const searchPages = async (query, pageNumber, profileId) => {
  const res = await httpGet(
    `${
      customBaseUrl.wayagramUrl
    }/main/page/search-pages?query=${query}&pageNumber=${
      pageNumber || 1
    }&userId=${profileId}`
  );
  if (res?.status) {
    return res.data;
  }
  return [];
};

export const getTrendingTags = async (profileId) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/get-trending-hashtags?profile_id=${profileId}`
  );
  if (res?.status) {
    return res.data.data;
  }
  return [];
};

export const searchTrendingTags = async (query, sort) => {
  const res = await httpGet(
    `${customBaseUrl.wayagramUrl}/all-posts/search-hashtags?query=${query}&sort=${sort}`
  );
  if (res && res.status) {
    return res.data;
  }
  return [];
};

// Search groups
export const searchWayagramPeople = async (query, profileId) => {
  if (!validator?.isEmpty(query || '')) {
    const res = await httpGet(
      `${customBaseUrl.wayagramUrl}/main/profile/search?query=${
        query || 'a'
      }&profile_id=${profileId}`
    );
    if (res?.status) {
      return res.data;
    }
    return [];
  }
  return [];
};

// get last login info
export const getLastLoginInfo = async () => {
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/history/my-last-login`
  );
  if (res && res.status) {
    return res.data;
  }
  return {};
};

// is bvn linked
export const getIsBvnLinked = async () => {
  const userId = localStorage.getItem('userId');
  const res = await httpGet(
    `${customBaseUrl.kycUrl}/kyc/isBvnLinked/${userId}`
  );
  if (res?.status) {
    return res.data;
  }
  return false;
};

// creates post
// export const createWayagramPost = async (data) => {
//   const res = await httpPost(`/post/create`, data, 'http://157.245.84.14:1000');
//   if (res.status) {
//     return res.data;
//   }
//   return res;
// };
