import styled from 'styled-components';

export const MomentWrapper = styled.div`
  border: none;
  margin-bottom: 40px;
  .moment-header {
    margin: 18px 0;
    width: 100%;
  }
  .carousel-root {
    padding: 0 !important;
  }
`;

export const moment = styled.div``;

export const UploadPostButton = styled.div`
  position: fixed;
  bottom: 30px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.15px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.orange};
  }
  background: #ff6634;
  right: 25px;
  color: white;
  @media (max-width: 768px) {
    bottom: 75px;
  }
`;
