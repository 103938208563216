import React, { useState, useContext, useEffect } from 'react';
import swal from 'sweetalert';
import Layout from '../Layout';
import 'react-multi-carousel/lib/styles.css';
import {
  httpDelete,
  httpPost,
  httpPut,
  httpGet,
  customBaseUrl,
} from '../../../action/http';
import CommentCard from './CommentPostCard';
import RightSidebar from '../../pages/Wayagram/RightSidebar';
import PostModal from '../Modals/Post';
import CreatePostModal from '../Modals/Post/CreatePost';
import CreateComment from '../Modals/Post/CreateComment';
import ChildComment from './CommendCard';
import { WayagramContext } from '../../../store/context/WayagramContext';
import Loader from '../Loader';

export default function Index(props) {
  const [feeds, setFeeds] = useState([]);
  const [post, setPost] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showCreateComment, setShowCreateComment] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { wayagramProfile: profile } = useContext(WayagramContext);
  const [comments, setComments] = useState([]);

  console.log(profile, 'COMMENTS.....COMMENTS');

  const handleGetComments = async (Id) => {
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/post-comments?post_id=${Id}`
      );
      if (res.status) {
        setComments(res.data.data);
      }
      return {};
    } catch (error) {
      return error.message;
    }
  };

  const getPostFeed = async () => {
    const fetchId = window.location.pathname;
    const result = fetchId.substring(fetchId.lastIndexOf('/') + 1);
    handleGetComments(result);
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/single-post?post_id=${result}`
      );
      if (res.status) {
        setPost(res.data);
      }
      return {};
    } catch (error) {
      return error.message;
    }
  };

  useEffect(() => {
    getPostFeed();
  }, []);

  // useEffect(() => {
  //   // eslint-disable-next-line no-use-before-define
  //   handleGetComments(result);
  // }, [showCreateComment]);

  const handleShowPostModal = async (data) => {
    console.log('show edit post modal called....');
    // setShowCreatePost(true);
    setSelectedData(data);
  };

  const handleDeletePost = async (postId) => {
    const data = {
      post_id: postId,
    };
    const res = await httpDelete(
      `/post/delete`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      setFeeds(feeds.filter((item) => item.id !== postId));
    }
    return {};
  };

  const handleEditPost = async (data) => {
    const postData = {
      description: data.description,
      profile_id: profile.id,
      post_id: data.postId,
      // type: 'user',
      isPoll: false,
      isPaid: false,
      forceTerms: false,
    };

    const res = await httpPut(
      `/post/update`,
      postData,
      customBaseUrl.wayagramUrl
    );
    if (!res.status) {
      swal('Done', res.message, 'success').then(() => {
        setShowCreatePost(false);
      });
    } else {
      swal('Oops!', res.message, 'error');
    }
  };

  const handleLikePost = async (postId) => {
    const data = {
      post_id: postId,
      profile_id: profile.id,
      type: 'user',
    };
    const res = await httpPost('/post', data, 'http://157.245.84.14:4200');
    if (res.status) {
      swal('Done', res.message, 'success');
    }
  };

  return (
    <Layout route={props} currentPage="wayagram">
      <div className="row wayagram-div">
        <div className="col-8">
          <div className="">
            {Object.keys(post).length ? (
              <CommentCard
                key={Math.random()}
                data={post}
                handleComment={setShowCreateComment}
                handleDeletePost={handleDeletePost}
                showPostModal={handleShowPostModal}
                handleLikePost={handleLikePost}
              />
            ) : (
              <Loader />
            )}
          </div>
          {comments.length
            ? comments.map((item) => (
                <ChildComment
                  key={Math.random()}
                  data={item}
                  handleComment={setShowCreateComment}
                  comment={item.comment}
                />
              ))
            : ''}
        </div>
        <RightSidebar />
      </div>
      {showCreateComment ? (
        <CreateComment
          showModal={showCreateComment}
          hideModal={setShowCreateComment}
          mode="create"
          post={post}
          profile={profile}
        />
      ) : (
        ''
      )}
      {setShowPostModal ? (
        <PostModal showModal={showPostModal} hideModal={setShowPostModal} />
      ) : (
        ''
      )}
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="edit"
          handleSubmit={handleEditPost}
          selectedData={selectedData}
          type="user"
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
