import React from 'react';
import PropTypes from 'prop-types';
import { ReceiveIcon, SendMoneyIcon } from '../../shared/Icons';

export default function TopTabs(props) {
  const {
    currentTab,
    setCurrentTab,
    setShowTransfer,
    setShowReceive,
    // setShowWithdraw,
    setShowRequest,
    // setCommingSoon,
  } = props;
  return (
    <div className="miditems-top-tabs py-5">
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={
          currentTab === 'transfer' ? 'mid-items-tab active' : 'mid-items-tab'
        }
        onClick={() => {
          setCurrentTab('transfer');
          setShowTransfer(true);
        }}
      >
        <SendMoneyIcon active={currentTab === 'transfer' || false} />
        <span>Transfer</span>
      </div>
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={
          currentTab === 'receive' ? 'mid-items-tab active' : 'mid-items-tab'
        }
        onClick={() => {
          setCurrentTab('receive');
          setShowReceive(true);
        }}
      >
        <ReceiveIcon active={currentTab === 'receive' || false} />
        <span>Receive</span>
      </div>
      {/* <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={
          currentTab === 'withdraw' ? 'mid-items-tab active' : 'mid-items-tab'
        }
        onClick={() => {
          setCurrentTab('withdraw');
          setShowWithdraw(true);
        }}
      >
        <SendMoneyIcon active={currentTab === 'withdraw' || false} />
        <span>Transfer to Other Banks</span>
      </div> */}
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={
          currentTab === 'request' ? 'mid-items-tab active' : 'mid-items-tab'
        }
        onClick={() => {
          setCurrentTab('request');
          setShowRequest(true);
          // setCommingSoon(true);
        }}
      >
        <ReceiveIcon active={currentTab === 'request' || false} />
        <span>Request</span>
      </div>
    </div>
  );
}

TopTabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  // setShowWithdraw: PropTypes.func.isRequired,
  // setShowRequest: PropTypes.func.isRequired,
  setShowTransfer: PropTypes.func.isRequired,
  setShowReceive: PropTypes.func.isRequired,
};
